import { BASE_URL } from "./helper";

export const sidebarContentScheduledByOthers = [
  {
    title: "My Games", redirectLink: "/my-games/scheduled-by-others", subTitles: [
      { title: "Scheduled By Others", redirectLink: "/my-games/scheduled-by-others" },
      { title: "Scheduled By Me", redirectLink: "/my-games/scheduled-by-me" },
    ],
    subTitleActive: "Scheduled By Others"
  },
  { title: "My Favourites", redirectLink: "/my-games/my-favourites" },
  // { title: "Poll Details", redirectLink: "/my-games/poll-details" },
  { title: "My Reports", redirectLink: "/my-games/my-reports" },
  { title: "Download Brochure", redirectLink: BASE_URL + "/api/org-gamedata/downloadBrochure" }
];
export const sidebarContentScheduledByMe = [
  {
    title: "My Games", redirectLink: "/my-games/scheduled-by-others", subTitles: [
      { title: "Scheduled By Others", redirectLink: "/my-games/scheduled-by-others" },
      { title: "Scheduled By Me", redirectLink: "/my-games/scheduled-by-me" },
    ],
    subTitleActive: "Scheduled By Me"
  },
  { title: "My Favourites", redirectLink: "/my-games/my-favourites" },
  // { title: "Poll Details", redirectLink: "/my-games/poll-details" },
  { title: "My Reports", redirectLink: "/my-games/my-reports" },
  { title: "Download Brochure", redirectLink: BASE_URL + "/api/org-gamedata/downloadBrochure" }

];
export const sideBarContentMyFavourites = [
  {
    title: "My Games", redirectLink: "/my-games/scheduled-by-others"
  },
  { title: "My Favourites", redirectLink: "/my-games/my-favourites" },
  // { title: "Poll Details", redirectLink: "/my-games/poll-details" },
  { title: "My Reports", redirectLink: "/my-games/my-reports" },
  { title: "Download Brochure", redirectLink: BASE_URL + "/api/org-gamedata/downloadBrochure" }
];

