import React, { useState } from "react";
import "./modal.css";
import Modal from "./modal";
import arrowback from "../../assets/images/arrow-left.svg";
import { useDispatch, useSelector } from "react-redux";

import { offlinePaymentCheckout } from "../../redux/actions/paymentApiActions";
import { axiosApiInstance, BASE_URL, RAZOR_PAY_API_KEY, Frontned_URL } from "../../helpers/helper";
import { getUser, signin } from "../../redux/actions/userAction";
import { ToastContainer } from 'react-toastify';
import LoadingComponent from "../loader/LoadingComponent";
import axios from "axios";
const EnterprisePlanModal = ({
  modalid,
  toggle,
  setOpenEnterprisePlanModal,
  closeMeesageModal
}) => {


  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [loaded, setLoaded] = useState(true);

  const GetUser = useSelector(state => state.getUser);
  const { userInfo } = GetUser;

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    })
  }



  const handleChange = (e) => {
    const { value } = e.target;
    setPaymentMethod(value);
  };

  return (
    <Modal modalid={modalid} toggle={toggle}>
      <LoadingComponent loaded={loaded} />
      <ToastContainer position="bottom-center" />
      <div className="modal-body payment-wrapper">
        <div className="close-icon" data-dismiss="modal" aria-label="Close" onClick={() => closeMeesageModal(false)}>
          <div
            className="close-btn-icon"
          ></div>
        </div>
        <div className="contact-modal ">
          <div className="plan-content">
            <div className="text-container">
              <h1 className="titlespan mb-2">
                Enterprise<div className="mb-0 highlightDiv">&nbsp;Plan</div>
              </h1>
              <p className="description">Managing larger teams? Reach out to our sales team to unlock the Enterprise Plan!</p>
            </div>
            <button className="button-right" onClick={() => setOpenEnterprisePlanModal(false)}>Contact Sales</button>
          </div>
       </div>
      </div>
    </Modal>
  );
};

export default EnterprisePlanModal;
