export const REPORT_GET_OVERALL_REQUEST="REPORT_GET_OVERALL_REQUEST"
export const REPORT_GET_OVERALL_SUCCESS="REPORT_GET_OVERALL_SUCCESS"
export const REPORT_GET_OVERALL_FAIL="REPORT_GET_OVERALL_FAIL"

export const REPORT_FOR_GAME_REQUEST="REPORT_FOR_GAME_REQUEST"
export const REPORT_FOR_GAME_SUCCESS="REPORT_FOR_GAME_SUCCESS"
export const REPORT_FOR_GAME_FAIL="REPORT_FOR_GAME_FAIL"

export const REPORT_DOWNLOAD_OVERALL_REQUEST="REPORT_DOWNLOAD_OVERALL_REQUEST"
export const REPORT_DOWNLOAD_OVERALL_SUCCESS="REPORT_DOWNLOAD_OVERALL_SUCCESS"
export const REPORT_DOWNLOAD_OVERALL_FAIL="REPORT_DOWNLOAD_OVERALL_FAIL"

export const REPORT_DOWNLOAD_FOR_GAME_REQUEST="REPORT_DOWNLOAD_FOR_GAME_REQUEST"
export const REPORT_DOWNLOAD_FOR_GAME_SUCCESS="REPORT_DOWNLOAD_FOR_GAME_SUCCESS"
export const REPORT_DOWNLOAD_FOR_GAME_FAIL="REPORT_DOWNLOAD_FOR_GAME_FAIL"

export const REVIEW_GET_BY_GAME_REQUEST="REVIEW_GET_BY_GAME_REQUEST";
export const REVIEW_GET_BY_GAME_SUCCESS="REVIEW_GET_BY_GAME_SUCCESS";
export const REVIEW_GET_BY_GAME_FAIL="REVIEW_GET_BY_GAME_FAIL";

export const REPORT_DOWNLOAD_FOR_GUEST_GAME_REQUEST="REPORT_DOWNLOAD_FOR_GUEST_GAME_REQUEST"
export const REPORT_DOWNLOAD_FOR_GUEST_GAME_SUCCESS="REPORT_DOWNLOAD_FOR_GUEST_GAME_SUCCESS"
export const REPORT_DOWNLOAD_FOR_GUEST_GAME_FAIL="REPORT_DOWNLOAD_FOR_GUEST_GAME_FAIL"