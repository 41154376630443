import React, { useEffect, useLayoutEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "./index.css";

import PageLayout from "../../components/pagelayout/pagelayout";
import ReportGameCard from "../../components/overviewgraph/reportgamecard";
import LeaderBoard from "../../components/leaderboard/leaderboard";
import ReviewTab from "../../components/reviewtab/reviewtab";
// imagge
import download from "../../assets/images/download.svg";
import ArrowBack from "../../assets/images/arrow-back.svg";
import { useDispatch, useSelector } from "react-redux";
import {
    downloadGameReport,
    getGameReport,
    getGameReview,
} from "../../redux/actions/reportsActions";
import { sideBarContentMyFavourites } from "../../helpers/sidebarContentMyGames";
import { getGameDetail } from "../../redux/actions/gameDetailAction";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { axiosApiInstance, BASE_URL, decryptData, encryptData, failureAlert, successAlert } from "../../helpers/helper";
import axios from "axios";
import ROLES from "../../helpers/userTypes";
import { ToastContainer } from "react-toastify";
import TeamLeaderBoard from "../../components/leaderboard/teamLeaderBoard";


const TeamUserReports = (props) => {
    const [loaded, setLoaded] = useState(true);

    const { userInfo } = useSelector(state => state.getUser);


    const [userEmail, setUserEmail] = useState("");

    const [overAllTeamReport, setOverAllTeamReport] = useState([]);
    const [teamMembersInfo, setTeamMembersInfo] = useState([])
    let teamId = "";
    teamId = decryptData(sessionStorage.getItem("teamReportId") || encryptData(""));

    const dispatch = useDispatch();
    const history = props.history;
    let sessionId = "";
    if (
        history &&
        history.location &&
        history.location.pathname &&
        history.location.pathname.split("/").length > 2
    ) {
        sessionId = history.location.pathname.split("/")[2];
    }
 


    useEffect(() => {
        const callBack = async()=>{
            const reportData = await axiosApiInstance.post(BASE_URL+"/api/organization-team/team-sessions/reports");
            // console.log("reportData: " + JSON.stringify(reportData.data.teamsScore[0].title));

            if (reportData && reportData.data.message === "team session report") {
                setOverAllTeamReport(reportData.data.teamsScore);
            }
        }
        if (teamId && teamId !== '') {
            callBack()
        }
    }, [teamId])

    useEffect(() => {
        if (teamId && overAllTeamReport && overAllTeamReport.length > 0) {
            let getTeam = overAllTeamReport.filter((team) => team.teamId === teamId);
            // console.log("------------>>>");
            // console.log("getTeam: " + JSON.stringify(getTeam));
            setTeamMembersInfo(getTeam)


        }

    }, [overAllTeamReport])

    
    useEffect(() => {

        if (userInfo?.data?.role === ROLES.ORG_SUPER_ADMIN) {
            setUserEmail(userInfo.data.email)
        }
    }, [userInfo, userEmail])


    //   const handalIdData = () => {
    //     const idData = {
    //       sessionId: sessionId,
    //       organizationId: organizationId,
    //       gameId: gameId,
    //       ownerMail: userEmail
    //     }
    //     if (organizationId && sessionId && gameId) {
    //       axios.post(BASE_URL + "/api/org-gamedata/create-zip", idData)
    //         .then((response) => {
    //           if (response.data.message === "Game data file link sent to your mail") {
    //             console.log('Game data file link sent to your mail');
    //             successAlert("Zip download link has been sent to yor mail");
    //           }
    //           else {
    //             failureAlert("failed to send mail with link to dowload game data zip")
    //           }
    //         })
    //     }
    //   }

    return (
        <div className="game-report">
            <PageLayout
                activeReport
                {...props}
                sideBarContents={sideBarContentMyFavourites}
                active={"My Reports"}
            >
                <LoadingComponent loaded={loaded} >
                    <ToastContainer position='top-center' />
                    <div className="report-section">
                        <h5>
                            <img
                                src={ArrowBack}
                                onClick={() => props.history.goBack()}
                                alt="back"
                            />
                            {/* {gameDetail && gameDetail.data && gameDetail.data.title} */}
                            {teamMembersInfo && teamMembersInfo[0]?.title ? teamMembersInfo[0].title : 'team'}
                        </h5>

                    </div>
                    {/* <ReportGameCard /> */}
                    {/* desktop show and mobile hide */}
                    <div className="c-white-card mhide">
                        <Tabs className="cg-tabs reports-tab">
                            <TabList>
                                <Tab>Leaderboard</Tab>
                            </TabList>
                            <TabPanel>
                                {/* <LeaderBoard history={history} /> */}
                                <TeamLeaderBoard history={history} teamData={teamMembersInfo && teamMembersInfo[0]?.members ? teamMembersInfo[0].members : []} />
                            </TabPanel>

                        </Tabs>
                    </div>
                    {/* mobile show and desktop hide */}
                    <div className="mshow">
                        <div className="responsive-show-data">
                            <div className="gamereport-title mb0 mshow">
                                <h5>Leaderboard</h5>
                            </div>
                            <LeaderBoard history={history} />
                        </div>

                    </div>
                </LoadingComponent>
            </PageLayout>
        </div>
    );
};

export default TeamUserReports;
