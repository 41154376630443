import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useMediaQuery } from 'react-responsive';

import ExtraMilePlay from '../assets/images/ExtramilPlay.png';
import nextBtn from '../assets/DDDT/Next Button Yellow.png';
import { BASE_URL, S3_BASE_URL, axiosApiInstance, decryptData, encryptData } from '../helpers/helper';
import { getDiwaliSettings, getWordsListArr } from '../redux/actions/diwaliGameApiActions';
import { getUser } from '../redux/actions/userAction';
import './dhoom.css';
import TicketBoard from './ticketBoard';
import WordBoard from './wordBoard';
import axios from 'axios';
import dhoomDhaamDiwali from '../assets/images/dhoomDhaamDiwali.png'
import ROLES from '../helpers/userTypes';
import { io } from 'socket.io-client';
import ChatBox from './chatBox';
import refreshLogo from '../assets/DDDT/refresh.svg'
import Modal from '../components/modal/customModal';

const Intro = ({ userType, orgId, linkType, url }) => {

    const history = useHistory();
    const [userDetail, setUserDetail] = useState({
        fullName: "",
        email: "",
        empEmail: "",
    });
    const [touched, setTouched] = useState({
        fullName: false,
        email: false,
        empEmail: false,
    });
    const [submitClicked, setSubmitClicked] = useState(false);
    const [organisation, setOrgazisation] = useState(null)

    // Not implemented.
    const getOrganisation = async () => {
        const response = await fetch(`${BASE_URL}/api/organization/${orgId}`)
        const data = await response.json();

        if (data && data.organization) {
            setOrgazisation(data.organization.name)
        }
    }
    useEffect(() => {
        getOrganisation();
    }, []);
    useEffect(() => {
        (async () => {
            try {
                if (touched.email && touched.fullName && submitClicked) {
                    const errors = validateInput(userDetail);
                    if (
                        (JSON.stringify(errors) ===
                            JSON.stringify({
                                fullName: "",
                                email: "",
                                empEmail: "",
                            }))
                    ) {
                        const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/guest-user-entry", {
                            fullName: userDetail?.fullName,
                            email: userDetail?.email,
                            userType,
                            organizationId: orgId

                        });
                        if (result?.data?.status === 409 || result.status === 200) {
                            history.push(`${url}/rules?org-id=${orgId}&link-type=${linkType}&guest=${userDetail?.email}`)
                        }
                    }
                }
                //   setSubmitClicked(false);
            } catch (err) {
                console.log('err - ', err);
            }
        })()

    }, [touched])

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        // const nameFormat = /^[A-Z]+$/i;
        // const numberFormat = /^[0-9]+$/;
        // if (
        //     value !== "" &&
        //     ((name === "fullName") && (!nameFormat.test(value))) ||
        //     value.length > 30
        // )
        //     return;
        if (touched[name])
            setTouched(prevState => ({ ...prevState, [name]: false }));
        setUserDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateInput = (user) => {
        const errors = {
            fullName: "",
            email: "",
            empEmail: "",
        };
        const emailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})/;
        if (touched.fullName && user.fullName.length === 0) {
            errors.fullName = "Full Name should be valid";
        }
        if (touched.email && !emailFormat.test(user.email)) {
            errors.email = "Enter valid email id";
        }
        if (touched.empEmail && !emailFormat.test(user.empEmail)) {
            errors.phoneNumber = "Enter valid phone number";
        }
        return errors;
    };
    let errorMessage = validateInput(userDetail);

    const handleNextStep = async (e) => {
        e.preventDefault();
        setSubmitClicked(true);
        setTouched({ fullName: true, email: true });
    };

    return (
        <form onSubmit={async (e) => handleNextStep(e)}>
            <div className="diwaliGameIntro">
                <div className="gameDesc font-bold text-5xl max-w-[450px] mx-auto text-center mt-10">The triumph of light over darkness</div>
                <div className='mt-[200px] flex flex-col items-center'>
                    <div>
                        <input
                            className="firstNameField nameInputField text-xl font-bold"
                            placeholder={"Full Name"}
                            onChange={handleChange}
                            name="fullName"
                            value={userDetail.fullName}
                            onBlur={() => setTouched({ fullName: true })}
                        />
                        <div className="text-white text-sm pt-2 pl-3">{errorMessage.fullName}</div>
                    </div>
                    <div>
                        <input
                            className="lastNameField nameInputField font-bold text-xl mt-10"
                            value={userDetail.email}
                            name="email"
                            onChange={handleChange}
                            onBlur={() => setTouched({ email: true })}
                            placeholder={"Email"}
                        />
                        <div className="text-white text-sm pt-2 pl-3">{errorMessage.email}</div>
                    </div>

                </div>
            </div>
            <div className="text-center flex justify-center mt-[30px]  ">
                <button
                    className='tooltip cursor pointer'
                    type="submit"
                >
                    <img
                        src={nextBtn}
                        // onClick={handleNextStep}
                        alt="Next Button"
                    />
                </button>
            </div>
        </form>
    )
}

const Rules = ({ userInfo, userType, url, orgId, linkType }) => {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const guestEmail = queryParams.get("guest");

    const handleNextStep = async () => {
        if (userType === "guest") {
            history.push(`${url}/game-dashboard?org-id=${orgId}&link-type=${linkType}&guest=${guestEmail}`);

        } else {
            history.push(`${url}/game-dashboard?org-id=${orgId}&link-type=${linkType}`);
        }
    };

    useEffect(() => {
    }, [userInfo, userType])

    return (
        <>
            <div className=''>
                <div className="gameDesc font-bold text-5xl max-w-[450px] mx-auto text-center mt-10">The triumph of light over darkness</div>
                < div className="rulesTxt nameInputField mx-auto mt-10 mb-[30px]">
                    Rules
                </div>
                <div className='bg-white-500 border p-7 rounded-lg shadow-lg max-w-[600px] mx-auto'>
                    <ul className="text-lg text-white list-inside list-disc space-y-2">
                        <li>
                            <div className="flex items-center">
                                <div className="w-4 h-4 mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-full h-full text-white">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                    </svg>
                                </div>
                                You can create your ticket only once
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <div className="w-4 h-4 mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-full h-full text-white">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                    </svg>
                                </div>
                                It will be saved under your login details
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <div className="w-4 h-4 mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-full h-full text-white">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                    </svg>
                                </div>
                                Enjoy the game together on a LIVE VC call
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="text-center flex justify-center mt-[30px]  ">
                <button
                    className='tooltip cursor pointer'
                >
                    <img
                        src={nextBtn}
                        onClick={handleNextStep}
                        alt="Next Button"
                    />

                </button>
            </div>
        </>
    )
}
const GameDashboard = ({
    // selectedValue: , 
    setSelectedValue: setSelectedValue2,
    userDetails, userInfo, guestEmail, userType, linkType, orgId, url
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { path, url: pathUrl } = useRouteMatch();
    const getWordsList = useSelector(state => state.diwaliGameDetails);
    const diwaliGameDetails = useSelector(state => state.diwaliGameDetails);
    const [wordTobeReleased, setWordTobeReleased] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedWords, setSelectedWords] = useState([]);
    const authToken = localStorage.getItem('isAdmin')
    const mobileWideScreen = useMediaQuery({ minWidth: 1010 });

    useEffect(() => {
        (async () => {
            try {
                const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/get-selected-words", userInfo?.data?.id ? {
                    userId: userInfo?.data?.id,
                } : {
                    email: guestEmail
                });

                if (result?.data?.data?.selectedWords?.length === 12) {
                    setSelectedValue(result?.data?.data?.selectedWords)
                    setSelectedWords(result?.data?.data?.selectedWords)
                }
            } catch (err) {

            }
        })()
    }, [])

    useEffect(() => {
        const socket = io(`${BASE_URL}`, {
            auth: { token: authToken }
        });

        socket.emit("getReleasedTag");
        socket.on("releasedTag", (data) => {
            setWordTobeReleased(data.wordsList?.sort() ?? [])
        })
    }, [])

    const [applyContainer, setIsApplyContainer] = useState(window.innerWidth < 900);

    // Update the state when the window is resized
    useEffect(() => {
        function handleResize() {
            setIsApplyContainer(window.innerWidth < 991);
        }
        window.addEventListener("resize", handleResize);
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const generateRandomValues = () => {
        const shuffledValues = [...wordTobeReleased];
        for (let i = shuffledValues.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledValues[i], shuffledValues[j]] = [shuffledValues[j], shuffledValues[i]];
        }
        let randomValues = shuffledValues.slice(0, 12).map((value, index) => ({
            value,
            index,
            selectedBy: 'Auto'
        }));

        const selectedByUser = selectedValue.filter((data) => data?.selectedBy === 'User' && data?.value !== '')
        randomValues = [...selectedByUser, ...randomValues].slice(0, 12)
        return randomValues;
    };

    const sumbmitAutoGenerateButton = () => {
        if (diwaliGameDetails?.diwaliGameSettings?.autoGenerateButton) {
            return;
        }
        setSelectedValue(generateRandomValues())
    }

    const handleNextStep = async () => {
        if ((selectedValue.filter((item) => item.value !== '').length !== 12)) {
            // setStep((prev) => {
            //     return prev
            // })
            Swal.fire({
                title: 'Error',
                text: 'Please select exactly 12 numbers or words before proceeding',
                icon: 'error',
            });
        } else if (guestEmail && userType === "guest") {
            try {
                const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/create-a-ticket", {
                    email: guestEmail,
                    userType,
                    selectedWords: selectedValue,
                    organizationId: orgId
                });
                if (result?.data?.status === 429) {
                    await Swal.fire({
                        title: 'Error',
                        text: "You can't create ticket now, Allowed ticket limit already reached",
                        icon: 'error',
                    }).then((result) => {
                        // history.push('/')
                    })
                } else if (result?.status === 200) {
                    setSelectedValue2(result?.data?.data ?? [])
                    history.push(`${url}/claim-ticket?org-id=${orgId}&link-type=${linkType}&guest=${guestEmail}`)
                }
            } catch (err) {
                console.log("err - ", err);
            }
        } else {
            const userSessionDetails = JSON.parse(sessionStorage.getItem("gameDetails"));
            try {

                const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/create-a-ticket", {
                    email: userInfo?.data?.email,
                    firstName: userInfo?.data?.firstName,
                    sessionId: userDetails?.sessionId || userSessionDetails?.sessionId,
                    userId: userInfo?.data?.id,
                    organizationId: userInfo?.data?.organizationId,
                    selectedWords: selectedValue
                });
                if (result?.data?.status === 429) {
                    await Swal.fire({
                        title: 'Error',
                        text: "You can't create ticket now, Allowed ticket limit already reached",
                        icon: 'error',
                    }).then((result) => {
                        // history.push('/')
                    })
                } else if (result?.status === 200) {
                    setSelectedValue2(result?.data?.data?.selectedWords ?? [])
                    history.push(`${url}/claim-ticket?org-id=${orgId}&link-type=${linkType}`)

                }
            } catch (err) {
                console.log("err - ", err);
            }
        }
    };

    // const handleBackStep = () => {
    //     history.push(`${url}/rules?org-id=${orgId}&link-type=${linkType}`)

    //    /*  if (userType === "guest") {
    //         history.push(`${url}/game-dashboard?org-id=${orgId}&link-type=${linkType}&guest=${guestEmail}`);

    //     } else { */
    //         // history.push(`${url}/game-dashboard?org-id=${orgId}&link-type=${linkType}`);
    //     // }

    // };

    return (
        <>
            <div className='text-center'>
                {/* <button className="bg-[#e9695e] text-white text-lg px-2 py-1 border-none ml-[10px] mr-[15px] mt-[10px] font-bold rounded-[5px]" onClick={handleBackStep}>BACK</button> */}

                <div className="gameDesc font-bold text-white text-5xl max-w-[450px] mx-auto text-center mt-5 mb-5">Feeling Lucky?</div>
                <div className='font-bold mb-3 text-3xl text-white mx-auto  max-w-[430px]'>FOLLOW THE 2 STEPS BELOW, TRY YOUR LUCK AND WIN!</div>
                <div className='text-white mx-auto text-sm  max-w-[470px]'><span className='font-bold '>step 1 </span> : Select any 12 of the 50 words by clicking on them one at a time. OR "Click to generate Autmatic ticket"</div>
                <div className='text-white mx-auto text-sm mb-3 max-w-[450px]'><span className='font-bold '>step 2 </span> : Click on "Proceed to play"</div>
                <div className={`boardContainer flex mx-auto justify-center flex-wrap ${applyContainer ? 'container' : ''}`}>
                    <div className='pr-5 mb-4 w-[50%] wordBoardContainer'>
                        <WordBoard wordTobeReleased={wordTobeReleased} selectedValue={selectedValue} setSelectedValue={setSelectedValue} selectedWords={selectedWords?.length ?? 0} />
                    </div>
                    <div className='pl-5 mb-3 w-[50%] ticketBoardContainer'>
                        <TicketBoard selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
                        <button
                            className={`nameInputField generateTicketBtn px-10 mt-7  w-fit mx-auto !w-auto ${!diwaliGameDetails?.diwaliGameSettings?.autoGenerateButton ? "" : "!cursor-not-allowed"}`}
                            onClick={() => sumbmitAutoGenerateButton()}
                            disabled={diwaliGameDetails?.diwaliGameSettings?.autoGenerateButton || selectedWords?.length === 12}
                        >
                            Click to generate Automatic Ticket
                        </button>
                        <div className="text-center flex justify-center py-[30px]  ">
                            <button
                                className='tooltip cursor pointer nameInputField generateTicketBtn rounded-full'
                                onClick={handleNextStep}
                            >
                                Proceed to Play
                            </button>
                        </div>
                    </div>
                    {/* <button
                    className={`nameInputField generateTicketBtn px-10 mt-4  w-fit mx-auto ${!diwaliGameDetails?.diwaliGameSettings?.autoGenerateButton ? "" : "!cursor-not-allowed"}`}
                    onClick={() => sumbmitAutoGenerateButton()}
                    disabled={diwaliGameDetails?.diwaliGameSettings?.autoGenerateButton}
                >
                    Click to generate Automatic Ticket
                </button>
                <div className="text-center flex justify-center py-[30px]  ">
                <button
                    className='tooltip cursor pointer nameInputField generateTicketBtn rounded-full'
                    onClick={handleNextStep}
                >
                    Proceed to Play
                </button> */}
                </div>
            </div>

        </>
    )
}
const ClaimTicket = ({ selectedValue, setSelectedValue, userDetails,
    // userInfo, highlightedWords, setHighlightedWords, guestEmail    , 
    userType, linkType, orgId, url }) => {
    const dispatch = useDispatch();
    const diwaliGameDetails = useSelector(state => state.diwaliGameDetails);
    const [selectedCliamType, setSelectedCliamType] = useState('')
    const [releasedWordsList, setReleasedWordsList] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [highlightedWords, setHighlightedWords] = useState([]);
    const queryParams = new URLSearchParams(window.location.search);
    const guestEmail = queryParams.get("guest");
    const UserInfo = useSelector(state => state.getUser);
    const { userInfo } = UserInfo;

    useEffect(() => {
        (async () => {
            if (userInfo?.data?.id) {
                const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/get-selected-words", {
                    userId: userInfo?.data?.id
                });
                if (result?.data?.data?.selectedWords?.length === 12) {
                    setHighlightedWords(result?.data?.data?.highlightWords)
                }
            }
        })();
    }, [userInfo?.data?.id])
    // const history = useHistory();
    useEffect(() => {

        (async () => {
            if (guestEmail) {
                const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/get-selected-words", {
                    email: guestEmail
                });
                if (result?.data?.data?.selectedWords?.length === 12) {
                    setHighlightedWords(result?.data?.data?.highlightWords)
                }
            }
        })()
    }, [guestEmail])

    const cliamTypeOptions = [
        { label: 'Early Five', value: 'earlyFive' },
        { label: 'Top Line', value: 'topLine' },
        { label: 'Bottom Line', value: 'bottomLine' },
        { label: 'Middle Line', value: 'middleLine' },
        { label: 'Four Corners', value: 'fourCorners' },
        { label: 'Full House', value: 'fullHouse' },
    ];
    const [cliamType, setCliamType] = useState(cliamTypeOptions);
    const [isLoading, setIsLoading] = useState(true);
    const authToken = localStorage.getItem('isAdmin')

    useEffect(() => {
        dispatch(getDiwaliSettings())
    }, []);

    const getReleasedWords = (type) => {
        if (type === 'refresh') {
            setReleasedWordsList([])
        }
        const socket = io(`${BASE_URL}`, {
            auth: { token: authToken }
        });

        socket.emit("getReleasedWords");
        socket.on("releasedWordsNumbers", (data) => {
            setReleasedWordsList(data.releasedWords ?? [])
        })
    }
    useEffect(() => {
        getReleasedWords();
    }, [])

    const SetHighLights = async (cellNumber, isValueSelected = false) => {
        try {
            let value = cellNumber
            if (isValueSelected) {
                value = highlightedWords?.filter((item) => item.value !== cellNumber)
            }

            const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/update-highlight-words", {
                email: userInfo?.data?.email || guestEmail,
                highlightWords: value
            });
            if (result?.data?.data?.count === 1) {
                setHighlightedWords(value)
            }
            // todo alert
        } catch (err) {
            console.log('err - ', err);
        }
    }

    useEffect(() => {
        if (diwaliGameDetails?.diwaliGameSettings) {
            setCliamType(cliamTypeOptions.filter((data) => diwaliGameDetails?.diwaliGameSettings[data?.value]))
        }
    }, [diwaliGameDetails]);

    const claimTypeToFilter = {
        topLine: [selectedValue[0], selectedValue[1], selectedValue[2], selectedValue[3]],
        middleLine: [selectedValue[4], selectedValue[5], selectedValue[6], selectedValue[7]],
        bottomLine: [selectedValue[8], selectedValue[9], selectedValue[10], selectedValue[11]],
        fourCorners: [selectedValue[0], selectedValue[3], selectedValue[8], selectedValue[11]],
        fullHouse: selectedValue,
        earlyFive: selectedValue
    };

    const submitClaims = async () => {
        if (!selectedCliamType) {
            Swal.fire({
                title: 'Warning',
                text: 'Please select the claim type',
                icon: 'warning',
            })
            // alert('Please Select Claim Type');
            return;
        }

        const selectedClaimValues = claimTypeToFilter[selectedCliamType?.value];
        // const userSessionDetails = JSON.parse(sessionStorage.getItem("gameDetails"));

        if (userType === "guest" && guestEmail) {
            const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/claim-words-for-guest", {
                selectedClaimValues,
                claimType: selectedCliamType?.value,
                email: guestEmail,
                orgId: orgId
            });
            Swal.fire({
                title: result.data.message === "Your Claim is rejected" ? 'Error' : result.data.status === 409 ? 'Already Claimed' : 'Success',
                text: result.data.message,
                icon: result.data.message === "Your Claim is rejected" ? 'error' : result.data.status === 409 ? 'warning' : 'success',
            })
        } else if (userInfo?.data?.email) {
            const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/claim-words", {
                selectedClaimValues,
                claimType: selectedCliamType?.value,
                userDetails: {
                    email: userInfo?.data?.email,
                    firstName: userInfo?.data?.firstName,
                    // sessionId: userDetails?.sessionId || userSessionDetails?.sessionId,
                    userId: userInfo?.data?.id,
                    organizationId: userInfo?.data?.organizationId,
                },
            });
            Swal.fire({
                title: result.data.message === "Your Claim is rejected" ? 'Error' : result.data.status === 409 ? 'Already Claimed' : 'Success',
                text: result.data.message,
                icon: result.data.message === "Your Claim is rejected" ? 'error' : result.data.status === 409 ? 'warning' : 'success',
            })
        } else {
            Swal.fire({
                title: 'Something Wrong',
                text: 'Something went wrong please try again',
                icon: 'error',
            })
        }
    };
    const chunkSize = 2;

    // const handleBackStep = () => {
    //     if(releasedWordsList.length === 0){
    //         if (userType === "guest") {
    //         history.push(`${url}/game-dashboard?org-id=${orgId}&link-type=${linkType}&guest=${guestEmail}`);

    //     } else {
    //         history.push(`${url}/game-dashboard?org-id=${orgId}&link-type=${linkType}`);
    //     }}else {
    //         return;
    //     }
    // };

    return (
        <>
            <div className='flex flex-wrap justify-center mt-5 diwaliGameClaimContainer'>
                <div className="p-[10px]  w-[45%] ">
                    {/* <button className="bg-[#e9695e] text-white text-lg px-2 py-1 border-none ml-[10px] mr-[15px] mt-[10px] font-bold rounded-[5px]" onClick={handleBackStep}>BACK</button> */}
                    <div
                        // style={{ width: "800px", height: "auto" }}
                        className="text-center w-[100%] pt-3 text-xl rounded text-white h-[100%] bg-[#89096b]">
                        <div className='flex justify-center items-center'>
                            {/* <div></div> */}
                            <div>
                                Dhoom Dhaam Diwali
                            </div>
                            <div className='hidden' style={{ float: "right", padding: "10px" }}>
                                <link
                                    href="//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.min.css"
                                    rel="stylesheet"
                                    type="text/css"
                                />
                                <i className="fa fa-circle text-danger-glow blink"></i>
                                <span> LIVE </span>
                            </div>
                        </div>
                        <div className="webinarBox ">
                            <ReactPlayer
                                url={process.env.REACT_APP_DDDT_STREAM_LINK}
                                controls={true}
                                width="100%"
                                height="100%"
                                playsinline={true}
                                // muted={true}
                                // playing={true} // Use the 'autoPlay' prop to control autoplay
                                autoPlay
                                config={{
                                    file: {
                                        attributes: {
                                            type: "application/x-mpegURL",
                                        },
                                    },
                                }}
                                onReady={() => {
                                    // Video is ready to play, update loading state
                                    setIsLoading(false);
                                }}
                                onPlay={() => {
                                    // Video started playing
                                    setIsLoading(false);
                                }}
                                light={isLoading && (
                                    <img
                                        src={dhoomDhaamDiwali}
                                        alt="My Image"
                                        className="object-contain"
                                        style={{
                                            width: "100%", // Adjust the width as needed
                                            height: "auto", // Adjust the height as needed
                                        }}
                                    />
                                )}
                            />

                            {/* <ReactPlayer
                            playing={false}
                            controls={true}
                            // width="800px"
                            className="!w-[100%]"
                            height="auto"
                            url="https://84ead617a91e.ap-south-1.playback.live-video.net/api/video/v1/ap-south-1.796085456656.channel.LXWwJWFxigdw.m3u8"
                            light={
                                <img
                                    src={dhoomDhaamDiwali}
                                    alt="My Image"
                                    className='object-contain'
                                    style={{
                                        width: '100%', // Adjust the width as needed
                                        height: 'auto', // Adjust the height as needed
                                    }}
                                />}
                        /> */}
                        </div>
                    </div>

                </div>
                <div className="p-[10px]  w-[55%] ">
                    <div className="flex w-[100%] justify-center ">
                        <div className='finalTicket mt-5'>
                            <TicketBoard action='ClaimTicket ' selectedValue={selectedValue} setSelectedValue={setSelectedValue} selectedTickets={highlightedWords} setSelectedTickets={SetHighLights} />
                            <div className='ticketButtons m-5 flex justify-evenly'>
                                {/* <button
                                className="buttonField"
                                onClick={() => console.log(" Button Clicked")}
                            > Select Claims </button> */}
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={cliamType}
                                    menuPlacement={"auto"}
                                    onChange={e => setSelectedCliamType(e)}
                                    placeholder="Select Claims Type"
                                    value={selectedCliamType}
                                    closeMenuOnSelect={true}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles, width: '200px', minHeight: '30px',
                                            height: '30px'
                                        }), // Adjust the width as needed
                                        menu: (styles) => ({ ...styles, width: '200px', maxHeight: '200px' }), // Adjust the width of the dropdown menu
                                        menuList: (styles) => ({ ...styles, width: '200px', maxHeight: '200px' }), // Adjust the width of the list of options
                                        option: (styles) => ({ ...styles, width: '100%', maxHeight: '200px' }),
                                    }}
                                />
                                <button
                                    className={`buttonField ${!diwaliGameDetails?.diwaliGameSettings?.claims ? "" : "!cursor-not-allowed"}`}
                                    disabled={diwaliGameDetails?.diwaliGameSettings?.claims}
                                    onClick={() => submitClaims()}
                                > Submit </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-around py-[15px] bg-[#800080] rounded-xl">
                        <div className="bg-white h-[435px] overflow-y-scroll p-[20px] w-[80%] md:w-[47%] ">
                            {/* <img src={refreshLogo} style={{ cursor: 'pointer' }} alt='refresh' onClick={() => getReleasedWords('refresh')} /> */}
                            <div className="w-[100%] h-[100px] p-[20px] justify-center rounded text-center">
                                {/* <button className='btn btn-primary' onClick={() => getReleasedWords('refresh')}>Refresh</button> */}
                                {releasedWordsList?.length ? <table className="table-auto">
                                    <tbody>
                                        {Array.from({ length: Math.ceil(releasedWordsList.length / chunkSize) }, (v, i) => i).map(row => (
                                            <tr key={row}>
                                                {releasedWordsList.slice(row * chunkSize, (row + 1) * chunkSize).map((number, index) => (
                                                    <td key={index} className="border p-2 w-[200px] text-black">{number}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table> : <div>Coming Soon...</div>}
                            </div>
                        </div>
                        <div className="pink-box w-[47%] h-[435px] p-[20px] justify-center hidden md:block">

                            {true ? <ChatBox fromGameChat={true} orgId={orgId} email={userInfo?.data?.email || guestEmail} userType={userType} /> :
                                <div className="bg-pink-600 text-white text-center w-[100%] h-[100px] p-[20px] mt-[150px] rounded justify-center ">
                                    This space will be active on 7th November 5pm !
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='fixed bottom-[10px] right-[10px] w-[100px] md:hidden'>
                <button style={{ backgroundColor: '#100829da', borderRadius: '8px' }} onClick={() => setShowModal(true)} className={`chatBtn`}>
                    <img src="https://extramile-static-files.s3.ap-south-1.amazonaws.com/CHAT_BUTTON.gif" alt="" />
                </button>

            </div>
            <Modal showModal={showModal} className={"gameChatMob rounded-lg bg-[#5f005f] md:hidden"} modalDialogClass={"h-[90%]"}>
                <div className="close-icon" onClick={() => setShowModal(false)}>
                    <div className="close-btn-icon"></div>
                </div>
                <h3 className='text-2xl text-white ml-[10px] mt-[15px]'>Dhoom Dham Diwali</h3>
                <ChatBox fromGameChat={true} orgId={orgId} email={userInfo?.data?.email || guestEmail} userType={userType} />
            </Modal>
        </>
    )
}

const linkTypes = {
    guest: "gwe13123sd",
    employee: "ezf678gaki",
    family: "fs10jnla97"
}

const DhoomGame = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { path, url } = useRouteMatch();
    const UserInfo = useSelector(state => state.getUser);
    const { userInfo } = UserInfo;

    const [step, setStep] = useState(2);
    const [selectedValue, setSelectedValue] = useState([]);
    // const [highlightedWords, setHighlightedWords] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [userType, setUserType] = useState("");

    const queryParams = new URLSearchParams(window.location.search);
    const linkType = queryParams.get("link-type");
    const orgId = queryParams.get("org-id");
    // const otp = queryParams.get("otp");
    const guestEmail = queryParams.get("guest");

    useEffect(() => {
        (async () => {
            if (!orgId) {
                await Swal.fire({
                    title: 'Error',
                    text: 'Need a valid gamelink to play the Dham Dhoom Diwali Game.',
                    icon: 'error',
                }).then((result) => {
                    history.push('/')
                })
            } else if (!linkType) {
                await Swal.fire({
                    title: 'Error',
                    text: 'Need a valid gamelink to play the Dham Dhoom Diwali Game.',
                    icon: 'error',
                }).then((result) => {
                    history.push('/')
                })
            }
            dispatch(getUser());
        })()
    }, []);
    const [customCompanyLogo, setCustomCompanyLogo] = useState('');

    useEffect(() => {
        let currentUrl = window.location.href;
        currentUrl = currentUrl.split('//')[1];
        let subDomain = currentUrl.split('.')[0];
        if (subDomain === 'uat' || subDomain === 'extramileplay' || currentUrl.split(':')) {
            return;
        }
        // dispatch(subdomainOrgIdLoading(true));
        axios.get(BASE_URL + `/api/organization/id/${subDomain}`).then((orgDetail) => {
            if (orgDetail?.data?.data) {
                axios.get(BASE_URL + `/api/organization/logo/${orgDetail?.data?.data}`).then((response) => {
                    setCustomCompanyLogo(response?.data?.organization?.companyLogo);
                });
                // dispatch(subdomainOrgIdLoading(false));
                // dispatch(updateSubdomainOrgId(orgDetail?.data?.data));
            }
        });
    }, []);
    useEffect(() => {

        (async () => {
            if (guestEmail) {
                const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/get-selected-words", {
                    email: guestEmail
                });
                if (result?.data?.data?.selectedWords?.length === 12) {
                    setSelectedValue(result?.data?.data?.selectedWords)
                    history.push(`${url}/claim-ticket?org-id=${orgId}&link-type=${linkType}&guest=${guestEmail}`)

                }
            }
        })()
    }, [guestEmail])
    useEffect(() => {
        (async () => {
            if (orgId) {
                const result = await axiosApiInstance.get(BASE_URL + "/api/organization/plan/" + orgId);
                if (result?.data?.organization?.id) {
                    const getLinkType = Object.keys(linkTypes).filter((val) => linkTypes[val] === linkType)
                    if (getLinkType?.length && typeof (getLinkType) === "object") {
                        setUserType(getLinkType[0])

                        if (getLinkType[0] === "guest" && !guestEmail) {
                            history.push(`${url}/intro?org-id=${orgId}&link-type=${linkType}`)
                        }
                        if (getLinkType[0] === "employee") {
                            const userLoginInfo = decryptData(localStorage.getItem("userSignInInfo") || encryptData({}));
                            if (!userLoginInfo?.email) {
                                await Swal.fire({
                                    title: 'Great',
                                    text: 'Click on ok and insert your official email id to participate in Dhoom Dhaam Diwali Tambola',
                                    icon: 'success',
                                }).then((result) => {
                                    history.push('/signin')
                                })
                            }

                        }
                    } else {
                        await Swal.fire({
                            title: 'Error',
                            text: 'Link type value is Invalid',
                            icon: 'error',
                        }).then((result) => {
                            history.push('/')
                        })
                    }

                } else {
                    await Swal.fire({
                        title: 'Error',
                        text: 'Organization is Invalid',
                        icon: 'error',
                    }).then((result) => {
                        history.push('/')
                    })
                }
            } else {

            }
        })();
    }, [linkType, orgId])


    useEffect(() => {
        (async () => {
            if (userInfo?.data?.id && url === "/game/dhoom-game") {
                // todo check with admin login
                history.push(`${url}/rules?org-id=${orgId}&link-type=${linkType}`)
            }
            if (userInfo?.data?.id) {
                const result = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/get-selected-words", {
                    userId: userInfo?.data?.id
                });
                if (result?.data?.data?.selectedWords?.length === 12) {
                    setSelectedValue(result?.data?.data?.selectedWords)
                    // setHighlightedWords(result?.data?.data?.highlightWords)
                    // if (step !== 4) {
                    history.push(`${url}/claim-ticket?org-id=${orgId}&link-type=${linkType}`)

                    // }
                }
            }
        })();
    }, [userInfo?.data?.id])

    // useEffect(() => {
    //     (async () => {
    //         if (otp) {
    //             const result = await axiosApiInstance.post(BASE_URL + "/api/game-server/login", {
    //                 otp
    //             }, { headers: { "client-secret": GAME_SERVER_TOKEN } });
    //             if (result?.data?.data) {
    //                 setUserDetails(result?.data?.data);
    //                 sessionStorage.setItem("gameDetails", JSON.stringify({ sessionMode: result?.data?.data?.sessionMode, sessionId: result?.data?.data?.sessionId, gameId: result?.data?.data?.gameId }));
    //                 setStep(2);
    //             }
    //         }
    //     })();
    // }, [otp]);

    const handleBackStep = () => {
        // if (step > 2) {
        //     setStep(step - 1);
        // }
    };

    useEffect(() => {
        // if (step === 2) {
        // history.push(`${url}/rules`);
        // } else
        // if (step === 3) {
        //     history.push(`${url}/game-dashboard`);
        // } else {
        //     history.push(`${url}/claim-ticket`);
        // }
    }, [step])

    return (
        <div className="diwaliGameContainer">
            <div className='w-[200px] ml-[5px]'>
                {customCompanyLogo ? (
                    <img src={S3_BASE_URL + customCompanyLogo} alt="logo" />
                ) : (
                    <> {
                        //     (
                        //     (userInfo || props.inviteId) &&
                        //     ((orgDetailsByEmail && orgDetailsByEmail.data?.companyLogo) || (orgDetailsById && orgDetailsById.organization?.companyLogo)) &&
                        //     (role !== ROLES.EXTRAMILE_SUPERADMIN)) ? (
                        //     <img src={S3_BASE_URL + (orgDetailsByEmail?.data?.companyLogo || orgDetailsById?.organization?.companyLogo)} alt="logo" />
                        //   ) : 
                        (
                            <img
                                alt="logo"
                                src={"https://extramileplay-public.s3.amazonaws.com/commons/logo_extramile.gif"}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = ExtraMilePlay;
                                }}
                            />
                        )}
                    </>
                )}
                {/* <button className="bg-[#e9695e] text-white text-lg px-2 py-1 border-none ml-[10px] mr-[15px] mt-[10px] font-bold rounded-[5px]" onClick={handleBackStep}>BACK</button> */}
            </div>
            <div className="flex justify-center">
                <div className="w-[100%] mx-auto">
                    <Switch>
                        {userType === "guest" ? <Route path={`${path}/intro`} component={() => <Intro userType={userType} orgId={orgId} linkType={linkType} url={url} />} /> : ""}
                        <>
                            <Route path={`${path}/rules`} component={() => <Rules userType={userType} userInfo={userInfo} orgId={orgId} linkType={linkType} url={url} />} />
                            <Route path={`${path}/game-dashboard`} component={() => <GameDashboard selectedValue={selectedValue} setSelectedValue={setSelectedValue} userInfo={userInfo} orgId={orgId} userDetails={userDetails} guestEmail={guestEmail} userType={userType} linkType={linkType} url={url} />} />
                            <Route path={`${path}/claim-ticket`} component={() => <ClaimTicket selectedValue={selectedValue} setSelectedValue={setSelectedValue} userDetails={userDetails} userInfo={userInfo}
                                // highlightedWords={highlightedWords} 
                                // setHighlightedWords={setHighlightedWords} 
                                guestEmail={guestEmail} userType={userType} linkType={linkType} url={url} orgId={orgId} />} />
                        </>
                    </Switch>

                </div>
            </div>
        </div>

    );
};

export default DhoomGame;
