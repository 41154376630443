import React from 'react'
import { useDispatch } from 'react-redux';
import { updateDiwaliSettings } from "../../redux/actions/diwaliGameApiActions";

const ToggleField = ({name, checked, keyValue}) => {
    const dispatch = useDispatch();
    return (
        <div className="flex flex-wrap items-center mb-5" key={keyValue}>
            <input checked={checked} onChange={(e)=>{
                dispatch(updateDiwaliSettings({[keyValue]: e.target.checked}))

            }} className="mr-3 toggleCheckbox hidden" type="checkbox" id={name}/>
            <label for={name} className="toggleBtn"></label>
            <label className="toggleContent ml-3">{name}</label>
        </div>
    )
}

export default ToggleField
