import React, { useEffect, useState } from 'react';

import './modal.css';
import Modal from './modal';
import { BASE_URL, axiosApiInstance, failureAlert, successAlert } from '../../helpers/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgTeams } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import { ToastContainer } from 'react-toastify';


const DeleteTeamModal = ({ modalid, toggle, setOpenAddTeamModal, teamId, handleDeleteFun, textInfo}) => {

    return (
        <Modal modalid={modalid} toggle={toggle}>
            <div className="modal-body">
                <div className="close-icon" data-dismiss="modal" aria-label="Close" onClick={() => setOpenAddTeamModal(false)} >
                    <div className="close-btn-icon" ></div>
                </div>
                <div className="thankyou-body users-added-body">
                <h4 className='title' style={{fontSize:'18px'}}>{textInfo ? textInfo : ''}</h4>
                    <div className="confrim-btn" style={{ margin: '15px' }}>
                        <button type="submit" className="btn btn-primary" onClick={(e) => setOpenAddTeamModal(false)}>No</button>
                        <button type="submit" className="btn btn-primary" onClick={(e) => handleDeleteFun(teamId)}>Yes</button>


                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default DeleteTeamModal;
