import React, { useEffect, useState } from "react";

import { IsAdmin } from "../../helpers/helper";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import PageLayout from "../pagelayout/pagelayout";
import ReleaseNumbers from "./ReleaseNumbers";
import AddNumbers from "./AddNumbers";
import DiwaliGameSetting from "./DiwaliGameSetting";
import "./diwaliAdmin.css";
import Claims from "./Claims";

const ClaimTable = (props) => {
  const isAdmin = IsAdmin();
  const [activeTabType, setActiveTabType] = useState("Release");

  const signOut = async () => {
    // await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };

  return (
    <>
      <PageLayout
        sidebartitle=""
        active={"Dhoom Dham Diwali"}
        category
        sideBarContents={sidebarContentAdmin}
        profile
        {...props}
        signOut={signOut}
        {...props}
        isAdmin={isAdmin}
      >
        <div className="flex items-center justify-center mb-4">
          <div className="flex items-center p-1 mb-4 border border-red-200 dark:border-red-400 rounded-md">
            <button
              onClick={() => setActiveTabType("Release")}
              className={`${
                activeTabType === "Release"
                  ? "bg-gradient-to-r from-[#e25569] to-[#fb9946] text-white"
                  : "text-red-600"
              } px-4 py-2 text-xl font-medium capitalize md:py-3 rounded-md md:px-12 dark:text-red-400 dark:hover:text-white focus:outline-none rounded-md md:px-12`}
            >
              Release
            </button>
            <button
              onClick={() => setActiveTabType("Add")}
              className={`${
                activeTabType === "Add"
                  ? "bg-gradient-to-r from-[#e25569] to-[#fb9946] text-white"
                  : "text-red-600"
              } px-4 py-2 text-xl font-medium capitalize md:py-3 rounded-md md:px-12 dark:text-red-400 dark:hover:text-white focus:outline-none rounded-md md:px-12`}
            >
              Add
            </button>
            <button
              onClick={() => setActiveTabType("Settings")}
              className={`${
                activeTabType === "Settings"
                  ? "bg-gradient-to-r from-[#e25569] to-[#fb9946] text-white"
                  : "text-red-600"
              } px-4 py-2 text-xl font-medium capitalize transition-colors duration-300 md:py-3 dark:text-red-400 dark:hover:text-white focus:outline-none rounded-md md:px-12`}
            >
              Settings
            </button>
            <button
              onClick={() => setActiveTabType("Claims")}
              className={`${
                activeTabType === "Claims"
                  ? "bg-gradient-to-r from-[#e25569] to-[#fb9946] text-white"
                  : "text-red-600"
              } px-4 py-2 mx-4 text-xl font-medium capitalize transition-colors duration-300 md:py-3 dark:text-red-400 dark:hover:text-white focus:outline-none rounded-md md:mx-8 md:px-12`}
            >
              Claims
            </button>
          </div>
        </div>
        {activeTabType === "Release" ? <ReleaseNumbers /> : null}
        {activeTabType === "Add" ? <AddNumbers /> : null}
        {activeTabType === "Claims" ? <Claims /> : null}
        {activeTabType === "Settings" ? <DiwaliGameSetting /> : null}
      </PageLayout>
    </>
  );
};

export default ClaimTable;
