import React from 'react';
import './custom-modal.css';

const Modal = ({ children, modalid, showModal, size, className="", modalDialogClass="" }) => {
    return (
        <div className={`custom-modal ${className} ${showModal ? "show" : ""}`} id={modalid ? modalid : Date.now()} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div style={{ width: window.innerWidth < 700 ? '94vw' : (size === 'lg' ? '600px' : '200px') }} className={`${modalDialogClass} modal-dialog`} role="document">
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div >
    );
};

export default Modal;