import React, { useState, useEffect } from "react";
import PageLayout from "../pagelayout/pagelayout";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import "./bookEventReq.css";
import search from "../../assets/images/search.svg";
import PaymentDetailsModal from "../modal/paymentDetailsModal";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import useDebouncedSearch from "../../helpers/debounce";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { failureAlert, IsAdmin, successAlert, Frontned_URL, axiosApiInstance, BASE_URL } from "../../helpers/helper";

import LoadingComponent from "../loader/LoadingComponent";
import { getAllEventQuestions } from "../../redux/actions/bookAnEventActions";


const EventsQuestions = (props) => {
    const useSearch = () => useDebouncedSearch(text => searchDetails(text));
    const searchDetails = (text) => {
        dispatch(getAllEventQuestions(text));
    }
    const { searchText, setSearchText, results } = useSearch();
    const isAdmin = IsAdmin();
    const dispatch = useDispatch();

    const [loaded, setLoaded] = useState(true);
    const [eventQuestionData, setEventQuestionsData] = useState([])
    const AllQuestions = useSelector(state => state.getAllEventQuest);
    const { getAllQuestion } = AllQuestions;

    useEffect(() => {
        dispatch(getAllEventQuestions())

    }, [dispatch]);

    useEffect(() => {
        if (getAllQuestion && getAllQuestion.message === "sucessfully fetch all event questions") {
            setEventQuestionsData(getAllQuestion.data)
        }
        if(AllQuestions && AllQuestions?.loading){
            setLoaded(false);
        }
        else{
            setLoaded(true);
        }
    }, [AllQuestions])

    const signOut = async () => {
        await dispatch(logOut());
        if (isAdmin) props.history.push("/admin");
        else props.history.push("/");
    };


    const handleSearch = (e) => {
        setSearchText(e.target.value);
    }
    return (
        <div className="admin-homepage">
            {/* <PageLayout
                sidebartitle=""
                active={"Event Questions"}
                category
                sideBarContents={sidebarContentAdmin}
                profile
                signOut={signOut}
                isAdmin={isAdmin}
                {...props}
            > */}
                <div className="container-payment">
                    <div className="payment">
                        <h1 className="title">Events Related Questions</h1>
                    </div>
                </div>
                <div className="input-icon search-bar">
                    <img src={search} alt="search" />
                    <input type="text" name="search" placeholder="Search" value={searchText} onChange={handleSearch} />
                </div>
                <ToastContainer position="bottom-center" />
                <LoadingComponent loaded={loaded} >
                    {/* <div className="data-table"> */}
                    <div className="flex w-full h-auto items-center flex-col">
                        <div className="w-full flex flex-row items-center h-[60px] bg-[#f8f9fd] rounded-[6px] pl-[15px] pr-[15px]">
                            <div className="flex items-center justify-start w-1/6">
                                <span className="text-[20px] font-semibold">Client Name</span>
                            </div>
                            <div className="flex items-center justify-start w-1/6">
                                <span className="text-[20px] font-semibold">Email</span>
                            </div>
                            <div className="flex items-center justify-start w-1/6">
                                <span className="text-[20px] font-semibold">Company Name</span>
                            </div>
                            <div className="flex items-center justify-start w-1/6">
                                <span className="text-[20px] font-semibold">Contact No</span>
                            </div>
                            <div className="flex items-center justify-start w-2/6">
                                <span className="text-[20px] font-semibold">Questions</span>
                            </div>
                        </div>

                        <div className="flex items-center justify-start flex-col overflow-y-scroll no-scrollbar w-full ">
                            {
                                eventQuestionData && eventQuestionData.length > 0 ?
                                    eventQuestionData.map((data, index) => {
                                        return (
                                            <div className="flex flex-row w-full h-[100px] max-h-[100px] border-[1px] border-black rounded-[6px] pl-[15px] pr-[15px] mb-3" key={index}>
                                                <div className="flex items-center justify-start w-1/6 p-[5px]">
                                                    <span className="text-[14px] flex-wrap break-all whitespace-normal">{data?.clientFullName ? data.clientFullName : ''}</span>
                                                </div>
                                                <div className="flex items-center justify-start w-1/6 p-[5px]">
                                                    <span className="text-[14px] flex-wrap break-all whitespace-normal">{data?.email ? data.email : ''}</span>
                                                </div>
                                                <div className="flex items-center justify-start w-1/6 p-[5px]">
                                                    <span className="text-[14px] flex-wrap break-all whitespace-normal">{data?.companyName ? data.companyName : ''}</span>
                                                </div>
                                                <div className="flex items-center justify-start w-1/6 p-[5px]">
                                                    <span className="text-[14px] break-all whitespace-normal">{data?.mobileNo ? data.mobileNo : ''}</span>
                                                </div>
                                                <div className="flex items-center justify-start w-2/6 p-[5px]">
                                                    <span className="text-[14px] flex-wrap break-all whitespace-normal">{data?.message ? data?.message : ''}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null
                            }


                        </div>



                    </div>
                    {/* </div> */}
                </LoadingComponent>
            {/* </PageLayout> */}
        </div>
    );
};
export default EventsQuestions;