export const GET_GAME_SESSIONS_REQUEST="GET_GAME_SESSIONS_REQUEST";
export const GET_GAME_SESSIONS_SUCCESS="GET_GAME_SESSIONS_SUCCESS";
export const GET_GAME_SESSIONS_FAIL="GET_GAME_SESSIONS_FAIL";

export const GET_PUBLIC_GAME_SESSIONS_REQUEST="GET_PUBLIC_GAME_SESSIONS_REQUEST";
export const GET_PUBLIC_GAME_SESSIONS_SUCCESS="GET_PUBLIC_GAME_SESSIONS_SUCCESS";
export const GET_PUBLIC_GAME_SESSIONS_FAIL="GET_PUBLIC_GAME_SESSIONS_FAIL"

export const GAME_CREATE_SESSION_REQUEST = 'GAME_CREATE_SESSION_REQUEST';
export const GAME_CREATE_SESSION_SUCCESS = 'GAME_CREATE_SESSION_SUCCESS';
export const GAME_CREATE_SESSION_FAIL = 'GAME_CREATE_SESSION_FAIL';

export const GAME_CREATE_PUBLIC_SESSION_REQUEST = 'GAME_CREATE_PUBLIC_SESSION_REQUEST';
export const GAME_CREATE_PUBLIC_SESSION_SUCCESS = 'GAME_CREATE_PUBLIC_SESSION_SUCCESS';
export const GAME_CREATE_PUBLIC_SESSION_FAIL = 'GAME_CREATE_PUBLIC_SESSION_FAIL';

export const GAME_UPDATE_SESSION_REQUEST="GAME_UPDATE_SESSION_REQUEST";
export const GAME_UPDATE_SESSION_SUCCESS="GAME_UPDATE_SESSION_SUCCESS";
export const GAME_UPDATE_SESSION_FAIL="GAME_UPDATE_SESSION_FAIL";

export const GAME_DELETE_SESSION_REQUEST="GAME_DELETE_SESSION_REQUEST";
export const GAME_DELETE_SESSION_SUCCESS="GAME_DELETE_SESSION_SUCCESS";
export const GAME_DELETE_SESSION_FAIL="GAME_DELETE_SESSION_FAIL";

export const GAME_DELETE_PUBLIC_SESSION_REQUEST="GAME_DELETE_PUBLIC_SESSION_REQUEST";
export const GAME_DELETE_PUBLIC_SESSION_SUCCESS="GAME_DELETE_PUBLIC_SESSION_SUCCESS";
export const GAME_DELETE_PUBLIC_SESSION_FAIL="GAME_DELETE_PUBLIC_SESSION_FAIL";

export const TOGGLE_CREATE_SESSION_ACCESS_REQUEST="TOGGLE_CREATE_SESSION_ACCESS_REQUEST";
export const TOGGLE_CREATE_SESSION_ACCESS_SUCCESS="TOGGLE_CREATE_SESSION_ACCESS_SUCCESS";
export const TOGGLE_CREATE_SESSION_ACCESS_FAIL="TOGGLE_CREATE_SESSION_ACCESS_FAIL";

