import React, { useEffect, useState, useRef } from 'react';
import './spinwheel.css'; // Import the CSS file for styling



const Spinner =({ show, spin, onValueChange }) => {
  const [rotation, setRotation] = useState(0);
  const [result, setResult] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);

  const discounts = [5, 10, 15, 20];
  const numSegments = discounts.length;
  const segmentAngle = 360 / numSegments;

  useEffect(() => {
    if (spin) {
      startSpin();
    }
  }, [spin]);

  const startSpin = () => {
    if (isSpinning) return; // Prevent multiple spins

    setIsSpinning(true);

    // Randomly choose a discount index
    const randomIndex = Math.floor(Math.random() * numSegments);
    const randomDiscount = discounts[randomIndex];

    // Calculate the stop angle to center the segment
    const stopAngle = (360 - (randomIndex * segmentAngle) + (segmentAngle / 2)) % 360;
    // Calculate total rotation (multiple full spins + stop angle)
    const totalRotation = 360 * 5 + stopAngle; // At least 5 full spins

    // Set rotation
    setRotation(totalRotation);

    // Set result after spinning
    setTimeout(() => {
      setResult(randomDiscount);
      setIsSpinning(false);
      onValueChange(randomDiscount); // Notify parent with the result
    }, 5000); // Timeout to match the spin duration
  };

  if (!show) {
    return null; // Do not render if not visible
  }

  return (
    <div className="wheel-container">
    <div className="arrowwheel"></div>
    <div className="wheel" style={{ transform: `rotate(${rotation}deg)` }}>
      {discounts.map((discount, index) => {
        // Define the colors you want to use
        const colors = ['orange', 'rgb(27, 127, 204)', '#07f181', '#f00'];
        // Calculate the color index based on segment index
        const colorIndex = index % colors.length;
        
        return (
          <div
            key={index}
            className={`segment segment-${index}`}
            style={{
              transform: `rotate(${index * segmentAngle}deg)`,
              backgroundColor: colors[colorIndex]
            }}
          >
            {discount}%
          </div>
        );
      })}
    </div>
    {/* {result !== null && <div className="result">You won ${result} off!</div>} */}
  </div>
  
  );
};
export default Spinner;
