import React from 'react';

import './modal.css';
import Modal from './modal';
import csvdownload from '../../assets/images/csvdownload.svg';
import sampleAddTeamUser from '../../helpers/sampleAddUserTeamData.json';
import { createSamplecsv } from '../../redux/actions/organisationActions';
import { useDispatch } from 'react-redux';
import { downloadFile } from '../../helpers/downloadFile';
import { failureAlert } from '../../helpers/helper';

const SampleCsvAddTeamUser = ({ showModal, setModal, dummyusers }) => {

    const dispatch = useDispatch();
    const handleDownload = (e) => {
        e.preventDefault();
        
            dispatch(createSamplecsv(sampleAddTeamUser.data)).then((res) => {
                if (res?.data?.downloadUrl)
                    downloadFile(res.data.downloadUrl);
                else
                    failureAlert("Something went wrong, try again later!");
            });
        
    }
    return (
        <Modal showModal={showModal}>
            <div className="modal-body">
                <div className="close-icon samplecsv-close" onClick={() => setModal(false)} aria-label="Close">
                    <div className="close-btn-icon"></div>
                </div>
                <div className="samplecsv-wrapper">
                    <div className="samplecsv-heading">
                        <h5> CSV Format</h5>
                        {/* <button onClick={handleDownload}>
                            Download Sample
                            <img src={csvdownload} alt="csv download" />
                        </button> */}
                    </div>
                    <div className="samplecsv-userid">
                        <span>Use Ids - 100</span>
                    </div>
                    <div className="csv-table">
                        <table>
                            <thead>
                                <tr>
                                    
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Role</th>
                                    <th>Email</th>
                                    <th>Team Name</th>
                                    <th>Captain</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sampleAddTeamUser && sampleAddTeamUser.data.map((user, index) => {
                                        return (
                                            <tr key={JSON.stringify(user)}>
                                                
                                                <td>{user.firstName}</td>
                                                <td>{user.lastName}</td>
                                                <td>{user.Role}</td>
                                                <td>{user.email}</td>
                                                <td>{user.teamName}</td>
                                                <td>{user.captain}</td>

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {/* <div className="csvpage-detail">
                            <span>23/04/2021</span>
                            <span>01</span>
                        </div> */}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default SampleCsvAddTeamUser;
