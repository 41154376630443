import React, { useEffect, useRef, useState } from 'react';
import "./TeamDetails.css"
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";

import { axiosApiInstance, BASE_URL, failureAlert, S3_BASE_URL, successAlert } from '../../helpers/helper';

import { getOrgTeams } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import PlayIcon from "../../assets/backgrounds/team logo.png"
import { GoStarFill } from 'react-icons/go';
import ROLES from '../../helpers/userTypes';




const TeamDetails = (props) => {
  const UserInfo = useSelector(state => state.getUser)
  const { userInfo } = UserInfo;
  const getAllOrgsTeam = useSelector((state) => state.getOrgsTeams);
  const { orgTeams } = getAllOrgsTeam;

  const [loaded, setLoaded] = useState(true);
  const [teamsInfo, setTeamsInfo] = useState([]);
  const [teamLogo, setTeamLogo] = useState()
  const [selectedTeamInfo, setSelectedTeamInfo] = useState()


  const dispatch = useDispatch()


  useEffect(() => {
    if (userInfo && userInfo.data && userInfo.data.organizationId && userInfo.data.organisationTeamId) {
      dispatch(getOrgTeams(userInfo.data.organizationId))
    }


  }, [userInfo])

  useEffect(() => {
    if (orgTeams && orgTeams.message === "Sucessfully fetch org teams") {
      setTeamsInfo(orgTeams.data);
      setTeamLogo(orgTeams.data[0].teamLogo);


    }
    else if (orgTeams && orgTeams.message === "org teams not available") {
      setTeamsInfo([]);
    }


  }, [getAllOrgsTeam])

  useEffect(() => {
    if (teamsInfo && teamsInfo.length > 0 && userInfo && userInfo.data && userInfo.data.organizationId && userInfo.data.organisationTeamId) {
      let userTeamData = teamsInfo.filter((team) => team.id === userInfo.data.organisationTeamId);
      setSelectedTeamInfo(userTeamData[0])
      setTeamLogo(userTeamData[0].teamLogo);
    }
  }, [teamsInfo])

  function capitalizeWords(str) {
    if (!str) {
      return '';
    }
    return str.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  }


  return (
    <div className={loaded ? "myaccount-wrapper" : "myaccount-wrapper loading"} id="myaccount-element">
      <ToastContainer position="bottom-center" />
      <LoadingComponent loaded={loaded} />
      <div className=" flex flex-col w-full h-full">

        <div className="flex w-full items-center justify-center mb-[50px]">
          <div className="flex flex-row justify-start sm:justify-center md:justify-center lg:justify-center items-center w-[100%]  lg:w-[824px] h-[99px] sm:h-[120px] md:h-[152px] lg:h-[152px] rounded-[6px] p-[13px]"
            style={{
              background: 'linear-gradient(90deg, #4587DC 0%, #9DC9F5 100%)'
            }}
          >
            <div className="flex w-[66px] h-[66px] sm:h-[100px] sm:w-[100px] md:h-[127px] md:w-[127px] lg:h-[127px] lg:w-[127px] justify-center items-center  rounded-[6px] relative  ml-0">
              <img
                src={teamLogo ? `${S3_BASE_URL}${teamLogo}` : PlayIcon}
                alt="logo" className="h-full w-full object-cover rounded-[8px]" />
              {/* 
                        <div className="flex absolute bg-white h-[32px] w-[32px] bottom-0 right-[-15px] rounded-[6px] items-center justify-center cursor-pointer" onClick={handleButtonClick}>
                            <div className="flex items-center justify-center w-full h-full bg-[#E2556926] rounded-[6px] relative">
                                <FaRegEdit className="h-[15px] w-[15px] rounded-[6px]" fill='#E25569' />
                                <input
                                    type="file"
                                    className="hidden w-[32px] h-[32px] rounded-[6px] opacity-0 cursor-pointer  "
                                    name="team-logo-upload"
                                    onChange={fileChanged}
                                    accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                    ref={fileInputRef}

                                />

                            </div>

                        </div> */}

            </div>
            <div className="flex flex-col items-center justify-center p-10">
              <h1 className=' text-center text-[24px] sm:text-[30px] md:text-[42px] lg:text-[42px] font-semibold text-white'> {selectedTeamInfo && selectedTeamInfo?.name ? capitalizeWords(selectedTeamInfo.name) : 'Team Name'}</h1>
              <span className='text-[18px] md:text-[20px] lg:text-[20px] font-light text-white'>  {`Members ${teamsInfo && teamsInfo.length > 0 && selectedTeamInfo ? selectedTeamInfo.members.length : 0}`}</span>
            </div>

          </div>

        </div>

        <div className="team-table">
          <table>
            <thead style={{ height: '69px' }}>
              <tr>
                <th style={{ fontSize: '20px', fontFamily: 'sans-serif' }}>Full Name</th>
                <th style={{ fontSize: '20px', fontFamily: 'sans-serif' }}>Email ID</th>
                <th style={{ fontSize: '20px', fontFamily: 'sans-serif' }}>Role</th>
                <th style={{ fontSize: '20px', fontFamily: 'sans-serif' }}></th>

              </tr>
            </thead>
            <tbody>
              {
                selectedTeamInfo && selectedTeamInfo.members.length > 0 && selectedTeamInfo.members.map((user, index) => {
                  return (
                    <tr style={{ height: '69px' }}>
                      <td>{`${capitalizeWords(user.firstName)} ${capitalizeWords(user.lastName)}`}</td>
                      <td>{user.email}</td>
                      <td className="teams-role">{user.role === ROLES.ORG_SUPER_ADMIN ? "Owner" : user.role === ROLES.ORG_ADMIN ? "Admin" : user.role === ROLES.EMPLOYEE ? "Employee" : "User"}</td>

                      <td className="teams-role">
                        {
                          user.id === selectedTeamInfo.teamCaptainId ? (
                            <div className="flex w-[23px]  items-start">
                              <GoStarFill className='w-[15px] h-[16px] sm:w-[18px] sm:h-[19px] md:w-[20px] md:h-[21px] lg:w-[20px] lg:h-[21px]' fill='#5ECA87' />
                            </div>
                          )
                            : null
                        }

                      </td>
                    </tr>
                  )
                })
              }

            </tbody>
          </table>
        </div>

        <div className="table-responsive m-teams-table">
          {
            selectedTeamInfo && selectedTeamInfo.members.length > 0 && selectedTeamInfo.members.map((user, index) => {
              return (
                <div className="team-m-card c-white-card"
                //  key={JSON.stringify(user)}
                >
                  <div className="team-m-top">
                    <div className="role">

                      <div className="teams-m-name">{`${capitalizeWords(user.firstName)} ${capitalizeWords(user.lastName)}`}</div>
                      <div className="team-m-user">{user.email}</div>
                      {/* <span>Role</span>
                      <div className="teams-m-title">{user.role === Roles.ORG_SUPER_ADMIN ? "Owner" : user.role === Roles.ORG_ADMIN ? "Admin" : "User"}</div> */}
                    </div>

                  </div>

                  <div className="team-m-bottom"
                  // style={
                  //   {
                  //     marginTop: userInfo && userInfo.data && user.email !== userInfo.data.email ? '0px' : '10px'

                  //   }
                  // }
                  >
                    <div>

                      <div className={`flex items-end justify-center h-full w-full text-[16px] font-sans font-semibold `}>Role-{user.role === ROLES.ORG_SUPER_ADMIN ? "Owner" : user.role === ROLES.ORG_ADMIN ? "Admin" : user.role === ROLES.EMPLOYEE ? "Employee" : "User"}</div>

                    </div>
                    <div className="flex ">
                      {
                        user.id === selectedTeamInfo.teamCaptainId ? (
                          <div className="flex w-[23px]  items-start">
                            <GoStarFill className='w-[15px] h-[16px] sm:w-[18px] sm:h-[19px] md:w-[20px] md:h-[21px] lg:w-[20px] lg:h-[21px]' fill='#5ECA87' />
                          </div>
                        )
                          : null
                      }
                    </div>
                    {/* <div className="flex w-[23px]  items-start">
                <GoStarFill className='w-[15px] h-[16px] sm:w-[18px] sm:h-[19px] md:w-[20px] md:h-[21px] lg:w-[20px] lg:h-[21px]' fill='#5ECA87' />
              </div> */}
                  </div>
                </div>
              )
            })
          }

        </div>


      </div>
    </div >
  );
};

export default TeamDetails;