const COLOR_OPTIONS = [
    { label: 'IndianRed', value: 'IndianRed', code: '#CD5C5C' },
    { label: 'Salmon', value: 'Salmon', code: '#FA8072' },
    { label: 'Crimson', value: 'Crimson', code: '#DC143C' },
    { label: 'Red', value: 'Red', code: '#FF0000' },
    { label: 'FireBrick', value: 'FireBrick', code: '#B22222' },
    { label: 'DarkRed', value: 'DarkRed', code: '#8B0000' },
    { label: 'HotPink', value: 'HotPink', code: '#FF69B4' },
    { label: 'DeepPink', value: 'DeepPink', code: '#FF1493' },
    { label: 'MediumVioletRed', value: 'MediumVioletRed', code: '#C71585' },
    { label: 'Coral', value: 'Coral', code: '#FF7F50' },
    { label: 'OrangeRed', value: 'OrangeRed', code: '#FF4500' },
    { label: 'DarkOrange', value: 'DarkOrange', code: '#FF8C00' },
    { label: 'Gold', value: 'Gold', code: '#FFD700' },
    { label: 'Yellow', value: 'Yellow', code: '#FFFF00' },
    { label: 'DarkKhaki', value: 'DarkKhaki', code: '#BDB76B' },
    { label: 'Plum', value: 'Plum', code: '#DDA0DD' },
    { label: 'Violet', value: 'Violet', code: '#EE82EE' },
    { label: 'Orchid', value: 'Orchid', code: '#DA70D6' },
    { label: 'Magenta', value: 'Magenta', code: '#FF00FF' },
    { label: 'MediumPurple', value: 'MediumPurple', code: '#9370DB' },
    { label: 'BlueViolet', value: 'BlueViolet', code: '#8A2BE2' },
    { label: 'DarkViolet', value: 'DarkViolet', code: '#9400D3' },
    { label: 'DarkOrchid', value: 'DarkOrchid', code: '#9932CC' },
    { label: 'Purple', value: 'Purple', code: '#800080' },
    { label: 'Indigo', value: 'Indigo', code: '#4B0082' },
    { label: 'RebeccaPurple', value: 'RebeccaPurple', code: '#663399' },
    { label: 'SlateBlue', value: 'SlateBlue', code: '#6A5ACD' },
    { label: 'DarkSlateBlue', value: 'DarkSlateBlue', code: '#483D8B' },
    { label: 'YaleBlue', value: 'YaleBlue', code: '#124191' },
    { label: 'GreenYellow', value: 'GreenYellow', code: '#ADFF2F' },
    { label: 'LawnGreen', value: 'LawnGreen', code: '#7CFC00' },
    { label: 'Lime', value: 'Lime', code: '#00FF00' },
    { label: 'MediumSpringGreen', value: 'MediumSpringGreen', code: '#00FA9A' },
    { label: 'SpringGreen', value: 'SpringGreen', code: '#00FF7F' },
    { label: 'SeaGreen', value: 'SeaGreen', code: '#2E8B57' },
    { label: 'Green', value: 'Green', code: '#008000' },
    { label: 'DarkGreen', value: 'DarkGreen', code: '#006400' },
    { label: 'Olive', value: 'Olive', code: '#808000' },
    { label: 'DarkOliveGreen', value: 'DarkOliveGreen', code: '#556B2F' },
    { label: 'LightSeaGreen', value: 'LightSeaGreen', code: '#20B2AA' },
    { label: 'Teal', value: 'Teal', code: '#008080' },
    { label: 'Aqua', value: 'Aqua', code: '#00FFFF' },
    { label: 'Aquamarine', value: 'Aquamarine', code: '#7FFFD4' },
    { label: 'Turquoise', value: 'Turquoise', code: '#40E0D0' },
    { label: 'CadetBlue', value: 'CadetBlue', code: '#5F9EA0' },
    { label: 'SteelBlue', value: 'SteelBlue', code: '#4682B4' },
    { label: 'SkyBlue', value: 'SkyBlue', code: '#87CEEB' },
    { label: 'DeepSkyBlue', value: 'DeepSkyBlue', code: '#00BFFF' },
    { label: 'DodgerBlue', value: 'DodgerBlue', code: '#1E90FF' },
    { label: 'RoyalBlue', value: 'RoyalBlue', code: '#4169E1' },
    { label: 'Blue', value: 'Blue', code: '#0000FF' },
    { label: 'Navy', value: 'Navy', code: '#000080' },
    { label: 'MidnightBlue', value: 'MidnightBlue', code: '#191970' },
    { label: 'RosyBrown', value: 'RosyBrown', code: '#BC8F8F' },
    { label: 'DarkGoldenrod', value: 'DarkGoldenrod', code: '#B8860B' },
    { label: 'Peru', value: 'Peru', code: '#CD853F' },
    { label: 'SaddleBrown', value: 'SaddleBrown', code: '#8B4513' },
    { label: 'Brown', value: 'Brown', code: '#A52A2A' },
    { label: 'Maroon', value: 'Maroon', code: '#800000' },
    { label: 'MistyRose', value: 'MistyRose', code: '#FFE4E1' },
    { label: 'AntiqueWhite', value: 'AntiqueWhite', code: '#FAEBD7' },
    { label: 'LightGray', value: 'LightGray', code: '#D3D3D3' },
    { label: 'Silver', value: 'Silver', code: '#C0C0C0' },
    { label: 'DarkGrey', value: 'DarkGrey', code: '#A9A9A9' },
    { label: 'DimGrey', value: 'DimGrey', code: '#696969' },
    { label: 'SlateGray', value: 'SlateGray', code: '#708090' },
    { label: 'DarkSlateGray', value: 'DarkSlateGray', code: '#2F4F4F' },
    { label: 'Black', value: 'Black', code: '#000000' },
]

export {
    COLOR_OPTIONS
}