import React, { useEffect, useState } from 'react';
// import Progress from 'react-progressbar';
import { useDispatch, useSelector } from 'react-redux';
import userTypes, { OrgRoles } from '../../helpers/userTypes';
import EventBookingHistory from './EventBookingHistory';
import { ToastContainer } from 'react-toastify';


const BookingTransactionHistory = (props) => {
    const dispatch = useDispatch();

    return (
        <div className="plandetail-card">
            <ToastContainer position='bottom-center' />
            {/* transaction history */}
            <h4 className=' text-[20px] sm:text-[23px] md:text-[24px] lg:text-[25px] font-sans font-semibold'>Event Booking Transaction History</h4>

            {(props.userType === userTypes.ORG_SUPER_ADMIN || props.userType === userTypes.ORG_ADMIN) && <div className="transaction-table-sect">
                <EventBookingHistory />
            </div>}

        </div>
    );
};

export default BookingTransactionHistory;
