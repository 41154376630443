import * as ActionTypes from "../constants/dateFilterConstants"


function getDateFilterReducer(state = {}, action) {
    switch (action.type) {
      case ActionTypes.GET_DATE_FILTER_REQUEST:
        return { loading: true };
      case ActionTypes.GET_DATE_FILTER_SUCCESS:
        return { loading: false, dateFilter: action.payload };
      case ActionTypes.GET_DATE_FILTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }


  function getOrgGamesReportReducer(state = {}, action) {
    switch (action.type) {
      case ActionTypes.GET_ORG_GAMES_REPORT_REQUEST:
        return { loading: true };
      case ActionTypes.GET_ORG_GAMES_REPORT_SUCCESS:
        return { loading: false, orgGamesAllReport: action.payload };
      case ActionTypes.GET_ORG_GAMES_REPORT_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  export {
    getDateFilterReducer,
    getOrgGamesReportReducer
  }