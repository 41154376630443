import * as ActionTypes from "../constants/teamApiConstants";
import { axiosApiInstance, BASE_URL } from "../../helpers/helper";

const getOrgTeams = (organizationId) => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_ORG_TEAMS_REQUEST });
    try {
        if (organizationId) {
            const { data } = await axiosApiInstance.post(BASE_URL + "/api/organization-team/get-org-teams", { organizationId });
            dispatch({ type: ActionTypes.GET_ORG_TEAMS_SUCCESS, payload: data });
            return { data };
        }
        else {
            dispatch({ type: ActionTypes.GET_ORG_TEAMS_FAIL, payload: "Organization id not found" });
        }

    } catch (error) {
        dispatch({ type: ActionTypes.GET_ORG_TEAMS_FAIL, payload: error.response });
    }
};

const getOrgTeamsGameSessions = (sessionType) => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_ORG_TEAM_SESSION_REQUEST });
    try {
        let url = "/api/session/get-teamSessions"
        if (sessionType === "Expired Sessions") {
            url = "/api/session/get-teamSessions?isExpired=true"
        }
        const { data } = await axiosApiInstance.get(BASE_URL + url);
        dispatch({ type: ActionTypes.GET_ORG_TEAM_SESSION_SUCCESS, payload: data });
        return { data };


    } catch (error) {
        dispatch({ type: ActionTypes.GET_ORG_TEAM_SESSION_FAIL, payload: error.response });
    }
};


export {
    getOrgTeams,
    getOrgTeamsGameSessions
}


