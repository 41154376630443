import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import HomeAddGameInSloganModal from "../bannerEdit/homeAddGameInSoganModal";
import ROLES from "../../helpers/userTypes";
import BannerDeleteModal from "../bannerEdit/BannerDeleteModal";
import { getAllSlogans, updateSlogan } from "../../redux/actions/homepageActions";
import ConfirmSloganSortModal from "../bannerEdit/ConfirmSloganSortModal";

const PlayCardHeading = React.memo(({
  title,
  titleHighlight,
  link,
  isAdmin,
  gamecount,
  sloganData,
  sloganType,
  setSloganData,
  mainUserType,
  sloganFor,
  clickAddGame,
  setOnClickAddGame,
  selectedOrganization,
  searchFilter
  // onUpdateGameOrder,
}) => {
  const AllGames = useSelector((state) => state.allGames);
  const { allGames } = AllGames;
  const GetSlogans = useSelector(state => state.getSlogans);
  const { allSlogans } = GetSlogans;
  const dispatch = useDispatch();

  const [addGameInSloganModal, setAddGameInSloganModal] = useState(false);
  const [gamePosition, setGamePosition] = useState({
    RELEASES: [],
    PICKS: [],
    TRENDING: []
  });

  const [organizationSubscribedStaus, setOrganizationSubscribedStaus] = useState("NONSUBSCRIBED");
  const [selectedRegion, setSelectedRegion] = useState({ regionId: "ASIA", regionName: "ASIA" })
  const [sloganEditId, setSloganEditId] = useState('');
  const [slogans, setSlogans] = useState([]);
  const [gameOrder, setGameOrder] = useState([]);
  const [selectedSloganIndex, setSelectSloganIndex] = useState("")
  const [desiredData, setDesiredData] = useState({})
  const [bannerGameId, setBannerGameId] = useState("");
  const [bannerDeleteModal, setBannerDeleteModal] = useState(false);
  const [sloganFlag, setSloganFlag] = useState("");
  const [gameDetails, setGameDetails] = useState({});
  const [activeSloganType, setActiveSloganType] = useState("");
  const [slogansFor, setSlogansFor] = useState("NONLOGIN");
  const [confirmSloganSortModal, setConfirmSloganSortModal] = useState(false);
  const [userType, setUserType] = useState("NONLOGIN");

  const { userInfo } = useSelector(state => state.getUser);


  const positionSort = (a, b, slogan) => {
    if (!b.position)
      return -1;
    if (!a.position)
      return 1;
    if (!b.position[slogan])
      return -1;
    if (!a.position[slogan])
      return 1;
    if (a.position[slogan] < b.position[slogan])
      return -1;
    return 1;
  }
  const onUpdateGameOrder = async () => {
    let currentSlogan = slogans[selectedSloganIndex - 1];
    let gameData = gameOrder?.map(data => {
      return { id: data.id }
    });
    await dispatch(updateSlogan(currentSlogan?.id, {
      ...currentSlogan,
      games: [...gameData]
    }));
    setConfirmSloganSortModal(false);
    const subscriptionStatus = isAdmin ? organizationSubscribedStaus : (userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired) ? "SUBSCRIBED" : "UNSUBSCRIBED"
    dispatch(getAllSlogans(isAdmin ? selectedOrganization.organizationId : userInfo?.data?.organizationId, isAdmin ? userType : userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, selectedRegion?.regionId, isAdmin));

  };
  const filterGames = (slogan) => {
    let games = allGames.data?.filter((game) => {
      if (game.slogan && game.slogan.length > 0) {
        let flag = false;
        game.slogan.map(gameSlogan => {
          if (JSON.stringify(gameSlogan) === JSON.stringify(slogan)) {
            flag = true;
          }
        })
        return flag;
      }
    })
    return games.sort((a, b) => positionSort(a, b, slogan.id));
  }

  const handleEditClick = (index, i) => {
    setAddGameInSloganModal(true);
    setActiveSloganType(sloganData.id);
    setSloganData(sloganData.id);
    setSelectSloganIndex(index + 1);
  };

  const [addGamesModel, setAddGamesMode] = useState(false);
  useEffect(() => {
    if (clickAddGame) {
      setAddGamesMode(true);
      handleEditClick(sloganType);
    }
  }, [clickAddGame])

  return (
    <>
      <div className="game-heading">
        <div className="game-heading-left">
          <div className="flex">
            <h2>
              {title}
              <span> {titleHighlight}
              </span>
            </h2>
            {userInfo?.data?.role === ROLES.ORG_SUPER_ADMIN && searchFilter === false || isAdmin  ? <FaEdit
              key={sloganType}
              style={{ marginLeft: '7px', marginTop: '3px' }}
              onClick={() => handleEditClick(sloganType)}
            /> : null}
          </div>
          {/* {gamecount && isAdmin && <div className="gamecount">({gamecount})</div>} */}
        </div>
        {link && !isAdmin && (
          ((gamecount <= 2 && window.innerWidth <= 350) || (gamecount >= 3 && window.innerWidth <= 690) || (gamecount === 5 && window.innerWidth <= 860) || (gamecount > 5)) &&
          <Link to={{ pathname: "/play-game/" + sloganType }}>
            <span>Show All</span>
          </Link>
        )}
      </div>
      {bannerDeleteModal && userInfo && userInfo.data && (userInfo.data.role === ROLES.ORG_SUPER_ADMIN) && (
        <BannerDeleteModal
          bannerGameId={bannerGameId}
          setBannerDeleteModal={setBannerDeleteModal}
          sloganFlag={sloganFlag}
          gameDetails={gameDetails}
          setSloganFlag={setSloganFlag}
          sloganData={sloganData}
          games={gamePosition[activeSloganType]}
          selectedOrganization={selectedOrganization.organizationId}
          slogansFor={slogansFor}
          gameOrder={gameOrder}
          setGameOrder={setGameOrder}
          
        />
      )}

      {addGameInSloganModal && userInfo && userInfo.data && (userInfo.data.role === ROLES.ORG_SUPER_ADMIN) && (
        <HomeAddGameInSloganModal
          setAddGameInSloganModal={setAddGameInSloganModal}
          sloganData={sloganData}
          sloganType={sloganType}
          existing={gamePosition[sloganData]}
          // userInfo={userType}
          isAdmin={isAdmin}
          userInfo={userInfo}
          selectedOrganization={selectedOrganization}
          organizationSubscribedStaus={organizationSubscribedStaus}
          selectedRegion={selectedRegion?.regionId}
          sloganEditId={sloganEditId}
          selectedSlogan={slogans[selectedSloganIndex - 1]}
          gameOrder={gameOrder}
          setSloganData={setSloganData}
        // session={session}
        />
      )}
      {addGameInSloganModal && userInfo && userInfo.data && (userInfo.data.role === ROLES.EXTRAMILE_SUPERADMIN) && (
        <HomeAddGameInSloganModal
          setAddGameInSloganModal={setAddGameInSloganModal}
          sloganData={sloganData}
          sloganType={sloganType}
          existing={gamePosition[sloganData]}
          userType={mainUserType}
          isAdmin={isAdmin}
          userInfo={userInfo}
          selectedOrganization={selectedOrganization}
          organizationSubscribedStaus={sloganFor}
          selectedRegion={selectedRegion?.regionId}
          sloganEditId={sloganEditId}
          selectedSlogan={slogans[selectedSloganIndex - 1]}
          gameOrder={gameOrder}
          setSloganData={setSloganData}
          setOnClickAddGame={setOnClickAddGame}
          addGamesModel={addGamesModel}
          setAddGamesMode={setAddGamesMode}
        // session={session}
        />
      )}

      {confirmSloganSortModal && (
        <ConfirmSloganSortModal
          setConfirmSloganSortModal={setConfirmSloganSortModal}
          updateGamePosition={onUpdateGameOrder}
          sloganCategory={sloganData}
        />
      )}
    </>
  );
});

export default PlayCardHeading;

