import React, { useState } from "react";
import search from "../../assets/images/search.svg";
import DataTable from "react-data-table-component";

function ClaimTable(props) {
  const handleSearchText = (e) => {
    props.setSearchText(e.target.value);
  };

  const customStyles = {
    headCells: {
      style: {
        "font-family": "firaSans-semibold",
        "border-bottom": "2px #cdd2f1 solid",
        border: "1px #cdd2f1 solid"
      }
    },
    cells: {
      style: {
        "font-family": "firaSans-regular",
        "font-size": "14px",
        "text-align": "center"
      }
    },
    header: {
      style: {
        "font-family": "firaSans-semibold",
        color: "#737373"
      }
    },
    pagination: {
      style: {
        "border-radius": "0px"
        // "justify-content":"center"
      }
    }
  };

  const org_colums = [
    {
      name: "Name",
      selector: "firstName",
      center: true,
      sortable: true
    },
    {
      name: "Email",
      selector: "email",
      center: true,
      sortable: true
    },
    {
      name: "Rank",
      selector: "rank",
      center: true,
      sortable: true
    },
    {
      name: "Relpos",
      selector: "whenReleased",
      center: true,
      sortable: true
    },
    {
      name: "Timestamp",
      selector: "createdAt",
      center: true,
      sortable: true,
      cell: (id) => (
        <span>{new Date(id?.createdAt).toLocaleString()}</span>
      )
    },
    {
      name: "Organization",
      selector: "organizationName",
      center: true,
      sortable: true,
    },
    {
      name: "User Type",
      selector: "pollsresultLive",
      center: true,
      sortable: true,
      cell: (id) => (
        <span>{id?.userId ? "Employee" : "Guest User"}</span>
      )
    },
    {
      name: "Status",
      selector: "TimeStamp",
      center: true,
      sortable: true,
      cell: (id) => (
        <span>{id.allowed ? "Claim Success" : " Claim Rejected"}</span>
      )
    }
  ];

  return (
    <>
      <div style={{ padding: "20px", display: "flex" }}>
        <div className="input-icon">
          <img src={search} alt="search" />
          <input
            type="text"
            name="search"
            value={props.searchText}
            onChange={handleSearchText}
            placeholder="Search by Game Name"
          />
        </div>
      </div>

      <div>
        <div>
          <div className="claim-table">
            <DataTable
              columns={org_colums}
              data={props.data}
              className="rdt_TableHead"
              responsive="true"
              striped="true"
              highlightOnHover="true"
              pagination="true"
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimTable;
