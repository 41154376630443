import React, { useEffect, useState } from "react";
import "./index.css";
import exploreMoreIcon from "../../assets/icons/exploreMoreIcon.png";
import bgImage from '../../assets/icons/BG-dots.jpg';
import image1 from '../../assets/icons/03.png';
import image2 from '../../assets/icons/01.png';
import image3 from '../../assets/icons/02.png';
import image4 from '../../assets/icons/06.png';
import image5 from '../../assets/icons/5.png';
import image6 from '../../assets/icons/04.png';
import image7 from '../../assets/icons/08.png';
import image8 from '../../assets/icons/07.png';
import image12 from '../../assets/icons/12.png';
import image14 from '../../assets/icons/14.png';
import image16 from '../../assets/icons/16.png';
import image17 from '../../assets/icons/17.png';
import image18 from '../../assets/icons/18.png';
import newRocket from '../../assets/icons/03.png';
import axios from "axios";
import { BASE_URL } from "../../helpers/helper";
import EmailExistModal from "../../components/modal/emailExistModal";

const initialPositions = [
  { top: "30vh", left: "-1vw", size: "90px", img: image3 },
  { top: "-4vh", left: "10vw", size: "115px", img: image2 },
  { top: "50vh", left: "25vw", size: "70px", img: image12 },
  { top: "-10vh", left: "25vw", size: "70px", img: image18 },
  { top: "70vh", left: "5vw", size: "30px", img: image4 },
  { top: "5vh", left: "35vw", size: "70px", img: image7 },
  { top: "64vh", left: "80vw", size: "18px", img: image17 },
  { top: "48vh", left: "90vw", size: "90px", img: image6 },
  { top: "72vh", left: "60vw", size: "90px", img: image8 },
  { top: "14vh", left: "80vw", size: "110px", img: image1 },
  { top: "65vh", left: "98vw", size: "18px", img: image5 },
  { top: "18vh", left: "120vw", size: "110px", img: image14 },
  { top: "60vh", left: "126vw", size: "30px", img: image16 },
];

const finalPositions = [
  { top: "58vh", left: "85vw", size: "100px" },
  { top: "70vh", left: "-2vw", size: "105px" },
  { top: "44vh", left: "120vw", size: "40px" },
  { top: "44vh", left: "68vw", size: "40px" },
  { top: "15vh", left: "82vw", size: "30px" },
  { top: "65vh", left: "60vw", size: "70px" },
  { top: "28vh", left: "28vw", size: "90px" },
  { top: "22vh", left: "45vw", size: "90px" },
  { top: "24vh", left: "3vw", size: "100px" },
  { top: "60vh", left: "200vw", size: "100px" },
  { top: "10vh", left: "24vw", size: "20px" },
  { top: "5vh", left: "95vw", size: "70px" },
  { top: "27vh", left: "88vw", size: "80px" },
];

const getResponsivePositions = (positions, isMobile) => {
  return positions.map(pos => ({
    ...pos,
    size: isMobile ? `${parseInt(pos.size) / 2}px` : pos.size,
  }));
};

const GenerateDiscount = ({ companyName, email, onInputChange, onScore }) => {
  const [planformData, setPlanFormData] = useState({
    companyName: '',
    email: ''
  });
  const [planformerrors, setPlanFormErrors] = useState({
    companyName: '',
    email: ''
  });
  useEffect(() => {
    if (companyName || email) {
      setPlanFormData({
        companyName,
        email
      });
    }
  }, [companyName, email]);
  const [openEmailExistModal, setOpenEmailExistModal] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [divPositions, setDivPositions] = useState([]);
  const [isMoved, setIsMoved] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [blurred, setBlurred] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [randomImages, setRandomImages] = useState([]);
  const [scaledIndex, setScaledIndex] = useState(null);
  const [clickedRockets, setClickedRockets] = useState(new Set());
  const [showSpinner, setShowSpinner] = useState(false);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    setDivPositions(getResponsivePositions(initialPositions, isMobile));
  }, [isMobile]);


  const handleMove = () => {
    if (isMoved) return;

    const finalDivPositions = getResponsivePositions(finalPositions, isMobile);
    setDivPositions(finalDivPositions.map((pos, index) => ({ ...pos, img: initialPositions[index].img })));
    setIsMoved(true);
    setTimeout(() => {
      setShowConfetti(true);
    }, 1000);

    setTimeout(() => {
      setBlurred(true);
      setTimeout(() => {
        setIsFormVisible(false);
        setShowConfetti(false);
        generateRandomImages();
      }, 1500);
    }, 1500);
  };

  const handlePlanFormSubmit = async (e) => {
    e.preventDefault();
    if (validatePlanForm()) {
      setLoaded(false);
      const checkEmail = await axios.post(BASE_URL + "/api/user/check/new-user-email", { email: planformData.email });
      if (checkEmail && checkEmail.data.message === "email is already taken") {
        setOpenEmailExistModal(true);
        setLoaded(true);
      } else {
        sessionStorage.setItem('companyInfo', JSON.stringify(planformData));
        setShowSpinner(true);
        setTimeout(() => {
          setShowSpinner(false);
        }, 5000);
        handleMove();
        setLoaded(true);
      }
    }
  };

  const validatePlanForm = () => {
    const errors = {};
    if (!planformData.companyName) {
      errors.companyName = 'Please fill out this field';
    }
    if (!planformData.email) {
      errors.email = 'Please fill out this field';
    } else if (!/\S+@\S+\.\S+/.test(planformData.email)) {
      errors.email = 'Enter valid email address';
    }
    setPlanFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const generateRandomImages = () => {
    const numberOfImages = Math.floor(Math.random() * 4) + 1;
    const selectedImages = [];
    let attempts = 0;

    const margin = 200;
    const containerWidth = window.innerWidth - margin * 2;
    const containerHeight = window.innerHeight - margin * 2;

    while (selectedImages.length < numberOfImages && attempts < 100) {
      attempts += 1;

      const randomSize = Math.floor(Math.random() * 20) + 50;
      const maxTop = containerHeight - randomSize;
      const maxLeft = containerWidth - randomSize;

      const randomTop = `${Math.floor(Math.random() * maxTop) + margin}px`;
      const randomLeft = `${Math.floor(Math.random() * maxLeft) + margin}px`;

      const newImage = {
        img: newRocket,
        top: randomTop,
        left: randomLeft,
        size: `${randomSize}px`,
      };

      if (!checkOverlap(newImage, selectedImages)) {
        selectedImages.push(newImage);
      }
    }

    setRandomImages(selectedImages);
  };

  const checkOverlap = (image, otherImages) => {
    const imageRect = {
      top: parseFloat(image.top),
      left: parseFloat(image.left),
      right: parseFloat(image.left) + parseFloat(image.size),
      bottom: parseFloat(image.top) + parseFloat(image.size)
    };

    for (const otherImage of otherImages) {
      const otherRect = {
        top: parseFloat(otherImage.top),
        left: parseFloat(otherImage.left),
        right: parseFloat(otherImage.left) + parseFloat(otherImage.size),
        bottom: parseFloat(otherImage.top) + parseFloat(otherImage.size)
      };

      if (!(imageRect.right < otherRect.left ||
        imageRect.left > otherRect.right ||
        imageRect.bottom < otherRect.top ||
        imageRect.top > otherRect.bottom)) {
        return true;
      }
    }
    return false;
  };

  const handleRocketClick = (index) => {
    if (clickedRockets.has(index)) return;
  
    // Add the index to the clicked set
    setClickedRockets(prev => new Set(prev).add(index));
  
    // Trigger scoring if all rockets are clicked
    if (clickedRockets.size + 1 === randomImages.length) {
      onScore(randomImages.length * 5);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlanFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    onInputChange(name, value);
  };
  const handlePlanFormChange = (e) => {
    const { name, value } = e.target;
    setPlanFormData({
      ...planformData,
      [name]: value
    });
    onInputChange(name, value);
    // if (name == 'email') {
    //   setFormValues(prevValues => ({
    //     ...prevValues,
    //     email: value,  // Set the new email
    //   }));
    // }
    // if (name == 'companyName') {
    //   setFormValues(prevValues => ({
    //     ...prevValues,
    //     companyname: value
    //   }));
    // }

  };
  return (
    <div className="parent-div flex items-center justify-center" style={{
      width: '100%',
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      cursor: 'pointer',
      padding: '2rem',
      boxSizing: 'border-box',
      position: 'relative',
    }}>
      {openEmailExistModal && (
        <EmailExistModal modalid={"email-exist-modal"} toggle={openEmailExistModal} setOpenEmailExistModal={setOpenEmailExistModal} />
      )}
  
      {divPositions.map((div, index) => (
        <img
          key={index}
          src={div.img}
          alt={`image-${index}`}
          className="child-div"
          style={{
            position: 'absolute',
            top: div.top,
            left: div.left,
            width: div.size,
            height: div.size,
            transition: "top 4s ease-in-out, left 4s ease-in-out",
            objectFit: 'cover',
            zIndex: 1, // Set lower z-index for animated images
          }}
          onClick={handleMove}
        />
      ))}
  
      {isFormVisible && (
        <form
          onSubmit={handlePlanFormSubmit}
          className={`bg-transparent rounded-lg w-full md:w-[50%]`}
          style={{ zIndex: 10 }} // Set higher z-index for the form
        >
          <h2 className="text-center text-[22px] md:text-[46px] font-semibold text-white">
            Find The Space Craft & Unlock <br /> Your Gift
          </h2>
          <p className="text-center text-sm text-white mb-6 text-[14px] md:text-[16px] blur1">
            Unlock an exclusive 5% discount on your next purchase! Hurry and avail this limited-time offer. Don't miss out on savings while enjoying our top-notch products and services. Grab your deal now!
          </p>
          <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
            <div className="flex flex-col w-full mb-2">
              <input
                type="text"
                placeholder="Company Name"
                id="companyName"
                name="companyName"
                value={planformData.companyName}
                onChange={handlePlanFormChange}
                className="w-full p-2 border border-gray-300 rounded discountforminput mb-4 sm:mb-0"
              />
              {planformerrors.companyName && (
                <span className="text-red-500 text-sm">{planformerrors.companyName}</span>
              )}
            </div>
            <div className="flex flex-col w-full">
              <input
                type="text"
                id="email"
                name="email"
                value={planformData.email}
                onChange={handlePlanFormChange}
                placeholder="Email"
                className="w-full p-2 border border-gray-300 rounded discountforminput"
              />
              {planformerrors.email && (
                <span className="text-red-500 text-sm">{planformerrors.email}</span>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="flex justify-center items-center text-white px-4 py-2 transition button-view-plans"
            >
              <span className="font-semibold">Find Now</span>
              <img
                src={exploreMoreIcon}
                alt="Icon"
                className="h-5 w-5 ml-2"
              />
            </button>
          </div>
        </form>
      )}
  
      {randomImages.map((img, index) => (
        <img
          key={index}
          src={img.img}
          alt={`random-image-${index}`}
          className={`random-image ${clickedRockets.has(index) ? 'scaled' : ''}`}
          style={{
            position: 'absolute',
            top: img.top,
            left: img.left,
            width: img.size,
            height: img.size,
            objectFit: 'cover',
            cursor: 'pointer',
            zIndex: 1, // Ensure random images have a lower z-index
          }}
          onClick={() => handleRocketClick(index)}
        />
      ))}
    </div>
  );
};

export default GenerateDiscount;
