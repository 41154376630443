import * as ActionTypes from "../constants/organizationLogoConstants";

const initialState = {
  loading: false,
  orgLogos: [],
  error: ""
};

function getAllOrganizationLogosReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_ORGANIZATIONLOGO_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_ORGANIZATIONLOGO_SUCCESS:
      return { ...state, loading: false, orgLogos: action.payload };
    case ActionTypes.FETCH_ORGANIZATIONLOGO_FAILURE:
      return { ...state,loading: false, orgLogos: [], error: action.payload };
    default:
      return state;
  }
}

export { getAllOrganizationLogosReducer };
