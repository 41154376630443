import { useSelector } from "react-redux";

const PointsEarned = () => {
  const UserPoints = useSelector(state => state.getUsersPoints)
  const {xoxoUserPoints} = UserPoints

  return (
    <>
      <div className="points-table">
        <table>
          <thead>
            <tr>
              <th className="thead-points">My Points</th>
              <th className="thead-earned">Points Earned</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td className="trow-head">My Favourites</td>
              <td className="trow-data flex">Total Points  <div className="redeem-points ml-20">{xoxoUserPoints?.data?.points_received}</div></td>
            </tr>
            <tr>
              <td className="trow-head">My Reports</td>
              <td className="trow-data"></td>
            </tr>
            <tr>
              <td className="trow-head">Download</td>
              <td className="trow-data flex">Redeemable Points <div className="redeem-points ml-6">{xoxoUserPoints?.data?.points_redeemed}</div></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
export default PointsEarned;