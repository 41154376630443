import React, { useState, useEffect } from 'react';
import './discount.css'; 

import wheelImage5 from '../../assets/icons/05.png';
import wheelImage10 from '../../assets/icons/10.png';
import wheelImage15 from '../../assets/icons/15.png';
import wheelImage20 from '../../assets/icons/20.png';

const imageMapping = {
  5: wheelImage5,
  10: wheelImage10,
  15: wheelImage15,
  20: wheelImage20,
};

const ImageZoomOut = ({ showImage, discountValue, onClose }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const imageToShow = imageMapping[discountValue] || wheelImage10;

  useEffect(() => {
    if (showImage) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [showImage]);

  useEffect(() => {
    if (showImage && isImageLoaded) {
      document.querySelector('.zoom-image')?.classList.add('pop-and-zoom');
    }
  }, [isImageLoaded, showImage]);

  return (
    showImage ? (
      <div className="zoom-container">
        <div className="image-wrapper">
          <button className="close-button" onClick={onClose}>×</button>
          <img
            src={imageToShow}
            alt="Discount"
            className={`zoom-image ${isImageLoaded ? 'pop-and-zoom' : ''}`}
            onLoad={() => setIsImageLoaded(true)}
          />
        </div>
      </div>
    ) : null
  );
};

export default ImageZoomOut;
