import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowDropup } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { IoTime } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";
import { Line } from 'react-chartjs-2';


import download from "../../assets/images/download.svg";


import { Chart, LineElement, PointElement, LineController, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import ReportsGrahpsGroupsTwo from "./reportDashBoardGraphs/reportGraphsGroup";
import DateRangePickerTwo from "./reportDashBoardGraphs/datePickerRange";
import { getOrgGamesReports } from "../../redux/actions/dateFilterAction";
import { size } from "synonyms/dictionary";

// Registering Chart.js components
Chart.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Title, Tooltip, Legend);

const OverviewGraphs = () => {

    const [activeUserCount, setActiveUserCount] = useState(0);
    const [gamesPlayedCount, setGamesPlayedCount] = useState(0);
    const [averagePlayTime, setAveragePlayTime] = useState({
        time: 0,
        type: 'mins'
    })

    const [objectivesGamesCount, setObjectivesGamesCount] = useState(0);
    const [categoryGamesCount, setCategoryGamesCount] = useState(0);


    const [userLineChatData, setUserLineChartData] = useState([]);
    const [userLineChartLables, setUserLineChartLables] = useState([]);

    const [avgUserPlayTimeLables, setAvgUserPlayTimeLables] = useState([]);
    const [avgUserPlayTimeData, setAvgUserPlayTimedata] = useState([]);

    const [gamesPlayedLables, setGamesPlayedLables] = useState([]);
    const [gamesPlayedData, setGamesPlayedData] = useState([]);

    const [topUsers, setTopUsers] = useState([]);

    const [prevUserCount, setPreUserCount] = useState(0);
    const [prevGameCount, setPreGameCount] = useState(0);
    const [prevAvgTimeCount, setPreAvgTimeCount] = useState(0);

    const [subCategoryLables, setSubCategoryLables] = useState([]);
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [subCategoryColorcode, setSubCategoryColorCode] = useState({});
    
    const [subObjLables, setSubObjLables] = useState([]);
    const [subObjData, setSubObjData] = useState([]);
    const [subObjColorcode, setSubObjColorCode] = useState({});


    const DateFilterValue = useSelector((state) => state.getDateFilterValue);
    const { dateFilter } = DateFilterValue;
    const OrgGamesReportData = useSelector((state) => state.getOrgGamesReports);
    const { orgGamesAllReport } = OrgGamesReportData;

    const dispatch = useDispatch()



    useEffect(() => {
        if (orgGamesAllReport && orgGamesAllReport.data && orgGamesAllReport.data.message === "user report fetch sucessfully") {
            setActiveUserCount(0);
            setGamesPlayedCount(0)
            setAveragePlayTime({
                time: 0,
                type: 'mins'
            })
            setCategoryGamesCount(0);
            setObjectivesGamesCount(0);
            setTopUsers([]);

            setUserLineChartData([]);
            setUserLineChartLables([]);

            setAvgUserPlayTimeLables([]);
            setAvgUserPlayTimedata([]);

            setGamesPlayedLables([]);
            setGamesPlayedData([]);

            setPreAvgTimeCount(0);
            setPreGameCount(0);
            setPreUserCount(0);

            setSubCategoryColorCode({});
            setSubObjColorCode({});

            setSubCategoryLables([]);
            setSubCategoryData([]);

            setSubObjLables([]);
            setSubObjData([]);

            if (orgGamesAllReport.data?.activeUsers) {
                const keys = Object.keys(orgGamesAllReport.data?.activeUsers);
                const values = Object.values(orgGamesAllReport.data?.activeUsers);
                setUserLineChartData(values.reverse());
                setUserLineChartLables(keys.reverse())
            }
            if (orgGamesAllReport.data?.gamesPlayed) {
                const keys = Object.keys(orgGamesAllReport.data?.gamesPlayed);
                const values = Object.values(orgGamesAllReport.data?.gamesPlayed);
                setGamesPlayedData(values.reverse());
                setGamesPlayedLables(keys.reverse());

            }
            if (orgGamesAllReport.data?.avgPlayTimes) {
                const keys = Object.keys(orgGamesAllReport.data?.avgPlayTimes);
                const values = Object.values(orgGamesAllReport.data?.avgPlayTimes);
                values.map((time) => {

                    const [minutes, seconds] = time.split(':');
                    const numericTime = Number(minutes);

                    setAvgUserPlayTimedata((prevState) => ([...prevState, numericTime]));
                })
                setAvgUserPlayTimeLables(keys);

            }
            if (orgGamesAllReport.data?.totalAvgTime) {
                // const [minutes, seconds] = orgGamesAllReport.data?.totalAvgTime.split(':');
                function formatTime(time) {
                    let [hours, minutes] = time.split(":");
                    hours = parseInt(hours, 10).toString();

                    if (hours.length < 2) {
                        hours = `0${hours}`;
                    }

                    return `${hours}:${minutes}`;
                }

                let formatedMins = formatTime(orgGamesAllReport.data?.totalAvgTime)
                setAveragePlayTime({
                    time: formatedMins,
                    type: "mins"
                })

            }
            if (orgGamesAllReport.data?.categoryCount) {
                setCategoryGamesCount(orgGamesAllReport.data?.categoryCount)
            }
            if (orgGamesAllReport.data?.objectivesCount) {
                setObjectivesGamesCount(orgGamesAllReport.data?.objectivesCount)
            }
            if (orgGamesAllReport.data?.gamesSize) {
                setGamesPlayedCount(orgGamesAllReport.data?.gamesSize)
            }
            if (orgGamesAllReport.data?.totalActiveUser) {
                setActiveUserCount(orgGamesAllReport.data?.totalActiveUser)
            }
            if (orgGamesAllReport.data?.topPlayers) {
                setTopUsers(orgGamesAllReport.data?.topPlayers)

            }
            if (orgGamesAllReport.data?.prevAvgTimeCount) {
                setPreAvgTimeCount(orgGamesAllReport.data?.prevAvgTimeCount)

            }
            if (orgGamesAllReport.data?.prevUsersCount) {
                setPreUserCount(orgGamesAllReport.data?.prevUsersCount)

            }
            if (orgGamesAllReport.data?.prevGamesCount) {
                setPreGameCount(orgGamesAllReport.data?.prevGamesCount)

            }
            if(orgGamesAllReport.data?.subCategoryArray){
                const keys = Object.keys(orgGamesAllReport.data?.subCategoryArray);
                const values = Object.values(orgGamesAllReport.data?.subCategoryArray);

                setSubCategoryLables(keys);
                setSubCategoryData(values)
            }
            if (orgGamesAllReport.data?.subcatColorCode) {
                setSubCategoryColorCode(orgGamesAllReport.data?.subcatColorCode)

            }
            if(orgGamesAllReport.data?.subObjectivesArray){
                const keys = Object.keys(orgGamesAllReport.data?.subObjectivesArray);
                const values = Object.values(orgGamesAllReport.data?.subObjectivesArray);

                setSubObjLables(keys);
                setSubObjData(values)
            }
            if (orgGamesAllReport.data?.subObjColorCode) {
                setSubObjColorCode(orgGamesAllReport.data?.subObjColorCode)

            }

        }
    }, [OrgGamesReportData])


    const data = {
        labels: userLineChartLables,
        datasets: [{
            label: 'Monthly Active Users',
            data: userLineChatData,
            fill: false,
            borderColor: '#A875E7', // Updated line color
            backgroundColor: '#A875E7',
            pointBackgroundColor: '#A875E7', // Keeps the point background color
            pointBorderColor: '#A875E7', // Keeps the point border color
            pointRadius: 0, // Larger point radius for easier hover
            pointHoverRadius: 3, // Larger hover radius for better UX
            pointHoverBorderWidth: 2,
            tension: 0.1
        }]
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false // Hides the vertical grid lines
                },
                ticks: {
                    font: {
                        size: 10
                    }
                }
            },
            y: {
                grid: {
                    borderDash: [5, 5] // Makes the horizontal grid lines dotted
                },
                title: {
                    display: true,
                    text: 'No of users',
                    color: '#8E8E8E',
                    font: {
                        size: 12
                    }
                },
                ticks: {
                   stepSize: 1
                }
            }
        },
        elements: {
            point: {
                radius: 0, // Initially hides the points
                hitRadius: 10, // Increases the hit area to make hover easier
                hoverRadius: 8,
            }
        },
        plugins: {
            legend: {
                display: true,
            }
        },
        tooltip: {
            enabled: true, // Ensures tooltips are enabled
            callbacks: {
                // Customize the tooltip to display the label and value
                label: function (tooltipItem) {
                    return `Count: ${tooltipItem.raw}`;
                }
            }
        }
    };
    return (
        <div className="overflow-wrapper">

            {/* avgerage card */}
            <div className="flex flex-col lg:flex-row justify-between mb-5">

                <div className="flex flex-row justify-between h-[122px] mb-3 lg:mb-0 w-full lg:w-[32%] rounded-[12px] p-[16px] relative overflow-hidden"
                    style={{
                        background: 'linear-gradient(272.64deg, #F5899E 2.89%, #F7C2B5 97.8%)'
                    }}
                >
                    <div className="flex absolute bg-[#FFFFFF33] w-[95px] h-[95px] rounded-full right-[-30px] top-[-20px]"></div>
                    <div className="flex absolute bg-[#FFFFFF33] w-[110px] h-[110px] rounded-full right-[-5px] bottom-[-40px]"></div>

                    <div className="flex flex-col h-full justify-between">
                        <span className=" text-[12px] lg:text-[14px] text-white font-sans">Monthly Active Users</span>
                        <h2 className="text-[24px] lg:text-[32px] text-white font-sans font-semibold">{activeUserCount ? activeUserCount : 0}</h2>

                        <div className="flex flex-row items-center">
                            <div className="flex flex-row p-[3px] bg-[#FFFFFF33] rounded-[4px]">
                                <IoMdArrowDropup fill="white" />
                                <span className="text-[12px] text-white font-sans">{`${prevUserCount}%`}</span>
                            </div>
                            <span className="text-[12px] text-white font-sans ml-2">{`Since ${dateFilter?.filterType }`}</span>
                        </div>

                    </div>

                    <div className="flex items-center justify-center w-[40px] h-[40px]">
                        <FaUser fill="white" style={{ width: '20px', height: '24px' }} />

                    </div>

                </div>

                <div className="flex flex-row justify-between h-[122px] mb-3 lg:mb-0 w-full lg:w-[32%] rounded-[12px] p-[16px] relative overflow-hidden"
                    style={{
                        background: 'linear-gradient(91.95deg, #9DC9F5 1.65%, #4587DC 98.35%)'
                    }}
                >
                    <div className="flex absolute bg-[#FFFFFF33] w-[95px] h-[95px] rounded-full right-[-30px] top-[-20px]"></div>
                    <div className="flex absolute bg-[#FFFFFF33] w-[110px] h-[110px] rounded-full right-[-5px] bottom-[-40px]"></div>

                    <div className="flex flex-col h-full justify-between">
                        <span className="text-[12px] lg:text-[14px] text-white font-sans">Avg Play time</span>
                        <h2 className="text-[24px] lg:text-[32px] text-white font-sans font-semibold">{averagePlayTime && averagePlayTime.time ? `${averagePlayTime.time} ${averagePlayTime.type}` : `0 mins`}</h2>

                        <div className="flex flex-row items-center">
                            <div className="flex flex-row p-[3px] bg-[#FFFFFF33] rounded-[4px]">
                                <IoMdArrowDropup fill="white" />
                                <span className="text-[12px] text-white font-sans">{`${prevAvgTimeCount}%`}</span>
                            </div>
                            <span className="text-[12px] text-white font-sans ml-2">{`Since ${dateFilter?.filterType }`}</span>
                        </div>

                    </div>

                    <div className="flex items-center justify-center w-[40px] h-[40px]">
                        <IoTime fill="white" style={{ width: '20px', height: '24px' }} />

                    </div>

                </div>

                <div className="flex flex-row justify-between h-[122px]  w-full lg:w-[32%] rounded-[12px] p-[16px] relative overflow-hidden"
                    style={{
                        background: 'linear-gradient(92deg, #98D7D2 3.04%, #66C5AF 96.79%)'

                    }}
                >
                    <div className="flex absolute bg-[#FFFFFF33] w-[95px] h-[95px] rounded-full right-[-30px] top-[-20px]"></div>
                    <div className="flex absolute bg-[#FFFFFF33] w-[110px] h-[110px] rounded-full right-[-5px] bottom-[-40px]"></div>

                    <div className="flex flex-col h-full justify-between">
                        <span className="text-[12px] lg:text-[14px] text-white font-sans">No. of Games PLayed</span>
                        <h2 className="text-[24px] lg:text-[32px] text-white font-sans font-semibold">{gamesPlayedCount ? gamesPlayedCount : 0}</h2>

                        <div className="flex flex-row items-center">
                            <div className="flex flex-row p-[3px] bg-[#FFFFFF33] rounded-[4px]">
                                <IoMdArrowDropup fill="white" />
                                <span className="text-[12px] text-white font-sans">{`${prevGameCount}%`}</span>
                            </div>
                            <span className="text-[12px] text-white font-sans ml-2">{`Since ${dateFilter?.filterType }`}</span>
                        </div>

                    </div>

                    <div className="flex items-center justify-center w-[40px] h-[40px]">
                        <FaPlay fill="white" style={{ width: '20px', height: '24px' }} />

                    </div>

                </div>
            </div>

            {/* <div className="flex w-full items-center bg-white mb-5 p-[20px] h-[360px] rounded-[8px]">
                <Line data={data} options={options} />
            </div> */}
            <div className="flex flex-col w-full bg-white rounded-[8px] p-[20px] mb-5 items-start">
                <div className="flex w-full" style={{ borderBottom: '1px solid #EDEDED' }}>
                    <h2 className=" text-[20px] font-sans font-semibold mb-[20px]">Monthly Active Users</h2>
                </div>

                <div className="flex flex-col w-full items-start bg-white rounded-[8px]" style={{ height: '360px' }}>
                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                        <Line data={data} options={options} />
                    </div>
                </div>
            </div>

            <ReportsGrahpsGroupsTwo
                categoryGamesCount={categoryGamesCount}
                objectiveGamesCount={objectivesGamesCount}
                avgUserPlayTimeLables={avgUserPlayTimeLables}
                avgUserPlayTimeData={avgUserPlayTimeData}
                totalGamesPlayedData={gamesPlayedData}
                totalGamesPlayedLables={gamesPlayedLables}
                topPlayers={topUsers}

                subCategoryLables={subCategoryLables}
                subCategoryData={subCategoryData}
                subCategoryColorcode={subCategoryColorcode}

                subObjData={subObjData}
                subObjLables={subObjLables}
                subObjColorCode={subObjColorcode}
            />



        </div>
    );
};

export default OverviewGraphs;
