import React from "react";
import StarRatings from "react-star-ratings";

import "./rating.css";

import diy from "../../assets/images/diy.svg";
import { useState } from "react";

const Rating = (props) => {
  const [gameDetailsInfo, setGameDetailsInfo] = useState(props.gameDetail)
  const isAdmin = true;
  return (
    <div className="game-rating">
      {isAdmin ? (
        <>
          <h6>Rating {(gameDetailsInfo.rating ? gameDetailsInfo.rating : 0).toFixed(1)}</h6>
          <StarRatings
            rating={gameDetailsInfo.rating ? gameDetailsInfo.rating : 0}
            starRatedColor="#FED10C"
            numberOfStars={5}
            name="rating"
          />
          <span>({gameDetailsInfo.playedCount} plays)</span>
          {/* <div className="diy-text">
            <img src={diy} alt="diy" className='diy-img' />
            DIY
          </div> */}
        </>
      ) : (
        <>
          <div>
            <h2>Rating</h2>

            <label className="switch">
              <input type="checkbox" />
              <span className="slider"></span>
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default Rating;
