import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Modal from "../modal/modal";
import arrowback from "../../assets/images/arrow-left.svg";
import { BASE_URL, axiosApiInstance } from "../../helpers/helper";

const XoxoModal = ({
  userInfo,
  sessionId,
  showModal,
  setShowModal,
  gameId,
  xoxoGameDetail = null,
  activeTabType
}) => {
  const [gameDetail, setGameDetail] = useState({
    participationPoints: "",
    winnersCount: "",
    winnerPoints: []
  });
  useEffect(() => {
    if (xoxoGameDetail) {
      setGameDetail({
        participationPoints: xoxoGameDetail?.participationPoints
          ? xoxoGameDetail?.participationPoints
          : "",
        winnersCount: xoxoGameDetail?.winnersCount
          ? xoxoGameDetail?.winnersCount
          : "",
        winnerPoints: xoxoGameDetail?.winnersPoints
          ? xoxoGameDetail?.winnersPoints
          : ""
      });
    }
  }, [xoxoGameDetail]);
  const handleClose = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^0-9-]/g, "");
    if (name === "winnersCount") {
      setGameDetail({
        ...gameDetail,
        winnersCount: sanitizedValue,
        winnerPoints: Array.from(
          { length: parseInt(sanitizedValue, 10) },
          () => ""
        )
      });
    } else {
      setGameDetail((prevState) => ({
        ...prevState,
        [name]: sanitizedValue
      }));
    }
  };

  const handleGamePointsSubmit = async (e) => {
    e.preventDefault();
    // setXoxoModal(true);
    // setXoxoGameSession(sessionId);
    try {
      if (sessionId) {
        const result = await axiosApiInstance.post(
          BASE_URL + "/api/xoxoday-game/update-game-detail",
          {
            orgId: userInfo?.data?.organizationId,
            sessionId,
            gameId,
            xoxoGameDetail,
            ...gameDetail
          }
        );
        setShowModal(false);

        if (result?.status === 200) {
          toast.success(
            "The participation and winner points has been Updated Successfully!",
            {
              position: "bottom-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              pauseOnFocusLoss: false
            }
          );
        } else {
          toast.error("Failed to Update the count !", {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            pauseOnFocusLoss: false
          });
        }
      }
    } catch (err) {
      setShowModal(false);
      toast.error("Failed to Update the count !", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        pauseOnFocusLoss: false
      });
      console.log("err - ", err);
    }
  };

  const handleWinnerPointsChange = (index, value) => {
    const updatedWinnerPoints = gameDetail.winnerPoints?.length
      ? [...gameDetail.winnerPoints]
      : [];
    updatedWinnerPoints[index] = { [`winner-position-${index + 1}`]: value };
    setGameDetail({
      ...gameDetail,
      winnerPoints: updatedWinnerPoints
    });
  };

  return (
    <div>
      <Modal size={"lg"} showModal={showModal}>
        <div className="relative ">
          <div className="close-icon" data-dismiss="modal" aria-label="Close">
            <div className="close-btn-icon" onClick={handleClose}></div>
          </div>
          <div className="startnewgame-body">
            <div className="back-arrow">
              <img src={arrowback} alt="back" onClick={handleClose} />
            </div>
            <div className="header-part">
              <div className="title">
                <h4>{"Rewards"} </h4>
              </div>
            </div>
            <br />
            <div className="body-part">
              <form onSubmit={handleGamePointsSubmit}>
                {!xoxoGameDetail.participation && !xoxoGameDetail.winners && (
                  <h1>
                    {" "}
                    contact Admin to set Participation Points and Winners Count
                  </h1>
                )}
                {xoxoGameDetail.participation && (
                  <div className="form-group">
                    <label htmlFor="name">Participation points</label>
                    <input
                      disabled={activeTabType === "Active Sessions" ? false : true}
                      type="number"
                      className="form-control"
                      value={gameDetail?.participationPoints}
                      maxLength={10}
                      name="participationPoints"
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter a participation points"
                    />
                  </div>
                )}
                {xoxoGameDetail.winners && (
                  <div className="form-group">
                    <label htmlFor="name">Winners count</label>
                    <input
                      type="number"
                      className="form-control"
                      pattern="^[A-Za-z0-9 !\+_,\'-]+$"
                      maxLength={10}
                      disabled={activeTabType === "Active Sessions" ? false : true}
                      value={gameDetail?.winnersCount}
                      name="winnersCount"
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter a winners count"
                    />
                  </div>
                )}
                {xoxoGameDetail.participation &&
                  xoxoGameDetail.winners &&
                  Number(gameDetail.winnersCount) > 0 &&
                  Array.from({
                    length: parseInt(gameDetail.winnersCount, 10)
                  }).map((_, index) => (
                    <div key={index} className="form-group">
                      <label
                        htmlFor={`winnerPoints-${index}`}
                      >{`Points for Winner Position - ${index + 1} `}</label>
                      <input
                        disabled={
                          activeTabType === "Active Sessions" ? false : true
                        }
                        type="number"
                        className="form-control"
                        value={
                          gameDetail.winnerPoints?.length &&
                          gameDetail.winnerPoints[index] &&
                          gameDetail.winnerPoints[index][
                            `winner-position-${index + 1}`
                          ]
                        }
                        name={`winnerPoints-${index}`}
                        onChange={(e) =>
                          handleWinnerPointsChange(index, e.target.value)
                        }
                        placeholder={`Enter winner ${index + 1} points`}
                      />
                    </div>
                  ))}
                {(xoxoGameDetail.participation || xoxoGameDetail.winners) &&
                  activeTabType === "Active Sessions" && (
                    <div
                      className="account-btn-group"
                      style={{ marginTop: "20px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-outline"
                        onClick={() => handleClose}
                      >
                        Cancel
                      </button>
                      <button type="submit" className={"btn btn-primary"}>
                        Submit
                      </button>
                    </div>
                  )}
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default XoxoModal;
