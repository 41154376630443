import React, { useEffect, useState } from 'react';

import './leaderboard.css';
import morevertical from '../../assets/images/more-vertical.svg';
import { useSelector } from 'react-redux';
import { REPORT_LEADERBOARD_TABLE_DATA } from '../../constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import { BASE_URL, axiosApiInstance } from '../../helpers/helper';
import { BASE_URL, decryptData, encryptData, failureAlert, successAlert } from "../../helpers/helper";
import { center, flex, game, guest, report, state } from 'synonyms/dictionary';
import { useDispatch } from 'react-redux';
import { getGameReport, DownloadGuestSessionReport } from '../../redux/actions/reportsActions';
import axios from 'axios';


const TeamLeaderBoard = ({teamData}) => {
  const mode = window.location.pathname.split("/")[1]
//   const { gameReport } = useSelector(state => state.gameReport);

  const getRole = { 'ORG_SUPER_ADMIN': 'OWNER', 'ORG_ADMIN': 'ADMIN', 'EMPLOYEE': 'EMPLOYEE', 'USER': 'EMPLOYEE' };
  const [tableHeader, setTableHeader] = useState(REPORT_LEADERBOARD_TABLE_DATA);

  const dispatch = useDispatch();


//   useEffect(()=>{
//     if(teamData){
//         console.log("teamData: "+JSON.stringify(teamData));
        
//     }
//   },[teamData])
 

  return (
    <div>
      {/* table for desktop */}
      <div className="report-table leader-table overflow-x-auto">
        <table className="leader-board-table">
          <thead>
            <tr id='report-tb-head'>
              <th>Player Name</th>
              <th>Scores</th>
              <th style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Time Taken</th>
              <th>User Type</th>
            </tr>
          </thead>
          <tbody>

        {
              teamData && teamData.length > 0 ? teamData.map((report, index) => {
                return (
                  <tr>
                    <td style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}>
                      {(report?.firstName) ? (
                        <div className="table-detail">
                          <div className="name-initial">
                            <span>{(report?.firstName && report?.firstName.slice) ? report?.firstName.slice(0, 2).toUpperCase() : report?.email && report?.email.slice && report?.email.slice(0, 2).toUpperCase()}</span>
                          </div>
                          <div className="table-desc">
                            <h4>{report?.firstName && report?.firstName !== "" ? report?.firstName : report?.email}</h4>
                          </div>
                        </div>
                      ) : null
                        
                      }
                    </td>
                    <td>{report?.totalPoints}</td>
              
                    <td>
                      <div className="reports-dropdown-view" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {report?.totalTime}
                     
                      </div>
                    </td>
                    <td>{getRole[report?.role] || 'GUEST USER'}</td>
                  </tr>
                )
              }) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamLeaderBoard;