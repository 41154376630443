import React, { useEffect, useState } from 'react';

import './modal.css';
import Modal from './modal';
import contactusimg from '../../assets/images/contactus.svg';
import { useDispatch, useSelector } from 'react-redux';
import { plansContactUs } from '../../redux/actions/plansApiActions';
import { contactOrgAdmin } from '../../redux/actions/organisationActions';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import LoadingComponent from '../loader/LoadingComponent';
import {
  S3_BASE_URL,
  BASE_URL,
  GOOGLE_RECAPTCHA_SITE_KEY,
  axiosApiInstance,
  encryptData,
  decryptData
} from "../../helpers/helper";
import Select from "react-select";
import { failureAlert, IsAdmin, successAlert, Frontned_URL } from "../../helpers/helper";
const AddGamesBookEvent = ({ modalid, setOpenBookEventModal, toggle, categoryTitle, idsArr,categoryId }) => {
  
  const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [AllGames, setAllGamesGames] = useState([]);
  const [bookEventGames, setBookEventGames] = useState([]);

  useEffect(() => {
    if (AllGames) {
      if (idsArr && idsArr.length > 0) {
        const transformedArray = AllGames
          .filter(game => idsArr.includes(game.id)) // Filter by matching IDs
          .map(game => ({
            value: game.id, // Assign id to value
            label: game.title // Assign title to label
          }));

        setMultiSelectOptions(transformedArray)
      }
    }
  }, [AllGames]);

  const getAllGames = async () => {
    try {
      const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getBookEventAllGames"); // Replace with your API URL
      // console.log(response.data.data, "getBookEventAllGames")
      const result = response.data.data;
      setAllGamesGames(result);
      const options = result.map(item => ({
        value: item.id,
        label: item.title,
      }));


      setBookEventGames(options);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  useEffect(() => {
    getAllGames();
  }, []);

  const handleMultiSelectChange = (options) => {
    setMultiSelectOptions(options);
  };

  const addGamesToCategory = async (e) => {
    e.preventDefault();
    // console.log("Multi Select:", multiSelectOptions);
    if(multiSelectOptions.length>0){
      const gameIdsToSave = multiSelectOptions.map(option => option.value);
      const dataToSend = {
        gameIds: gameIdsToSave, // Array of IDs
        categoryId: categoryId,
      };
      try {
        setLoaded(false);
        const result = await axiosApiInstance.put(BASE_URL + "/api/bookEvent/updateGamesOnCategory", dataToSend)
          .then(async (response) => {
            successAlert('Games updated successfully');
            setOpenBookEventModal(false);
            setLoaded(true);
          })
          .catch((error) => {
            failureAlert("Failed to add request");
            setLoaded(true);
          });

      } catch (error) {
        setLoaded(true);
        console.error("Error during file uploads:", error);
      }
    }
  }
  return (
    <Modal modalid={modalid} toggle={toggle}>
      <div className={`modal-body contact-wrapper `}>
        {/* <LoadingComponent loaded={loaded} /> */}
        <div className="close-icon" id='close-icon' data-dismiss="modal" aria-label="Close" onClick={() => setOpenBookEventModal(false)}>
          <div className="close-btn-icon"></div>
        </div>
        <div className="loginModal" style={{ padding: "15px 36px" }}>
          <form class="bg-white w-full rounded p-3" onSubmit={addGamesToCategory}>
            <h2 class="text-xl font-semibold mb-4 text-center">Add Games To Category</h2>

            <div class="mb-4">
              <label for="firstSelect" class="block text-gray-700 text-sm font-bold mb-2 text-left"> {categoryTitle}</label>

            </div>

            <div class="mb-4">
              <label for="secondSelect" class="block text-gray-700 text-sm font-bold mb-2 text-left">Select Games</label>
              <Select
                value={multiSelectOptions}
                onChange={handleMultiSelectChange}
                options={bookEventGames}
                isMulti
                placeholder="Select Games"
              />
            </div>

            <div class="flex items-center justify-between">
              <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Update Games
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export default AddGamesBookEvent;