import React, { useEffect, useState } from "react";

import "./plandetail.css";
import { useDispatch, useSelector } from "react-redux";
import { downloadTransactionById } from "../../redux/actions/plansApiActions";
import { downloadFile } from "../../helpers/downloadFile";
import { SYMBOLS } from "../../constants";
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const TransactionTable = () => {

  const [showPlanDescr, setShowPlanDescr] = useState(-1);

  const dispatch = useDispatch();
  const { myPlanDetails } = useSelector((state) => state.getMyPlans);
  const priceFormatIndianLocale = Intl.NumberFormat('en-IN');

  const handleDownloadClick = (e, transId) => {
    dispatch(downloadTransactionById(transId)).then((res) => {
      downloadFile(res.data.downloadUrl);
    });
  };

  useEffect(() => { }, [myPlanDetails]);

  function capitalizeFirstLetter(str) {
    if (!str) {
      return "";
    }

    return str
      .toLowerCase()
      .split(/[_\s]+/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const formatFeatureText = (text) => {
    const capitalizedText = capitalizeFirstLetter(text);
    const words = capitalizedText.split(' ');
    const firstTwoWords = words.slice(0, 2).map((word, index) => {
      if (index === 1 && word.length === 2) {
        return word.toUpperCase();
      }
      return word;
    }).join(' ');
    const restOfWords = words.slice(2).join(' ');
    return `<strong>${firstTwoWords}</strong> ${restOfWords}`;
  };


  const handlePlanDescr = (index) => {
    if (index) {
      if (showPlanDescr === index) {
        setShowPlanDescr(-1)

      }
      else {
        setShowPlanDescr(index)

      }
    }
  }

  return (
    <div onClick={(e) => setShowPlanDescr(-1)}>
      <div className="report-table">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Plan Details</th>
              <th>Price</th>
              <th>See More</th>
              <th>Transaction ID</th>
            </tr>
          </thead>
          <tbody>
            {myPlanDetails &&
              myPlanDetails.data &&
              myPlanDetails.data.transactions &&
              myPlanDetails.data.transactions.map((transaction, index) => {
                return (
                  <tr key={transaction.id}>
                    <td>
                      {new Intl.DateTimeFormat(["ban", "id"]).format(
                        new Date(transaction.createdAt)
                      )}
                    </td>
                    <td>{transaction.plan.title} Plan</td>
                    <td>
                      <b>{`${SYMBOLS[transaction.currency]} ${transaction.amount}`}</b>
                    </td>
                    <td className=" flex items-center justify-center relative h-[80.8px]" >
                      {
                        transaction?.plan && transaction?.plan?.description ? 
                        
                          showPlanDescr === index ? (
                            <FaEye onClick={(e) => {
                              e.stopPropagation()
                              handlePlanDescr(index)
                            }
                            } />
                          ) : (
                            <FaEyeSlash onClick={(e) => {
                              e.stopPropagation()
                              handlePlanDescr(index)
                            }
                            } />
                          )
                        
                        : null
                      }
                      
                      {
                        showPlanDescr === index ? (
                          <div className=" absolute flex flex-col w-[240px] h-auto max-h-[360px] bg-white rounded-[10px] p-2 shadow-custom right-[-210px] top-[-200px]">
                            <div className=" flex w-[100%] h-[40px] bg-themeColor text-white text-lg items-center justify-center rounded-[5px]">
                              {
                                transaction?.plan && transaction?.plan?.planType ?
                                  capitalizeFirstLetter(transaction?.plan?.planType) : ''}

                            </div>
                            <div className="flex w-[100%] h-[30px] text-lg items-center font-semibold">
                              What You Get?
                            </div>
                            <div className="flex h-auto max-h-[290px] items-center justify-start flex-col">

                              {
                                transaction?.plan && transaction?.plan?.description &&
                                transaction?.plan?.description.map((data, indx) => {
                                  return (
                                    <div className=" flex flex-row items-center w-[100%] h-[35px] mt-[2px]">
                                      <div className=" flex items-center justify-center h-[15px] w-[15px] ">

                                        <svg
                                          stroke="currentColor"
                                          fill="#34C759"
                                          strokeWidth="2"
                                          viewBox="0 0 24 24"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{ height: '15px', width: '15px' }}
                                        >
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                          <path d="M12 2c-.218 0 -.432 .002 -.642 .005l-.616 .017l-.299 .013l-.579 .034l-.553 .046c-4.785 .464 -6.732 2.411 -7.196 7.196l-.046 .553l-.034 .579c-.005 .098 -.01 .198 -.013 .299l-.017 .616l-.004 .318l-.001 .324c0 .218 .002 .432 .005 .642l.017 .616l.013 .299l.034 .579l.046 .553c.464 4.785 2.411 6.732 7.196 7.196l.553 .046l.579 .034c.098 .005 .198 .01 .299 .013l.616 .017l.642 .005l.642 -.005l.616 -.017l.299 -.013l.579 -.034l.553 -.046c4.785 -.464 6.732 -2.411 7.196 -7.196l.046 -.553l.034 -.579c.005 -.098 .01 -.198 .013 -.299l.017 -.616l.005 -.642l-.005 -.642l-.017 -.616l-.013 -.299l-.034 -.579l-.046 -.553c-.464 -4.785 -2.411 -6.732 -7.196 -7.196l-.553 -.046l-.579 -.034a28.058 28.058 0 0 0 -.299 -.013l-.616 -.017l-.318 -.004l-.324 -.001zm2.293 7.293a1 1 0 0 1 1.497 1.32l-.083 .094l-4 4a1 1 0 0 1 -1.32 .083l-.094 -.083l-2 -2a1 1 0 0 1 1.32 -1.497l.094 .083l1.293 1.292l3.293 -3.292z" fill="#34C759" strokeWidth="0"></path>
                                        </svg>

                                      </div>
                                      <span style={{ marginLeft: '10px', fontSize: '13px', textAlign: 'start' }} dangerouslySetInnerHTML={{ __html: formatFeatureText(data) }}></span>

                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        )
                          : null
                      }


                    </td>
                    <td>
                      <div className="trans-btn">
                        {transaction.id}
                        <button
                          type="submit"
                          className="download-btn"
                          onClick={(e) =>
                            handleDownloadClick(e, transaction.id)
                          }
                        >
                          <svg
                            width="11"
                            height="12"
                            viewBox="0 0 11 12"
                            fill="none"
                          >
                            <path
                              d="M1 8.64703V9.8235C1 10.1355 1.12395 10.4348 1.34458 10.6554C1.56521 10.876 1.86445 11 2.17647 11H9.23529C9.54731 11 9.84655 10.876 10.0672 10.6554C10.2878 10.4348 10.4118 10.1355 10.4118 9.8235V8.64703"
                              stroke="var(--color-theme)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2.76172 5.11757L5.70289 8.05874L8.64407 5.11757"
                              stroke="var(--color-theme)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5.70312 1V8.05882"
                              stroke="var(--color-theme)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {/* table for mobile */}
      </div>
      <div className="table-responsive m-transacton-table">
        {/* first card */}
        {myPlanDetails &&
          myPlanDetails.data &&
          myPlanDetails.data.transactions &&
          myPlanDetails.data.transactions.map((transaction) => {
            return (
              <div className="trans-m-card c-white-card" key={transaction.id}>
                <div className="trans-m-top">
                  <div className="trans-detail">
                    <h5>{transaction.plan.title} Plan</h5>
                    <span>
                      {new Intl.DateTimeFormat(["ban", "id"]).format(
                        new Date(transaction.createdAt)
                      )}
                    </span>
                  </div>
                  <h4>{`${SYMBOLS[transaction.currency]} ${transaction.amount}`}</h4>
                </div>
                <div className="trans-m-bottom">
                  <span>Transaction Id: {transaction.id}</span>
                  <button
                    type="submit"
                    className="download-btn"
                    onClick={(e) => handleDownloadClick(e, transaction.id)}
                  >
                    <svg width="11" height="12" viewBox="0 0 11 12" fill="none">
                      <path
                        d="M1 8.64703V9.8235C1 10.1355 1.12395 10.4348 1.34458 10.6554C1.56521 10.876 1.86445 11 2.17647 11H9.23529C9.54731 11 9.84655 10.876 10.0672 10.6554C10.2878 10.4348 10.4118 10.1355 10.4118 9.8235V8.64703"
                        stroke="var(--color-theme)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.76172 5.11757L5.70289 8.05874L8.64407 5.11757"
                        stroke="var(--color-theme)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.70312 1V8.05882"
                        stroke="var(--color-theme)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TransactionTable;
