import * as ActionTypes from "../constants/adminApiConstants";
import { axiosApiInstance, BASE_URL } from "../../helpers/helper";


const getUsersCount = () => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_USERS_COUNT_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.get(BASE_URL + "/api/user/user-count");
        dispatch({ type: ActionTypes.GET_USERS_COUNT_SUCCESS, payload: data, });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.GET_USERS_COUNT_FAIL, payload: error.message, });
    }
};

const downloadRegisteredUsers = () => async (dispatch) => {
    dispatch({ type: ActionTypes.DOWNLOAD_REGISTERED_USERS_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.get(BASE_URL + "/api/user/download-registered-users");
        dispatch({ type: ActionTypes.DOWNLOAD_REGISTERED_USERS_SUCCESS, payload: data, });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.DOWNLOAD_REGISTERED_USERS_FAIL, payload: error.message, });
    }
};

const downloadSubscribedUsers = () => async (dispatch) => {
    dispatch({ type: ActionTypes.DOWNLOAD_SUBSCRIBED_USERS_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.get(BASE_URL + "/api/user/download-subscribed-users");
        dispatch({ type: ActionTypes.DOWNLOAD_SUBSCRIBED_USERS_SUCCESS, payload: data, });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.DOWNLOAD_SUBSCRIBED_USERS_FAIL, payload: error.message, });
    }
};

const updateSSODetails = (body) => async (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_SSO_DETAILS_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/sso/add", body);
        dispatch({ type: ActionTypes.UPDATE_SSO_DETAILS_SUCCESS, payload: data, });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.UPDATE_SSO_DETAILS_FAIL, payload: error.message, });
        return error;
    }
};

const getDiwaliCliams = (body) => async (dispatch) => {

    dispatch({ type: ActionTypes.GET_DIWALI_CLAIMS_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/get-diwali-claims", body);
        dispatch({ type: ActionTypes.GET_DIWALI_CLAIMS_SUCCESS, payload: data });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.GET_DIWALI_CLAIMS_FAIL, payload: error.message, });
        return error;
    }
};

const getDiwaliGameLinkInfo = (id) => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_DIWALI_GAME_LINK_INFO_REQUEST })
    try {
        const { data, status } = await axiosApiInstance.get(BASE_URL + `/api/game/diwali/game-link/link-info/${id}`,);
        dispatch({ type: ActionTypes.GET_DIWALI_GAME_LINK_INFO_SUCCESS, payload: data });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.GET_DIWALI_GAME_LINK_INFO_FAIL, payload: error.message, });
        return error;
    }
}

const updateGameLinkToggle = (body) => async (dispatch) => {
    dispatch({ type: ActionTypes.TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_REQUEST })
    try {
        const { status } = await axiosApiInstance.put(BASE_URL + "/api/game/diwali/game-link/toggle-active-status", body);
        dispatch({ type: ActionTypes.TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_SUCCESS });
        return status;
    } catch (error) {
        dispatch({ type: ActionTypes.TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_FAIL, payload: error.message, });
        return error;
    }
}

const updateGameLinkInfo = (body) => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_DDDT_LINKS_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/game-link/update-link-info", body);
        dispatch({ type: ActionTypes.GET_DDDT_LINKS_SUCCESS, payload: data });
        return { status };
    } catch (error) {
        dispatch({ type: ActionTypes.GET_DDDT_LINKS_FAIL, payload: error.message, });
        return error;
    }
};

const downloadGameLinkReportRequests = (body) => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_DDDT_LINKS_GAME_REPORT_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/game-link/download-report", body);
        dispatch({ type: ActionTypes.GET_DDDT_LINKS_GAME_REPORT_SUCCESS, payload: data, });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.GET_DDDT_LINKS_GAME_REPORT_FAIL, payload: error.message, });
    }
};

const addXoxodayGame = (body) => async (dispatch) => {

    dispatch({ type: ActionTypes.XOXODAY_GAME_ADD_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/xoxoday-game/add", body);
        dispatch({ type: ActionTypes.XOXODAY_GAME_ADD_SUCCESS, payload: data, });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.XOXODAY_GAME_ADD_FAIL, payload: error.message, });
    }
};

const getXoxodayGames = () => async (dispatch) => {

    dispatch({ type: ActionTypes.XOXODAY_GAMES_GET_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.get(BASE_URL + "/api/xoxoday-game/all");
        dispatch({ type: ActionTypes.XOXODAY_GAMES_GET_SUCCESS, payload: data, });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.XOXODAY_GAMES_GET_FAIL, payload: error.message, });
    }
};

// Get xoxoday organization active Games list
const getXoxoOrgActiveGamesList = (id) => async (dispatch) => {
    dispatch({ type: ActionTypes.XOXODAY_ORGANIZATION_ACTIVE_GAMES_GET_REQUEST});
    try {
        const { data, status } = await axiosApiInstance.get(`${BASE_URL}/api/xoxoday-game/xoxoday-org-active-game/${id}`);
        dispatch({ type: ActionTypes.XOXODAY_ORGANIZATION_ACTIVE_GAMES_SUCCESS, payload: data, });

        return { status, data }
    } catch (error) {
        dispatch({ type: ActionTypes.XOXODAY_ORGANIZATION_ACTIVE_GAMES_REQUEST_FAIL, payload: error.message, });
    }
};

const getUsersPoints = (id) => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_USERS_POINTS_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.get(`${BASE_URL}/api/xoxoday-game/getUserPoints/${id}`);
        dispatch({ type: ActionTypes.GET_USERS_POINTS_SUCCESS, payload: data, });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.GET_USERS_POINTS_FAIL, payload: error.message, });
    }
};

const pollQuestionPublish = (body) => async (dispatch) => {
    dispatch({ type: ActionTypes.PUBLISH_POLL_QUESTION_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/poll/publish-poll-question",body);
        dispatch({ type: ActionTypes.PUBLISH_POLL_QUESTION_SUCCESS, payload: data, });
        return { status, data };
    } catch (error) {
        dispatch({ type: ActionTypes.PUBLISH_POLL_QUESTION_FAIL, payload: error.message, });
    }
};
 
export {
    getUsersCount,
    downloadRegisteredUsers,
    downloadSubscribedUsers,
    updateSSODetails,
    getDiwaliCliams,
    getDiwaliGameLinkInfo,
    updateGameLinkInfo,
    updateGameLinkToggle,
    downloadGameLinkReportRequests,
    addXoxodayGame,
    getXoxodayGames,
    getXoxoOrgActiveGamesList,
    getUsersPoints,
    pollQuestionPublish
}