import React from "react";
import "./bannereditcard.css";

import { S3_BASE_URL } from "../../helpers/helper";

function BannerEditCard({ image, clickFunction, webinar, fromWebinarDescr }) {
    return (
        <div className="webinar-card"
            style={{
                height: fromWebinarDescr ? '300px' : '',
                width: fromWebinarDescr ? '200px' : ''
            }}
        >
            {
                image && /\.(gif|jpg|jpeg|png)$/.test(image) ? (
                    <img
                        src={S3_BASE_URL + image}
                        alt="img" onClick={() => clickFunction(webinar)}
                        style={{
                            height: fromWebinarDescr ? '100%' : '',
                            width: fromWebinarDescr ? '100%' : '',
                            objectFit: fromWebinarDescr ? 'fill' : '',
                            maxHeight: 'fit-content'

                        }}
                    />
                )
                    : (
                        <div className="flex w-[100%] rounded-[10px] h-[100%] bg-slate-100 skeleton-animation"></div>

                    )
            }

        </div>

    )
}
export default BannerEditCard;