import React, { useState, useRef, useEffect } from "react";
import PageLayout from "../../components/pagelayout/pagelayout";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import "./testimonial.css";
import DataTable from "react-data-table-component";
import search from "../../assets/images/search.svg";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { getAllUserTestimonials } from "../../redux/actions/userTestimonialActions";
import { successAlert, failureAlert, BASE_URL, axiosApiInstance, IsAdmin } from "../../helpers/helper";
import deleteIcon from '../../assets/images/delete.svg';
import { uploadFile } from '../../redux/actions/commonActions';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { S3_BASE_URL } from "../../helpers/helper";

import OfflineQuoteSuccess1 from "../../components/modal/offlineQuoteSuccessModal1";
const OrganizationLogos = (props) => {
  const customStyles = {
    headCells: {
      style: {
        fontFamily: "firaSans-semibold",
        fontSize: "15px",
        borderBottom: "1px solid Black",
        borderTop: "1px solid Black",
      }
    },
    cells: {
      style: {
        fontFamily: "firaSans-regular",
        fontSize: "14px",
        textAlign: "center",
      }
    },
    header: {
      style: {
        fontFamily: "firaSans-semibold",
        color: "#737373",
      }
    }
  };

  const dispatch = useDispatch();
  const { userTestimonial } = useSelector(state => state.userTestimonial);
  const isLoading = useSelector(state => state.offlineQuotes.loading);
  const isAdmin = IsAdmin();
  const [allUserTestimonial, setUserTestimonial] = useState([]);
  const [loaded, setLoaded] = useState(true);

  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [comments, setComments] = useState('');
  const [preview, setPreview] = useState('');
  const [nameError, setNameError] = useState('');
  const [designationError, setDesignationError] = useState('');
  const [commentsError, setCommentsError] = useState('');
  const [fileError, setFileError] = useState('');
  const fileInputRef = useRef(null);
  const [rowData, setcommentsDetails] = useState('');
  const [commentsDetailsModal, setcommentsDetailsModal] = useState(false);


  useEffect(() => {
    dispatch(getAllUserTestimonials(""));
  }, [dispatch]);

  const handleDelete = async (id, path) => {
    const logo = [path];

    if (logo.length > 0) {
      setLoaded(false);
      try {
        await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", logo);
        await axiosApiInstance.delete(`${BASE_URL}/api/userTestimonial/delete/${id}`);
        dispatch(getAllUserTestimonials(""));
        successAlert("Data deleted successfully");
      } catch (error) {
        failureAlert("Failed to delete banner");
      } finally {
        setLoaded(true);
      }
    }
  };

  useEffect(() => {
    setUserTestimonial(userTestimonial || []);
  }, [userTestimonial]);

  useEffect(() => {
    setLoaded(!isLoading);
  }, [isLoading]);

  const validateName = (value) => {
    return value.trim() ? '' : 'Name is required.';
  };

  const validateDesignation = (value) => {
    return value.trim() ? '' : 'Designation is required.';
  };

  const validateComments = (value) => {
    return value.trim() ? '' : 'Comments are required.';
  };

  const validateFile = (file) => {
    if (!file) {
      return 'Please select a photo.';
    } else if (file.size > 10 * 1024 * 1024) { // 10MB in bytes
      return 'File size should not exceed 10MB.';
    } 
    // else {
    //   return new Promise((resolve) => {
    //     const img = new Image();
    //     img.onload = () => {
    //       if (img.width !== 259 || img.height !== 194) {
    //         resolve('Image dimensions must be 259x194 pixels.');
    //       } else {
    //         setPreview(URL.createObjectURL(file));
    //         resolve('');
    //       }
    //     };
    //     img.src = URL.createObjectURL(file);
    //   });
    // }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setNameError(validateName(value));
  };

  const handleCommentsChange = (e) => {
    const value = e.target.value;
    setComments(value);
    setCommentsError(validateComments(value));
  };

  const handleDesignationChange = (e) => {
    const value = e.target.value;
    setDesignation(value);
    setDesignationError(validateDesignation(value));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const error = await validateFile(file);
    setFileError(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const file = fileInputRef.current.files[0];
    const nameError = validateName(name);
    const designationError = validateDesignation(designation);
    const commentsError = validateComments(comments);
    const fileError = await validateFile(file);

    setNameError(nameError);
    setDesignationError(designationError);
    setCommentsError(commentsError);
    setFileError(fileError);

    if (!nameError && !designationError && !commentsError && !fileError) {
      try {
        const formData = new FormData();
        formData.append("company-logos", file);

        setLoaded(false);
        const response = await dispatch(uploadFile(formData));

        if (response.status === 200) {
          const logoPath = response.data.data.path;
          const obj = {
            Name: name,
            Designation: designation,
            Comment: comments,
            path: logoPath,
          };

          const result = await axiosApiInstance.post(`${BASE_URL}/api/userTestimonial/add`, obj);

          if (result.status === 200) {
            successAlert("Data added successfully");
            setName('');
            setDesignation('');
            setComments('');
            setPreview('');
            if (fileInputRef.current) {
              fileInputRef.current.value = ''; // Clear the file input value
            }
            dispatch(getAllUserTestimonials("")); // Refresh the testimonials list
          }
        }
      } catch (error) {
        failureAlert("Failed to upload. Please try again.");
      } finally {
        setLoaded(true);
      }
    }
  };
  const viewComment = (row) => {

    setcommentsDetailsModal(true);
     setcommentsDetails(row);
  };
  const closeCommentModal = () => {
    setcommentsDetailsModal(false)
    };

  const columns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      center: true
    },
    {
      name: "Photo",
      selector: (row) => row.path,
      cell: row => (
        <img
          src={`${S3_BASE_URL}${row.path}`}
          style={{ width: '100px', height: '70px', padding: '10px' }}
          alt="Logo"
        />
      ),
      center: true
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      center: true
    },
    {
      name: "Designation",
      selector: (row) => row.Designation,
    },
    {
      name: "Details",
      cell: (row) => (
        <button
          className="btn btn-primary "
          data-toggle="modal"
          data-target="#payment-details-modal"
          onClick={() => viewComment(row)}
        >
    Comment
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Actions",
      cell: row => (
        <button 
          onClick={() => handleDelete(row.id, row.path)}
          className="btn btn-primary"
          style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
        >
          <img 
            src={deleteIcon}
            alt="Delete" 
            style={{ width: '20px', height: '20px' }} 
          />
        </button>
      ),
      center: true
    }
  ];

  const signOut = async () => {
    await dispatch(logOut());
    props.history.push(isAdmin ? "/admin" : "/");
  };
 
  return (
    <div className="admin-homepage">
          <OfflineQuoteSuccess1
          toggle={commentsDetailsModal}
          closeCommentModal={closeCommentModal}
          data={rowData}
        />
      <LoadingComponent loaded={loaded} />
      <ToastContainer position="bottom-center" />
    
      <PageLayout
        sidebartitle=""
        active={"Testimonials"}
        category
        sideBarContents={sidebarContentAdmin}
        profile
        {...props}
        signOut={signOut}
        isAdmin={isAdmin}
      >
        <div className="container-payment">
          <div className="payment">
            <h1 className="title">Testimonial</h1>
          </div>
        </div>

        <div className="search-bar-container">
          <div className="input-icon search-bar">
            <img src={search} alt="search" />
            <input type="text" name="search" placeholder="Search" />
          </div>
        </div>

        <div className="logo-formtable-container">
          <div className="left-section">
            <div className="data-table">
              <DataTable
                title="Testimonials (Add atleast 5 records to table)"
                columns={columns}
                data={allUserTestimonial}
                className="rdt_TableHead"
                responsive
                striped
                highlightOnHover
                pagination
                customStyles={customStyles}
              />
            </div>
          </div>

          <div className="right-section">
            <form className="company-form" onSubmit={handleSubmit}>
              <h3>Upload Logo</h3>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Enter name"
                />
                {nameError && <div className="errormessage">{nameError}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="designation">Designation</label>
                <input
                  type="text"
                  id="designation"
                  name="designation"
                  value={designation}
                  onChange={handleDesignationChange}
                  placeholder="Enter designation"
                />
                {designationError && <div className="errormessage">{designationError}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="comments">Comments</label>
                <textarea
                  name="comments"
                  id="comments"
                  value={comments}
                  onChange={handleCommentsChange}
                  style={{ height: '100px' }}
                  placeholder="Enter comments"
                ></textarea>
                {commentsError && <div className="errormessage">{commentsError}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="logo">User Photo</label>
                <label htmlFor="" className="errormessage">Size : 259 x 194 px</label>
                <input
                  type="file"
                  id="logo"
                  name="logo"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                {fileError && <div className="errormessage">{fileError}</div>}
              </div>
              {preview && (
                <div className="preview-container">
                  <img src={preview} alt="Logo Preview" className="logo-preview" />
                </div>
              )}
              <div className="form-group">
                <button type="submit" className="btn btn-primary" disabled={!!(nameError || designationError || commentsError || fileError)}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default OrganizationLogos;
