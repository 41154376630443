import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { io } from "socket.io-client";

import { getWordsListArr, upadteReleasedWordsList } from '../../redux/actions/diwaliGameApiActions';
import CardComp from './card';
import _isEqual from "lodash/isEqual";
import { BASE_URL } from '../../helpers/helper';

const ReleaseNumbers = () => {

    const chunkSize = 5;
    const dispatch = useDispatch();
    const getWordsList = useSelector(state => state.diwaliGameDetails);

    const [wordTobeReleased, setWordTobeReleased] = useState([]);
    const [releasedWords, setReleasedWords] = useState([]);
    const authToken = localStorage.getItem('isAdmin')
    const socket = io(`${BASE_URL}`, {
        auth: { token: authToken }
    });
    useEffect(() => {
        dispatch(getWordsListArr())
    }, [])

    useEffect(() => {
        if (getWordsList?.wordsListArr?.wordsList) {
            setWordTobeReleased(getWordsList.wordsListArr.wordsList)
        }
    }, [getWordsList?.wordsListArr?.wordsList])

    useEffect(() => {
        if (getWordsList?.wordsListArr?.releasedWords) {
            setReleasedWords(getWordsList.wordsListArr.releasedWords)
        }
    }, [getWordsList?.wordsListArr?.releasedWords])

    // useEffect(() => {
    //     setReleasedWords(getWordsList?.wordsListArr?.releasedWords
    //         ?? [])
    // }, [getWordsList?.wordsListArr?.releasedWords])

    const getRandomUniqueNumber = () => {
        const availableNumbers = wordTobeReleased.filter((num) => !releasedWords.includes(num));

        if (availableNumbers.length === 0) {
            alert("All numbers have been released!");
        } else {
            let releaseLimitNumber = getWordsList?.wordsListArr?.releaseLimitNumber;
            if (releaseLimitNumber === 1) {
                const randomIndex = Math.floor(Math.random() * availableNumbers.length);
                const randomNumber = availableNumbers[randomIndex];
                setReleasedWords([...releasedWords, randomNumber]);
                dispatch(upadteReleasedWordsList([...releasedWords, randomNumber]))
            } else {
                for (let i = availableNumbers.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [availableNumbers[i], availableNumbers[j]] = [availableNumbers[j], availableNumbers[i]];
                }
                const randomNumbers = availableNumbers.slice(0, releaseLimitNumber);
                setReleasedWords([...releasedWords, randomNumbers]);
                dispatch(upadteReleasedWordsList([...releasedWords, ...randomNumbers]))
            }
        }
    };

    const getCustomSequenceNumber = () => {
        const availableNumbers = wordTobeReleased.filter((num) => !releasedWords.includes(num));

        if (availableNumbers.length === 0) {
            alert("All numbers have been released!");
        } else {
            let releaseLimitNumber = getWordsList?.wordsListArr?.releaseLimitNumber;
            if (releaseLimitNumber === 1) {
                const randomNumber = availableNumbers[0];
                setReleasedWords([...releasedWords, randomNumber]);
                socket.emit('updateReleasedWords', [...releasedWords, randomNumber]);
                // dispatch(upadteReleasedWordsList([...releasedWords, randomNumber]))
            } else {
                const randomNumbers = availableNumbers.slice(0, releaseLimitNumber);

                setReleasedWords([...releasedWords, ...randomNumbers]);
                socket.emit('updateReleasedWords',[...releasedWords, ...randomNumbers]);

                // dispatch(upadteReleasedWordsList([...releasedWords, ...randomNumbers]))
            }
        }
    };

    const resetLaunch = () => {
        setReleasedWords([]);
        // dispatch(upadteReleasedWordsList([]))
        socket.emit('updateReleasedWords',[]);
    }

    return (
        <div className=''>
            <div className="flex flex-row justify-around">
                <div className='w-[47%]'>

                    <CardComp cardTitle={"Release Numbers / Words"}>
                        <table className="table-auto">
                            <tbody>
                                {Array.from({ length: Math.ceil(wordTobeReleased.length / chunkSize) }, (v, i) => i).map(row => (
                                    <tr key={row}>
                                        {releasedWords.slice(row * chunkSize, (row + 1) * chunkSize).map((number, index) => (
                                            <td key={index} className="border p-2 w-[200px]">{number}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='flex flex-col items-center'>
                            <button onClick={getCustomSequenceNumber} className="bg-[#e25569] text-white px-3 py-2 w-max rounded  my-4" > Release {getWordsList?.wordsListArr?.releaseLimitNumber ?? 'one'} Number or Word</button>
                            <button onClick={resetLaunch} className="bg-[#e25569] text-white px-3 py-2 w-max rounded" type="submit" name="submit">Reset Launch</button>
                        </div>
                    </CardComp>
                </div>
                <div className="w-[47%]">
                    <CardComp cardTitle={"Numbers or words to be Released"}>
                        <table className="table-auto">
                            <tbody>
                                {Array.from({ length: Math.ceil(wordTobeReleased.length / chunkSize) }, (v, i) => i).map(row => (
                                    <tr key={row}>
                                        {wordTobeReleased.slice(row * chunkSize, (row + 1) * chunkSize).map((number, index) => (
                                            <td key={index} className="border p-2 w-[200px]">{number}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='flex justify-center my-4  justify-content-even'>
                            <button onClick={getRandomUniqueNumber} disabled className="cursor-not-allowed bg-[#e25569] text-white px-3 py-2 rounded mr-3" > Shuffle</button>
                            <button onClick={getCustomSequenceNumber} className="bg-[#28afd0] text-white px-3 py-2 rounded" > Custom Sequence</button>
                        </div>
                        <div className=" text-red-700 font-bold text-center" >Do Not Shuffle after Releasing numbers</div>
                    </CardComp>
                </div>
            </div>
        </div>
    )
}

export default ReleaseNumbers