import React, { useEffect } from 'react';

import './modal.css';
import Modal from './modal';

const SaveAccountChanges = ({ enableSave, modalid,toggle,setOpenSaveAccountChangesModal,updateChanges, handleSiginPageBannerUpload, signInBannerUpload}) => {
  const handleUpdateType = async(e)=>{
    e.preventDefault();

    if(signInBannerUpload === true && enableSave === true){ 
      await handleSiginPageBannerUpload('addSignInBanner');
      updateChanges(e);
    }
    else if(signInBannerUpload === true){
      handleSiginPageBannerUpload('addSignInBanner');
    }
    else{
      updateChanges(e);
    }
  }
  return(
    // signInBannerUpload ? handleSiginPageBannerUpload() :
    <Modal modalid={modalid} toggle={toggle}>
      <div className="modal-body">
        <div className="close-icon" data-dismiss="modal" aria-label="Close" onClick={()=>setOpenSaveAccountChangesModal(false)} >
          <div className="close-btn-icon" ></div>
        </div>
        <div className="thankyou-body">
          <h5>Save Changes</h5>
          <span>Would you like to confirm the changes?</span>
          <div className="confrim-btn">
            <button type="submit" className="btn btn-outline" onClick={()=>setOpenSaveAccountChangesModal(false)}>No</button>
            <button type="submit" className="btn btn-primary"onClick={handleUpdateType}>Yes</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default SaveAccountChanges;
