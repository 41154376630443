import React, { useEffect, useState } from "react";
import 'react-tagsinput/react-tagsinput.css';
import TagsInput from 'react-tagsinput';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';

import './diwaliAdmin.css';
import CardComp from './card';
import { addWordsArr, getWordsListArr } from "../../redux/actions/diwaliGameApiActions";
import sampleCSVFile from "../../assets/DDDT/sample.csv";
import { io } from "socket.io-client";
import { BASE_URL } from "../../helpers/helper";

const AddNumbers = () => {
    const dispatch = useDispatch();
    const getWordsList = useSelector(state => state.diwaliGameDetails);
    const [tags, setTags] = useState([]);
    const [canSubmit, setCanSubmit] = useState(true);
    const [wordCount, setWordCount] = useState(0);
    const [file, setFile] = useState(null);
    const [openConfirmUploadModal, setOpenConfirmUploadModal] = useState(false);
    const authToken = localStorage.getItem('isAdmin')

    const socket = io(`${BASE_URL}`, {
        auth: { token: authToken }
    });

    const defaultWords =  [
        "Taare",
        "Padmaavat",
        "Fuljhadi",
        "Ghoomar",
        "Dhanteras",
        "Bole Chudiyan",
        "Kumkum",
        "Saffron",
        "Shehnaiyan",
        "Selfie",
        "Gulabo",
        "Mysore Pak",
        "Baaliyan",
        "Kaju Katli",
        "Dosti",
        "Chennai Express",
        "Rang",
        "Baazigar",
        "Diye",
        "Harmony",
        "Kesariya",
        "Pushpa",
        "Reunion",
        "Kalakaar",
        "Sparklers",
        "Om Shanti Om",
        "Aarti",
        "Kala Chashma",
        "Damini",
        "Rock On",
        "Emotion",
        "Saree",
        "Ghungroo",
        "Radha",
        "Mohabbatein",
        "Taal",
        "Sufi",
        "Naina",
        "Josh",
        "Happy",
        "Thali",
        "Nagada",
        "Dhamaka",
        "Wealth",
        "Deva",
        "Chakli",
        "Padva",
        "Tradition",
        "Sweets",
        "Prosperity"
    ]

    useEffect(() => {
        dispatch(getWordsListArr())
    }, [])

    useEffect(() => {
        if (getWordsList?.wordsListArr?.wordsList) {
            setTags(getWordsList.wordsListArr.wordsList)
            setWordCount(getWordsList.wordsListArr.wordsList?.length)
        }
    }, [getWordsList?.wordsListArr?.wordsList])

    const handleTagChange = newTags => {
        if (newTags.length > 50) {
            Swal.fire({
                title: 'Exceed Word Limit',
                text: "Only 50 Words are Allowed",
                icon: 'error',
            })
        } else if (hasDuplicates(newTags)) {
            Swal.fire({
                title: 'Duplicate Words',
                text: 'Duplicate words are not allowed',
                icon: 'error',
            });
        } else {
            setTags(newTags);
            setWordCount(newTags.length);
        }
    };

    const updateWordCount = tags => {
        const wordLength = tags.length;
        setWordCount(wordLength);
        setCanSubmit(wordLength <= 50);
    };

    const handleTagButtonSubmit = () => {
        if (tags.length === 0) {
            Swal.fire({
                title: 'Length Error',
                text: '50 Words must be included',
                icon: 'error',
            })
        } else if (tags.length < 50) {
            Swal.fire({
                title: 'Length Error',
                text: '50 Words must be included',
                icon: 'error',
            })
        } else {
            socket.emit('updateReleasedTag', tags);
        }
        // todo check
        if (canSubmit) {
            socket.emit('updateReleasedTag', tags);
        } else {
            alert("Length Error: 50 Words must be included");
        }
    };

    const hasDuplicates = (newTags) => {
        return (new Set(newTags)).size !== newTags.length;
    };

    const handleAddNumbers = () => {
        // Add predefined numbers to tags
        const numbersArray = Array.from({ length: 50 }, (_, i) => `${i + 1}`);
        setTags(numbersArray);
        updateWordCount(numbersArray);
    };

    const handleDefaultWords = () => {
        setTags(defaultWords);
        updateWordCount(defaultWords);
    };

    const handleResetTags = () => {
        setTags([]);
        updateWordCount([]);
    };
    // const handleBackspacePress = () => {
    //     if (tags.length > 0) {
    //         const lastTag = tags[tags.length - 1];
    //         const editedTag = prompt('Edit the tag:', lastTag);
    //         if (editedTag !== null) {
    //           const newTags = [...tags];
    //           newTags[newTags.length - 1] = editedTag;
    //           setTags(newTags);
    //         } else {
    //           const newTags = tags.slice(0, -1);
    //           setTags(newTags);
    //         }
    //       }
    //   };


    const handleKeyDown = (e) => {
        if (e.key === "Backspace") {
            // console.log("todo--->")
            // handleBackspacePress()
        }
    };

    const parseCSVContent = (csvContent) => {
        const lines = csvContent.split('\n');
        const data = lines
            .map((line) => line.split(','))
            .map((row) => row.map((cell) => cell.trim()))
        const uniqueData = [];
        const uniqueSet = new Set();

        for (let i = 1; i < data.length; i++) {
            const row = data[i];
            if (row[0] && !uniqueSet.has(row[0])) {
                uniqueData.push(row[0]);
                uniqueSet.add(row[0]);
            }
        }
        if (uniqueData.length > 50) {
            Swal.fire({
                title: 'Exceed Word Limit',
                text: 'Only 50 Words are Allowed',
                icon: 'error',
            });
            return [];
        }

        return uniqueData;
    };


    const handleUpload = (e) => {
        e.preventDefault();
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const csvContent = event.target.result;
                const parsedData = parseCSVContent(csvContent);
                if (parsedData.length > 0) {
                    setTags(parsedData);
                }
            };
            reader.readAsText(file);
        }
    };

    const fileChanged = (e, isDummyUsersFile) => {
        const fileInput = e.target;
        const fileLabel = document.getElementById('file-label');
        if (fileInput.files.length > 0) {
            fileLabel.querySelector('span').textContent = fileInput.files[0].name;
        } else {
            fileLabel.querySelector('span').textContent = 'Choose File';
        }
        e.persist();
        const inputFile = e.target.files[0];
        e.target.value = [];
        if (!isDummyUsersFile) {
            setFile(inputFile);
            setOpenConfirmUploadModal(true);
        }
    };

    return (
        <div className="flex justify-between">
            <div className="w-[48%]">
                <CardComp cardTitle={"Add Words or Numbers"}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <fieldset>
                                    <label>These Numbers or words Will Reflect on Landing Page</label>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <TagsInput value={tags} onChange={handleTagChange} />

                    <div className=" text-red-700 font-bold mt-4">
                        * Duplicate Words Not Allowed <br />* Word Must be less than 9 Characters
                    </div>
                    <div className="flex flex-col items-center">
                        <button className="bg-[#e25569] text-white px-3 py-2 w-max rounded" type="submit" name="submit">
                            Words {wordCount} / 50
                        </button>
                        <br />
                        <button onClick={handleTagButtonSubmit} className="bg-[#e25569] text-white px-3 py-2 w-max rounded" type="submit" name="submit">
                            Save
                        </button>
                    </div>
                </CardComp>
            </div>
            <div className="w-[48%]">
                <CardComp cardTitle={"Themes"}>

                    <div className="flex mx-3 my-3">
                        <button onClick={handleAddNumbers} className="bg-black w-50px text-white py-1 px-2 mr-3 rounded">
                            Add 50 numbers
                        </button>
                        <button onClick={handleDefaultWords} className="bg-black w-50px text-white py-1 px-2 mr-3 rounded">
                            Default 50 Words
                        </button>
                        <button onClick={handleResetTags} className="bg-red-500 w-40 text-white py-1 px-2 rounded">
                            Clear Field
                        </button>
                    </div>
                    <div className=" text-red-700 font-bold text-center" >* Clear Input field first to select your preferred theme</div>
                    <div className="col-span-12 mt-20">
                        <h4 className="text-2xl font-normal font-serif">Upload words/numbers from CSV file</h4>
                        <form id="myform" onSubmit={handleUpload}>
                            <fieldset className="space-y-4">
                                <div className="border-2 border-black-400">
                                    <input
                                        type="file"
                                        id="file"
                                        className="hidden"
                                        accept=".csv"
                                        onChange={fileChanged}
                                    />
                                    <label
                                        htmlFor="file"
                                        className="w-[100%] px-2 py-1 text-white rounded-lg cursor-pointer transition"
                                    >
                                        <span id="file-label" className="bg-red text-lg font-thin spanContent">
                                            <span className="text-black cursor-pointer">Choose File</span>
                                        </span>
                                        <span className="bg-[#f9fafd] py-1 px-3 float-right text-black cursor-pointer">Browse</span>
                                    </label>
                                </div>
                                <p className="text-red-700">
                                    <code>* use CSV file only</code><br />
                                    <code>* whitespaces will be removed automatically</code>
                                </p>
                                <div className="progress hidden mt-4">
                                    <div className="bg-blue-500 w-70 h-6 text-white text-center leading-6 rounded-lg">
                                        0%
                                    </div>
                                </div>
                                <div className="space-x-4 flex">
                                    <button

                                        className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-[#5ed84f] transition" type="submit">
                                        <i className="ft-upload-cloud"></i> Upload
                                    </button>
                                    <a
                                        href={sampleCSVFile}
                                        download="sample.csv"
                                        className="px-3 py-2 bg-[#5ed84f] text-white rounded-lg transition">
                                        <i className="ft-upload-cloud"></i> Download Sample CSV
                                    </a>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    <div className="text-red-700 font-bold text-center" >Do Not Shuffle after Releasing numbers</div>
                </CardComp>
            </div>
        </div>
    );
};

export default AddNumbers;