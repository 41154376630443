export const HOME_GET_ALL_GAMES_REQUEST = "HOME_GET_ALL_GAMES_REQUEST";
export const HOME_GET_ALL_GAMES_SUCCESS = "HOME_GET_ALL_GAMES_SUCCESS";
export const HOME_GET_ALL_GAMES_FAIL = "HOME_GET_ALL_GAMES_FAIL";

export const HOME_GET_MOST_PLAYED_GAMES_REQUEST = "HOME_GET_MOST_PLAYED_GAMES_REQUEST";
export const HOME_GET_MOST_PLAYED_GAMES_SUCCESS = "HOME_GET_MOST_PLAYED_GAMES_SUCCESS";
export const HOME_GET_MOST_PLAYED_GAMES_FAIL = "HOME_GET_MOST_PLAYED_GAMES_FAIL";

export const HOME_ADD_NEW_GAME_REQUEST = "HOME_ADD_NEW_GAME_REQUEST";
export const HOME_ADD_NEW_GAME_SUCCESS = "HOME_ADD_NEW_GAME_SUCCESS";
export const HOME_ADD_NEW_GAME_FAIL = "HOME_ADD_NEW_GAME_FAIL";

export const HOME_GET_BANNER_GAMES_REQUEST = "HOME_GET_BANNER_GAMES_REQUEST";
export const HOME_GET_BANNER_GAMES_SUCCESS = "HOME_GET_BANNER_GAMES_SUCCESS";
export const HOME_GET_BANNER_GAMES_FAIL = "HOME_GET_BANNER_GAMES_FAIL";

export const HOME_UPDATE_BANNER_REQUEST = "HOME_UPDATE_BANNER_REQUEST";
export const HOME_UPDATE_BANNER_SUCCESS = "HOME_UPDATE_BANNER_SUCCESS";
export const HOME_UPDATE_BANNER_FAIL = "HOME_UPDATE_BANNER_FAIL";

export const HOME_ADD_TO_FAVOURITE_REQUEST = "HOME_ADD_TO_FAVOURITE_REQUEST";
export const HOME_ADD_TO_FAVOURITE_SUCCESS = "HOME_ADD_TO_FAVOURITE_SUCCESS";
export const HOME_ADD_TO_FAVOURITE_FAIL = "HOME_ADD_TO_FAVOURITE_FAIL";

export const HOME_REMOVE_FROM_FAVOURITE_REQUEST = 'HOME_REMOVE_FROM_FAVOURITE_REQUEST';
export const HOME_REMOVE_FROM_FAVOURITE_SUCCESS = 'HOME_REMOVE_FROM_FAVOURITE_SUCCESS';
export const HOME_REMOVE_FROM_FAVOURITE_FAIL = 'HOME_REMOVE_FROM_FAVOURITE_FAIL';


export const HOME_UPDATE_GAME_REQUEST = "HOME_UPDATE_GAME_REQUEST";
export const HOME_UPDATE_GAME_SUCCESS = "HOME_UPDATE_GAME_SUCCESS";
export const HOME_UPDATE_GAME_FAIL = "HOME_UPDATE_GAME_FAIL";

export const HOME_GET_GAMES_BY_CATEGORY_REQUEST =
  "HOME_GET_GAMES_BY_CATEGORY_REQUEST";
export const HOME_GET_GAMES_BY_CATEGORY_SUCCESS =
  "HOME_GET_GAMES_BY_CATEGORY_SUCCESS";
export const HOME_GET_GAMES_BY_CATEGORY_FAIL =
  "HOME_GET_GAMES_BY_CATEGORY_FAIL";

export const HOME_GET_GAMES_BY_OBJECTIVE_REQUEST =
  "HOME_GET_GAMES_BY_OBJECTIVE_REQUEST";
export const HOME_GET_GAMES_BY_OBJECTIVE_SUCCESS =
  "HOME_GET_GAMES_BY_OBJECTIVE_SUCCESS";
export const HOME_GET_GAMES_BY_OBJECTIVE_FAIL =
  "HOME_GET_GAMES_BY_OBJECTIVE_FAIL";

export const HOME_GET_GAMES_BY_CATEGORY_FOR_BANNER_REQUEST =
  "HOME_GET_GAMES_BY_CATEGORY_FOR_BANNER_REQUEST";
export const HOME_GET_GAMES_BY_CATEGORY_FOR_BANNER_SUCCESS =
  "HOME_GET_GAMES_BY_CATEGORY_FOR_BANNER_SUCCESS";
export const HOME_GET_GAMES_BY_CATEGORY_FOR_BANNER_FAIL =
  "HOME_GET_GAMES_BY_CATEGORY_FOR_BANNER_FAIL";

export const HOME_GET_GAMES_BY_SLOGAN_REQUEST =
  "HOME_GET_GAMES_BY_SLOGAN_REQUEST";
export const HOME_GET_GAMES_BY_SLOGAN_SUCCESS =
  "HOME_GET_GAMES_BY_SLOGAN_SUCCESS";
export const HOME_GET_GAMES_BY_SLOGAN_FAIL = "HOME_GET_GAMES_BY_SLOGAN_FAIL";

export const HOME_SEARCH_REQUEST = "HOME_SEARCH_REQUEST";
export const HOME_SEARCH_SUCCESS = "HOME_SEARCH_SUCCESS";
export const HOME_SEARCH_FAIL = "HOME_SEARCH_FAIL";

export const HOME_GET_SOCIAL_LINK_REQUEST = "HOME_GET_SOCIAL_LINK_REQUEST";
export const HOME_GET_SOCIAL_LINK_SUCCESS = "HOME_GET_SOCIAL_LINK_SUCCESS";
export const HOME_GET_SOCIAL_LINK_FAIL = "HOME_GET_SOCIAL_LINK_FAIL";

export const HOME_UPDATE_SOCIAL_LINK_REQUEST =
  "HOME_UPDATE_SOCIAL_LINK_REQUEST";
export const HOME_UPDATE_SOCIAL_LINK_SUCCESS =
  "HOME_UPDATE_SOCIAL_LINK_SUCCESS";
export const HOME_UPDATE_SOCIAL_LINK_FAIL = "HOME_GET_SOCIAL_LINK_FAIL";

export const HOME_GET_DESCRIPTION_REQUEST = "HOME_GET_DESCRIPTION_REQUEST";
export const HOME_GET_DESCRIPTION_SUCCESS = "HOME_GET_DESCRIPTION_SUCCESS";
export const HOME_GET_DESCRIPTION_FAIL = "HOME_GET_DESCRIPTION_FAIL";

export const HOME_UPDATE_DESCRIPTION_REQUEST =
  "HOME_UPDATE_DESCRIPTION_REQUEST";
export const HOME_UPDATE_DESCRIPTION_SUCCESS =
  "HOME_UPDATE_DESCRIPTION_SUCCESS";
export const HOME_UPDATE_DESCRIPTION_FAIL = "HOME_GET_DESCRIPTION_FAIL";

export const HOME_CONTACT_US_REQUEST = "HOME_CONTACT_US_REQUEST";
export const HOME_CONTACT_US_PASS = "HOME_CONTACT_US_SUCCESS";
export const HOME_CONTACT_US_FAIL = "HOME_CONTACT_US_FAIL";
export const HOME_CONTACT_US_UPDATED = "HOME_CONTACT_US_UPDATED";

export const HOME_UPDATE_GAME_POSITION_REQUEST =
  "HOME_UPDATE_GAME_POSITION_REQUEST";
export const HOME_UPDATE_GAME_POSITION_SUCCESS =
  "HOME_UPDATE_GAME_POSITION_SUCCESS";
export const HOME_UPDATE_GAME_POSITION_FAIL = "HOME_UPDATE_GAME_POSITION_FAIL";

export const HOME_DELETE_BANNER_GAME_REQUEST =
  "HOME_DELETE_BANNER_GAME_REQUEST";
export const HOME_DELETE_BANNER_GAME_SUCCESS =
  "HOME_DELETE_BANNER_GAME_SUCCESS";
export const HOME_DELETE_BANNER_GAME_FAIL = "HOME_DELETE_BANNER_GAME_FAIL";

export const HOME_ADD_BANNER_GAME_REQUEST = "HOME_ADD_BANNER_GAME_REQUEST";
export const HOME_ADD_BANNER_GAME_SUCCESS = "HOME_ADD_BANNER_GAME_SUCCESS";
export const HOME_ADD_BANNER_GAME_FAIL = "HOME_ADD_BANNER_GAME_FAIL";

export const HOME_ADD_BULK_BANNER_GAME_REQUEST = "HOME_ADD_BULK_BANNER_GAME_REQUEST";
export const HOME_ADD_BULK_BANNER_GAME_SUCCESS = "HOME_ADD_BULK_BANNER_GAME_SUCCESS";
export const HOME_ADD_BULK_BANNER_GAME_FAIL = "HOME_ADD_BULK_BANNER_GAME_FAIL";

export const HOME_UPDATE_BANNER_GAME_REQUEST = "HOME_UPDATE_BANNER_GAME_REQUEST";
export const HOME_UPDATE_BANNER_GAME_SUCCESS = "HOME_UPDATE_BANNER_GAME_SUCCESS";
export const HOME_UPDATE_BANNER_GAME_FAIL = "HOME_UPDATE_BANNER_GAME_FAIL";

export const HOME_UPDATE_BANNER_GAME_BULK_REQUEST = "HOME_UPDATE_BANNER_GAME_BULK_REQUEST";
export const HOME_UPDATE_BANNER_GAME_BULK_SUCCESS = "HOME_UPDATE_BANNER_GAME_BULK_SUCCESS";
export const HOME_UPDATE_BANNER_GAME_BULK_FAIL = "HOME_UPDATE_BANNER_GAME_BULK_FAIL";

export const GET_SUBSCRIBED_GAMES_REQUEST = "GET_SUBSCRIBED_GAMES_REQUEST";
export const GET_SUBSCRIBED_GAMES_SUCCESS = "GET_SUBSCRIBED_GAMES_SUCCESS";
export const GET_SUBSCRIBED_GAMES_FAIL = "GET_SUBSCRIBED_GAMES_FAIL";

export const GET_CURRENTLY_PLAYING_GAMES_REQUEST = "GET_CURRENTLY_PLAYING_GAMES_REQUEST"
export const GET_CURRENTLY_PLAYING_GAMES_SUCCESS = "GET_CURRENTLY_PLAYING_GAMES_SUCCESS"
export const GET_CURRENTLY_PLAYING_GAMES_FAIL = "GET_CURRENTLY_PLAYING_GAMES_FAIL"

export const GET_PREVIOUSLY_PLAYED_GAMES_REQUEST = "GET_PREVIOUSLY_PLAYED_GAMES_REQUEST";
export const GET_PREVIOUSLY_PLAYED_GAMES_SUCCESS = "GET_PREVIOUSLY_PLAYED_GAMES_SUCCESS";
export const GET_PREVIOUSLY_PLAYED_GAMES_FAIL = "GET_PREVIOUSLY_PLAYED_GAMES_FAIL";

export const GET_ALL_SLOGANS_REQUEST = "GET_ALL_SLOGANS_REQUEST";
export const GET_ALL_SLOGANS_SUCCESS = "GET_ALL_SLOGANS_SUCCESS";
export const GET_ALL_SLOGANS_FAIL = "GET_ALL_SLOGANS_FAIL";

export const ADD_SLOGAN_REQUEST = "ADD_SLOGAN_REQUEST";
export const ADD_SLOGAN_SUCCESS = "ADD_SLOGAN_SUCCESS";
export const ADD_SLOGAN_FAIL = "ADD_SLOGAN_FAIL";

export const DELETE_SLOGAN_REQUEST = "DELETE_SLOGAN_REQUEST";
export const DELETE_SLOGAN_SUCCESS = "DELETE_SLOGAN_SUCCESS";
export const DELETE_SLOGAN_FAIL = "DELETE_SLOGAN_FAIL";

export const UPDATE_SLOGAN_BULK_REQUEST="UPDATE_SLOGAN_BULK_REQUEST";
export const UPDATE_SLOGAN_BULK_SUCCESS="UPDATE_SLOGAN_BULK_SUCCESS";
export const UPDATE_SLOGAN_BULK_FAIL="UPDATE_SLOGAN_BULK_FAIL";

export const BANNER_CUSTOMIZE_REQUEST = "BANNER_CUSTOMIZE_REQUEST";
export const BANNER_CUSTOMIZE_SUCCESS = "BANNER_CUSTOMIZE_SUCCESS";
export const BANNER_CUSTOMIZE_FAIL = "BANNER_CUSTOMIZE_FAIL";

export const BANNER_BACK_TO_DEFAULT_REQUEST = "BANNER_BACK_TO_DEFAULT_REQUEST";
export const BANNER_BACK_TO_DEFAULT_SUCCESS = "BANNER_BACK_TO_DEFAULT_SUCCESS";
export const BANNER_BACK_TO_DEFAULT_FAIL = "BANNER_BACK_TO_DEFAULT_FAIL";

export const UPDATE_SLOGAN_REQUEST = "UPDATE_SLOGAN_REQUEST";
export const UPDATE_SLOGAN_SUCCESS = "UPDATE_SLOGAN_SUCCESS";
export const UPDATE_SLOGAN_FAIL = "UPDATE_SLOGAN_FAIL";