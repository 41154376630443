import React from "react";
import Modal from "../modal/customModal";
import QRCodeDetail from "./qrCodeDetails";
 
const QrDownloadModal = ({ session, showModal, setShowModal}) => {

    return (
        <div>
            <Modal size={"lg"} showModal={showModal}>
                <QRCodeDetail session={session} setShowModal={setShowModal}  />
            </Modal>
        </div >
    );
};
export default QrDownloadModal;
