// formReducer.js
import { UPDATE_FORM_DATA }  from "../constants/bookEventFilterConstants";

// Initial state for the form
const initialState = {
  formData: null,  // Default state is no form data
};

// Reducer to handle form data updates
const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FORM_DATA:
      return {
        ...state,
        formData: action.payload,  // Update form data in the state
      };
    default:
      return state;
  }
};

export default formReducer;
