import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./myPoints.css";
import LoadingComponent from "../loader/LoadingComponent";
import RedeemPage from "./redeem";
import PointsEarned from "./pointsEarned";
import HistoryPage from "./historyPage";
import { getUsersPoints } from "../../redux/actions/adminApiActions";

const MyPoints = (props) => {
  const UserInfo = useSelector(state => state.getUser)
  const { userInfo } = UserInfo;

  const[userId, setUserId]= useState("")
  const dispatch = useDispatch();
  const [activeTabType, setActiveTabType] = useState("Points Earned");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (userInfo?.data?.id) {
      dispatch(getUsersPoints(userInfo.data.id));
      setLoaded(true)
    }
  }, [userInfo?.data?.id]);

  return (
    <div className="">
      <LoadingComponent loaded={loaded} />
      <div className="plandetail-premium ">
        {/* <h5>XOXODay Storefront</h5> */}
        <div className="container-fluid points-dashboard-btn-group">
          <RedeemPage />
        </div>
        <div className="container-fluid points-dashboard-btn-group">
          <div
            className={
              activeTabType === "Points Earned"
                ? "group-item active"
                : "group-item"
            }
            onClick={() => {
              setActiveTabType("Points Earned");
            }}
          >
            Points Earned
          </div>
          <div
            className={
              activeTabType === "Redeem" ? "group-item active" : "group-item"
            }
            onClick={() => {
              setActiveTabType("Redeem");
            }}
          >
            Redeem
          </div>
          <div
            className={
              activeTabType === "History" ? "group-item active" : "group-item"
            }
            onClick={() => {
              setActiveTabType("History");
            }}
          >
            History
          </div>
        </div>
        <hr />
        {activeTabType === "Points Earned" && (
          <PointsEarned />
        )}
        {activeTabType === "Redeem" && (
          <RedeemPage />
        )}
        {activeTabType === "History" && (
          <HistoryPage />
        )}

      </div>
    </div>
  );
};

export default MyPoints;