export const OFFLINE_PAYMENT_CHECKOUT_REQUEST = 'OFFLINE_PAYMENT_CHECKOUT_REQUEST';
export const OFFLINE_PAYMENT_CHECKOUT_SUCCESS = 'OFFLINE_PAYMENT_CHECKOUT_SUCCESS';
export const OFFLINE_PAYMENT_CHECKOUT_FAIL = 'OFFLINE_PAYMENT_CHECKOUT_FAIL';

export const PAYMENT_GET_ALL_PAYMENT_ADMIN_REQUEST = "PAYMENT_GET_ALL_PAYMENT_ADMIN_REQUEST";
export const PAYMENT_GET_ALL_PAYMENT_ADMIN_SUCCESS = "PAYMENT_GET_ALL_PAYMENT_ADMIN_SUCCESS";
export const PAYMENT_GET_ALL_PAYMENT_ADMIN_FAIL = "PAYMENT_GET_ALL_PAYMENT_ADMIN_FAIL";

export const PAYMENT_VERIFY_OFFLINE_PAYMENT_REQUEST = "PAYMENT_VERIFY_OFFLINE_PAYMENT_REQUEST";
export const PAYMENT_VERIFY_OFFLINE_PAYMENT_SUCCESS = "PAYMENT_VERIFY_OFFLINE_PAYMENT_SUCCESS";
export const PAYMENT_VERIFY_OFFLINE_PAYMENT_FAIL = "PAYMENT_VERIFY_OFFLINE_PAYMENT_FAIL";

export const ADMIN_OFFLINE_PAYMENT_CHECKOUT_REQUEST = 'ADMIN_OFFLINE_PAYMENT_CHECKOUT_REQUEST';
export const ADMIN_OFFLINE_PAYMENT_CHECKOUT_SUCCESS = 'ADMIN_OFFLINE_PAYMENT_CHECKOUT_SUCCESS';
export const ADMIN_OFFLINE_PAYMENT_CHECKOUT_FAIL = 'ADMIN_OFFLINE_PAYMENT_CHECKOUT_FAIL';
