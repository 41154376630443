import React from "react";
import Modal from "./modal";
import "./tablemodal.css";
import "./modal.css"
import { useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../helpers/helper";

const EventPaymentDetailModal = ({ details, toggle, setOpenDetailsModal, verify, verifyPayment }) => {

    const [touched, setTouched] = useState({ GSTIN: false });
    const [transactionDetails, setTransactionDetails] = useState({});
    useEffect(() => {
        setTransactionDetails(details);
      

    }, [details]);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setTransactionDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleBlur = (e) => {
        const { name } = e.target;
        setTouched((prevState) => ({
            ...prevState,
            [name]: true,
        }));
    };
    const validateInput = (payment) => {
        const error = {
            GSTIN: "",
        };
        if (touched.GSTIN && payment.GSTIN.length === 0)
            error.GSTIN = "*GSTIN should be Provided";
        const gstinFormat = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        if (touched.GSTIN && payment.GSTIN.length > 0 && !gstinFormat.test(payment.GSTIN)) {
            error.GSTIN = "Please enter valid GSTIN"
        }

        return error;
    }
    let errorMessage = validateInput(transactionDetails);

    return (
        <Modal modalid={"payment-details-modal"} toggle={toggle}>
            <div className="modal-body payment-details-modal">
                <div className={`thankyou-body failer payment-details ${verify ? '' : '!w-[480px]'}`}>
                    <div className={verify ? "details-section-wrapper verify" : "details-section-wrapper"}>
                        <div className="title">
                            <h4>Payment Details</h4>
                            <img src={BASE_URL + "/public/uploads/company-logos/2021-05-18_05:55:46_JPEG-gold.png"} alt="" />
                        </div>
                        <div className="details">
                            <ul>
                                <li key={'name'}>
                                    <span className="label">Name</span>
                                    <span className='value'>{details.clientName}</span>
                                </li>
                                <li key={'email'}>
                                    <span className="label">Email Id</span>
                                    <span className='value'>{details.email}</span>
                                </li>
                                <li key={'phone'}>
                                    <span className="label">Phone Number</span>
                                    <span className='value'>{details.phoneNumber}</span>
                                </li>
                                <li key={'companyname'}>
                                    <span className="label">Company Name</span>
                                    <span className='value'>{details.companyName}</span>
                                </li>
                                <li key={'eventName'} className=" !max-h-max">
                                    <span className="label !whitespace-nowrap">Event Name</span>
                                    <span className='value !text-right'>{details.eventName}</span>
                                </li>
                                <li key={'noOFGuest'}>
                                    <span className="label">No Of Guest</span>
                                    <span className='value'>{details.noOfGuest}</span>
                                </li>
                                <li key={'startDate'}>
                                    <span className="label">Start Date</span>
                                    <span className='value'>{details.starDate}</span>
                                </li>
                                <li key={'StartTime'}>
                                    <span className="label">Start Time</span>
                                    <span className='value'>{details.StartTime}</span>
                                </li>
                                <li key={'address'} className=" !max-h-max">
                                    <span className="label">Address</span>
                                    <span className='value !text-right'>{details.address}</span>
                                </li>
                                {/* <li key={'costOfEvent'}>
                                    <span className="label">Payment Status</span>
                                    <span className='value'>{details.status}</span>
                                </li> */}
                                <li key={'modeofpayment'}>
                                    <span className="label">Mode Of Payment</span>
                                    <span className='value'>{details.paymentType}</span>
                                </li>
                                <li key={'paymentstatus'}>
                                    <span className="label">Payment Status</span>
                                    <span className='value'>{details.status}</span>
                                </li>
                              
                                {
                                    details.reasonForFailed &&
                                    <li key={'reasonforfailed'}>
                                        <span className="label">Reason for Failed</span>
                                        <span className='value'>{details.reasonForFailed}</span>
                                    </li>
                                }
                                {
                                    details.reasonForPending &&
                                    <li key={'reasonforpending'}>
                                        <span className="label">Reason for Pending</span>
                                        <span className='value'>{details.reasonForPending}</span>
                                    </li>
                                }
                                <li key={'paidamount'}>
                                    <span className="label">Amount Paid</span>
                                    <span className='value'>{details.Amount}</span>
                                </li>
                                <li key={'currecy'}>
                                    <span className="label">Currency</span>
                                    <span className='value'>{details.currency}</span>
                                </li>
                            
                                {details.comments && details.mode === "OFFLINE" &&
                                    <li key={'comments'}>
                                        <span className="label">Comments</span>
                                        <span className='value'>{details.comments}</span>
                                    </li>}
                            </ul>
                        </div>
                        <div className={verify ? "close hide" : "close"}>
                            <button data-dismiss="modal" onClick={() => setOpenDetailsModal(false)}>Close</button>
                        </div>
                        {/* <div className="close">
                                <button data-dismiss="modal" onClick={() => setOpenDetailsModal(false)}>Close</button>
                                <button data-dismiss="modal"
                                style={{
                                    display: verify ? 'flex' : 'none'
                                }} 
                                    onClick={() => verifyPayment(transactionDetails)}>Verify</button>
                            </div> */}
                    </div>
                    <div className={verify ? "details-section-wrapper-form" : "details-section-wrapper-form hide"}>
                        <div className="title">
                            <h4>Verify Payment</h4>
                        </div>
                        <form >
                            {/* <div className="form-group">
                                <label htmlFor="gstIn">GSTIN *</label>
                                <input type="text" id="GSTIN" name="GSTIN" value={transactionDetails.GSTIN} onChange={handleChange} onBlur={handleBlur} required />
                                <div className="error-warning">{touched.GSTIN && errorMessage.GSTIN}</div>

                            </div>
                            <div className="form-group">
                                <label htmlFor="gstIn">Comment</label>
                                <input type="text" id="comments" name="comments" onChange={handleChange} />
                            </div> */}
                            <div className="close">
                                <button data-dismiss="modal" onClick={() => setOpenDetailsModal(false)}>Close</button>
                                <button data-dismiss="modal" 
                                    onClick={(e) =>{e.preventDefault(); verifyPayment(transactionDetails);} }>Verify</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default EventPaymentDetailModal;