import React from 'react'
import Sortablelist, { SortableItem } from 'react-easy-sort'

import { S3_BASE_URL } from '../../helpers/helper';
import './sortablelist.css';

const SortableListGrid = ({ items, onSortEnd, setGameDetails, setSloganFlag, setBannerDeleteModal, zIndex10,handleGameChange, setSloganData, sloganData, ownerEdit = false, setSelectedGames = () => {} }) => {

    const handleClose = (gameDetail) => {
      if (ownerEdit) {
        handleGameChange(gameDetail)
        setSelectedGames((prevSelectedGames) => prevSelectedGames.filter((gameId) => gameId !== gameDetail.id));
      } else {
        setGameDetails(gameDetail);
        setSloganFlag("gameSlogan");
        setSloganData(sloganData);
        setBannerDeleteModal(true);
      }
    };
  
  
    const itemClassName = zIndex10 ? "drag-item zIndex10" : "drag-item";
    let className = "drag-list";
  
    if (items.length > 8 && items.length <= 10) {
      className = "drag-list show10";
    } else if (items.length > 10 && items.length <= 12) {
      className = "drag-list show12";
    } else if (items.length > 12) {
      className = "drag-list show15";
    }
  
    return (
      <Sortablelist
        onSortEnd={onSortEnd}
        className={className}
        draggedItemClassName="dragged"
      >
        {items.map((game) => (
          <SortableItem key={game.id}>
            <div className={itemClassName} style={{ background: `url('${game.coverMedia[0] && game.coverMedia[0].includes("https://youtu.be") ? S3_BASE_URL + game.coverMedia[1] : S3_BASE_URL + game.coverMedia[0]}')`, backgroundSize: "100% 100%", zIndex: 0 }}>
              <div className="close" style={{ padding: "2%", float: 'right' }}>
                <span style={{ color: "black" }} onClick={() => handleClose(game)}>X</span>
              </div>
              <div className='title-container'>
                <h4 className="game-title">{game.title}</h4>
              </div>
            </div>
          </SortableItem>
        ))}
      </Sortablelist>
    );
  };

export default SortableListGrid;