export const PLANS_CONTACT_US_REQUEST = "PLANS_CONTACT_US_REQUEST";
export const PLANS_CONTACT_US_SUCCESS = "PLANS_CONTACT_US_SUCCESS";
export const PLANS_CONTACT_US_FAIL = "PLANS_CONTACT_US_FAIL";

export const PLAN_GET_MY_PLAN_REQUEST = "PLAN_GET_MY_PLAN_REQUEST";
export const PLAN_GET_MY_PLAN_SUCCESS = "PLAN_GET_MY_PLAN_SUCCESS";
export const PLAN_GET_MY_PLAN_FAIL = "PLAN_GET_MY_PLAN_FAIL";

export const PLAN_DOWNLOAD_TRANSACTION_REQUEST =
  "PLAN_DOWNLOAD_TRANSACTION_REQUEST";
export const PLAN_DOWNLOAD_TRANSACTION_SUCCESS =
  "PLAN_DOWNLOAD_TRANSACTION_SUCCESS";
export const PLAN_DOWNLOAD_TRANSACTION_FAIL = "PLAN_DOWNLOAD_TRANSACTION_FAIL";

export const ADD_ORG_DETAILS_REQUEST = "ADD_ORG_DETAILS_REQUEST";
export const ADD_ORG_DETAILS_SUCCESS = "ADD_ORG_DETAILS_SUCCESS";
export const ADD_ORG_DETAILS_FAIL = "ADD_ORG_DETAILS_FAIL";

export const GET_ORG_BY_EMAIL_REQUEST = "GET_ORG_BY_EMAIL_REQUEST";
export const GET_ORG_BY_EMAIL_SUCCESS = "GET_ORG_BY_EMAIL_SUCCESS";
export const GET_ORG_BY_EMAIL_FAIL = "GET_ORG_BY_EMAIL_FAIL";

export const GET_ORG_BY_ID_REQUEST = "GET_ORG_BY_ID_REQUEST";
export const GET_ORG_BY_ID_SUCCESS = "GET_ORG_BY_ID_SUCCESS";
export const GET_ORG_BY_ID_FAIL = "GET_ORG_BY_ID_FAIL";

export const ADMIN_ADD_PLAN_REQUEST="ADMIN_ADD_PLAN_REQUEST";
export const ADMIN_ADD_PLAN_SUCCESS="ADMIN_ADD_PLAN_SUCCESS";
export const ADMIN_ADD_PLAN_FAIL="ADMIN_ADD_PLAN_FAIL";

export const  ADD_PLAN_TO_ORGANISATION_BY_ADMIN_REQUEST="ADD_PLAN_TO_ORGANISATION_BY_ADMIN_REQUEST";
export const  ADD_PLAN_TO_ORGANISATION_BY_ADMIN_SUCCESS="ADD_PLAN_TO_ORGANISATION_BY_ADMIN_SUCCESS";
export const  ADD_PLAN_TO_ORGANISATION_BY_ADMIN_FAIL="ADD_PLAN_TO_ORGANISATION_BY_ADMIN_FAIL";
