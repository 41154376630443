import { useState } from "react"

import CardHeader from "./cardHeader"

const CardComp = ({ children, cardTitle }) => {
    const [maxScreen, setMaxScreen] = useState(false);
    return (
        <div className={`cardDesign bg-white ${maxScreen ? "cardFullScreen" : ""}`}>
            <CardHeader title={cardTitle} maxScreen={maxScreen} setMaxScreen={setMaxScreen} />
            <div className="cardBody p-4">
                {children}
            </div>
        </div>
    )
}

export default CardComp