import React, { useEffect } from 'react';

const ChatBot = () => {
  useEffect(() => {
    const Tawk_LoadStart = new Date();

    const script = document.createElement("script");
    const firstScript = document.getElementsByTagName("script")[0];

    script.async = true;
    script.src = 'https://embed.tawk.to/670cd37c4304e3196ad13d5b/1ia51k7lm';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    firstScript.parentNode.insertBefore(script, firstScript);

    // Optional: You can now use Tawk_API methods after the script loads
    script.onload = () => {
      if (window.Tawk_API) {
        // You can use Tawk_API here
        console.log("Tawk.to loaded and ready to use.");
      }
    };

    return () => {
      // Cleanup the script when the component unmounts
      if (script) {
        script.remove();
      }
    };
  }, []);

  return null; // No UI to render
};

export default ChatBot;
