import React, { useEffect, useState } from "react";
import "./qrCodeDetail.css";
import { QRCodeSVG } from 'qrcode.react';
import { BASE_URL, CLIENT_URL, axiosApiInstance, encryptData, failureAlert } from "../../helpers/helper";
import copyIcon from "../../assets/images/share.svg"
import { HiLink } from "react-icons/hi";
import { PiDownloadSimpleBold } from "react-icons/pi";
import QR_BG from "../../assets/qrCodeBackgrounds/QR_BG (1).png";
import handScan from "../../assets/qrCodeBackgrounds/Hand_Scan.png";
import axios from "axios";
import { error, flex } from "synonyms/dictionary";

const QRCodeDetail = ({ copyLinkTypeFunc, guestGame, sessionId, gameId, setGameShareLink, session, setShowModal, copyGameLink, handleClose, Show }) => {
    const [linkCopied, setLinkCopied] = useState(false);
    const [longUrlLink, setLongUrlLink] = useState('')
    const [shortUrlLink, setShortUrlLink] = useState('');
    const [sessionStartTime, setSessionStartTime] = useState('');
    const [sessionEndTime, setSessionEndTime] = useState('');
    const [downloadQrCode, setDownloadQrCode] = useState(false);
    const [sessionSplitName, setSessionSplitName] = useState('')

    const readableTime = (timeString) => {
        const date = new Date(timeString);
        const options = {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit"
        };

        const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
        return formattedDate;
    }

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    function removeHyphen(str) {
        const parts = str.split('-').map(part => part.trim());
        if (parts.length > 1 && parts[parts.length - 1] === '') {
            return parts.slice(0, -1).join('-').trim();
        } else {
            return str.trim();
        }
    }
    const [sessionType, setSessionType] = useState('')
    const onDownloadQrCode = async (e) => {
        e.preventDefault();
        setDownloadQrCode(true);
        const startTime = await readableTime(session.scheduleFrom);
        const endTime = await readableTime(session.scheduleTo);
        const sessionName = await removeHyphen(session.name);
        const editedSessionType = await capitalizeFirstLetter(session.mode)
        setSessionSplitName(sessionName)
        setSessionStartTime(startTime);
        setSessionEndTime(endTime);
        setSessionType(editedSessionType)
    }


    const downloadQr = (id) => {
        const svgElement = document.getElementById(id);
        const svgData = new XMLSerializer().serializeToString(svgElement);
        //const modifiedSvgData = svgData.replace(/xmlns="[^"]*"/, '');

        // const centeredSvgData = svgData.replace('<svg', '<svg  style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"');
        const centeredSvgData = svgData.replace('<svg', '<svg');

        const blob = new Blob([centeredSvgData], { type: 'image/svg+xml' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${Date.now()}qrcode`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        setDownloadQrCode(false);
        setSessionStartTime('');
        setSessionEndTime('');
        setSessionSplitName('')
        document.body.removeChild(downloadLink);
    };


    const downloadQr1 = (id, text) => {
        const svgElement = document.getElementById(id);
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgData, 'image/svg+xml');

        // Center QR code on the page
        // svgDoc.documentElement.setAttribute('style', 'height:700; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);');

        svgDoc.documentElement.setAttribute();

        // Append text below QR code
        const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        textElement.setAttribute('x', '50%');
        textElement.setAttribute('y', '-10%');
        textElement.setAttribute('text-anchor', 'middle');
        textElement.setAttribute('font-size', '5');
        textElement.setAttribute('fill', '#000');
        textElement.textContent = text;
        svgDoc.documentElement.appendChild(textElement);

        const svgWithText = new XMLSerializer().serializeToString(svgDoc);

        const blob = new Blob([svgWithText], { type: 'image/svg+xml' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${Date.now()}-qrcode.svg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };




    const getGameLink = (session) => {
        const obj = { from: session?.createdBy?.email, organizationId: session?.organizationId, onBoardType: "INVITE" };
        const inviteToken = encryptData(obj);
        let shareLink = '';
        if (session?.mode === 'ANYONE') {
            shareLink = CLIENT_URL + "/join/guest?gameId=" + session?.organizationGame?.gameId + "&sessionId=" + session?.id + "&inviteId=" + inviteToken;
        } else {
            shareLink = CLIENT_URL + "/join?gameId=" + session?.organizationGame?.gameId + "&sessionId=" + session?.id + "&inviteId=" + inviteToken;
        }
        return shareLink;
    };

    const onClickCopyGameLink = () => {
        setLinkCopied(true);
        setTimeout(() => {
            setLinkCopied(false);
        }, 2000);
        copyGameLink("longLink");
    };

    const onClickCopyShortGameLink = () => {
        setLinkCopied(true);
        setTimeout(() => {
            setLinkCopied(false);
        }, 2000);
        copyGameLink("shortLink");
    };

    const generateGameLink = () => {
        const obj = { from: session?.createdBy?.email, organizationId: session?.organizationId, onBoardType: "INVITE" };
        const inviteToken = encryptData(obj);
        let shareLink = "";
        if (guestGame) {
            shareLink = CLIENT_URL + "/join/guest?gameId=" + gameId + "&sessionId=" + sessionId + "&inviteId=" + inviteToken;
        }
        else {
            shareLink = CLIENT_URL + "/join?gameId=" + gameId + "&sessionId=" + sessionId + "&inviteId=" + inviteToken;
        }
        setLongUrlLink(shareLink)
        return shareLink;
    }

    const generateShortGameLink = async () => {

        const getFullUrl = generateGameLink();
        await axiosApiInstance.post(BASE_URL + "/api/session/session-shortlink", { sessionLink: getFullUrl })
            .then((response) => {
                if (!response && !response.message === "sucessfuly generated short link") {
                    return failureAlert("Failed to generate copy url");
                }
                const shortSessionUrl = response.data.shortLink;
                setShortUrlLink(shortSessionUrl);
            })
            .catch((error) => {
                console.log("Error: " + error);
                return null;
            })

    }

    useEffect(() => {
        generateShortGameLink();
    }, [])

    return (
        <div className="bg-white mx-4 md:mx-0 pb-16 pt-10 text-center rounded">
            <div className="games-title">{session.name}</div>
            <div className="flex justify-center">
                <div
                    className="qr-download-detail"
                    id={`qr-code-detail$${session.id}`}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                        flexDirection: 'column',
                        backgroundImage: `url(${QR_BG})`, // Use the imported image as background
                        backgroundSize: 'cover'
                    }}

                >
                    <h1
                        style={{
                            // fontSize: '0.8rem',
                            display: downloadQrCode ? 'block' : 'none'
                        }}
                    >{sessionSplitName}</h1>
                    <div className="time-cont"
                        style={{
                            display: downloadQrCode ? 'flex' : 'none',
                            flexDirection: 'row'
                        }}
                    >
                        <span
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                fontFamily: 'firaSans-semibold'
                            }}
                        >Game Visibility: {sessionType}</span>
                        <span
                            style={{
                                fontSize: '20px',
                                marginLeft: '20px',
                                fontWeight: 'bold',
                                fontFamily: 'firaSans-semibold'
                            }}
                        >Start Time: {sessionStartTime}</span>
                        <span
                            style={{
                                marginLeft: '20px',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                fontFamily: 'firaSans-semibold'
                            }}
                        >End Time: {sessionEndTime}</span>
                    </div>


                    <QRCodeSVG
                        id={`qr-$${session.id}`}
                        value={getGameLink(session)}
                        size={downloadQrCode ? 400 : 300}
                        bgColor={"#ffffff"}
                        fgColor={"#202120"}
                        level={"L"}
                        includeMargin={true}
                    />
                    <span
                        style={{
                            fontWeight: 'bold',
                            fontSize: '25px',
                            fontFamily: 'firaSans-semibold',
                            display: downloadQrCode ? 'flex' : 'none',
                        }}
                    >SCAN ME</span>
                </div>
                {/* <QRCodeSVG
                    id={`qr-$${session.id}`}
                    value={getGameLink(session)}
                    size={300}
                    bgColor={"#ffffff"}
                    fgColor={"#202120"}
                    level={"L"}
                    includeMargin={true}
                /> */}
            </div>
            {/* {copyGameLink && (
                <div style={{ color: 'var(--color-theme)' }} className="flex justify-center font-medium cursor-pointer text-xl mt-4" onClick={onClickCopyGameLink}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>
                    {linkCopied ? "Copied" : "Copy Game Link"}
                </div>
            )} */}
            {
                copyGameLink && (
                    <>
                        <div className="flex flex-row w-[100%] relative">
                            <div className="flex w-[65%] h-[120px] items-center justify-center flex-col">

                                <div className="flex w-[100%] flex-row items-center h-[50px] justify-between">
                                    <div className="gameLink-main-cont">
                                        <div className="game-session-link-cont">
                                            <p>{shortUrlLink ? shortUrlLink : ""}</p>
                                        </div>
                                        <div className="coplyLink-container" onClick={onClickCopyShortGameLink} style={{ width: '35%' }}>
                                            <HiLink size={30} color="var(--color-theme)"></HiLink>
                                            <div className="linkBtn-text">{linkCopied ? "Copied" : "Copy Short Link"}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="flex w-[100%] flex-row items-center h-[50px] justify-between mt-3">
                                    <div className="gameLink-main-cont">
                                        <div className="game-session-link-cont">
                                            <p>{longUrlLink ? longUrlLink : ""}</p>
                                        </div>
                                        <div className="coplyLink-container" onClick={onClickCopyGameLink} style={{ width: '35%' }}>
                                            <HiLink size={30} color="var(--color-theme)"></HiLink>
                                            <div className="linkBtn-text">{linkCopied ? "Copied" : "Copy Long Link"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className=" flex items-center justify-evenly flex-row h-[120px] ">

                                <div className="download-qr-btn w-[45px] h-[40px] lg:w-[55px] lg:h-[50px] md:w-[55px] md:h-[50px] m-1" onClick={() => downloadQr(`qr-code-detail$${session.id}`, `${session.name}`)}>
                                    <PiDownloadSimpleBold size={window.innerWidth <= 500 ? 20 : 30} color="white"></PiDownloadSimpleBold>
                                    <div className="qrBtn-text">Download QR </div>
                                </div>
                                {Show && (
                                    <div
                                        className="save-btn-container w-[65px] h-[40px] lg:w-[75px] lg:h-[50px] md:w-[75px] md:h-[50px]"
                                        onClick={handleClose}
                                    >
                                        Save
                                        <div className="saveBtn-text ">Save</div>
                                    </div>
                                )}
                            </div>


                        </div>

                        {/* <div className="link-btn-container" style={{marginTop:'3%'}}>
                            <div className="gameLink-main-cont">
                                <div className="game-session-link-cont">
                                    <p>{shortUrlLink ? shortUrlLink : ""}</p>
                                </div>
                                <div className="coplyLink-container" onClick={onClickCopyShortGameLink} style={{width:'20%'}}>
                                    <HiLink size={30} color="var(--color-theme)"></HiLink>
                                    <div className="linkBtn-text">{linkCopied ? "Copied" : "Copy Short Link"}</div>
                                </div>
                            </div>
                            <div className="download-qr-btn" onClick={() => downloadQr(`qr-$${session.id}`)}>
                                <PiDownloadSimpleBold size={window.innerWidth <= 500 ? 20 : 30} color="white"></PiDownloadSimpleBold>
                                <div className="qrBtn-text">Download QR </div>
                            </div>
                            {Show && (
                                <div
                                    className="save-btn-container"
                                    onClick={handleClose}
                                >
                                    Save
                                    <div className="saveBtn-text">Save</div>
                                </div>
                            )}
                        </div> */}

                    </>
                )
            }

            {setShowModal && (<>
                <button
                    type="submit"
                    className={"btn btn-primary mx-4"}
                    onClick={async (e) => { await onDownloadQrCode(e); downloadQr(`qr-code-detail$${session.id}`, session.name); }}
                >
                    Download
                </button>
                <button
                    type="submit"
                    className={"btn btn-primary mx-4"}
                    onClick={() => setShowModal(false)}
                >
                    Close
                </button>
            </>
            )}
            {/* {Show && (
                <button
                    type="submit"
                    className={"btn btn-primary mx-4"}
                    onClick={handleClose}
                >
                    Close
                </button>
            )} */}
        </div>
    );
};
export default QRCodeDetail;
