import { BiMinus } from "react-icons/bi";
import { MdRefresh } from "react-icons/md";
import { AiOutlineExpand, AiOutlinePlus } from "react-icons/ai";
import { FiMinimize } from "react-icons/fi";


const CardHeader = ({ title, maxScreen, setMaxScreen }) => {
    const changeScreenSize = (val) => {
        setMaxScreen(val)
    }
    return (
        <div className="cardHeader p-4 flex justify-between">
            <div className="font-bold">
                {title}
            </div>
            <div className="headerElements flex items-center">
                <BiMinus size={20} className="ml-2" />
                <MdRefresh size={20} className="ml-2" />
                {
                    maxScreen ?
                        <FiMinimize size={16} className="ml-2" onClick={() => changeScreenSize(false)} /> :
                        <AiOutlineExpand size={16} className="ml-2" onClick={() => changeScreenSize(true)} />
                }
            </div>
        </div>
    )
}

export default CardHeader