import React from "react";
import "./analyticscard.css"

const AnalyticsCard = ({ title, value }) => {
    return (
        <div className="analytics-card">
            <div className="title mb-1">{title}</div>
            {value}
        </div>
    )
}

export default AnalyticsCard;