import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { AiOutlineClose } from "react-icons/ai";

import { updateDiwaliSettings } from "../../redux/actions/diwaliGameApiActions";

import CardComp from "./card";

const SettingsTab = ({ releaseLimitNumber }) => {
    const dispatch = useDispatch();
    const [releaseNumber, setReleaseNumber] = useState(1);

    useEffect(() => {
        setReleaseNumber(releaseLimitNumber)
    }, [releaseLimitNumber])

    const onClickSubmit = () => {
        if (releaseNumber > 10) {
            setReleaseNumber(1);
        } else {
            dispatch(updateDiwaliSettings({ releaseLimitNumber: Number(releaseNumber) }))
        }
    }

    return (
        <>
            <h4 className="font-bold mb-4" >Release Limit In Numbers</h4>
            <input type="number" className="border border-gray-700 rounded px-2 py-1" value={releaseNumber} max={10} min={1} onChange={(e) => setReleaseNumber(e.target.value)} />
            <div for="relimit" className="font-medium-2 text-bold-600 text-danger mt-1 text-[#fa626b]">Do not Change After Release</div>
            <button className="bg-[#e25569] text-white px-3 py-2 rounded" onClick={onClickSubmit}>Save</button>
        </>
    )
}

const SettingsSectionTwo = ({ gameSettings }) => {
    const [activeTabType, setActiveTabType] = useState("Settings");

    const ContentTab = () => {
        return (
            <div>
                <h4 className="font-bold">Add Custom Rules:</h4>
                <div className="flex items-center">
                    <input type="text" placeholder="Rules" name="rules" className="border border-gray-700 px-2 py-1" />
                    <button className="bg-[#fa626b] p-[9px]" type="button" data-repeater-delete>
                        <AiOutlineClose className="text-white" />
                    </button>

                </div>
            </div>
        )
    }
    return (
        <CardComp cardTitle={"Claim Type"}>
            <div className="flex items-center p-1 mb-4">
                <button onClick={() => setActiveTabType('Settings')} className={`${activeTabType === 'Settings' ? 'bg-gradient-to-r from-[#e25569] to-[#fb9946] text-white' : 'text-red-600'} px-4 py-2 text-sm font-medium capitalize md:py-3 rounded-md md:px-12 dark:text-red-400 dark:hover:text-white focus:outline-none rounded-md md:px-12`}>Settings</button>
                <button onClick={() => setActiveTabType('Content')} className={`${activeTabType === 'Content' ? 'bg-gradient-to-r from-[#e25569] to-[#fb9946] text-white' : 'text-red-600'} px-4 py-2 text-sm font-medium capitalize md:py-3 rounded-md md:px-12 dark:text-red-400 dark:hover:text-white focus:outline-none rounded-md md:px-12`}>Content</button>
            </div>
            {activeTabType === "Settings" ? <SettingsTab releaseLimitNumber={gameSettings?.releaseLimitNumber} /> : <ContentTab />}
        </CardComp>
    )
}

export default SettingsSectionTwo