export const GET_USERS_COUNT_REQUEST = "GET_USERS_COUNT_REQUEST";
export const GET_USERS_COUNT_SUCCESS = "GET_USERS_COUNT_SUCCESS";
export const GET_USERS_COUNT_FAIL = "GET_USERS_COUNT_FAIL";

export const DOWNLOAD_REGISTERED_USERS_REQUEST = "DOWNLOAD_REGISTERED_USERS_REQUEST";
export const DOWNLOAD_REGISTERED_USERS_SUCCESS = "DOWNLOAD_REGISTERED_USERS_SUCCESS";
export const DOWNLOAD_REGISTERED_USERS_FAIL = "DOWNLOAD_REGISTERED_USERS_FAIL";

export const DOWNLOAD_SUBSCRIBED_USERS_REQUEST = "DOWNLOAD_SUBSCRIBED_USERS_REQUEST";
export const DOWNLOAD_SUBSCRIBED_USERS_SUCCESS = "DOWNLOAD_SUBSCRIBED_USERS_SUCCESS";
export const DOWNLOAD_SUBSCRIBED_USERS_FAIL = "DOWNLOAD_SUBSCRIBED_USERS_FAIL";

export const UPDATE_SSO_DETAILS_REQUEST = "UPDATE_SSO_DETAILS_REQUEST";
export const UPDATE_SSO_DETAILS_SUCCESS = "UPDATE_SSO_DETAILS_SUCCESS";
export const UPDATE_SSO_DETAILS_FAIL = "UPDATE_SSO_DETAILS_FAIL";


export const GET_DIWALI_CLAIMS_REQUEST = "GET_DIWALI_CLAIMS_REQUEST";
export const GET_DIWALI_CLAIMS_SUCCESS = "GET_DIWALI_CLAIMS_SUCCESS";
export const GET_DIWALI_CLAIMS_FAIL = "GET_DIWALI_CLAIMS_FAIL";

export const GET_DIWALI_GAME_LINK_INFO_REQUEST = "GET_DIWALI_GAME_LINK_INFO_REQUEST";
export const GET_DIWALI_GAME_LINK_INFO_SUCCESS = "GET_DIWALI_GAME_LINK_INFO_SUCCESS";
export const GET_DIWALI_GAME_LINK_INFO_FAIL = "GET_DIWALI_GAME_LINK_INFO_FAIL";

export const TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_REQUEST = "TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_REQUEST";
export const TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_SUCCESS = "TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_SUCCESS";
export const TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_FAIL = "TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_FAIL";

export const GET_DDDT_LINKS_REQUEST="GET_DDDT_LINKS_REQUEST";
export const GET_DDDT_LINKS_SUCCESS="GET_DDDT_LINKS_SUCCESS";
export const GET_DDDT_LINKS_FAIL="GET_DDDT_LINKS_FAIL";

export const GET_DDDT_LINKS_GAME_REPORT_REQUEST="GET_DDDT_LINKS_GAME_REPORT_REQUEST";
export const GET_DDDT_LINKS_GAME_REPORT_SUCCESS="GET_DDDT_LINKS_GAME_REPORT_SUCCESS";
export const GET_DDDT_LINKS_GAME_REPORT_FAIL="GET_DDDT_LINKS_GAME_REPORT_FAIL";

export const XOXODAY_GAME_ADD_REQUEST="XOXODAY_GAME_ADD_REQUEST";
export const XOXODAY_GAME_ADD_SUCCESS="XOXODAY_GAME_ADD_SUCCESS";
export const XOXODAY_GAME_ADD_FAIL="XOXODAY_GAME_ADD_FAIL";

export const XOXODAY_GAMES_GET_REQUEST="XOXODAY_GAMES_GET_REQUEST";
export const XOXODAY_GAMES_GET_SUCCESS="XOXODAY_GAMES_GET_SUCCESS";
export const XOXODAY_GAMES_GET_FAIL="XOXODAY_GAMES_GET_FAIL";

export const XOXODAY_ORGANIZATION_ACTIVE_GAMES_GET_REQUEST="XOXODAY_ORGANIZATION_ACTIVE_GAMES_GET_REQUEST";
export const XOXODAY_ORGANIZATION_ACTIVE_GAMES_SUCCESS="XOXODAY_ORGANIZATION_ACTIVE_GAMES_SUCCESS";
export const XOXODAY_ORGANIZATION_ACTIVE_GAMES_REQUEST_FAIL="XOXODAY_ORGANIZATION_ACTIVE_GAMES_REQUEST_FAIL";

export const GET_USERS_POINTS_REQUEST = "GET_USERS_POINTS_REQUEST";
export const GET_USERS_POINTS_SUCCESS = "GET_USERS_POINTS_SUCCESS";
export const GET_USERS_POINTS_FAIL = "GET_USERS_POINTS_FAIL";

export const PUBLISH_POLL_QUESTION_REQUEST = "PUBLISH_POLL_QUESTION_REQUEST";
export const PUBLISH_POLL_QUESTION_SUCCESS = "PUBLISH_POLL_QUESTION_SUCCESS";
export const PUBLISH_POLL_QUESTION_FAIL = "PUBLISH_POLL_QUESTION_FAIL";