import React, { useEffect } from "react";
import { toast } from "synonyms/dictionary";
import { BASE_URL, axiosApiInstance } from "../../helpers/helper";
import { ToastContainer } from 'react-toastify';

function RewardDistributionModal({ setRewardDistribution, sessionId, gameId, userInfo, selectedSessionId, handleRewardDistributionMessage, gameRewards = {} }) {
    const ref = React.createRef();
    useEffect(() => {
        if (ref.current)
            ref.current.style.top = document.documentElement.scrollTop + "px";
    }, [ref])
    const handleClick = async (e) => {
        e.preventDefault();

        try {
            const result = await axiosApiInstance.post(BASE_URL + "/api/xoxoday-game/points-distribution", { selectedSessionId, gameId, orgId: userInfo?.data?.organizationId, userId: userInfo?.data?.id });
            setRewardDistribution(false);

            if (result?.status === 200) {

                handleRewardDistributionMessage(result.data.message, true)
            } else {
                handleRewardDistributionMessage("Failed to  distribute the  Reward Points", false)
            }
        } catch (err) {
            handleRewardDistributionMessage("Failed to  distribute the  Reward Points", false)
            setRewardDistribution(false)

        }
    }

    return (
        <div className="confirm-slogan-sort-modal" ref={ref} id="confirm-slogan-sort-modal">
            <ToastContainer position="bottom-center" />

            <div className="container">
                <div className="mb-3">
                    <h1 className="text-xl">Rewards Distribution</h1>
                </div>
                {gameRewards?.responseStatus &&(<>
                <div className="mt-2">Total points available in your Organization - {gameRewards?.totalPointsInOrganization} Points</div>
                <div className="mt-2">Rewards tobe detected from Organization -  {gameRewards?.pointsToBeDeductedFromOrganization} </div>
                {/* {
                    gameRewards?.participation ? (
                        <>
                        <div>Participation points - {gameRewards?.participationPoints} Points</div>
                        <div>Game Played user count - {gameRewards?.totalPlayersCount}</div>
                        </>
                    ): null
                } */}
                <div className="!text-base text-red-700 mt-3">{gameRewards?.totalPointsInOrganization < gameRewards?.pointsToBeDeductedFromOrganization ? "To distribute the reward points your organization doesn't have enough ponits" : "Are you sure you want to Distribute the points to the participants ?"}</div>
                </>) }
               
                <div className="text-red-600/100 mt-3"> {!gameRewards?.responseStatus ? `* ${gameRewards?.message}`:""}</div>

               
                <div className="btn-group mt-3">
                    {(gameRewards?.totalPointsInOrganization < gameRewards?.pointsToBeDeductedFromOrganization || !gameRewards?.responseStatus) ? null :
                        <button className="btn btn-primary" onClick={handleClick}>Yes</button>}
                    <button className="btn btn-primary" onClick={() => setRewardDistribution(false)}>Cancel</button>
                </div>
            </div>
        </div>
    );
}
export default RewardDistributionModal;