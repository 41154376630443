import * as ActionTypes from "../constants/featureBannersConstants";

const initialState = {
  loading: false,
  featureBanner: [],
  error: ""
};

function getFeatureBannersReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_FEATUREBANNER_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_FEATUREBANNER_SUCCESS:
      return { ...state, loading: false, featureBanner: action.payload,error:'' };
    case ActionTypes.FETCH_FEATUREBANNER_FAILURE:
      return { ...state,loading: false, featureBanner: [], error: action.payload };
    default:
      return state;
  }
}

export { getFeatureBannersReducer };
