import LoadingComponent from "../loader/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getSSORedirection } from "../../redux/actions/xoxodayActions";

const RedeemPage = () => {
  const [ssoToken, setSsoToken] = useState("");
  const dispatch = useDispatch();
  const SSOLink = useSelector(state => state.SSOLink);
  const UserInfo = useSelector((state) => state.getUser);
  useEffect(() => {
    dispatch(getSSORedirection({ email: UserInfo.userInfo?.data?.email, url: "https://stagingaccount.xoxoday.com/chef/v1/oauth/sso/stores/company" }))
  }, [])

  useEffect(() => {
    if (SSOLink.SSO?.data?.ssoToken) {
      const link = document.createElement('a');
      link.href = `https://stagingaccount.xoxoday.com/chef/v1/oauth/redirect/stores/${SSOLink.SSO?.data?.ssoToken}`;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
        // Simulate a click on the link
      link.click();
      setSsoToken(SSOLink.SSO?.data?.ssoToken)
    }
  }, [SSOLink])

  return (
    <>
      {ssoToken !== "" ? (
        <a
          href={`https://stagingaccount.xoxoday.com/chef/v1/oauth/redirect/stores/${ssoToken}`}

          target="_blank"
          className="group-item active"
          rel="noopener noreferrer"
        >Access XOXOday Storefront</a>
      ) : <LoadingComponent loaded={false} />}

    </>
  )
  // return (
  //   <div
  //     style={{
  //       width: "100%",
  //       minHeight: "800px",
  //       height: "100%",
  //       border: "1px solid black"
  //     }}
  //   >
  //     {ssoToken !== "" ? (<iframe
  //       id="xoxoday-iframe"
  //       title="xoxoday"
  //       src={`https://stagingaccount.xoxoday.com/chef/v1/oauth/redirect/stores/${ssoToken}`}
  //     ></iframe>) :
  //       <LoadingComponent loaded={false} />
  //     }
  //   </div>
  // )
}
export default RedeemPage;