import OneSignal from 'react-onesignal'

const setOneSignalTags = async (tags) => {
    OneSignal.getTags((data) => {
        console.log("ONE SIGNAL : ", data)
        if(!data.organization || !data.userId){
            OneSignal.setExternalUserId(tags.userId)
            OneSignal.sendTags(tags)
        }
    })
}

export {
    setOneSignalTags
}