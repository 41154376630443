import React, { useCallback, useEffect, useState } from 'react';
import { BASE_URL, CLIENT_URL, S3_BASE_URL, axiosApiInstance, encryptData, failureAlert, successAlert } from '../../helpers/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgTeams, getOrgTeamsGameSessions } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import { ToastContainer } from 'react-toastify';
import Header from '../header/header';
import arrowback from "../../assets/images/arrow-back.svg";
import copy from "copy-to-clipboard";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { homeSearch } from '../../redux/actions/homepageActions';

import privateOnly from "../../assets/images/private.svg";
import publicOnly from "../../assets/images/public.svg";
import guestOnly from "../../assets/images/guestUser.svg";
import inviteOnly from "../../assets/images/inviteOnly.svg";
import gameArrow from "../../assets/images/gameArrow.svg";

import { FaSearch } from "react-icons/fa";
import { IoIosAddCircle } from 'react-icons/io';
import StartNewTeamGameModal from '../modal/StartNewTeamGameModal';
import QrDownloadModal from '../activeGamesDetail/qrDownloadModal';
import DeleteDomainModal from '../modal/deleteDomainModal';
import { getGameServerOtp } from '../../redux/actions/gameDetailAction';
import StartNewTeamGameModalTwo from '../modal/StartNewGameModalTwo';
import { logOut } from '../../redux/actions/userAction';






const ScheduleTeamsGames = (props) => {


    const [loaded, setLoaded] = useState(true);
    const [allGames, setAllGames] = useState([])
    const [selectedGameInfo, setSelectedGameInfo] = useState()


    const [openNewGameModal, setOpenNewGameModal] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editSession, setEditSession] = useState({});
    const [gameSearchText, setGameSearchText] = useState()
    const [gameId, setGameId] = useState()

    const [activeTabType, setActiveTabType] = useState('Active Sessions')
    const [gameSessions, setGameSessions] = useState([])

    const [showQrModal, setShowQrModal] = useState(false);
    const [selectedSession, setSelectedSession] = useState('')
    const [linkCopied, setLinkCopied] = useState(false)

    const [openDeleteDomainModal, setOpenDeleteDomainModal] = useState(false)
    const [deleteSession, setDeleteSession] = useState()

    const [loadFlag, setLoadFlag] = useState();
    const [joinPressed, setJoinPressed] = useState(false);

    const UserInfo = useSelector((state) => state.getUser);
    const { userInfo } = UserInfo;
    const GameDetail = useSelector(state => state.gameDetail);
    const { gameDetail } = GameDetail;
    const GameServerOtp = useSelector(state => state.gameServerOtp);
    const { gameServerOtp } = GameServerOtp;

    const OrgTeamSessions = useSelector((state) => state.getOrgTeamsGameSession)
    const { teamSessions } = OrgTeamSessions;

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        getAllOrgGames()
        // getAllTeamsGameSessions()
        dispatch(getOrgTeamsGameSessions(activeTabType))
    }, [])

    useEffect(()=>{
        if(activeTabType){
            dispatch(getOrgTeamsGameSessions(activeTabType))
        }
    },[activeTabType])

    useEffect(() => {
        if (teamSessions && teamSessions.message === "sessions fetch sucessfully") {
            setGameSessions(teamSessions.data)
        }
    }, [OrgTeamSessions])

    useEffect(() => {
        if (gameSearchText && gameSearchText !== '') {
            getAllOrgGames(gameSearchText)
        }
        else if (gameSearchText === '') {
            getAllOrgGames()
        }
    }, [gameSearchText])

    useEffect(() => {
        if (joinPressed && gameServerOtp && gameServerOtp.data) {
            setJoinPressed(false);
            window.location.replace(gameServerOtp.data.redirectUrl);
        }
    }, [gameServerOtp, joinPressed])

    const getAllOrgGames = async (searchText) => {
        setLoaded(false)
        let url = '/api/game/getAllOrgGames'
        if (searchText && searchText !== '') {
            url = '/api/game/getAllOrgGames?search=' + searchText
        }
        const response = await axiosApiInstance.get(BASE_URL + url)
        if (response && response.data.message === "GAMES FETCHED SUCCESSFULLY") {
            setAllGames(response.data.data)
        }
        setLoaded(true)
    }

    const handleGameSelection = (game) => {
        if ((game && !selectedGameInfo) || (game && selectedGameInfo.id !== game.id)) {
            setSelectedGameInfo(game)
        }
        else if (game && selectedGameInfo.id === game.id) {
            setSelectedGameInfo([])

        }

    }

    const checkSelectedGame = (gameId) => {
        if (selectedGameInfo && selectedGameInfo.id === gameId) {
            return true
        }
        else {
            return false
        }
    }


    const handleBack = () => {
        history.push("/manage-edit-teams")
    }
    const handleEditTeamsClick = ()=>{
        history.push("/manage-edit-teams")
    }
    const handleCreateTeamGameSession = () => {
        if (selectedGameInfo && selectedGameInfo?.id) {
            setGameId(selectedGameInfo.id);
            setOpenNewGameModal(true)
        }
        else {
            failureAlert("Please select game to create session")
        }


    }

    const getAllTeamsGameSessions = async () => {
        const response = await axiosApiInstance.get(BASE_URL + "/api/session/get-teamSessions");
        if (response && response.data && response.data.message === "sessions fetch sucessfully") {
            setGameSessions(response.data.data)
        }
    }

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const formatToDate = (dateString) => {
        const date = new Date(dateString);
        const newDateString = `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}
        -${monthNames[date.getMonth()]}-
        ${date.getFullYear()} 
        ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
        return newDateString;
    }

    const handleEdit = (e, session) => {
        e.preventDefault();
        setEditSession(session);
        setGameId(session.organizationGame.gameId)
        setEdit(true);
        setOpenNewGameModal(true);
    }

    const copyGameLink = async (session) => {
        const obj = { from: session?.createdBy?.email, organizationId: session?.organizationId, onBoardType: "INVITE" };
        const inviteToken = encryptData(obj);
        let shareLink = "";

        if (session?.mode === 'ANYONE') {
            shareLink = `${CLIENT_URL}/join/guest?gameId=${session?.organizationGame?.gameId}&sessionId=${session?.id}&inviteId=${inviteToken}`;
        } else {
            shareLink = `${CLIENT_URL}/join?gameId=${session?.organizationGame?.gameId}&sessionId=${session?.id}&inviteId=${inviteToken}`;
        }
        const response = await axiosApiInstance.post(`${BASE_URL}/api/session/session-shortlink`, { sessionLink: shareLink });

        if (response?.data?.message !== "sucessfuly generated short link") {
            return failureAlert("Failed to generate copy url");
        }

        const shortSessionUrl = response.data.shortLink;

        copy(shortSessionUrl)
        setLinkCopied(true);

    };

    const handleDelete = async (session) => {
        if (session && session.id) {
            const body = {
                sessionId: session.id
            }
            const response = await axiosApiInstance.post(BASE_URL + "/api/session/delete-teamSession", body);
            if (response && response.data && response.data.message === "SESSION_DELETED_SUCCESSFULLY") {
                successAlert("Session deleted sucessfully")
                dispatch(getOrgTeamsGameSessions(activeTabType));
            }
            else {
                failureAlert("Failed to delete session")
            }
            setOpenDeleteDomainModal(false);
            setDeleteSession()
        }

    }

    const handleJoinGame = async (sessionId, from, to, sessionGameId) => {
        if ((new Date(from) > new Date()) || (new Date(to) < new Date())) {
            setLoadFlag(prevState => prevState + 1);
            return;
        }
        setLoaded(false);
        const response = await dispatch(getGameServerOtp(sessionGameId, sessionId));
        if (response && response.status === 200) {
            setJoinPressed(true);
        }
        else {
            setLoaded(true);
        }
    }
    const signOut = async () => {
        await dispatch(logOut());
        if (props.history)
            props.history.push("/");

    };

    return (<>
        <Header profile  signOut={signOut}/>
        <div className="flex w-[100%] h-[100vh] flex-col items-center justify-start pt-[110px] md:pr-8 md:pl-8 lg:pr-10 lg:pl-10 pr-5 pl-5 pb-5 overflow-y-scroll">
            <ToastContainer position='bottom-center' />
            <LoadingComponent loaded={loaded} />
            {
                openNewGameModal &&
                <StartNewTeamGameModalTwo
                    modalid={"new-game-modal"}
                    gameId={gameId} toggle={openNewGameModal}
                    setOpenNewGameModal={setOpenNewGameModal}
                    editSession={editSession}
                    setEditSession={setEditSession}
                    edit={edit}
                    setEdit={setEdit}
                />
            
            }
            {openDeleteDomainModal && <DeleteDomainModal toggle={openDeleteDomainModal} setOpenDeleteDomainModal={setOpenDeleteDomainModal} deleteFunction={handleDelete} deleteData={deleteSession} session />}

            <QrDownloadModal showModal={showQrModal} setShowModal={setShowQrModal} session={selectedSession} />

            <div className="flex items-center justify-center w-[100%] relative">
                <h1 className='edit-team-head' style={{ width: 'auto' }}> Schedule Team Games</h1>

                <img src={arrowback} alt="back" onClick={handleBack} className='flex h-[30px] w-[30px] absolute left-0 hover:cursor-pointer' />
            </div>

            <div className="flex flex-col lg:flex-row items-center lg:items-start justify-start mt-12 w-[100%]">

                <div className="flex w-[100%] md:w-[80%] lg:w-[30%] h-auto max-h-[500px] flex-col overflow-y-scroll no-scrollbar ">

                    <div className="flex w-[100%] h-[40px] relative items-center  border border-[#7e7e7e] mb-0">
                        <input type="text" className='team-members-search' placeholder='Search for game a create a game session '
                            onChange={(e) => setGameSearchText(e.target.value)}
                            value={gameSearchText}
                        />
                        <FaSearch className=' absolute right-[20px] h-[23px] w-[23px]' />
                    </div>
                    <div className="flex w-[100%] h-[40px] flex-row items-center justify-between pl-3 text-[#5f5f5f]">
                        <span>List</span>

                        <span>Select Game</span>
                    </div>
                    <div className="fex items-center justify-center w-[100%] h-auto max-h-[340px] overflow-y-scroll no-scrollbar pt-1">
                        {
                            allGames && allGames.length > 0 &&
                            allGames.map((game, index) => {
                                return (
                                    <div className="flex items-center justify-between w-[100%] h-[40px] pr-3 pl-3 hover:bg-hoverThemeColor bg-[#fcfcfc] text-[#5f5f5f] mt-2 " >
                                        <span>{game.title}</span>
                                        <div className=" flex h-[30px] w-[40px] items-center justify-center"
                                        //  onClick={(e) => { e.stopPropagation(); handleTeamCaptainSelection(member.id); }}
                                        >
                                            <label class="checkbox-label">
                                                <input type="checkbox" class="checkbox-tow"
                                                    checked={checkSelectedGame(game.id)}
                                                    onClick={(e) => { e.stopPropagation(); handleGameSelection(game); }}
                                                />
                                                <div class="svg-icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="16px"
                                                        viewBox="0 0 448 512"
                                                    >
                                                        <path
                                                            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                                <span class="check-box-container"></span>
                                            </label>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={`flex items-center justify-center w-[100%] h-[80px] p-3 text-[#7e7e7e] flex-col cursor-pointer `}
                        // onClick={(e) => handleCreateTeamGameSession()}
                    >
                        {/* <IoIosAddCircle style={{
                            width: '25px',
                            height: '25px'
                        }}
                            fill='var(--color-theme)'
                        /> */}
                        {/* <span>Create Game</span> */}

                        <button type='button' className='btn btn-primary' 
                         onClick={(e) =>{ 
                            e.preventDefault();
                            handleCreateTeamGameSession();
                        }}
                        >Create Game </button>


                    </div>

                </div>

                <div className="flex  w-[100%] md:w-[80%] lg:w-[65%] h-[500px] lg:ml-[35px] mt-5 lg:mt-0 flex-col items-center justify-start">

                    <div className=" flex flex-row w-[100%]">
                        <div className="flex h-[40px] items-center justify-center p-3 cursor-pointer" onClick={() => setActiveTabType("Active Sessions")}>
                            <span className={`${activeTabType === "Active Sessions" ? 'text-red-600 ' : ''}`}> Active Session</span>
                        </div>
                        <div className="flex h-[40px] items-center justify-center ml-3 p-3 cursor-pointer" onClick={() => setActiveTabType("Expired Sessions")}>
                            <span className={`${activeTabType === "Expired Sessions" ? 'text-red-600 ' : ''}`}> Expired Session</span>
                        </div>
                    </div>
                    <div className="flex  w-[100%] h-[460px] flex-col items-center justify-start overflow-y-scroll no-scrollbar pt-1">

                        <div className="game-table w-[100%]">

                            <table>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Initiated By</th>
                                        <th>Game Visibility</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        {/* <th></th> */}
                                        {activeTabType === "Active Sessions" ? (<th>Action</th>) : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {gameSessions?.paginationData?.totalEntries > 0 ? <></> : <></>} */}
                                    {/* {
                                    gameSessions.map?.((session) => {
                                        return (
                                            <tr key={JSON.stringify(session)}>
                                                <td className="games-title">{session?.name}</td>
                                                <td>
                                                    Extramile
                                                </td>
                                                <td>
                                                    {
                                                        session.mode === "PUBLIC" ?
                                                            <div className="game-mode">
                                                                <img src={publicOnly} alt="public" /> <span>Public</span>
                                                            </div>
                                                            : session.mode === "PRIVATE" ?
                                                                <div className="game-mode">
                                                                    <img src={privateOnly} alt="public" /> <span>Private</span>
                                                                </div>
                                                                : session.mode === 'INVITE' ?
                                                                    <div className="game-mode">
                                                                        <img src={inviteOnly} alt="public" /> <span>Invite Only</span>
                                                                    </div>
                                                                    : session?.mode === "ANYONE" ?
                                                                        <div className="game-mode">
                                                                            <img src={guestOnly} alt="public" /> <span>Guest User</span>
                                                                        </div> : null
                                                    }
                                                </td>
                                                <td>{formatToDate(session.scheduleFrom)}</td>
                                                <td>
                                                    <div className="game-btn">
                                                        {formatToDate(session.scheduleTo)}
                                                        {activeTabType === "Active Sessions" ? (
                                                            <div className="action-group">
                                                                <div className="btn-tooltip">


                                                                    <div class="icon-conatiner">
                                                                        <svg
                                                                            width="19px"
                                                                            height="21px"
                                                                            viewBox="0 0 19 21"
                                                                            version="1.1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <title>Group</title>
                                                                            <g
                                                                                id="Page-1"
                                                                                stroke="none"
                                                                                stroke-width="1"
                                                                                fill="none"
                                                                                fill-rule="evenodd"
                                                                            >
                                                                                <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                                                                    <g id="Group" transform="translate(142.000000, 122.000000)">
                                                                                        <path
                                                                                            d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                                                                            id="Rectangle-Copy"
                                                                                            fill="#C4FFE4"
                                                                                        ></path>
                                                                                        <path
                                                                                            d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                                                                            id="Rectangle"
                                                                                            fill="#85EBBC"
                                                                                        ></path>
                                                                                        <path
                                                                                            d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                                                                            id="Path-2"
                                                                                            fill="#64B18D"
                                                                                        ></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <svg
                                                                            width="19px"
                                                                            height="21px"
                                                                            viewBox="0 0 19 21"
                                                                            version="1.1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <title>Group</title>
                                                                            <g
                                                                                id="Page-1"
                                                                                stroke="none"
                                                                                stroke-width="1"
                                                                                fill="none"
                                                                                fill-rule="evenodd"
                                                                            >
                                                                                <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                                                                    <g id="Group" transform="translate(142.000000, 122.000000)">
                                                                                        <path
                                                                                            d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                                                                            id="Rectangle-Copy"
                                                                                            fill="#C4FFE4"
                                                                                        ></path>
                                                                                        <path
                                                                                            d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                                                                            id="Rectangle"
                                                                                            fill="#85EBBC"
                                                                                        ></path>
                                                                                        <path
                                                                                            d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                                                                            id="Path-2"
                                                                                            fill="#64B18D"
                                                                                        ></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </div>



                                                                </div>

                                                                <div className="animated-btn-tooltip">
                                                                    <div class="qr-loader"
                                                                    //  onClick={() => { setSelectedSession(session); setShowQrModal(true); }}
                                                                    >
                                                                        <div class="qr-box1"></div>
                                                                        <div class="qr-box2"></div>
                                                                        <div class="qr-box3"></div>
                                                                    </div>
                                                                    <div className="hover-qrCode-tooltip" role="tooltip">
                                                                        <span>Download QR</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </td>


                                                {activeTabType === "Active Sessions" ? (<td>
                                                    {" "}
                                                    <div style={{ margin: "0px -10px" }} className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "" : "btn-tooltip"}>
                                                        <button
                                                            type="submit"
                                                            className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date()) && session.mode !== "ANYONE") ? "btn btn-primary" : "btn btn-primary disabled"}
                                                            onClick={e => handleJoinGame(session.id, session.scheduleFrom, session.scheduleTo)}
                                                            disabled={session.mode === "ANYONE"}
                                                        >
                                                            Join Game
                                                        </button>
                                                        <div className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "tooltip hide" : "tooltip "} role="tooltip">
                                                            <span>
                                                                {
                                                                    new Date(session.scheduleFrom) > new Date() ?
                                                                        "This session not yet started."
                                                                        : "This session was ended."
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>) : null}


                                            </tr>
                                        )
                                    })
                                } */}

                                    {
                                        gameSessions?.map((session, index) => {
                                            return (
                                                <tr key={JSON.stringify(session)}>



                                                    <td className="games-title text-sm">
                                                        {session.name}
                                                    </td>


                                                    <td className=' text-sm'>
                                                        {
                                                            session.name.includes(gameDetail && gameDetail.data && gameDetail.data.title + " - PUBLIC SESSION") && session.guestUserFields === null ? "Default"
                                                                : (userInfo && userInfo.data && session.createdBy.email === userInfo.data.email) ? "Me"
                                                                    : session.createdBy.email
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            session.mode === "PUBLIC" ?
                                                                <div className="game-mode">
                                                                    <img src={publicOnly} alt="public" /> <span>Public</span>
                                                                </div>
                                                                : session.mode === "PRIVATE" ?
                                                                    <div className="game-mode">
                                                                        <img src={privateOnly} alt="public" /> <span>Private</span>
                                                                    </div>
                                                                    : session.mode === 'INVITE' ?
                                                                        <div className="game-mode">
                                                                            <img src={inviteOnly} alt="public" /> <span>Invite Only</span>
                                                                        </div>
                                                                        : session?.mode === "ANYONE" ?
                                                                            <div className="game-mode">
                                                                                <img src={guestOnly} alt="public" /> <span>Guest User</span>
                                                                            </div> : null
                                                        }
                                                    </td>
                                                    <td className='text-sm'>{formatToDate(session.scheduleFrom)}</td>
                                                    <td>
                                                        <div className="game-btn text-sm">
                                                            {formatToDate(session.scheduleTo)}
                                                            <div className="action-group flex">

                                                                {(userInfo && userInfo.data && session.createdBy.email === userInfo.data.email) &&
                                                                    <>
                                                                        {activeTabType === "Active Sessions" ? (<>

                                                                            <div className="animated-btn-tooltip">
                                                                                <button class="animated-editBtn"
                                                                                    onClick={(e) => handleEdit(e, session)}
                                                                                >
                                                                                    <svg height="1em" viewBox="0 0 512 512">
                                                                                        <path
                                                                                            fill="#5F638A"
                                                                                            d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                                                                        ></path>
                                                                                    </svg>
                                                                                </button>
                                                                                <div className="hover-tooltip" role="tooltip">
                                                                                    <span>Edit</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="animated-btn-tooltip">
                                                                                <button class="session-delete-button"
                                                                                    onClick={(e) => { e.preventDefault(); setDeleteSession(session); setOpenDeleteDomainModal(true) }}
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none"
                                                                                        viewBox="0 0 69 14"
                                                                                        class="svgIcon bin-top"
                                                                                    >
                                                                                        <g clip-path="url(#clip0_35_24)">
                                                                                            <path
                                                                                                fill="black"
                                                                                                d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                                                                            ></path>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_35_24">
                                                                                                <rect fill="white" height="14" width="69"></rect>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>

                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none"
                                                                                        viewBox="0 0 69 57"
                                                                                        class="svgIcon bin-bottom"
                                                                                    >
                                                                                        <g clip-path="url(#clip0_35_22)">
                                                                                            <path
                                                                                                fill="black"
                                                                                                d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                                                                            ></path>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_35_22">
                                                                                                <rect fill="white" height="57" width="69"></rect>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </button>
                                                                                <div className="hover-tooltip" role="tooltip">
                                                                                    <span>Delete</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="animated-btn-tooltip">
                                                                                <div class="icon-conatiner"
                                                                                    onMouseOut={() => setLinkCopied(false)}
                                                                                    onClick={(e) => { e.preventDefault(); copyGameLink(session) }}
                                                                                >
                                                                                    <svg
                                                                                        width="19px"
                                                                                        height="21px"
                                                                                        viewBox="0 0 19 21"
                                                                                        version="1.1"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >


                                                                                        <g
                                                                                            id="Page-1"
                                                                                            stroke="none"
                                                                                            stroke-width="1"
                                                                                            fill="none"
                                                                                            fill-rule="evenodd"
                                                                                        >
                                                                                            <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                                                                                <g id="Group" transform="translate(142.000000, 122.000000)">
                                                                                                    <path
                                                                                                        d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                                                                                        id="Rectangle-Copy"
                                                                                                        fill="#a1a3b2"
                                                                                                    ></path>
                                                                                                    <path
                                                                                                        d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                                                                                        id="Rectangle"
                                                                                                        fill="#5F638A"
                                                                                                    ></path>
                                                                                                    <path
                                                                                                        d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                                                                                        id="Path-2"
                                                                                                        fill="#373a57"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                    <svg
                                                                                        width="19px"
                                                                                        height="21px"
                                                                                        viewBox="0 0 19 21"
                                                                                        version="1.1"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >


                                                                                        <g
                                                                                            id="Page-1"
                                                                                            stroke="none"
                                                                                            stroke-width="1"
                                                                                            fill="none"
                                                                                            fill-rule="evenodd"
                                                                                        >
                                                                                            <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                                                                                <g id="Group" transform="translate(142.000000, 122.000000)">
                                                                                                    <path
                                                                                                        d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                                                                                        id="Rectangle-Copy"
                                                                                                        fill="#a1a3b2"
                                                                                                    ></path>
                                                                                                    <path
                                                                                                        d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                                                                                        id="Rectangle"
                                                                                                        fill="#5F638A"
                                                                                                    ></path>
                                                                                                    <path
                                                                                                        d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                                                                                        id="Path-2"
                                                                                                        fill="#373a57"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>

                                                                                </div>
                                                                                <div className="hover-tooltip" role="tooltip">
                                                                                    <span>
                                                                                        {linkCopied ? 'Copied' : 'Copy Link'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="animated-btn-tooltip">
                                                                                <div class="qr-loader"
                                                                                    onClick={() => { setSelectedSession(session); setShowQrModal(true); }}
                                                                                >
                                                                                    <div class="qr-box1"></div>
                                                                                    <div class="qr-box2"></div>
                                                                                    <div class="qr-box3"></div>
                                                                                </div>
                                                                                <div className="hover-qrCode-tooltip" role="tooltip">
                                                                                    <span>Download QR</span>
                                                                                </div>
                                                                            </div>

                                                                            {/**animated btn tooltip end*/}

                                                                        </>) : null
                                                                        }
                                                                        {
                                                                            activeTabType === "Expired Sessions" ? (

                                                                                <div className="animated-btn-tooltip">
                                                                                    <button class="session-delete-button"
                                                                                        onClick={(e) => { e.preventDefault(); setDeleteSession(session); setOpenDeleteDomainModal(true) }}
                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            fill="none"
                                                                                            viewBox="0 0 69 14"
                                                                                            class="svgIcon bin-top"
                                                                                        >
                                                                                            <g clip-path="url(#clip0_35_24)">
                                                                                                <path
                                                                                                    fill="black"
                                                                                                    d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                                                                                ></path>
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_35_24">
                                                                                                    <rect fill="white" height="14" width="69"></rect>
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>

                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            fill="none"
                                                                                            viewBox="0 0 69 57"
                                                                                            class="svgIcon bin-bottom"
                                                                                        >
                                                                                            <g clip-path="url(#clip0_35_22)">
                                                                                                <path
                                                                                                    fill="black"
                                                                                                    d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                                                                                ></path>
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_35_22">
                                                                                                    <rect fill="white" height="57" width="69"></rect>
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                    </button>
                                                                                    <div className="hover-tooltip" role="tooltip">
                                                                                        <span>Delete</span>
                                                                                    </div>

                                                                                </div>

                                                                            ) : ""
                                                                        }
                                                                    </>
                                                                }
                                                                {/* {
                                userInfo && userInfo.data && (userInfo.data.role === ROLES.ORG_SUPER_ADMIN || session?.createdBy?.email === userInfo.data.email) && showConfigure &&
                                
                                                        <div className="animated-btn-tooltip"
                                                         onClick={(e) => handleConfigure(e, session.id)}
                                                         >
                                                            <button class="setting-btn">
                                                                <span class="bar bar1"></span>
                                                                <span class="bar bar2"></span>
                                                                <span class="bar bar1"></span>
                                                            </button>
                                                            <div className="hover-tooltip" role="tooltip">
                                                                <span>setting</span>
                                                            </div>

                                                        </div>

                                                        }  */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {activeTabType === "Active Sessions" ? (<td>
                                                        {" "}
                                                        <div style={{ margin: "0px -10px" }} className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "" : "btn-tooltip"}>
                                                            <button
                                                                type="submit"
                                                                className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date()) && session.mode !== "ANYONE") ? "btn btn-primary" : "btn btn-primary disabled"}
                                                                onClick={e => handleJoinGame(session.id, session.scheduleFrom, session.scheduleTo, session.organizationGame.gameId)}
                                                                disabled={session.mode === "ANYONE"}
                                                            >
                                                                Join Game
                                                            </button>
                                                            {/* tooltip */}
                                                            <div className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "tooltip hide" : "tooltip "} role="tooltip">
                                                                <span>
                                                                    {
                                                                        new Date(session.scheduleFrom) > new Date() ?
                                                                            "This session not yet started."
                                                                            : "This session was ended."
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>) : null}
                                                </tr>
                                            );
                                        })
                                    }
                                    {
                                        gameSessions.length === 0 && <div>
                                            <h3 className="no-data-desk">No Sessions Found !</h3>
                                        </div>
                                    }
                                </tbody>
                            </table>
                        </div>


                        <div className="table-responsive m-games-table w-[100%]">
                            {/* <div className="game-m-head">
                                <button
                                    onClick={() => setActiveTabType("Active Sessions")}
                                    className={`${activeTabType === "Active Sessions"
                                        ? "text-red-600"
                                        : "text-gray-600"
                                        }  text-sm font-medium md:px-12 focus:outline-none rounded-md float-left`}
                                >
                                    Active Sessions
                                </button>
                                <button
                                    onClick={() => setActiveTabType("Expired Sessions")}
                                    className={`${activeTabType === "Expired Sessions"
                                        ? "text-red-600"
                                        : "text-gray-600"
                                        } px-4 py-2 text-sm font-medium capitalize md:py-3 rounded-md md:px-12 focus:outline-none`}
                                >
                                    Expired Sessions
                                </button>

                                {
                                    activeTabType === "Active Sessions" ? (
                                        <button type="submit"
                                            className={`btn btn-secondry  ${userInfo &&
                                                userInfo.data && userInfo.data.role === ROLES.EMPLOYEE
                                                && gameDetail && gameDetail.data && !gameDetail.data.allowEmployeeSession ? "disabled" : ""}`}
                                            onClick={handleClick}>
                                            Start new Game</button>
                                    ) : ""
                                }


                            </div> */}
                            {/* {
                                gameSessions?.publicSessions?.map?.((session) => {
                                    return (
                                        <div className="game-m-card c-white-card" key={JSON.stringify(session)}>
                                            <div className="game-m-top">
                                                <div className="game-detail">
                                                    <div className="game-mode">
                                                        <img src={publicOnly} alt="public" /> <span>Public</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="games-m-title">{session.name}</div>
                                            <span className="games-m-date">
                                                {formatToDate(session.scheduleFrom)}
                                                <img src={gameArrow} alt="public" /> {formatToDate(session.scheduleTo)}
                                            </span>
                                            <div className="game-m-bottom">
                                                <div>
                                                    <div className="game-m-initiate">Initiated by</div>
                                                    <div className="game-m-user">
                                                        Extramile
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="action-group">

                                                    </div>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "btn btn-primary join-game" : "btn btn-primary join-game disabled"}
                                                    onClick={() => handleJoinGame(session.id, session.scheduleFrom, session.scheduleTo)}
                                                >
                                                    Join Game
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })
                            } */}
                            {
                                gameSessions.length > 0 &&
                                gameSessions.map((session) => {
                                    return (
                                        <div className="game-m-card c-white-card" key={JSON.stringify(session)}>
                                            <div className="game-m-top">
                                                <div className="game-detail">
                                                    {
                                                        session.mode === "PUBLIC" ?
                                                            <div className="game-mode">
                                                                <img src={publicOnly} alt="public" /> <span>Public</span>
                                                            </div>
                                                            : session.mode === "PRIVATE" ?
                                                                <div className="game-mode">
                                                                    <img src={privateOnly} alt="public" /> <span>Private</span>
                                                                </div>
                                                                : session.mode === "INVITE" ?
                                                                    <div className="game-mode">
                                                                        <img src={inviteOnly} alt="public" /> <span>Invite only</span>
                                                                    </div>
                                                                    :
                                                                    <div className="game-mode">
                                                                        <img src={guestOnly} alt="public" /> <span> Guest User</span>
                                                                    </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="games-m-title">{session.name}</div>

                                            <span className="games-m-date">
                                                {formatToDate(session.scheduleFrom)}
                                                <img src={gameArrow} alt="public" /> {formatToDate(session.scheduleTo)}
                                            </span>
                                            <div className="game-m-bottom game-m-bottom-mob">
                                                <div>
                                                    <div className="game-m-initiate">Initiated by</div>
                                                    <div className="game-m-user">
                                                        {
                                                            session.name.includes(gameDetail && gameDetail.data && gameDetail.data.title + " - PUBLIC SESSION") ? "Default"
                                                                : (userInfo && userInfo.data && session.createdBy.email === userInfo.data.email) ? "Me"
                                                                    : session.createdBy.email
                                                        }
                                                    </div>
                                                </div>



                                                <div>
                                                    <div className="action-group" style={{ marginTop: '12px', marginBottom: '12px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>

                                                        {
                                                            activeTabType === "Expired Sessions" ? (


                                                                <div className="animated-btn-tooltip">
                                                                    <button class="session-delete-button"
                                                                        onClick={(e) => { e.preventDefault(); setDeleteSession(session); setOpenDeleteDomainModal(true) }}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 69 14"
                                                                            class="svgIcon bin-top"
                                                                        >
                                                                            <g clip-path="url(#clip0_35_24)">
                                                                                <path
                                                                                    fill="black"
                                                                                    d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                                                                ></path>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_35_24">
                                                                                    <rect fill="white" height="14" width="69"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>

                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 69 57"
                                                                            class="svgIcon bin-bottom"
                                                                        >
                                                                            <g clip-path="url(#clip0_35_22)">
                                                                                <path
                                                                                    fill="black"
                                                                                    d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                                                                ></path>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_35_22">
                                                                                    <rect fill="white" height="57" width="69"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                    <div className="hover-tooltip" role="tooltip">
                                                                        <span>Delete</span>
                                                                    </div>

                                                                </div>
                                                            ) : ""
                                                        }

                                                        {(userInfo && userInfo.data && session.createdBy.email === userInfo.data.email) && new Date(session.scheduleTo) > new Date() &&
                                                            <>

                                                                <div className="animated-btn-tooltip">
                                                                    <button class="animated-editBtn" onClick={(e) => handleEdit(e, session)}>
                                                                        <svg height="1em" viewBox="0 0 512 512">
                                                                            <path
                                                                                d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                                                            ></path>
                                                                        </svg>
                                                                    </button>
                                                                    <div className="hover-tooltip" role="tooltip">
                                                                        <span>Edit</span>
                                                                    </div>
                                                                </div>

                                                                <div className="animated-btn-tooltip">
                                                                    <button class="session-delete-button"
                                                                        onClick={(e) => { e.preventDefault(); setDeleteSession(session); setOpenDeleteDomainModal(true) }}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 69 14"
                                                                            class="svgIcon bin-top"
                                                                        >
                                                                            <g clip-path="url(#clip0_35_24)">
                                                                                <path
                                                                                    fill="black"
                                                                                    d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                                                                ></path>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_35_24">
                                                                                    <rect fill="white" height="14" width="69"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>

                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 69 57"
                                                                            class="svgIcon bin-bottom"
                                                                        >
                                                                            <g clip-path="url(#clip0_35_22)">
                                                                                <path
                                                                                    fill="black"
                                                                                    d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                                                                ></path>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_35_22">
                                                                                    <rect fill="white" height="57" width="69"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                    <div className="hover-tooltip" role="tooltip">
                                                                        <span>Delete</span>
                                                                    </div>

                                                                </div>
                                                                <div className="animated-btn-tooltip">
                                                                    <div class="icon-conatiner" onMouseOut={() => setLinkCopied(false)} onClick={(e) => { e.preventDefault(); copyGameLink(session) }} >
                                                                        <svg
                                                                            width="19px"
                                                                            height="21px"
                                                                            viewBox="0 0 19 21"
                                                                            version="1.1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >


                                                                            <g
                                                                                id="Page-1"
                                                                                stroke="none"
                                                                                stroke-width="1"
                                                                                fill="none"
                                                                                fill-rule="evenodd"
                                                                            >
                                                                                <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                                                                    <g id="Group" transform="translate(142.000000, 122.000000)">
                                                                                        <path
                                                                                            d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                                                                            id="Rectangle-Copy"
                                                                                            fill="#a1a3b2"
                                                                                        ></path>
                                                                                        <path
                                                                                            d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                                                                            id="Rectangle"
                                                                                            fill="#5F638A"
                                                                                        ></path>
                                                                                        <path
                                                                                            d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                                                                            id="Path-2"
                                                                                            fill="#373a57"
                                                                                        ></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <svg
                                                                            width="19px"
                                                                            height="21px"
                                                                            viewBox="0 0 19 21"
                                                                            version="1.1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >

                                                                            <g
                                                                                id="Page-1"
                                                                                stroke="none"
                                                                                stroke-width="1"
                                                                                fill="none"
                                                                                fill-rule="evenodd"
                                                                            >
                                                                                <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                                                                    <g id="Group" transform="translate(142.000000, 122.000000)">
                                                                                        <path
                                                                                            d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                                                                            id="Rectangle-Copy"
                                                                                            fill="#a1a3b2"
                                                                                        ></path>
                                                                                        <path
                                                                                            d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                                                                            id="Rectangle"
                                                                                            fill="#5F638A"
                                                                                        ></path>
                                                                                        <path
                                                                                            d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                                                                            id="Path-2"
                                                                                            fill="#373a57"
                                                                                        ></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>

                                                                    </div>
                                                                    <div className="hover-tooltip" role="tooltip">
                                                                        <span>{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="animated-btn-tooltip">
                                                                    <div class="qr-loader" onClick={() => { setSelectedSession(session); setShowQrModal(true); }}>
                                                                        <div class="qr-box1"></div>
                                                                        <div class="qr-box2"></div>
                                                                        <div class="qr-box3"></div>
                                                                    </div>
                                                                    <div className="hover-qrCode-tooltip" role="tooltip">
                                                                        <span>Download QR</span>
                                                                    </div>
                                                                </div>

                                                                {/*new tooltip btn end*/}
                                                            </>
                                                        }
                                                        {/* {
                                                            userInfo && userInfo.data && (userInfo.data.role === ROLES.ORG_SUPER_ADMIN || session?.createdBy?.email === userInfo.data.email) && showConfigure &&


                                                            <div className="animated-btn-tooltip" onClick={(e) => handleConfigure(e, session.id)}>
                                                                <button class="setting-btn">
                                                                    <span class="bar bar1"></span>
                                                                    <span class="bar bar2"></span>
                                                                    <span class="bar bar1"></span>
                                                                </button>
                                                                <div className="hover-tooltip" role="tooltip">
                                                                    <span>setting</span>
                                                                </div>

                                                            </div>
                                                        } */}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    );
                                })
                            }
                            {
                                gameSessions.length == 0 && gameSessions.length == 0 && <div>
                                    <h3 className="no-data-mob">No Sessions Found !</h3>
                                </div>
                            }
                        </div>


                    </div>

                </div>

            </div>

        </div >


    </>

    );
};
export default ScheduleTeamsGames;
