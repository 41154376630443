import React, { useState } from 'react';
import './accordion.css'; // Import your CSS file

const Accordion = ({ items, isBottomBorder }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const toggleItem = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="accordion !ml-0 w-full" >
      {items.map((item, index) => (
        <div className='outer-accordion-container'>
          <div className="accordion-item mb-2 w-full" key={index}>
            <div
              className={`accordion-title text-[16px] font-semibold ${expandedIndex === index ? 'open' : ''}`}
              onClick={() => toggleItem(index)}
            >
              {item.title}
            </div>
            <div className={`accordion-content ${expandedIndex === index ? 'open' : ''}`}>
              {item.content}
            </div>
          </div>
          {
            isBottomBorder ? (
              <div style={{
                width: '90%',
                border: '0.5px solid #707070',
                display:  index >= 0 && index !== items.length -1 && index >= 0 ? 'block' : 'none'
              }}></div>
            )
            : null
          }
       
        </div>

      ))}
    </div>
  );
};

export default Accordion;
