import { useEffect, useState } from "react";
import ClaimTable from "./ClaimTable";
import CardComp from "./card";
import { useDispatch, useSelector } from "react-redux";
import { getDiwaliCliams } from "../../redux/actions/adminApiActions";
import useDebouncedSearch from "../../helpers/debounce";

const claimsArr = [
  { name: "topLine", label:"Top Line" },
  { name: "middleLine", label:"Middle Line" },
  { name: "bottomLine", label:"Bottom Line" },
  { name: "fourCorners", label:"Four Corners" },
  { name: "fullHouse", label:"Full House" },
  { name: "earlyFive", label:"Early Five" },
];
const Claims = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.diwaliClaims);

  const [claimType, setClaimType] = useState("topLine");
  const [diwaliClaims, setDewaliClaims] = useState();

  const useSearch = () => useDebouncedSearch((text) => searchGames(text));
  const searchGames = async (text) => {
    await dispatch(getDiwaliCliams({ claimType, text }));
  };
  const { searchText, setSearchText, results } = useSearch();

  useEffect(() => {
    dispatch(getDiwaliCliams({ claimType }));
  }, [claimType, searchText]);

  useEffect(() => {
    if (data?.diwaliClaims?.data) {
      setDewaliClaims(data.diwaliClaims.data);
    }
  }, [data]);
  return (
    <div className="w-100 px-2">
      <CardComp cardTitle={"View all claims"}>
        <div className="flex">
          {claimsArr.map((val) => {
            return (
              <button
                className={`py-[7px] px-[14px]  ml-[10px] rounded pointer ${claimType === val.name ? "bg-[#e9695e] text-white" : "bg-white border-[#e9695e] border text-[#e9695e]"}`}
                key={val.name}
                onClick={() => setClaimType(val.name)}
              >
                {val.label}
              </button>
            );
          })}
        </div>
        <div className=" text-red-700 font-bold mt-4">
          Toogle switch to Disable / Enable
        </div>
        <ClaimTable
          data={diwaliClaims}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </CardComp>
    </div>
  );
};

export default Claims;
