import React from 'react';

import './modal.css';
import Modal from './modal';

import paymentsucess from '../../assets/images/paymentsucess.gif';
import thankyou from '../../assets/images/thankyou.svg';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/actions/userAction';


const OfflineQuoteSuccess = ({ modalid, toggle, closeSuccessModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClick = () => {
    closeSuccessModal(false);
    history.push('/');
  }

  return (
    <Modal modalid={modalid} toggle={toggle}>
      <div className="modal-body">
        <div className="close-icon" data-dismiss="modal" aria-label="Close" onClick={handleClick}>
          <div className="close-btn-icon"></div>
        </div>
        <div className="thankyou-body success">
        <h4 className="thank-you-title">Thank you for your query! </h4>
        <h6 className="thank-you-heading">A member of our team will contact you shortly.  </h6>
        <span className="f-14 p-0">Alternatively if you prefer to schedule a call with one of our representatives, please refer to the email sent to your registered email address to book an appointment.</span>
        
        </div>
      </div>
    </Modal>
  );
};
export default OfflineQuoteSuccess;
