import { axiosApiInstance, BASE_URL } from "../../helpers/helper";
import * as ActionTypes from "../constants/bookAnEventConstants"

const addEventQuestion = (body) => async (dispatch) => {
    dispatch({ type: ActionTypes.ADD_QUESTION_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.post(
            BASE_URL + "/api/bookEvent/add-event/questions",
            body,
        );
        dispatch({ type: ActionTypes.ADD_QUESTION_SUCCESS, payload: data });
        return status;
    } catch (error) {
        dispatch({
            type: ActionTypes.ADD_QUESTION_FAIL,
            payload: error.message
        });
    }
};


const getAllEventQuestions = (searchText) => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_EVENT_QUESTIONS_REQUEST });
    try {
        const { data, status } = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/get-all/event-questions?search="+searchText);
        dispatch({ type: ActionTypes.GET_EVENT_QUESTIONS_SUCCESS, payload: data });
        return status;
    } catch (error) {
        dispatch({
            type: ActionTypes.GET_EVENT_QUESTIONS_FAIL,
            payload: error.message
        });
    }
};

const getAllEventBooking = (searchText) => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_EVENT_BOOKING_REQUEST });
    try {
        let url = BASE_URL + "/api/bookEvent/get-booking-req"
        if(searchText){
            url = BASE_URL + "/api/bookEvent/get-booking-req?search="+searchText
        }
        const { data, status } = await axiosApiInstance.get(url);
        dispatch({ type: ActionTypes.GET_EVENT_BOOKING_SUCCESS, payload: data });
        return status;
    } catch (error) {
        dispatch({
            type: ActionTypes.GET_EVENT_BOOKING_FAIL,
            payload: error.message
        });
    }
};



export {
    addEventQuestion,
    getAllEventQuestions,
    getAllEventBooking,
}