import  * as ActionTypes from "../constants/bookAnEventConstants"

function addEventQuestionReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.ADD_QUESTION_REQUEST:
      return { loading: true };
    case ActionTypes.ADD_QUESTION_SUCCESS:
      return { loading: false, addQuestion: action.payload };
    case ActionTypes.ADD_QUESTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function getEventQuestionReducer(state = {}, action) {
    switch (action.type) {
      case ActionTypes.GET_EVENT_QUESTIONS_REQUEST:
        return { loading: true };
      case ActionTypes.GET_EVENT_QUESTIONS_SUCCESS:
        return { loading: false, getAllQuestion: action.payload };
      case ActionTypes.GET_EVENT_QUESTIONS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  
function getAllEventBookingReducer(state = {}, action) {
    switch (action.type) {
      case ActionTypes.GET_EVENT_BOOKING_REQUEST:
        return { loading: true };
      case ActionTypes.GET_EVENT_BOOKING_SUCCESS:
        return { loading: false, getAllEventBookings: action.payload };
      case ActionTypes.GET_EVENT_BOOKING_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

export {
    addEventQuestionReducer,
    getEventQuestionReducer,
    getAllEventBookingReducer
}