import React, { useEffect, useState } from "react";

// import "./plandetail.css";
import { useDispatch, useSelector } from "react-redux";
import { downloadTransactionById } from "../../redux/actions/plansApiActions";
import { downloadFile } from "../../helpers/downloadFile";
import { SYMBOLS } from "../../constants";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { axiosApiInstance, BASE_URL, failureAlert } from "../../helpers/helper";
import DataTable from "react-data-table-component";
import LoadingComponent from "../../components/loader/LoadingComponent";


const EventBookingHistory = () => {

    const [bookingHistoryData, setBookingHistoryData] = useState([])
    const [loaded, setLoaded] = useState(false);

    const priceFormatIndianLocale = Intl.NumberFormat('en-IN');

    //   const handleDownloadClick = (e, transId) => {
    //     dispatch(downloadTransactionById(transId)).then((res) => {
    //       downloadFile(res.data.downloadUrl);
    //     });
    //   };

    //   useEffect(() => { }, [myPlanDetails]);

    useEffect(() => {
        const callBack = async () => {
            setLoaded(false);
            const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/event-booking/transaction-history");
            if (response && response.data.message === "successfuly fetch event booking history") {
                const transactions = response.data.data.map((record, index) => ({
                    id: record.id,
                    title: record?.title,
                    guestCount: record?.guestCount,
                    eventStartDate: new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                        // hour: "numeric",
                        // minute: "numeric",
                        // hour12: true
                    }).format(new Date(record.startDate)),
                    startTime: record?.startTime,
                    createdAtFormatted: new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric"
                    }).format(new Date(record.createdAt)),
                    currency: record?.currency,
                    price: record?.amount,
                    status: record?.status === "COMPLETED" ? 'PAID' : 'PENDING',
                }));

                setBookingHistoryData(transactions)
            }
            else {
                // failureAlert("Something went wrong")
                // setLoaded(true)

            }
            setLoaded(true)

        }

        callBack()

    }, [])

    function capitalizeFirstLetter(str) {
        if (!str) {
            return "";
        }

        return str
            .toLowerCase()
            .split(/[_\s]+/)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const customStyles = {
        headCells: {
            style: {
                fontFamily: "firaSans-semibold",
                fontSize: "15px",
                borderBottom: "1px solid Black",
                borderTop: "1px solid Black"
            },
        },
        cells: {
            style: {
                fontFamily: "firaSans-regular",
                fontSize: "14px",
                textAlign: "center"
            },
        },
        header: {
            style: {
                fontFamily: "firaSans-semibold",
                color: '#737373'
            },
        },
    };

    const columns = [

        {
            name: "Booking Date",
            selector: row => row.createdAtFormatted,
            minWidth: "150px"
        },
        {
            name: "Transaction ID",
            selector: row => row.id,
            minWidth: "350px"
        },
        {
            name: "Event Name",
            selector: row => row.title,
            minWidth: "300px"
        },
        {
            name: "Start Date",
            selector: row => row.eventStartDate,
            minWidth: "150px"
        },
        {
            name: "Start Time",
            selector: row => row.startTime,
            minWidth: "150px"
        },
        {
            name: "Members",
            selector: row => row.guestCount,
            // minWidth: "250px"
        },
        {
            name: "Price",
            selector: row => row.price,
            cell: row => (
                <span >
                    {`${SYMBOLS[row.currency]} ${row.price}`}
                </span>
            ),
        },
        {
            name: "Status",
            selector: row => row.status,
            cell: row => (
                <span style={{
                    color: row.status === 'PAID' ? '#44cc30' : '#ff5d00',
                    fontWeight: 'bold',
                    fontSize: '15px'
                }}>
                    {row.status}
                </span>
            ),
        },

    ];


    return (

        <div>

            <div className="hidden lg:block ">
                <LoadingComponent loaded={loaded} />
                <div className="data-table">
                    <DataTable
                        title="Transactions"
                        columns={columns}
                        data={bookingHistoryData}
                        className="rdt_TableHead"
                        responsive
                        striped
                        highlightOnHover
                        pagination
                        paginationPerPage={7}  // Set default rows per page to 6
                        paginationRowsPerPageOptions={[7]}
                        customStyles={customStyles}
                    />
                </div>

                {/* <table>
                    <thead className="h-[50px]">
                        <tr>
                            <th>Booking Date</th>
                            <th className="!text-left">Event Name</th>
                            <th>Members</th>
                            <th>Price</th>
                            <th>Transaction ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookingHistoryData &&
                            bookingHistoryData.length > 0 &&
                            bookingHistoryData.map((transaction, index) => {
                                return (
                                    <tr key={transaction.id} className="h-[50px]">
                                        <td>
                                            {new Intl.DateTimeFormat(["ban", "id"]).format(
                                                new Date(transaction.createdAt)
                                            )}
                                        </td>
                                        <td className="!text-left">{transaction?.title ? capitalizeFirstLetter(transaction?.title) : ''} </td>
                                        <td>
                                            {transaction.guestCount}
                                        </td>
                                        <td>
                                            <b>{`${SYMBOLS[transaction.currency]} ${transaction.amount}`}</b>
                                        </td>
                                        <td>
                                            <div className="trans-btn">
                                                {transaction.id}

                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table> */}
                {/* table for mobile */}
            </div>
            <div className="flex lg:hidden flex-col">
                {bookingHistoryData &&
                    bookingHistoryData.length > 0 &&
                    bookingHistoryData.map((transaction) => {
                        return (
                            <div className="trans-m-card c-white-card" key={transaction.id}>

                                <div className="trans-m-top">
                                    <div className="trans-detail">
                                        <h5>{transaction.title} </h5>
                                        {/* <span>
                                            {transaction.createdAtFormatted}
                                        </span> */}
                                    </div>
                                    <h4 className=" whitespace-nowrap">{`${SYMBOLS[transaction.currency]} ${transaction.price}`}</h4>
                                </div>

                                <div className="flex w-full mb-2 items-start flex-col justify-start">
                                    <span className=" text-[#23282e] text-[14px]">
                                        Booking Date: {transaction.createdAtFormatted}
                                    </span>

                                </div>

                                <div className="flex w-full mb-3 items-start flex-col justify-start">
                                    <span className=" mb-2 text-[#23282e] text-[14px]">
                                        Start Date: {transaction.eventStartDate}
                                    </span>

                                    <span className="text-[#23282e] text-[14px]">
                                        Start Time: {transaction.startTime}
                                    </span>
                                </div>

                                <div className="trans-m-bottom">
                                    <span>Transaction Id: {transaction.id}</span>
                                    <span
                                        style={{
                                            color: transaction.status === "PAID" ? '#44cc30' : '#ff5d00'
                                        }}
                                    >{transaction.status}</span>

                                </div>

                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default EventBookingHistory;
