import React, { useState, useEffect, useRef } from "react";
import OnBoard from "../../components/onboard/onboard";
import Header from "../../components/header/header";
import "./index.css";
// image
// import facebook from "../../assets/images/facebook.svg";
// import linkedin from "../../assets/images/linkedin.svg";
// import google from "../../assets/images/google.svg";

//Redux
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { sendOtpToUser, signin } from "../../redux/actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import { axiosApiInstance, AZURE_CLIENT_ID, decryptData, encryptData, EXTRAMILE_SUPERADMIN_EMAIL, failureAlert, SOCKET_URL, successAlert } from "../../helpers/helper";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { BASE_URL } from '../../helpers/helper';

// //Social media login
// import GoogleLogin from "react-google-login";
// //import FacebookLogin from "react-facebook-login";
// import { LinkedIn } from "react-linkedin-login-oauth2";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import Axios from "axios";
import azureAd from "../../assets/images/azure.svg";
import peopleStrong from "../../assets/images/peoplestrong.svg";
import MicrosoftLogin from "react-microsoft-login";
import { getGameServerOtp } from "../../redux/actions/gameDetailAction";
import OneSignal from 'react-onesignal'
import { log } from "synonyms/dictionary";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { io } from "socket.io-client";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import * as ActionTypes from "../../redux/constants/commonApiConstants";
import { CiCircleInfo } from "react-icons/ci";
import { TbLogin2 } from "react-icons/tb";
import { IoEnterSharp } from "react-icons/io5";
import { BiSolidExit } from "react-icons/bi";



const SignIn = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [inputValue, setInputValue] = useState({ email: "" });
  const [loginInputValue, setLoginInputeValue] = useState({ email: "", password: "" });
  const [touched, setTouched] = useState({ email: false });
  const [loginTouch, setLoginTouch] = useState({ email: false, password: false });
  //const userSignin = useSelector((state) => state.userSignin);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loginType, setLoginType] = useState("OTP");
  const [authMSData, setAuthMSData] = useState(null);
  const [activeLoginForm, setActiveLoginForm] = useState("login via otp");
  const [showPassword, setShowPassword] = useState(false);
  const [orgSignInBanner, setOrgSignInBanner] = useState('');
  const [invalidDataCount, setInvalidDataCount] = useState(0);
  const [otpTouched, setOtpTouched] = useState({ otp: false });
  const [OtpinputValue, setOtpInputValue] = useState({ otp: "" });
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [signInOtpBtn, setSignInOtpBtn] = useState('send-otp');
  const [isMasterOtp, setIsMasterOtp] = useState(false);
  const [resendCounter, setResendCounter] = useState(0);

  const [displayFieldError, setDisplayFieldError] = useState({
    email: false,
    password: false,
    otpEmail: false,
    otp: false
  })

  const dddtBloackDomain = [
    "spambog.com",
    "maildrop.cc",
    "throwawayemail.com",
    "trashmail.plus",
    "tempmailo.com",
    "anonbox.net",
    "mintemail.com",
    "bouncr.com",
    "harakirimail.com",
    "33mail.com",
    "sharklasers.com",
    "owlymail.com",
    "jetable.org",
    "meltmail.com",
    "tempinbox.com",
    "easytrashmail.com",
    "spamfree24.org",
    "spamhoot.com",
    "mailtoss.com",
    "gishpuppy.com",
    "shortmail.me",
    "instantemailaddress.com",
    "discreet.ly",
    "sizemail.com",
    "guerrillatemp.com",
    "tempmailgen.com",
    "spamdecoy.net",
    "moomail.com",
    "mail7.io",
    "emailtemporario.com.br",
    "nadaemail.com",
    "ptorx.com",
    "disposableinbox.com",
    "tempail.com",
    "relaytemp.com",
    "dudmail.com",
    "fakeinbox.com",
    "temporar.email",
    "yeggt.com",
    "emailondeck.com",
    "guerillamail.org",
    "filzmail.com",
    "e4ward.com",
    "bumpmail.me",
    "mydomainmail.com",
    "tmail.com",
    "tempb.com",
    "flowermail.com",
    "onetimeemail.com",
    "fakemailgenerator.com",
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "throwawaymail.com",
    "getnada.com",
    "trashmail.com",
    "fakemail.net",
    "yopmail.com",
    "dispostable.com",
    "mohmal.com",
    "burnermail.io",
    "emailondeck.com",
    "mytemp.email",
    "tempmailaddress.com",
    "mailcatch.com",
    "spamgourmet.com",
    "tempmail.tutanota.com",
    "minuteinbox.com",
    "mailnesia.com",
    "luxusmail.com",
    "spambox.me",
    "10minutemail.net",
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "protonmail.com",
    "zoho.com",
    "aol.com",
    "gmx.com",
    "yandex.com",
    "icloud.com",
    "tutanota.com"
  ];

  const sendOtpDetails = useSelector(state => state.sendOtpToUser);
  const userDetails = useSelector(state => state.getUser)
  let { loading, error } = sendOtpDetails;
  const dispatch = useDispatch();
  const UserSignin = useSelector((state) => state.userSignin);
  let { userInfo } = UserSignin;
  //const UserInfo = useSelector((state) => state.userSignin) || { userInfo: {} };
  // const { orgDetailsByEmail } = useSelector((state) => state.getOrganisation);
  const { orgDetailsById } = useSelector(state => state.getOrganisationById);
  // let { inviteId } = useParams();
  let inviteId = new URLSearchParams(props.location.search).get('inviteId');
  const pollId = new URLSearchParams(props.location.search).get('pollId');
  const inviteToken = new URLSearchParams(props.location.search).get('inviteToken');

  let signInPg = false;
  if (props && props.location && props.location.state && props.location.state.signIn) {
    signInPg = props.location.state.signIn;
  }

  // const userInfo = useRef(null);

  // if (UserInfo && UserInfo.userInfo && UserInfo.userInfo.email)
  //   var email = UserInfo.userInfo.email;
  // else var email = "";
  // useEffect(() => {
  //   setInputValue((prevState) => ({ ...prevState, email: email }));
  // }, [email]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const sessionid = new URLSearchParams(props.location.search).get('sessionId');
    const gameId = new URLSearchParams(props.location.search).get('gameId');
    if (sessionid && gameId) {
      sessionStorage.setItem("sessionId", encryptData(sessionid));
      sessionStorage.setItem("gameId", encryptData(gameId));
      fetchOrgSigInBanner();
    }
    if (gameId && sessionid && inviteId && !signInPg) {
      setActiveLoginForm('inviteId');

    }
    if (gameId && sessionid && inviteId && signInPg === true) {
      setActiveLoginForm('login via otp');

    }
    if (inviteId) {
      sessionStorage.setItem("inviteId", encryptData(inviteId));
    }
    else {
      sessionStorage.setItem("inviteId", encryptData(""));
    }
  }, [inviteId]);

  const fetchOrgSigInBanner = async () => {
    setOrgSignInBanner('');
    const sessionid = new URLSearchParams(props.location.search).get('sessionId');
    const getSigninBannerImg = await axiosApiInstance.get(BASE_URL + "/api/organization/signin-banner/" + sessionid);
    if (getSigninBannerImg && getSigninBannerImg.data && getSigninBannerImg.data.data !== null) {
      setOrgSignInBanner(getSigninBannerImg.data.data);
    }

  }

  const fromOtpPage = decryptData(localStorage.getItem("fromOtpPage") || encryptData(false));


  const handelOtpChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (otpTouched[name]) {
      setOtpTouched(prevState => ({ ...prevState, [name]: false }));
    }
    setOtpInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOtpLoginChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (touched[name]) {
      setTouched(prevState => ({ ...prevState, [name]: false }));
    }
    setInputValue((prevState) => ({
      ...prevState,
      [name]: name === 'email' ? value.toLowerCase() : value,
    }));
  };

  useEffect(() => {
    if (activeLoginForm === "login via otp") {
      setLoginInputeValue({ email: "", password: "" });
      setLoginTouch({ email: false, password: false });
      setShowPassword(false);
      setInvalidDataCount(0);
      setDisplayFieldError({
        email: false,
        password: false,
        otpEmail: false,
        otp: false
      })
    }
    if (activeLoginForm === "pass-login") {
      setTouched({ email: false });
      setInputValue({ email: "" });
      setOtpTouched({ otp: false });
      setOtpInputValue({ otp: "" });
      setSignInOtpBtn('send-otp');
      setResendCounter(0);
      setDisplayFieldError({
        email: false,
        password: false,
        otpEmail: false,
        otp: false
      })
    }
    if (signInOtpBtn === 'send-otp') {
      setOtpTouched({ otp: false });
      setOtpInputValue({ otp: "" });
      setResendCounter(0);
    }

  }, [activeLoginForm, signInOtpBtn])

  const handlePassLoginChange = (e, fieldName) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (loginTouch[name]) {
      setLoginTouch(prevState => ({ ...prevState, [name]: false }));
    }
    setLoginInputeValue((prevState) => ({
      ...prevState,
      [name]: name === 'email' ? value.toLowerCase() : value,
    }))
  }

  const handleSubmit = async (e, msFlag) => {
    e.preventDefault();
    setLoginType(msFlag);
    setSubmitClicked(true);
    setTouched({ email: true });
    await login(msFlag);
  };

  const searchParams = new URLSearchParams(window.location.search);
  const xoxoValue = searchParams.get('xoxo');
  const isPollLink = pollId ? ("pollId=" + pollId + "&inviteToken=" + inviteToken) : "";

  const redirectToOtpVerification = xoxoValue === "true" ? "/otp-verification?xoxo=true" : pollId ? `/otp-verification?${isPollLink}` : "/otp-verification";
  // const redirectToOtpVerification = "/otp-verification";

  const counterinterval = useRef();

  useEffect(() => {
    if (resendCounter === 30) {
      let count = 30;
      counterinterval.current = setInterval(() => {
        if (count === 0)
          clearInterval(counterinterval.current);
        else {
          count -= 1;
          setResendCounter(count);
        }
      }, 1000);
    }
  }, [resendCounter]);

  useEffect(() => {
    return () => {
      if (counterinterval.current)
        clearInterval(counterinterval.current);
    }
  }, [])

  const login = async (currentLoginType) => {
    const errors = validateInput(inputValue);
    if (inputValue.email.length > 0 && errors.email === "") {
      if (currentLoginType === "AZUREAD") {
        if (inputValue.email.toLowerCase() === EXTRAMILE_SUPERADMIN_EMAIL) {
          failureAlert("Email not allowed");
        }
        else {
          let url = `${BASE_URL}/api/user/login/sso?email=${inputValue.email}`;
          if (pollId) {
            url = url + isPollLink
          }
          if (inviteId)
            url = url + "&inviteId=" + inviteId + (isPollLink ? isPollLink : "");
          window.location.replace(url);
        }
      }
      else if (currentLoginType === "PEOPLESTRONG") {
        if (inputValue.email.toLowerCase() === EXTRAMILE_SUPERADMIN_EMAIL) {
          failureAlert("Email not allowed");
        }
        else {
          let url = `api/user/login/peoplestrong-sso?email=${inputValue.email}`;
          if (pollId) {
            url = url + isPollLink
          }
          if (inviteId)
            url = url + "&inviteId=" + inviteId;
          window.location.replace(url);
        }
      }
      else {
        let getSubdomain = await Axios.get(BASE_URL + `/api/user/organization/subdomain/${inputValue.email}`);
        let currentUserSubDomain = getSubdomain?.data?.subDomain;
        if (currentUserSubDomain) {
          let currentUrl = window.location.href;
          currentUrl = currentUrl.split('//')[1];
          let subDomain = currentUrl.split('.')[0];
          let remainingUrl = currentUrl.split(subDomain)[1];
          if (currentUserSubDomain && currentUserSubDomain !== subDomain) {
            setInterval(() => {
              window.location.assign(`https://${currentUserSubDomain}.extramileplay${remainingUrl}`);
            }, 1000);
            toast.success('Redirect to your comapany page', {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              pauseOnFocusLoss: false
            });
            return;
          };
        };

        const resStatus = await dispatch(sendOtpToUser(inputValue.email));
        // const resStatus = { status: 200 };
        if (resStatus?.data?.status === false) {
          failureAlert(resStatus?.data?.message);

        }
        else if (resStatus?.status === 200) {
          if (resStatus?.data?.message === "user is not register") {
            return failureAlert(<div>This user is not registered. <br></br>   Please sign up to create an account.</div>);
          }
          else if (resStatus?.data?.message === "User limit exceeded. Please contact the admin.") {
            return failureAlert("User limit exceeded. Please contact the admin.")
          }
          else if (resStatus?.data?.message === "organization does not have an active plan please contact your HR") {
            failureAlert("organization does not have an active plan please contact your HR")
          }
          setSignInOtpBtn('verify-otp');
          const statevalue = { email: inputValue.email };
          setResendCounter(30);
          if (inviteId) {
            statevalue.inviteId = inviteId;
            setSignInOtpBtn('verify-otp');
            // props.history.push(redirectToOtpVerification, statevalue);
          }
          if (resStatus?.data?.masterOtp) {
            setIsMasterOtp(true);
            statevalue.masterOtp = true;
            setResendCounter(0);
            setSignInOtpBtn('verify-otp');
            // props.history.push(redirectToOtpVerification, statevalue);

          }
          // console.log("Organisation ID : ", userDetails);
        }
      }
      try {
        OneSignal.setExternalUserId(inputValue.email)
      } catch (err) {
        console.log(err)
      }
    }
    setSubmitClicked(false);
  };

  // useEffect(async () => {
  //   if (touched.email && submitClicked) {
  //     const errors = validateInput(inputValue);
  //     if (inputValue.email.length > 0 && errors.email === "") {
  //       if (loginType === "AZUREAD") {
  //         if (inputValue.email.toLowerCase() === EXTRAMILE_SUPERADMIN_EMAIL) {
  //           failureAlert("Email not allowed");
  //         }
  //         else {
  //           let url = `${BASE_URL}/api/user/login/sso?email=${inputValue.email}`;
  //           if (inviteId)
  //             url = url + "&inviteId=" + inviteId;
  //           window.location.replace(url);
  //         }
  //       }
  //       else if (loginType === "PEOPLESTRONG") {
  //         if (inputValue.email.toLowerCase() === EXTRAMILE_SUPERADMIN_EMAIL) {
  //           failureAlert("Email not allowed");
  //         }
  //         else {
  //           let url = `api/user/login/peoplestrong-sso?email=${inputValue.email}`;
  //           if (inviteId)
  //             url = url + "&inviteId=" + inviteId;
  //           window.location.replace(url);
  //         }
  //       }
  //       else {
  //         const resStatus = await dispatch(sendOtpToUser(inputValue.email));
  //         // const resStatus = { status: 200 };
  //         if (resStatus?.data?.status === false)
  //           failureAlert(resStatus?.data?.message);
  //         else if (resStatus?.status === 200) {
  //           const statevalue = { email: inputValue.email };
  //           if (inviteId)
  //             statevalue.inviteId = inviteId;
  //           if (resStatus?.data?.masterOtp)
  //             statevalue.masterOtp = true;
  //           console.log("Organisation ID : ", userDetails)
  //           props.history.push(redirectToOtpVerification, statevalue);
  //         }
  //       }
  //       // Setting the externalUserId for One signal
  //       try {
  //         OneSignal.setExternalUserId(inputValue.email)
  //       } catch (err) {
  //         console.log(err)
  //       }
  //     }
  //     setSubmitClicked(false);
  //   }
  // }, [touched])

  useEffect(() => {
    if (error)
      failureAlert('OTP Not sent.Try Again');
  }, [error]);

  function validateEmail(email) {
    const regex = /[A-Z]/;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && !regex.test(email);
  }

  function validatePassword(password) {
    let validLength = false;
    if (password.length > 4 && password.length <= 12) {
      validLength = true;
    }
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);

    return hasUpperCase && hasLowerCase && hasNumber && hasSymbol && validLength;
  }

  const validateInput = (input) => {
    const errors = {
      email: ""
    };
    if (
      touched.email &&
      (!validateEmail(input.email) || input.email === "")
    ) {
      errors.email = "Please provide a valid email";
    }
    return errors;
  };

  const LoginValidateInput = (input) => {

    const errors = {
      logimEmail: "",
      loginPass: ""
    };
    if (loginTouch.email && (!validateEmail(input.email) || input.email === " ")) {

      errors.logimEmail = "Please provide a valid email";
    }
    if (loginTouch.password && input.password === "") {
      errors.loginPass = "Please enter your password"
    }
    return errors;
  };

  let errorMessage = validateInput(inputValue);

  let LoginFormErrorMessg = LoginValidateInput(loginInputValue);

  // //Google Authentication
  // const responseGoogle = (response) => {
  //   // To add the user to the DB
  //   if (!response.error) {
  //     var email = response.profileObj.email;
  //     Axios.post(BASE_URL + "/api/user/third-party-login", {
  //       email: response.profileObj.email,
  //       name: response.profileObj.name,
  //     }).then((response) => {
  //       let userData = { ...response.data, email };
  //       localStorage.setItem("userSignInInfo", encryptData(userData));
  //       if (!response.data.isNewUser) {
  //         props.history.push("/");
  //       } else {
  //         props.history.push("/user-detail");
  //       }
  //     });
  //   }
  // };

  // //Facebook Authentication
  // const responseFacebook = (response) => {
  //   // To add the user to the DB
  //   if (response) {
  //     var email = response.email;
  //     Axios.post(BASE_URL + "/api/user/third-party-login", {
  //       email: response.email,
  //       name: response.name,
  //     })
  //       .then((response) => {
  //         let userData = { ...response.data, email };
  //         localStorage.setItem("userSignInInfo", encryptData(userData));
  //         if (!response.data.isNewUser) {
  //           props.history.push("/");
  //         } else {
  //           props.history.push("/user-detail");
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };
  // //Linkedin Authentication
  // const responseLinkedin = (response) => {
  //   const clientID = "781hl6t14llqdk";
  //   const clientSecret = "ungau7BPxHyhpDa5";
  //   const redirectURI = BASE_URL"/linkedin";
  //   const token = response.code;

  //   var name = "";
  //   var email = "";
  //   Axios.get(
  //     `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&client_id=${clientID}&client_secret=${clientSecret}&code=${response.code}&redirect_uri=${redirectURI}`
  //   )
  //     .then((response) => {
  //       const access_token = response.data.access_token;
  //       //To get the Name of the user
  //       Axios.get("https://api.linkedin.com/v2/me", {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`,
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //       }).then((response) => {
  //         name =
  //           response.data.firstName.localized.en_US +
  //           " " +
  //           response.data.lastName.localized.en_US;

  //         // To get the Email address of the user
  //         Axios.get(
  //           "https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))",
  //           {
  //             headers: {
  //               Authorization: `Bearer ${access_token}`,
  //               "Access-Control-Allow-Origin": "*",
  //             },
  //           }
  //         ).then((response) => {
  //           email = response.data.elements[0]["handle~"].emailAddress;

  //           // To add the user to the DB
  //           Axios.post(BASE_URL + "/api/user/third-party-login", {
  //             email: email,
  //             name: name,
  //           }).then((response) => {
  //             let email = email;
  //             let userData = { ...response.data, email };
  //             localStorage.setItem("userSignInInfo", encryptData(userData));
  //             if (!response.data.isNewUser) {
  //               props.history.push("/");
  //             } else {
  //               props.history.push("/user-detail");
  //             }
  //           });
  //         });
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  var isPopupBlockerActivated = function (popupWindow) {
    if (popupWindow) {
      if (/chrome/.test(navigator.userAgent.toLowerCase())) {
        try {
          popupWindow.focus();
        } catch (e) {
          return true;
        }
      } else {
        popupWindow.onload = function () {
          return (popupWindow.innerHeight > 0) === false;
        };
      }
    } else {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setLoaded(!loading);
  }, [loading])

  const getOrgIdFromInviteId = () => {
    if (inviteId) {
      try {
        const { organizationId } = decryptData(inviteId);
        return organizationId;
      } catch (err) {
        console.log(err)
      }
    }
    return null;
  }
  const invitedOrgId = getOrgIdFromInviteId();

  const authHandler = async (err, data, msalInstance) => {
    if (err && err.errorCode === "popup_window_error")
      failureAlert("Please allow popups in settings and try again!");
    if (data?.accessToken) {
      if (authMSData && authMSData.accessToken !== data.accessToken)
        setAuthMSData(data);
      else if (!authMSData)
        setAuthMSData(data);
    }
  };

  // useEffect(() => {
  //   const callBack = async () => {
  //     if (authMSData?.accessToken && authMSData?.mail) {
  //       setLoaded(false);
  //       // const response = {}
  //       const response = await dispatch(signin({ accessToken: authMSData.accessToken, inviteId, email: authMSData.mail }));
  //       if (response && response.status === 200) {
  //         localStorage.setItem("otpVerified", encryptData(true));
  //         userInfo.current = response.data
  //         const redirectToHome = "/";
  //         const redirectToUserDetail = "/user-detail";
  //         const redirectToPlans = "/plans";
  //         const gameId = decryptData(sessionStorage.getItem("gameId") || encryptData(""));
  //         const sessionId = decryptData(sessionStorage.getItem("sessionId") || encryptData(""));
  //         if (userInfo.current && userInfo.current.isNewUser) {
  //           window.gtag("event", "Users Registered", {
  //             event_category: "USER",
  //             event_label: "Users registered to the ExtramilePlay platform"
  //           });
  //           // props.history.replace(redirectToUserDetail);
  //           window.location.href = redirectToUserDetail;
  //         }
  //         else {
  //           localStorage.setItem("isAuthenticated", encryptData(true));
  //           sessionStorage.clear();
  //           var obj = null
  //           if (inviteId)
  //             obj = decryptData(inviteId);
  //           if (obj && obj.redirectURL) {
  //             // props.history.replace(obj.redirectURL);
  //             window.location.href = obj.redirectURL;
  //           }
  //           else if (userInfo.current && gameId && sessionId) {
  //             sessionStorage.setItem("gameId", encryptData(""));
  //             sessionStorage.setItem("sessionId", encryptData(""));
  //             redirectToGame(gameId, sessionId);
  //           }
  //           else if (userInfo.current && userInfo.current.role === 'USER' && !inviteId) {
  //             // props.history.replace(redirectToPlans);
  //             window.location.href = redirectToPlans;
  //           } else if (sessionStorage.getItem('sharedSessionGameId') != "") {
  //             window.location.href = "/active-games/" + sessionStorage.getItem('sharedSessionGameId')
  //           } else {
  //             // props.history.replace(redirectToHome);
  //             // window.location.href = redirectToHome;
  //           }
  //           sessionStorage.setItem("inviteId", encryptData(null));
  //         }
  //         localStorage.setItem("fromOtpPage", encryptData(false));
  //       }
  //       else {
  //         failureAlert("Something went wrong!");
  //       }
  //       setLoaded(true);
  //       setLoginType("OTP");
  //     }
  //   }
  //   callBack();
  // }, [loginType, authMSData])

  const redirectToGame = async (gameId, sessionId) => {
    const response = await dispatch(getGameServerOtp(gameId, sessionId));
    console.log("GAME LINK server otp");
    if (response && response.status === 200) {
      if (response.data && response.data.message === "Your plan is not activated") {
        failureAlert(response.data.message);
        setTimeout(() => {
          props.history.push("/");
        }, 1000);
      }
      if (response.data && response.data.data && response.data.data.redirectUrl) {
        window.location.replace(response.data.data.redirectUrl);
      }
      else {
        failureAlert("Something went wrong");
        setTimeout(() => {
          props.history.push("/");
        }, 1000);
      }
    }
    else {
      failureAlert("You are not allowed to access this game");
      setTimeout(() => {
        const redirectToHome = "/";
        const redirectToPlans = "/plans";
        if (userInfo.current && !userInfo.current.isNewUser && userInfo.current.role === 'USER') {
          props.history.replace(redirectToPlans);
          window.location.href = redirectToPlans;
        } else if (userInfo.current && !userInfo.current.isNewUser) {
          props.history.replace(redirectToHome);
          window.location.href = redirectToHome;
        }
      }, 2000);
    }
  }
  const handleSingUpPage = async (e) => {
    e.preventDefault();
    if (inviteId) {
      const sessionid = new URLSearchParams(props.location.search).get('sessionId');
      const gameId = new URLSearchParams(props.location.search).get('gameId');

      let userData = await decryptData(inviteId);
      let organizationId = userData.organizationId
      const statevalue = {
        organizationId: organizationId
      }
      props.history.push(`signup?gameId=${gameId}&sessionId=${sessionid}&inviteId=${inviteId}`, statevalue);
      // props.history.push(`signup?gameId=${gameId}&sessionId=${sessionid}&inviteId=${inviteId}`)

    }
    else {
      props.history.push("signup");

    }
  }

  const handleForgotPasswordClick = () => {
    props.history.push(`forgot-password?email=${inputValue.email ? inputValue.email : loginInputValue.email ? loginInputValue.email : 'dummy@gmail.com'}`)
  }

  function containsDisposableEmailDomain(email) {
    if (!email) return false; // Handle null or undefined emails

    return dddtBloackDomain.some(domain => email.endsWith(`@${domain}`));
  }

  useEffect(() => {
    if (userInfo) {
      const searchParams = new URLSearchParams(window.location.search);
      const xoxoValue = searchParams.get('xoxo');
      const pollId = searchParams.get('pollId');
      const inviteToken = searchParams.get('inviteToken');
      const isPollLink = pollId ? ("poll/" + pollId + "?inviteToken=" + inviteToken) : "";

      const redirectToHome = xoxoValue === "true" ? "/?xoxo=true" : pollId ? `/${isPollLink}` : "/";
      const redirectToUserDetail = "/user-detail";
      const redirectToPlans = "/plans";
      const gameId = decryptData(sessionStorage.getItem("gameId") || encryptData(""));
      const sessionId = decryptData(sessionStorage.getItem("sessionId") || encryptData(""));
      if (userInfo && sessionStorage.getItem('sharedSessionGameId') != "") {
        setTimeout(() => {
          props.history.replace("/active-games/" + sessionStorage.getItem('sharedSessionGameId'));
          sessionStorage.setItem('sharedSessionGameId', "")
        }, 1000)
      }
      localStorage.setItem("isAuthenticated", encryptData(true));
      var obj = null
      if (inviteId)
        obj = decryptData(inviteId);
      if (obj && obj.redirectURL) {
        props.history.replace(obj.redirectURL);
      }
      else if (userInfo && gameId && sessionId) {
        sessionStorage.setItem("gameId", encryptData(""));
        sessionStorage.setItem("sessionId", encryptData(""));
        let blockUser = false
        if (gameId === "7eb78ccc-23f6-4e54-b07e-cf4ff304bb05" && userInfo?.email) {
          console.log("userInfo: " + JSON.stringify(userInfo));
          blockUser = containsDisposableEmailDomain(userInfo?.email)
        }
        if (!blockUser) {
          redirectToGame(gameId, sessionId);

        }
        else {
          failureAlert("You are not permitted to participate in this game")
          setTimeout(()=>{
            props.history.replace("/");
          }, 1000)
        }
      }
      else if ((userInfo && userInfo.role === 'USER' && !inviteId) || (userInfo && userInfo.isNewUser && !inviteId)) {
        setLoaded(false)
        setTimeout(() => {
          props.history.replace(redirectToPlans);
          setLoginInputeValue({ email: "", password: "" });
          setLoginTouch({ email: false, password: false });
          setLoaded(true)
        }, 1300)
      } else {
        setLoaded(false)
        setTimeout(() => {
          props.history.replace(redirectToHome);
          setLoginInputeValue({ email: "", password: "" });
          setLoginTouch({ email: false, password: false });
          setLoaded(true)
        }, 1300)
      }
      sessionStorage.setItem("inviteId", encryptData(null));
    }
  }, [userInfo]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (loginInputValue.email !== "" && loginInputValue.password !== "") {
      setLoaded(false);
      const resStatus = await dispatch(signin({ email: loginInputValue.email, password: loginInputValue.password, inviteId: inviteId }));
      setLoaded(true);
      if (resStatus.payload) {
        setInvalidDataCount(invalidDataCount + 1);
        return failureAlert(resStatus.payload);
      }
      if (resStatus && resStatus.status === 200) {
        localStorage.setItem("otpVerified", encryptData(true));
      }
    }

  }
  const reSendOtp = async (e) => {
    e.preventDefault();
    if (resendCounter > 0) {
      return;
    }
    if (inputValue && inputValue.email !== 'resendOtp' && errorMessage.email === '') {
      const resStatus = await dispatch(sendOtpToUser(inputValue.email));
      if (resStatus?.status === 200) {
        setResendCounter(30);
        successAlert('OTP sent successfully!');
        setOtpErrorMessage("");
      } else {
        failureAlert('Resend OTP failed. Try Again!');
      }
    }
    else {
      failureAlert('Please enter your email');

    }

  }

  const verifyOTP = async (e) => {
    e.preventDefault();
    if (OtpinputValue.otp && OtpinputValue.otp.length === 6) {
      const body = {
        email: inputValue.email,
        otp: OtpinputValue.otp,
        inviteId: inviteId
      }
      const resStatus = await dispatch(signin(body));
      if (resStatus && resStatus.status === 200) {
        localStorage.setItem("otpVerified", encryptData(true));

        if (!window.socket && resStatus.data?.token) {
          window.socket = io.connect(SOCKET_URL, {
            transports: ["websocket", "polling", "flashsocket"],
            reconnect: true,
            auth: {
              token: resStatus.data.token
            }
          });
          window.socket?.on("notification", (notifications) => {
            dispatch({ type: ActionTypes.GET_NOTIFICATION_SUCCESS, payload: notifications, });
          })
          window.socket?.on("connect_error", (err) => {
            console.log(err instanceof Error); // true
            console.log(err.message); // not authorized
            console.log(err.data); // { content: "Please retry later" }
          });
          window.socket?.on("disconnect", (reason) => {
            console.log({ reason });
          })
        }
      }
      else if (resStatus && resStatus.data) {
        if (resStatus.data.message === 'USER_IS_DELETED')
          setOtpErrorMessage("User is deleted.")
        else if (resStatus.data.message === "USER EXISTS IN OTHER ORGANIZATION")
          setOtpErrorMessage("You are already linked with some other organisation.")
        else if (resStatus.data.message === "INVALID OTP")
          setOtpErrorMessage("Invaild OTP")
        else if (resStatus.data.message === "ACCESS DENIED")
          setOtpErrorMessage("You are not allowed to join");
        else if (resStatus.data.message === "Rate limit exceeded, retry in 15 minutes") {
          // setErrorMessage("You have made maximum unsuccessful attempts, please try again after some time!")
        }
      }
      else {
        setOtpErrorMessage("Please enter a valid OTP")
      }
    }
    else
      setOtpErrorMessage("Please enter a valid OTP")
  }


  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleIiconClick = (fieldName) => {
    setDisplayFieldError((prev) => ({ ...prev, [fieldName]: !displayFieldError[fieldName] }));
  }


  return (
    <div>
      <Header {...props} disableHomepageButton={fromOtpPage} onboard inviteId={invitedOrgId} />
      <ToastContainer style={{ marginTop: '4rem' }} position="bottom-center" />
      <LoadingComponent loaded={loaded} />
      <OnBoard sideBannerImage={orgSignInBanner}>
        <div className="form-container">
          {activeLoginForm === "inviteId" ? (
            <div className="descr-text-cont">
              <p>Spread positivity and have fun with your teams using ExtraMile Play</p>
            </div>
          ) : null
          }

          <div className={loaded ? "auth-flow login" : "auth-flow login loading"}>
            {
              activeLoginForm !== "inviteId" ? (<>
                <div className="login-via-tabs">
                  <div className="login-via-tab"
                    onClick={() => setActiveLoginForm("pass-login")}
                    style={{
                      borderBottom: activeLoginForm === "pass-login" ? ' 3px solid #EA6C00' : 'none',
                    }}
                  >
                    <span
                      style={{
                        color: activeLoginForm === "pass-login" ? '#EA6C00' : '#BFBFBF',
                        fontWeight: activeLoginForm === "pass-login" ? 'bold' : 'normal',
                        fontSize: activeLoginForm === "pass-login" ? '18px' : '13.34px',
                      }}
                    >Login With password</span>
                  </div>

                  <div className="login-via-tab"
                    onClick={() => setActiveLoginForm("login via otp")}
                    style={{
                      borderBottom: activeLoginForm === "login via otp" ? ' 3px solid #EA6C00' : 'none',
                    }}
                  >
                    <span
                      style={{
                        color: activeLoginForm === "login via otp" ? '#EA6C00' : '#BFBFBF',
                        fontWeight: activeLoginForm === "login via otp" ? 'bold' : 'normal',
                        fontSize: activeLoginForm === "login via otp" ? '18px' : '13.34px',
                      }}
                    >Login With OTP</span>
                  </div>
                </div>
              </>) : null
            }

            {/* <h5 className="login-heading">Login/Sign Up On ExtraMile {inviteId && orgDetailsById?.organization ? `Play to join ${orgDetailsById.organization.name} team` : ""}</h5> */}
            {
              activeLoginForm === "login via otp" ? (
                <form onSubmit={async (e) => await handleSubmit(e, "OTP")} className="user-login-form" >
                  <div className="auth-form">
                    {/* style={{marginBottom:errorMessage.email ? '23px' :'0' }} */}
                    <div className="form-group" style={{ marginBottom: '30px' }}>
                      <label htmlFor="email">Email Address * {signInOtpBtn === "verify-otp" ? (
                        <span onClick={(e) => setSignInOtpBtn('send-otp')} style={{ fontSize: '13.34px', fontWeight: 'bold', color: '#e75000', cursor: 'pointer' }}>  Edit Email</span>
                      ) : null}
                      </label>
                      <div className="tooltip-input-container">

                        {errorMessage.email !== "required*" && displayFieldError.otpEmail && <div className="tolltip-mid-error-text" style={{ display: errorMessage.email === "" ? 'none' : 'flex' }}>{errorMessage.email}</div>}
                        {errorMessage.email !== "" && <CiCircleInfo style={{ position: 'absolute', left: '95%', transform: 'translateY(15px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('otpEmail')}></CiCircleInfo>}

                        <input
                          className={errorMessage.email !== "" ? "error-input" : "form-control login-input"}
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={handleOtpLoginChange}
                          onBlur={() => setTouched({ email: true })}
                          value={inputValue.email}
                          required
                          disabled={signInOtpBtn === "send-otp" ? false : true}
                        />
                        {errorMessage.email && <div className="error-message">{errorMessage.email}</div>}
                      </div>
                    </div>
                    {
                      signInOtpBtn === "verify-otp" ? (
                        <div className="form-group" style={{ marginBottom: '30px' }}>
                          <label htmlFor="email">{isMasterOtp ? 'Enter Master OTP*' : 'Enter OTP *'}</label>
                          <div className="tooltip-input-container">
                            {otpErrorMessage !== "required*" && displayFieldError.otp && otpErrorMessage === "User is deleted" &&
                              <div className="tolltip-error-text-required" style={{ display: otpErrorMessage === "" ? 'none' : 'flex', left: '-42%' }}>{"user is deletes"}</div>
                            }
                            {otpErrorMessage !== "required*" && displayFieldError.otp && otpErrorMessage !== "User is deleted" && <div className="tolltip-error-text-required" style={{ display: otpErrorMessage === "" ? 'none' : 'flex', left: '-35%' }}>{otpErrorMessage}</div>
                            }
                            {otpErrorMessage !== "" && <CiCircleInfo style={{ position: 'absolute', left: '95%', transform: 'translateY(15px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('otp')}></CiCircleInfo>}

                            <input
                              className={otpErrorMessage !== "" ? "error-input" : "form-control"}
                              id="otp"
                              name="otp"
                              placeholder={isMasterOtp ? 'Enter Master OTP' : 'Enter OTP'}
                              onChange={handelOtpChange}
                              onBlur={() => setOtpTouched({ otp: true })}
                              value={OtpinputValue.otp}
                              required={signInOtpBtn === "verify-otp" ? true : false}
                              disabled={signInOtpBtn === "verify-otp" ? false : true}
                            />
                            {otpErrorMessage && <div className="error-message" style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>{otpErrorMessage}</div>}
                          </div>

                          {!isMasterOtp && signInOtpBtn === "verify-otp" && resendCounter > 0 && <span className={`error-message ${resendCounter > 0 ? "" : "invisible"}`}>{`Resend in 00:${resendCounter > 9 ? resendCounter : "0" + resendCounter}`}</span>}
                          {!isMasterOtp && signInOtpBtn === "verify-otp" && resendCounter === 0 ? (
                            <div className="error-message" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => reSendOtp(e)}>Resend OTP</div>
                          ) : null
                          }

                        </div>
                      ) : null
                    }

                    {
                      signInOtpBtn === "send-otp" ? (
                        <button type="submit" className="btn btn-primary" style={{ marginTop: '30px' }}>
                          Request OTP
                        </button>
                      ) : signInOtpBtn === "verify-otp" ? (
                        <button type="button" className="btn btn-primary" onClick={(e) => verifyOTP(e)} style={{ marginTop: '30px' }}>
                          verify OTP
                        </button>
                      ) : null
                    }

                  </div>
                </form>
              ) : activeLoginForm === "pass-login" ? (
                <form onSubmit={async (e) => await handleLoginSubmit(e)} className="user-login-form" >
                  <div className="auth-form">

                    <div className="form-group" style={{ marginBottom: '0px' }}>
                      <label htmlFor="email">Email Address *</label>
                      <div className="tooltip-input-container">

                        {LoginFormErrorMessg.logimEmail !== "required*" && displayFieldError.email && <div className="tolltip-mid-error-text" style={{ display: LoginFormErrorMessg.logimEmail === "" ? 'none' : 'flex' }}>{LoginFormErrorMessg.logimEmail}</div>}
                        {LoginFormErrorMessg.logimEmail !== "" && <CiCircleInfo style={{ position: 'absolute', left: '95%', transform: 'translateY(15px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('email')}></CiCircleInfo>}

                        <input
                          className={LoginFormErrorMessg.logimEmail ? "error-input" : "form-control login-input"}
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={(e) => handlePassLoginChange(e, "email")}
                          onBlur={() => setLoginTouch(prevState => ({ ...prevState, email: true }))}
                          value={loginInputValue.email}
                          required
                        />
                        {LoginFormErrorMessg.logimEmail && <div className="error-message" style={{ position: 'static' }}>{LoginFormErrorMessg.logimEmail}</div>}

                      </div>

                      <label htmlFor="email" style={{ marginTop: '30px' }}>Password *</label>
                      <div className="tooltip-input-container">

                        {LoginFormErrorMessg.loginPass !== "required*" && displayFieldError.password && <div className="tolltip-mid-error-text" style={{ display: LoginFormErrorMessg.loginPass === "" ? 'none' : 'flex' }}>{LoginFormErrorMessg.loginPass}</div>}
                        {LoginFormErrorMessg.loginPass !== "" && <CiCircleInfo style={{ position: 'absolute', left: '87%', transform: 'translateY(15px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('password')}></CiCircleInfo>}

                        <div className="password-container">
                          <input
                            className={LoginFormErrorMessg.loginPass ? "error-input" : "form-control"}
                            id="password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Enter your password"
                            onChange={(e) => handlePassLoginChange(e, "password")}
                            onBlur={() => setLoginTouch(prevState => ({ ...prevState, password: true }))}
                            value={loginInputValue.password}
                            required
                          // style={{ fontSize: '100%' }}
                          />
                          <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            style={{
                              position: 'relative',
                              border: 'none',
                              background: 'transparent',
                              cursor: 'pointer',
                              marginBottom: '12px',
                              height: '0px'
                            }}
                          >
                            {showPassword ? <FaEye style={{ position: 'absolute', right: '10px' }} /> : <FaEyeSlash style={{ position: 'absolute', right: '10px' }} />}
                          </button>
                        </div>
                        {LoginFormErrorMessg.loginPass && <div className="error-message" style={{ position: 'static' }}>{LoginFormErrorMessg.loginPass}</div>}
                      </div>

                    </div>
                    {/* {invalidDataCount >= 1 && loginInputValue.email !== '' && <div className="singUp-link" onClick={handleForgotPasswordClick} style={{ cursor: "pointer", marginBottom:'-20px' }}>Forgot Password ?</div>} */}
                    <div className="singUp-link" onClick={handleForgotPasswordClick} style={{ cursor: "pointer", marginBottom: '-24px', marginTop: '5px', color: 'var(--background-theme)' }}>Forgot Password ?</div>

                    <button type="submit" className="btn btn-primary" style={{ marginTop: '30px' }}>
                      Sign In
                    </button>
                    {/* <a href="/signup">gggg</a> */}
                  </div>
                </form>
              ) : activeLoginForm === "inviteId" ? (<>

                <div className="login-via-tabs" style={{ borderBottom: '3px solid var(--background-theme)' }}>
                  <span className="signUp-heading">Login/Sign Up On ExtraMile Play</span>
                </div>

                <form className="user-login-form">

                  <button type="button" className="btn btn-primary" style={{ height: '78px' }} onClick={() => setActiveLoginForm('login via otp')}>
                    <div className="signIn-btn-text">
                      sign In
                      <span>Already have an Account?</span>

                    </div>
                    <TbLogin2
                      className="signIn-btn-icon"

                      style={{
                        height: '70px',
                        width: '48px',
                        marginRight: '-20%'
                      }}

                    />

                  </button>
                  <button type="button" className="btn btn-primary" style={{ background: '#1D1D1D', height: '78px' }} onClick={handleSingUpPage}>
                    <div className="signIn-btn-text" style={{ marginRight: '16%', color: 'var(--color-theme)' }}>
                      sign Up
                      <span>Don’t have an account ?</span>

                    </div>
                    <BiSolidExit
                      style={{
                        height: '66px',
                        width: '45px',
                        marginRight: '-22%'
                      }}
                      className="signIn-btn-icon"
                    />

                  </button>
                  {/* <a href="/signup">gggg</a> */}

                </form>
              </>) : null
            }
            {
              activeLoginForm !== 'inviteId' ? (<>
                <h4 className="or" style={{
                  margin: '16px',
                  lineHeight: 'normal'
                }}>Sign in with one of the following</h4>
                <div className="btn-group-sso" >
                  <button className="btn btn-secondry-sso" onClick={async (e) => { await handleSubmit(e, "AZUREAD") }} style={{ marginTop: '0px' }}>
                    <img src={azureAd} alt="" />
                  </button>
                  <button className="btn btn-secondry-sso" onClick={async (e) => { await handleSubmit(e, "PEOPLESTRONG") }} style={{ marginTop: '0px' }}>
                    <img src={peopleStrong} alt="" style={{ margin: '0px' }} />
                  </button>
                </div>
                <div className="signUp-form-link-cont">
                  <div className="singUp-link">Don’t have account? <span onClick={handleSingUpPage}> Sign Up</span></div>
                </div>
              </>) : null
            }




            {/* <MicrosoftLogin className="ms-auth" withUserData clientId={AZURE_CLIENT_ID} authCallback={authHandler}  >
            <button className="btn btn-secondry" onClick={() => setLoginWithMsClicked(true)} >Login With MS</button>
          </MicrosoftLogin> */}

            {/* <div className="login-option">
            <div className="login-divider">
              <span>or</span>
            </div>
            <div className="login-withaccount">
              <GoogleLogin
                clientId="874126450915-998pghgdq3a71s6d7pqovq7fanm8jevn.apps.googleusercontent.com"
                buttonText="Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
                render={(renderProps) => (
                  <button
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #23282E",
                      "box-sizing": "border-box",
                      "border-radius": "6px",
                    }}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <img src={google} alt="google" />
                  </button>
                )}
              ></GoogleLogin>

              <FacebookLogin
                appId="763056201270294"
                //autoLoad={true}
                fields="name,email,picture"
                callback={responseFacebook}
                icon="fa-facebook"
                render={(renderProps) => (
                  <button
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #23282E",
                      "box-sizing": "border-box",
                      "border-radius": "6px",
                    }}
                    onClick={renderProps.onClick}
                  >
                    <img src={facebook} alt="facebook" />
                  </button>
                )}
              ></FacebookLogin>

              <LinkedIn
                clientId="781hl6t14llqdk"
                scope="r_liteprofile,r_emailaddress"
                onFailure={responseLinkedin}
                onSuccess={responseLinkedin}
                redirectUri={BASE_URL"/linkedin"}
                redirectPath="/linkedin"
                renderElement={({ onClick }) => (
                  <button
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #23282E",
                      "box-sizing": "border-box",
                      "border-radius": "6px",
                    }}
                    onClick={onClick}
                  >
                    <img src={linkedin} alt="linkedin" />
                  </button>
                )}
              />
            </div>
          </div> */}

          </div>
        </div>
      </OnBoard>
    </div>
  );
};

export default SignIn;
