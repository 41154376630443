import * as ActionTypes from "../constants/diwaliGameApiConstants";

function addWordsListReducer(state = {}, action) {
    switch (action.type) {
        case ActionTypes.DIWALI_GAME_UPDATE_REQUEST:
            return { loading: true };
        case ActionTypes.DIWALI_GAME_UPDATE_SUCCESS:
            return { loading: false};
        case ActionTypes.DIWALI_GAME_UPDATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case ActionTypes.GET_WORDS_LIST_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.GET_WORDS_LIST_SUCCESS:
            return { ...state, loading: false, wordsListArr: action.payload };
        case ActionTypes.GET_WORDS_LIST_FAIL:
            return { ...state, loading: false, error: action.payload };

        case ActionTypes.GET_RELEASED_WORDS_LIST_REQUEST:
            return { loading: true };
        case ActionTypes.GET_RELEASED_WORDS_LIST_SUCCESS:
            return { loading: false, wordsListArr: action.payload  };
        case ActionTypes.GET_RELEASED_WORDS_LIST_FAIL:
            return { loading: false, error: action.payload };
        
        case ActionTypes.GET_DIWALI_SETTINGS_REQUEST:
            return { loading: true };
        case ActionTypes.GET_DIWALI_SETTINGS_SUCCESS:
            return { ...state, loading: false, diwaliGameSettings: action.payload  };
        case ActionTypes.GET_DIWALI_SETTINGS_FAIL:
            return { loading: false, error: action.payload };

        case ActionTypes.UPDATE_DIWALI_SETTINGS_REQUEST:
            return { loading: true };
        case ActionTypes.UPDATE_DIWALI_SETTINGS_SUCCESS:
            return { ...state, loading: false, diwaliGameSettings: action.payload  };
        case ActionTypes.UPDATE_DIWALI_SETTINGS_FAIL:
            return { loading: false, error: action.payload };
        
        default:
            return state;
    }

}

export {
    addWordsListReducer
}