import React, { useEffect, useState } from "react";
import Select from "react-select";
import "react-multi-email/style.css";
import "./links.css";
import PageLayout from "../../components/pagelayout/pagelayout";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { ToastContainer } from "react-toastify";
import { logOut } from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import { BASE_URL, IsAdmin, failureAlert, } from "../../helpers/helper";

import { useSelector } from "react-redux";
import { getAllOrganisations } from "../../redux/actions/organisationActions";
import { downloadGameLinkReportRequests, getDiwaliGameLinkInfo, updateGameLinkInfo, updateGameLinkToggle } from "../../redux/actions/adminApiActions";
import { downloadFile } from "../../helpers/downloadFile";

const selectStyles = {
  control: (base) => ({
    ...base,
    height: 45,
    background: "#f6f7f9",
    border: 0,
    fontSize: 14
  }),
  valueContainer: (base) => ({
    ...base,
    height: 45
  }),
  input: (base) => ({
    ...base,
    height: 45
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: 45
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 13
  })
};

const Links = (props) => {
  const dispatch = useDispatch();
  const isAdmin = IsAdmin();
  const [selectOrganization, setSelectedOrganization] = useState()
  const [loaded, setLoaded] = useState(true);
  const [orgAdded, setOrgAdded] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isActive, setActive] = useState(false)
  const [allOrganisations, setAllOrganisations] = useState([]);
  const [copy, setCopy] = useState({ name: "", isCopied: false })
  const [details, setDetails] = useState({
    organization: null,
    linkType: "",
    maxFamilyMembers: null,
    maximumTicket: null,
    employeeLink: "",
    familyLink: "",
    guestLink: ""
  });
  const [subDomain, setSubdomain] = useState(null)
  const AllOrganisationData = useSelector((state) => state.allOrganisationData);
  const { allOrganisationData } = AllOrganisationData;
  const GameLinks = useSelector(state => state.gameLinksInfo);
  const { gameLinksInfo } = GameLinks;

  const ToggleGaameLinkActive = useSelector(state => state.toggleGameLinkActive)


  useEffect(() => {
    dispatch(getAllOrganisations("", true));
  }, [])

  useEffect(() => {
    if (allOrganisationData && allOrganisationData.data && allOrganisationData.data.allOrganisationDetails) {
      setAllOrganisations(allOrganisationData.data.allOrganisationDetails.map(data => (
        { label: data.organizationName, value: data.organizationName, id: data.organizationId, subDomain: data.subDomain }

      )))
    }
  }, [allOrganisationData]);

  useEffect(() => {
    if (gameLinksInfo && gameLinksInfo.data) {
      const { isActive, linkType, maxFamilyMembers, maximumTicket } = gameLinksInfo.data[0];
      setActive(isActive)
      setDetails({
        ...details, linkType: { label: linkType, value: linkType },
        maxFamilyMembers: { label: maxFamilyMembers, value: maxFamilyMembers },
        maximumTicket: details.organization === null ? 0 : maximumTicket,
        employeeLink: details.organization === null ? "" : `${subDomain ? `https://${subDomain}.extramileplay.com` : BASE_URL}/game/dhoom-game?org-id=${details.organization?.id}&link-type=ezf678gaki`,
        familyLink: details.organization === null ? "" : `${subDomain ? `https://${subDomain}.extramileplay.com` : BASE_URL}/game/dhoom-game?org-id=${details.organization?.id}&link-type=fs10jnla97`,
        guestLink: details.organization === null ? "" : `${subDomain ? `https://${subDomain}.extramileplay.com` : BASE_URL}/game/dhoom-game?org-id=${details.organization?.id}&link-type=gwe13123sd`
      })
    }
  }, [gameLinksInfo])

  useEffect(() => {
    if ((GameLinks && GameLinks.loading) || (ToggleGaameLinkActive && ToggleGaameLinkActive.loading)) {
      setLoaded(false)
    } else {
      setLoaded(true)
    }
  }, [GameLinks, ToggleGaameLinkActive])

  useEffect(() => {
    const callBack = async () => {
      if (submitClicked && gameLinksInfo && gameLinksInfo?.data?.length) {
        const { id } = gameLinksInfo?.data?.[0];
        let dataObject = {
          linkType: details.linkType.value,
          maxFamilyMembers: details.maxFamilyMembers.value,
          maximumTicket: parseInt(details.maximumTicket),
          organizationId: details.organization.id,
          isActive: isActive,
          employeeLink: `${subDomain ? `https://${subDomain}.extramileplay.com` : BASE_URL}/game/dhoom-game?org-id=${details.organization.id}&link-type=ezf678gaki`,
          familyLink: `${subDomain ? `https://${subDomain}.extramileplay.com` : BASE_URL}/game/dhoom-game?org-id=${details.organization.id}&link-type=fs10jnla97`,
          guestLink: `${subDomain ? `https://${subDomain}.extramileplay.com` : BASE_URL}/game/dhoom-game?org-id=${details.organization.id}&link-type=gwe13123sd`
        };
        if (submitClicked && typeof (id) !== 'undefined') {
          dataObject.id = id
        }
        const response = await dispatch(updateGameLinkInfo(dataObject));
        if (response.status === 200) {
          dispatch(getDiwaliGameLinkInfo(details.organization.id))
        }
      }
    }
    callBack();
    setSubmitClicked(false)

  }, [submitClicked]);

  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const { subDomain } = value
    setSubdomain(subDomain)
    if (name === 'organization') {
      setSelectedOrganization(value.label)
      dispatch(getDiwaliGameLinkInfo(value.id))
    }
    setDetails(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleCopyClipboard = (data) => {
    let text = document.getElementById(data).value;
    navigator.clipboard.writeText(text);
    if (text && data) { setCopy({ ...copy, name: data, isCopied: true }) }
    setTimeout(() => { setCopy({ ...copy, name: "", isCopied: false }) }, 5000)
  };

  const handleOnsubmit = (e) => {
    e.preventDefault();
    setSubmitClicked(true)
  };

  const handleToggle = () => {
    const { id } = gameLinksInfo?.data?.[0];
    dispatch(updateGameLinkToggle({ userId: id, isActive: !isActive }))
    setActive(!isActive)
  }
  const downloadGameLinkReport = async () => {
    let data = {
      orgName: details.organization.value,
      familyMembersAllowed: details.maxFamilyMembers.value,
      ticketsAllowed: details.maximumTicket.value,
      employeeLink: details.employeeLink,
      familyLink: details.familyLink,
      guestLink: details.guestLink

    }
    const response = await dispatch(downloadGameLinkReportRequests(data));
    if (response && response.data && response.data.data && response.data.data.downloadUrl) {
      downloadFile(response.data.data.downloadUrl);
    }
    else {
      failureAlert("Something went wrong!");
    }
  }

  const downloadLiveAttendies = async () => {
    const response = await fetch(`${BASE_URL}/api/game/diwali/diwali-game-collect`)
    const downloadData = await response.json()
    if (downloadData && downloadData.data && downloadData.data.downloadUrl) {
      downloadFile(downloadData.data.downloadUrl);
    }
    else {
      failureAlert("Something went wrong!");
    }
  }

  return (
    <div className="admin-homepage">
      <PageLayout
        sidebartitle=""
        active={"DDDT Links"}
        sideBarContents={sidebarContentAdmin}
        profile
        {...props}
        signOut={signOut}
        isAdmin={isAdmin}
      >
        <LoadingComponent loaded={loaded} />
        <ToastContainer position="bottom-center" />
        <div className="add-new-organisation-admin">
          <div className="title">
            <h4>DDDT Links</h4>
            <span style={{ float: "right", width: "50%" }}>
              <Select
                // className="form-select-orgs mt-5"
                options={allOrganisations}
                placeholder="All Organisation"
                onChange={e => handleChange({ target: { name: "organization", value: e } })}
                styles={selectStyles}
                value={details.organization}
              />
            </span>
          </div>
          <br />
          <br />
          {selectOrganization && <div className="title">
            <span style={{ float: "right", width: "50%", display: "flex", alignItems: "center", marginTop: "10px", justifyContent: 'space-between' }}>
              <button className="btn btn-primary" onClick={downloadGameLinkReport}>
                {/* <svg onClick={downloadGameLinkReport} style={{ height: '40px', margin: '4px', cursor: 'pointer' }} fill="#e25569" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" /></svg> */}
                Download CSV</button>
              <button className="btn btn-secondary" onClick={downloadLiveAttendies}>
                {/* <svg onClick={downloadGameLinkReport} style={{ height: '40px', margin: '4px', cursor: 'pointer' }} fill="#e25569" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" /></svg> */}
                Download Live attendes </button>
            </span>
          </div>}
          <br />



          <div style={{ padding: "60px" }}>
            <form
              onSubmit={handleOnsubmit}
              className={`add-org ${orgAdded ? "faded03" : ""}`}>
              {selectOrganization && <div className="form-group">
                <label >{selectOrganization !== "" ? selectOrganization : ""}</label>
                <label htmlFor="name" className="switch" onClick={handleToggle}>
                  <input type="checkbox" checked={isActive} />
                  <span className="slider"></span>
                </label>
              </div>}

              <div className="form-group">
                <label htmlFor="phone">
                  Family Members Allowed For Each Employee
                </label>
                <Select
                  className="width-50"
                  options={[1, 2, 3, 4, 5].map((item) => ({ label: item, value: item }))}
                  placeholder="No of family members allowed for each employee"
                  onChange={e => handleChange({ target: { name: "maxFamilyMembers", value: e } })}
                  styles={selectStyles}
                  value={details.maxFamilyMembers}
                />
                {/* <div className="error-message">{errors.phoneNumber}</div> */}
              </div>

              <div className="form-group">
                <label htmlFor="phone">
                  Maximum Tickets Allowed to create for the Client
                </label>

                <input
                  style={{ textOverflow: "ellipsis", width: "50%" }}
                  type="number"
                  min={0}
                  placeholder="Maximum Ticket"
                  onChange={e => handleChange({ target: { name: "maximumTicket", value: e.target.value } })}
                  value={details.maximumTicket}
                />
                {/* <div className="error-message">{errors.phoneNumber}</div> */}

              </div>

              <div className="form-group">
                <label htmlFor="">Employee Link</label>
                <div className="width-50" style={{ background: "#f6f7f9", borderRadius: "6px" }}>
                  <span className="text-span" onClick={() => handleCopyClipboard("EmployeeLink")}>{copy.name === 'EmployeeLink' && copy.isCopied ? "Copied" : "Copy"}
                  </span>
                  <input
                    style={{ textOverflow: "ellipsis", width: "89%" }}
                    type="textbox"
                    value={details.employeeLink}
                    id="EmployeeLink"
                    disabled
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="">Family Link</label>
                <div className="width-50" style={{ background: "#f6f7f9", borderRadius: "6px" }}>
                  <span className="text-span" onClick={() => handleCopyClipboard("FamilyLink")}>{copy.name === 'FamilyLink' && copy.isCopied ? "Copied" : "Copy"}
                    {/* <span class="tooltiptext">Copy</span> */}
                  </span>
                  <input
                    style={{ textOverflow: "ellipsis", width: "89%" }}
                    type="textbox"
                    value={details.familyLink}
                    id="FamilyLink"
                    disabled
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="">Guest Link</label>
                <div className="width-50" style={{ background: "#f6f7f9", borderRadius: "6px" }}>
                  <span className="text-span" onClick={() => handleCopyClipboard("GuestLink")}>{copy.name === 'GuestLink' && copy.isCopied ? "Copied" : "Copy"}</span>
                  {/* <span class="tooltiptext">Copy</span></span> */}
                  <input
                    style={{ textOverflow: "ellipsis", width: "89%" }}
                    type="textbox"
                    value={details.guestLink}
                    id="GuestLink"
                    disabled
                  />
                </div>

              </div>
              <div className="submit-group">
                <div className="btn-group">
                  <button type="submit" className="btn btn-gradientgreen" >
                    Submit
                  </button>
                </div>
              </div>
              {orgAdded && <div className="envelope"></div>}
            </form>
          </div>

          <br />
        </div>
      </PageLayout>
    </div>
  );
};
export default Links;
