import * as ActionTypes from "../constants/userTestimonialConstants";

const initialState = {
  loading: false,
  userTestimonial: [],
  error: ""
};

function userTestimonialReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_USERTESTIMONIAL_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_USERTESTIMONIAL_SUCCESS:
      return { ...state, loading: false, userTestimonial: action.payload };
    case ActionTypes.FETCH_USERTESTIMONIAL_FAILURE:
      return { ...state,loading: false, userTestimonial : [], error: action.payload };
    default:
      return state;
  }
}

export { userTestimonialReducer };
