import React, { useEffect, useState } from "react";
import "./analytics.css";
import { Link } from "react-router-dom";
import { Bar, Doughnut } from 'react-chartjs-2';

import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../../components/pagelayout/pagelayout";
import { failureAlert, IsAdmin, successAlert, axiosApiInstance, BASE_URL } from "../../helpers/helper";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { logOut } from "../../redux/actions/userAction";
import AnalyticsCard from "../../components/Analytics/AnalyticsCard";
import { downloadRegisteredUsers, downloadSubscribedUsers, getUsersCount } from "../../redux/actions/adminApiActions";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { ToastContainer } from "react-toastify";
import { downloadDemoRequests } from "../../redux/actions/commonActions";
import moment from "moment";
import Select from "react-select";
import { downloadFile } from "../../helpers/downloadFile";
import { CSVLink } from "react-csv";
import axios from "axios";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
);

const AnalyticsTwo = (props) => {

    const [startDateValue, setStartDateValue] = useState();
    const [endDateValue, setEndDateValue] = useState();

    const [startDateValueDate, setStartDateValueDate] = useState();
    const [endDateValueDate, setEndDateValueDate] = useState();

    const [monthLabel, setMonthLable] = useState([]);
    const [dataYears, setDataYears] = useState([]);


    const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];

    const doughnutOptions = {
        cutout: 50,
        plugins: {
            legend: {
                position: 'bottom',
                backgroundColor: 'rgba(53, 162, 235, 0.5)'
            },
            title: {
                display: true,
                text: 'Active Users',
            },
        },
    };

    const options = {
        maintainAspectRatio: false,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        plugins: {
            legend: {
                position: 'bottom',
                backgroundColor: 'rgba(53, 162, 235, 0.5)'
            },
            title: {
                display: true,
                text: 'Game Played',
            },
        },
    };

    const dispatch = useDispatch();
    const isAdmin = IsAdmin();
    const [loaded, setLoaded] = useState(true);
    const [gamePlayedCount, setGamePlayedCount] = useState('');
    const [activeUserCount, setActiveUserCount] = useState('');
    const [gameCountByUser, setGameCountByUser] = useState([]);
    const [gameCountByDate, setGameCountByDate] = useState([]);
    const [activeUserCountWithFilter, setActiveUserCountWithFilter] = useState();
    const [dateFilter, setDateFilter] = useState({ startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') });
    const [buttonSelected, setButtonSelected] = useState(1);
    const [organizationFilter, setOrganizationFilter] = useState({ organizationName: 'All Organization', organizationId: 'all' });
    const [gameFilter, setGameFilter] = useState({ title: 'All Game', id: 'all' });
    const [customDate, setCustomDate] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [gameList, setGameList] = useState([]);
    const [gameCountByDateArray, setGameCountByDateArray] = useState([]);
    const [notificationDetails, setNotificationDetails] = useState({ message: "", userType: null, redirectLink: "" });

    const [gamePlayedTime, setGamePlayedTime] = useState();
    const [totalPlayTime, setTotalPlayTime] = useState('');
    const [avgPlayTime, setAvgPlayTime] = useState('');


    const GetUsersCount = useSelector(state => state.getUsersCount);
    const { usersCount } = GetUsersCount;
    const DownloadRegisteredUsers = useSelector(state => state.downloadRegisteredUsers);
    const DownloadSubscribedUsers = useSelector(state => state.downloadSubscribedUsers);
    const DownloadDemoRequests = useSelector(state => state.downloadedDemoRequests);

    const userTypes = [
        { label: "User", value: "USER" },
        { label: "Employee", value: "EMPLOYEE" },
        { label: "Admin", value: "ORG_ADMIN" },
        { label: "Owner", value: "ORG_SUPER_ADMIN" },
    ]

    const doughnutData = {
        labels: ["New User", "Old User"],
        datasets: [
            {
                label: "# of Votes",
                data: [activeUserCountWithFilter?.[1], (activeUserCountWithFilter?.[0] - activeUserCountWithFilter?.[1])],
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                ],
                borderColor: [
                    "#e25569",
                    "#e25569",
                    "#e25569",
                ],
                borderWidth: 1,
            }
        ]
    };

    const chartData = {
        labels: monthLabel.length > 1 && monthLabel.length < 7 ? monthLabel : monthLabel.length > 11 && dataYears && dataYears.length > 0 ? dataYears : labels,

        datasets: [
            {
                label: 'No Of Game Played',
                data: gameCountByDateArray,
                backgroundColor: '#d18e8e',
                borderRadius: 3,
                borderSkipped: false,
                borderColor: '#966363',
                borderWidth: 2,
            },
        ],
    };

    useEffect(() => {


        if (gamePlayedTime && gamePlayedTime.length > 0) {
            const filterTime = gamePlayedTime.filter((time) => time.time !== " 2:18" && time.time !== "NA" && time.time !== "N/A" && time.time !== null);

            const timeStrings = filterTime.map(obj => obj.time);
            const { totalTime, averageTime } = addResponseTimes(timeStrings);
            setTotalPlayTime(totalTime);
            setAvgPlayTime(averageTime);

        }
        else{
            setTotalPlayTime('')
            setAvgPlayTime('')
        }

    }, [gamePlayedTime])


    useEffect(() => {
        if (!gameCountByDate) {
            return;
        }
        if (monthLabel.length > 1 && monthLabel.length < 12) {
            const monthCountResult = calculateTotalCounts(gameCountByDate);
            setGameCountByDateArray(monthCountResult.map((data) => data.totalCount));
        }
        else if(monthLabel.length > 11){
            const monthCountResult = calculateTotalCounts(gameCountByDate);
            const yearlyData =  calculateYearlyTotalCounts(monthCountResult);
            if(yearlyData.length > 6){
                return failureAlert("Can Only Display 6 Year Data");
            }
            else{
                setDataYears(yearlyData.map((data)=> "Year "+data.year));
                setGameCountByDateArray(yearlyData.map((data)=> data.totalcount));
            }
        }

        else {
            let currData = Array(31).fill(0);
            labels.map((data, i) => {
                if (gameCountByDate[i]?.createdAt?.split('-')[2]) {
                    currData[gameCountByDate[i]?.createdAt?.split('-')[2] - 1] = gameCountByDate[i]?.count;
                }
            });
            setGameCountByDateArray(currData);
        }


    }, [gameCountByDate])

    useEffect(() => {
        dispatch(getUsersCount());
        window.socket?.on("addNotification", (data) => {
            successAlert("Notification sent");
        })
    }, [window.socket]);

    const getActiveUserByDate = () => {
        axiosApiInstance.get(BASE_URL + "/api/dashboard/getGoogleToken").then(data => {
            let token = data?.data;
            axios({
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                data: {
                    "reportRequests": [
                        {
                            "viewId": "256899622",
                            "dateRanges": [
                                {
                                    "startDate": dateFilter?.startDate,
                                    "endDate": dateFilter?.endDate
                                }
                            ],
                            "metrics": [
                                {
                                    "expression": "ga:users"
                                },
                                {
                                    "expression": "ga:newUsers"
                                }
                            ]
                        }
                    ]
                },
                url: 'https://analyticsreporting.googleapis.com/v4/reports:batchGet',
            })
                .then((response) => {
                    setActiveUserCountWithFilter(response?.data?.reports?.[0]?.data?.totals?.[0]?.values);
                });
        });
    };

    const getGameCountByUser = () => {
        let { startDate, endDate } = dateFilter;
        axiosApiInstance.get(BASE_URL + `/api/dashboard/getGameCountByUser/${organizationFilter?.organizationId}/${gameFilter?.id}/${startDate}/${endDate}`).then(data => {
            setGameCountByUser(data?.data?.data);
        })
    };

    const getGamePlayedByDate = () => {
        let { startDate, endDate } = dateFilter;
        axiosApiInstance.get(BASE_URL + `/api/dashboard/getGamePlayedByDate/${organizationFilter?.organizationId}/${gameFilter?.id}/${startDate}/${endDate}`).then(data => {
            setGameCountByDate(data?.data?.data);
        });

        axiosApiInstance.get(BASE_URL + `/api/dashboard/getGamePlayedTimeByDate/${organizationFilter?.organizationId}/${gameFilter?.id}/${startDate}/${endDate}`)
        .then(data => {
            setGamePlayedTime(data?.data?.gamePlayTimes);
        });

    }

    useEffect(() => {
        axiosApiInstance.get(BASE_URL + "/api/dashboard/totalGamePlayedCount").then(data => {
            setGamePlayedCount(data?.data?.count);
        });
        axiosApiInstance.get(BASE_URL + "/api/organization/all?search=&sendAll=true").then(data => {
            setOrganizationList(data?.data?.data?.allOrganisationDetails);
        });
        axiosApiInstance.get(BASE_URL + "/api/game/default/all/filter").then(data => {
            setGameList(data?.data?.data);
        });

        getGameCountByUser();
        getGamePlayedByDate();
        axiosApiInstance.get(BASE_URL + "/api/dashboard/getGoogleToken").then(data => {
            let token = data?.data;
            axios({
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                data: {
                    "reportRequests": [
                        {
                            "viewId": "256899622",
                            "dateRanges": [
                                {
                                    "startDate": "1daysAgo",
                                    "endDate": "today"
                                }
                            ],
                            "metrics": [
                                {
                                    "expression": "ga:users"
                                },
                                {
                                    "expression": "ga:newUsers"
                                }
                            ]
                        }
                    ]
                },
                url: 'https://analyticsreporting.googleapis.com/v4/reports:batchGet',
            })
                .then((response) => {
                    setActiveUserCount(response?.data?.reports?.[0]?.data?.totals?.[0]?.values);
                });
        });
        getActiveUserByDate();
    }, []);

    useEffect(() => {
        getActiveUserByDate();
        getGameCountByUser();
        getGamePlayedByDate();
    }, [dateFilter])

    const onSubmitFilter = () => {
        getActiveUserByDate();
        getGameCountByUser();
        getGamePlayedByDate();

    };

    const onResetFilter = () => {
        setCustomDate(false);
        setDateFilter({ startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') });
        setOrganizationFilter({ organizationName: 'All Organization', organizationId: 'all' });
        setGameFilter({ title: 'All Game', id: 'all' });
        setButtonSelected(1);
        getActiveUserByDate();
        getGameCountByUser();
        getGamePlayedByDate();
        setMonthLable([]);
        setStartDateValueDate();
        setEndDateValueDate();
        setStartDateValue()
        setEndDateValueDate()
        setDataYears([]);

    };

    useEffect(() => {
        if (
            (GetUsersCount && GetUsersCount.loading) ||
            (DownloadRegisteredUsers && DownloadRegisteredUsers.loading) ||
            (DownloadSubscribedUsers && DownloadSubscribedUsers.loading) ||
            (DownloadDemoRequests && DownloadDemoRequests.loading)
        ) {
            setLoaded(false);
        }
        else {
            setLoaded(true);
        }
    }, [GetUsersCount, DownloadRegisteredUsers, DownloadSubscribedUsers, DownloadDemoRequests]);
    const downloadSubscribedUsersData = async () => {
        const response = await dispatch(downloadSubscribedUsers());
        if (response && response.data && response.data.data && response.data.data.downloadUrl) {
            downloadFile(response.data.data.downloadUrl);
        }
        else {
            failureAlert("Something went wrong!");
        }
    }
    const downloadRegisteredUsersData = async () => {
        const response = await dispatch(downloadRegisteredUsers());
        if (response && response.data && response.data.data && response.data.data.downloadUrl) {
            downloadFile(response.data.data.downloadUrl);
        }
        else {
            failureAlert("Something went wrong!");
        }
    }
    const downloadDemoRequestsData = async () => {
        const response = await dispatch(downloadDemoRequests());
        if (response && response.data && response.data.data && response.data.data.downloadUrl) {
            downloadFile(response.data.data.downloadUrl);
        }
        else {
            failureAlert("Something went wrong!");
        }
    }
    const signOut = async () => {
        await dispatch(logOut());
        if (isAdmin) props.history.push("/admin");
        else props.history.push("/");
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNotificationDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleSendNotifications = (e) => {
        e.preventDefault();
        if (window.socket) {
            setNotificationDetails({ message: "", userType: null, redirectLink: "" });
            window.socket.emit("addNotification", notificationDetails);
        }
        else {
            failureAlert("Something went wrong!");
        }
    }


    const onStartDateChange = (e) => {

        const dateValue = new Date(e.target.value);
        setStartDateValueDate(e.target.value)
        setStartDateValue(dateValue);
    }
    const onEndDateChange = (e) => {
        setEndDateValueDate(e.target.value);
        const dateValue = new Date(e.target.value);
        setEndDateValue(dateValue);
    }

    const onSubmitCustomDate = () => {

        const result = getMonthsBetweenDates(startDateValue, endDateValue);
        if (result.length > 6 && result.length < 12) {
            return failureAlert("Can Only View 6 Month Data OR Yearly Data");
        }
        else if(result.length > 11){
            setMonthLable(result.map((data) => data.month + " " + data.year))
            setDateFilter({ startDate: startDateValueDate, endDate: endDateValueDate });

        }
        else {
            setMonthLable(result.map((data) => data.month + " " + data.year))
            setDateFilter({ startDate: startDateValueDate, endDate: endDateValueDate });
        }
    }

    const getMonthsBetweenDates = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const monthsArray = [];

        while (start <= end) {

            const month = start.toLocaleString('en-US', { month: 'long' });
            const year = start.getFullYear();
            monthsArray.push({ month, year });
            start.setMonth(start.getMonth() + 1, 1);
        }

        return monthsArray;
    }

    const calculateTotalCounts = (data) => {
        const countsByYearMonth = {};
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        data.forEach(entry => {
            const date = new Date(entry.createdAt);
            const year = date.getFullYear();
            const monthIndex = date.getMonth();

            if (!countsByYearMonth[year]) {
                countsByYearMonth[year] = {};
            }

            if (!countsByYearMonth[year][monthIndex]) {
                countsByYearMonth[year][monthIndex] = 0;
            }

            countsByYearMonth[year][monthIndex] += entry.count;
        });

        const totalCounts = [];
        for (const year in countsByYearMonth) {
            for (const monthIndex in countsByYearMonth[year]) {
                totalCounts.push({
                    year: parseInt(year),
                    month: monthNames[parseInt(monthIndex)],
                    totalCount: countsByYearMonth[year][monthIndex]
                });
            }
        }

        return totalCounts;
    }

    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const [orgCount, setOrgCount] = useState(0);

    useEffect(() => {
        getAllOrgCount();
    }, [])

    const getAllOrgCount = () => {
        axios.get(BASE_URL + "/api/organization/get-orgcount")
            .then((response) => {
                setOrgCount(response.data);
            })
            .catch((error) => {
                console.error("Error fetching org count:", error);
            });
    };

    const downloadAllOrgDetail = () => {
        setLoaded(false);
        axios.get(BASE_URL + "/api/organization/all-org-sheetdata", {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'organization_data.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoaded(true);
        }).catch((error) => {
            console.error("Error downloading org data:", error);
        })

    }

    const calculateYearlyTotalCounts = (data) => {
        const yearlyCounts = {};

        data.forEach(item => {
            const { year, totalCount } = item;
            if (!yearlyCounts[year]) {
                yearlyCounts[year] = 0;
            }
            yearlyCounts[year] += totalCount; 
        });

        const yearlyTotalCountsArray = Object.keys(yearlyCounts).map(year => ({
            year: parseInt(year),
            totalcount: yearlyCounts[year]
        }));
    
        return yearlyTotalCountsArray;
    };

    const addResponseTimes = (times) => {
        let totalHours = 0;
        let totalMinutes = 0;
        let totalSeconds = 0;

        times.forEach(time => {
            const [hours, minutes, seconds] = time.split(':').map(Number);

            totalHours += hours;
            totalMinutes += minutes;
            totalSeconds += seconds;
        });

        totalMinutes += Math.floor(totalSeconds / 60);
        totalSeconds %= 60;
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes %= 60;

        const totalInSeconds = totalHours * 3600 + totalMinutes * 60 + totalSeconds;

        const averageInSeconds = totalInSeconds / times.length;

        const averageHours = Math.floor(averageInSeconds / 3600);
        const averageMinutes = Math.floor((averageInSeconds % 3600) / 60);
        const averageSeconds = Math.floor(averageInSeconds % 60);

        const formattedTotalTime = `${String(totalHours).padStart(2, '0')}:${String(totalMinutes).padStart(2, '0')}:${String(totalSeconds).padStart(2, '0')}`;
        const formattedAverageTime = `${String(averageHours).padStart(2, '0')}:${String(averageMinutes).padStart(2, '0')}:${String(averageSeconds).padStart(2, '0')}`;

        return { totalTime: formattedTotalTime, averageTime: formattedAverageTime };
    }



    return (
        <div className='admin-homepage'>
            <PageLayout
                sidebartitle=""
                active={"AnalyticsTwo"}
                sideBarContents={sidebarContentAdmin}
                profile
                {...props}
                signOut={signOut}
                isAdmin={isAdmin}
            >
                <LoadingComponent loaded={loaded} />
                <ToastContainer position="bottom-center" />
                <div className="analytics-container">
                    <div className="">
                        <span className="text-3xl font-bold text-gray-800">Analytics</span>
                    </div>
                    {/* <div className="download-btn-grp">
                        <button className="btn btn-primary" onClick={downloadDemoRequestsData}>Download Demo Requests</button>
                        <button className="btn btn-primary" onClick={downloadRegisteredUsersData}>Download Registered Users Data</button>
                        <button className="btn btn-primary" onClick={downloadSubscribedUsersData}>Download Subscribed Users Data</button>
                    </div> */}
                    <div className="analyticscard-container">
                        <AnalyticsCard title="Total Registered Users" value={
                            <div className="grid grid-cols-2">
                                <div className="text-5xl font-bold text-gray-600">{usersCount?.data?.registeredUsers ? usersCount?.data?.registeredUsers : 0}</div>
                                <div className="text-right justify-self-end">
                                    <svg onClick={downloadRegisteredUsersData} style={{ height: '40px', margin: '4px', cursor: 'pointer' }} fill="#e25569" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" /></svg>
                                </div>
                            </div>
                        } />
                        <AnalyticsCard title="Total Subscribers" value={
                            <div className="grid grid-cols-2">
                                <div className="text-5xl font-bold text-gray-600">{usersCount?.data?.subscribedUsers ? usersCount?.data?.subscribedUsers : 0}</div>
                                <div className="text-right justify-self-end">
                                    <svg onClick={downloadSubscribedUsersData} style={{ height: '40px', margin: '4px', cursor: 'pointer' }} fill="#e25569" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" /></svg>
                                </div>
                            </div>
                        } />
                        <AnalyticsCard title="Total Game Played" value={<div className="text-5xl font-bold text-gray-600">{gamePlayedCount ? gamePlayedCount : 0}</div>} />
                        <AnalyticsCard title="Today Active User" value={<div className="text-5xl font-bold text-gray-600">{activeUserCount ? activeUserCount[0] : 0}</div>} />
                        <AnalyticsCard title="" value={
                            <div className="grid grid-cols-2">
                                {console.log(downloadDemoRequestsData, '-----------------')}
                                <div className="text-2xl font-bold text-gray-600">Demo <br />Requests</div>
                                <div className="text-right justify-self-end">
                                    <svg onClick={downloadRegisteredUsersData} style={{ height: '40px', margin: '4px', cursor: 'pointer' }} fill="#e25569" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" /></svg>
                                </div>
                            </div>
                        } />

                        <AnalyticsCard title="Total Organization" value={
                            <div className="grid grid-cols-2">
                                <div className="text-5xl font-bold text-gray-600">{orgCount ? orgCount.data : 0}</div>
                                <div className="text-right justify-self-end">
                                    <svg onClick={downloadAllOrgDetail} style={{ height: '40px', margin: '4px', cursor: 'pointer' }} fill="#e25569" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" /></svg>
                                </div>
                            </div>
                        } />
                        <AnalyticsCard title="Average Game Play Time" value={<div className="text-5xl font-bold text-gray-600">{avgPlayTime ? avgPlayTime : 0}</div>} />
                        <AnalyticsCard title="Total Game Play Time" value={<div className="text-5xl font-bold text-gray-600">{totalPlayTime ? totalPlayTime : 0}</div>} />


                    </div>
                    <div className="grid lg:grid-cols-12 mt-6">
                        {customDate ? (
                            <>
                                <div className="mx-2 col-span-2">
                                    <span>Start Date</span>
                                    <input style={{ padding: '7px 7px 7px 7px' }} type="date" onChange={(e) => onStartDateChange(e)} max={getCurrentDate()} id="default-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>


                                <div className="mx-2 col-span-2">
                                    <span>End Date</span>
                                    <input style={{ padding: '7px 7px 7px 7px' }} type="date" onChange={(e) => onEndDateChange(e)} disabled={!startDateValueDate ? true : false} max={getCurrentDate()} min={startDateValueDate} id="default-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                {/* <div className="mx-2 col-span-2">
                                    <input style={{ padding: '7px 7px 7px 7px' }} type="date" onChange={(e) => setDateFilter({ ...dateFilter, endDate: moment(e.target.value).format('YYYY-MM') })} value={dateFilter?.endDate} id="default-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div> */}
                            </>
                        ) : (
                            <div className="col-span-5">
                                <button onClick={() => { setButtonSelected(1); setDateFilter({ startDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'), endDate: moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD') }); }} className={`bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1.5 mx-1 px-4 border border-red-500 hover:border-transparent rounded ${(buttonSelected === 1) && 'bg-red-500 text-gray-100'}`}>Today</button>
                                <button onClick={() => { setButtonSelected(2); setDateFilter({ startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().endOf('isoWeek').format('YYYY-MM-DD') }); }} className={`bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1.5 mx-1 px-4 border border-red-500 hover:border-transparent rounded ${(buttonSelected === 2) && 'bg-red-500 text-gray-100'}`}>This Week</button>
                                <button onClick={() => { setButtonSelected(3); setDateFilter({ startDate: moment().startOf('months').format('YYYY-MM-DD'), endDate: moment().endOf('months').format('YYYY-MM-DD') }); }} className={`bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1.5 mx-1 px-4 border border-red-500 hover:border-transparent rounded ${(buttonSelected === 3) && 'bg-red-500 text-gray-100'}`}>This Month</button>
                                <button onClick={() => { setButtonSelected(4); setCustomDate(true); }} className={`bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1.5 mx-1 px-4 border border-red-500 hover:border-transparent rounded ${(buttonSelected === 4) && 'bg-red-500 text-gray-100'}`}>Custom Month</button>
                            </div>
                        )}
                        <div className="mx-2 col-span-2">
                            <Select
                                classNamePrefix="react-select"
                                className=""
                                options={[...organizationList, { organizationName: 'All Organization', organizationId: 'all' }]}
                                menuPlacement={"auto"}
                                isMulti={false}
                                onChange={(value) => setOrganizationFilter(value)}
                                value={organizationFilter}
                                getOptionLabel={(option) => option.organizationName}
                                getOptionValue={(option) => option.organizationId}
                            />
                        </div>
                        <div className="mx-2 col-span-2">
                            <Select
                                classNamePrefix="react-select"
                                className=""
                                options={[...gameList, { title: 'All Game', id: 'all' }]}
                                menuPlacement={"auto"}
                                isMulti={false}
                                onChange={(value) => setGameFilter(value)}
                                value={gameFilter}
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.id}
                            />
                        </div>
                        <div className="mx-4 col-span-3">
                            <button onClick={() => { startDateValueDate && endDateValueDate ? onSubmitCustomDate() : onSubmitFilter() }} className="bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1.5 mx-2 px-4 border border-red-500 hover:border-transparent rounded">APPLY</button>
                            <button onClick={() => onResetFilter()} className="bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1.5 mx-2 px-4 border border-red-500 hover:border-transparent rounded">RESET</button>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-12">
                        <div className="col-span-7 justify-self-start" style={{ margin: '40px 0px 40px 0px' }}>
                            <Bar style={{ height: '400px' }} options={options} data={chartData} />
                        </div>
                        <div className="col-span-5 justify-self-end" style={{ margin: '80px 0px 40px 20px' }}>
                            <Doughnut
                                data={doughnutData}
                                options={doughnutOptions}
                            />
                            <div className="grid justify-center border border-red-400 bg-green-200">TOTAL : {activeUserCountWithFilter?.[0]}</div>
                        </div>
                    </div>
                    <div className="mt-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className=" overflow-hidden sm:rounded-lg border-4 border-red-200" style={{ maxHeight: '490px', overflowY: "scroll" }}>
                                <table className="min-w-full text-sm text-gray-100">
                                    <thead className="bg-red-400 text-xs uppercase font-medium sticky top-0">
                                        <tr className="bg-black bg-opacity-20">
                                            <th></th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                User Name
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                Email
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                Phone
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                Onboard Type
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                Role
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                No Game Played
                                            </th>
                                        </tr>
                                    </thead>
                                    {/* <div style={{ height: '200px', overflowX: "scroll" }}> */}
                                    <tbody className="bg-red-300 divide-y divide-red-300 h-2" >
                                        {
                                            (gameCountByUser?.length > 1) ? gameCountByUser.map((data, i) => (
                                                <tr className="bg-black bg-opacity-20">
                                                    <td className="pl-4">
                                                        {i + 1}
                                                    </td>
                                                    <td className="flex px-6 py-4 whitespace-nowrap">
                                                        <img className="w-5" src="https://www.cornwallbusinessawards.co.uk/wp-content/uploads/2017/11/dummy450x450.jpg" alt="" />
                                                        <span className="ml-2 font-medium">{data?.firstName}{' '}{data?.lastName}</span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {data?.email}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {data?.phoneNumber}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {data?.onBoardType}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {data?.role}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {data?.count}
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td className="text-center p-4" colspan="8">NO DATA</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                    {/* </div> */}
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="text-right mb-4">
                        {(gameCountByUser?.length > 1) && <button className="bg-red-300 p-2 rounded"><CSVLink data={gameCountByUser}>Download CSV</CSVLink></button>}
                    </div>
                    {/* <div className="send-notifications">
                        <h4>Send Notifications</h4>
                        <form onSubmit={handleSendNotifications}>
                            <div className="form-group">
                                <input type="text" placeholder="Enter message to send" value={notificationDetails.message} name="message" onChange={handleChange} className="form-field" />
                            </div>
                            <div className="form-group">
                                <input type="text" placeholder="Enter redirect link" value={notificationDetails.redirectLink} name="redirectLink" onChange={handleChange} className="form-field" />
                            </div>
                            <div className="form-group">
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={userTypes}
                                    menuPlacement={"auto"}
                                    isMulti
                                    onChange={(e) => handleChange({ target: { name: "userType", value: e } })}
                                    placeholder="Select User Type"
                                    value={notificationDetails.userType}
                                // onBlur={() => handleBlur({ target: { name: "category" } })}
                                />
                            </div>
                            <button className="btn btn-primary">Send</button>
                        </form>
                    </div> */}
                </div >
            </PageLayout >
        </div >
    )
}
export default AnalyticsTwo;