import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDiwaliSettings } from '../../redux/actions/diwaliGameApiActions';

import CardComp from "./card"
import SettingsSectionTwo from "./SettingsSectionTwo"
import ToggleField from "./toggleField"

const arr = [
    { name: "Disable Ticket Creation", key: "ticketCreation" },
    { name: "Disable Claims", key: "claims" },
    { name: "Disable Auto Generate Button", key: "autoGenerateButton" },
    { name: "Show Leaderboard to everyone", key: "showLeaderBoard" },
    { name: "Multiple Claims", key: "multipleClaims" },
    { name: "Winner Restriction", key: "winnerRestriction" },
]
const claimArr = [
    { key: "topLine", name: "Top Line" },
    { key: "middleLine", name: "Middle Line" },
    { key: "bottomLine", name: "Bottom Line" },
    { key: "fourCorners", name: "Four Coners" },
    { key: "fullHouse", name: "Full House" },
    { key: "earlyFive", name: "Early Five" },
    // { key: "no_luck", name: "No Luck" },
    // { key: "unlucky_five", name: "Unlucky Five" },
]

const DiwaliGameSetting = () => {
    const dispatch = useDispatch();
    const diwaliGameDetails = useSelector(state => state.diwaliGameDetails);
    const [gameSettings, setGameSettings] = useState([]);

    useEffect(() => {
        dispatch(getDiwaliSettings())
    }, []);

    useEffect(() => {
        if (diwaliGameDetails?.diwaliGameSettings) {
            setGameSettings(diwaliGameDetails?.diwaliGameSettings)
        }
    }, [diwaliGameDetails?.diwaliGameSettings]);

    return (
        <div className="flex">
            <div className="w-4/12 px-2">
                <CardComp cardTitle={"Dhoom Dham Settings"}>
                    {
                        arr.map((val, inx) => {
                            return <ToggleField name={val.name} keyValue={val.key} checked={gameSettings[val.key]} />
                        })
                    }
                    <div className=" text-red-700 font-bold mt-4" >Toogle switch to Disable / Enable</div>
                </CardComp>

            </div>
            <div className="w-5/12 px-2">
                <SettingsSectionTwo gameSettings={gameSettings} />
            </div>
            <div className="w-3/12 px-2">
                <CardComp cardTitle={"Claim Type"}>
                    {
                        claimArr.map((val) => {
                            return <ToggleField checked={gameSettings[val.key]} name={val.name} keyValue={val.key} />
                        })
                    }
                </CardComp>
            </div>
        </div>
    )
}
export default DiwaliGameSetting