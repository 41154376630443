import * as ActionTypes from "../constants/offlineConstants";

const initialState = {
  loading: false,
  offlineQuotesData: [],
  error: ""
};

function getAllOfflineQuotesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_OFFLINEQUOTE_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_OFFLINEQUOTE_SUCCESS:
      return { ...state, loading: false, offlineQuotesData: action.payload };
    case ActionTypes.FETCH_OFFLINEQUOTE_FAILURE:
      return { ...state,loading: false, offlineQuotesData: [], error: action.payload };
    default:
      return state;
  }
}

export { getAllOfflineQuotesReducer };
