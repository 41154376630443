import React, { useState, useEffect, useRef } from "react";
import "./modal.css";
import Modal from "./modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import the CSS for styling
import axios from "axios";
import { BASE_URL } from "../../helpers/helper";
import loginicon from "../../assets/images/login.svg";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import LoadingComponent from "../loader/LoadingComponent";
import { useDispatch, useSelector } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { getOrganisationDetailsById } from '../../redux/actions/plansApiActions';
import {
  successAlert,
  failureAlert,
  GOOGLE_RECAPTCHA_SITE_KEY,
  axiosApiInstance,
  encryptData,
  decryptData
} from "../../helpers/helper";
import { getAllOrganisations, toggleOrganisationByAdmin } from "../../redux/actions/organisationActions";

const OfflineTypeModal = ({
  gameId,
  modalid,
  toggle,
  setOpenOfflineTypeModal,
  gameDetailPage,
  plan
}) => {
  const [defaultOrganizationName, setOrganizationName] = useState("");
  const dispatch = useDispatch();
  const org = useSelector(state => state.getOrganisationById);
  const [isnameDisabled, setIsNameDisabled] = useState(false);
  const [isemailDisabled, setIsEmailDisabled] = useState(false);
  const [isOrganizationDisabled, setIsOrganizationDisabled] = useState(false);
  const UserInfo = useSelector(state => state.getUser);
  const { userInfo } = UserInfo;
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    organization: ""
  });

  // State to store validation errors
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    organization: ""
  });

  // State to track if fields have been touched
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    organization: false,
    phone: false
  });
  const [phone, setPhone] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [googleReCaptchaToken, setGoogleReCaptchaToken] = useState("");
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (userInfo && !userInfo.data.isPlanExpired && userInfo?.data?.organizationId) {
      dispatch(getOrganisationDetailsById(userInfo.data.organizationId));
    }
  }, [dispatch]);



  useEffect(() => {

    if (userInfo && org?.orgDetailsById?.organization?.name) {
      setFormValues(prevValues => ({
        ...prevValues,
        name: `${userInfo?.data?.firstName || ""} ${userInfo?.data?.lastName || ""}`.trim(),
        email: userInfo?.data?.email.trim(),
        organization: org?.orgDetailsById?.organization?.name.trim()
      }));
      setIsNameDisabled(true);
      setIsEmailDisabled(true);
      setIsOrganizationDisabled(true);
    }
    else {
      setFormValues(prevValues => ({
        ...prevValues,
        name: '',
        email: '',
        organization: ''
      }));
      setIsNameDisabled(false);
      setIsEmailDisabled(false);
      setIsOrganizationDisabled(false);
    }


  }, [org]);
  const handleChange = (e) => {

    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, "");

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: name=='name'? trimmedValue.replace(/[^A-Za-z\s]/g, ''):trimmedValue
    }));
    // Validate the field if it has been touched
    if (touched[name]) {
      validateField(name, trimmedValue);
    }
  };

  // Handle input blur
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true
    }));

    if (name == 'phone') {
      errors[name] = validateField('phone', isValidPhone);
    }
    else {
      errors[name] = validateField(name, formValues[name]);
    }

  };

  // Validate a specific field and return error message
  const validateField = (name, value) => {

    let error = "";
    if (name === "name" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }
    else if (name === "email" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }

    else if (name === "email" && !/^\S+@\S+\.\S+$/.test(value)) {
      error = "Invalid Email Address";
    } else if (name === "organization" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }
    else if (name === "phone" && phone == "") {
      error = "Please fill out this field";
    }
    else if (name === "phone" && !value) {

      error = "Invalid Phone Number";
    }
    return error;
  };

  // Validate all fields and update errors
  const validateAllFields = () => {
    const newErrors = {
      name: validateField("name", formValues.name),
      email: validateField("email", formValues.email),
      organization: validateField("organization", formValues.organization),
      phone: validateField("phone", isValidPhone)
    };
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {

    e.preventDefault();

    // Mark all fields as touched
    const newTouched = {
      name: true,
      email: true,
      organization: true,
      phone: true
    };
    setTouched(newTouched);

    // Validate all fields
    const newErrors = validateAllFields();
    setErrors(newErrors);

    if (
      Object.values(newErrors).every((error) => error === "") &&
      isValidPhone
    ) {

      if (googleReCaptchaToken) {
        const response = await axios.post(
          BASE_URL + `/api/user/verify/googlecaptcha`,
          { token: googleReCaptchaToken }
        );

        if (response.data.data === true) {
          const subscription = userInfo && !userInfo.data.isPlanExpired ? 'Login' : 'Non-Login'
          const offlineQuoteObj = {
            gameId: gameId,
            name: formValues.name,
            email: formValues.email,
            organization: formValues.organization,
            phone: phone,
            subscription: subscription
          };

          setLoaded(false);
          await axiosApiInstance
            .post(BASE_URL + "/api/offline-quote/add", offlineQuoteObj)
            .then(async (response) => {
              if (response && response.data && response.data.success) {
                setOpenOfflineTypeModal(true);
                setLoaded(true);
              }
            })
            .catch((error) => {
              failureAlert("Failed to add request");
              setLoaded(true);
            });
        } else {
          failureAlert("Please verify you are not a robot");
        }
      } else {
        failureAlert("Please verify you are not a robot");
      }
    }
  };

  const handleGoogleCaptcha = async (value) => {
    if (value) {
      setGoogleReCaptchaToken(value);
    }
  };
  return (
    <div>
      <ToastContainer position="bottom-center" />
      <LoadingComponent loaded={loaded} />
      <Modal modalid={modalid} toggle={toggle}>

        <div className="modal-body">
          <div
            className="close-icon"
            onClick={() => setOpenOfflineTypeModal(false)}
            aria-label="Close"
          >
            <div className="close-btn-icon"></div>
          </div>
          <div className="loginModal" style={{ padding: "15px 36px" }}>
            <div className="quote-form-container">
              <form className="custom-form">
                <h4 className="form-title">Offline Quotes</h4>
                <div className="form-group">
                  <label htmlFor="input1">Full Name *</label>
                  <input
                    type="text"
                    name="name"
                    className="inputColor"
                    id="name"
                    value={formValues.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter full name"
                    disabled={isnameDisabled}
                  />

                  {(touched.name || errors.name) && (
                    <div className="errorMessage">{errors.name}</div>
                  )}


                </div>
                <div className="form-group">
                  <label htmlFor="input4">Email *</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={formValues.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter email"
                    disabled={isemailDisabled}
                  />

                  {(touched.email || errors.email) && (
                    <div className="errorMessage">{errors.email}</div>
                  )}


                </div>
                <div className="form-group">
                  <label htmlFor="input2">Organization Name *</label>
                  <input
                    type="text"
                    name="organization"
                    id="organization"
                    value={formValues.organization}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter organization name"
                    disabled={isOrganizationDisabled}
                  />

                  {(touched.organization || errors.organization) && (
                    <div className="errorMessage">{errors.organization}</div>
                  )}


                </div>
                <div className="form-group">
                  <label htmlFor="input3">Phone Number *</label>
                  <PhoneInput
                    type="numbers"
                    placeholder="Enter phone number"
                    country="in"
                    onBlur={handleBlur}
                    inputProps={{
                      name: 'phone', // Setting name attribute for the input
                      id: 'phone'
                    }}
                    onChange={(val, country, e, formattedValue) => {
                      if (
                        country &&
                        ((country.format &&
                          formattedValue &&
                          formattedValue.length === country.format.length) ||
                          val.length <= country.dialCode.length)
                      ) {
                        setIsValidPhone(true);
                        setPhone(val);
                      } else {
                        setIsValidPhone(false);
                        setPhone(val);
                      }
                    }}
                    value={phone}
                    id="phoneNo"
                    containerStyle={{
                      borderBottom: "1px solid #bfc1d7"
                    }}
                  />

                  {(touched.phone || errors.phone) && (
                    <div className="errorMessage">{errors.phone}</div>
                  )}


                </div>

                <div className="button-container center">
                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={handleGoogleCaptcha}
                  ></ReCAPTCHA>
                </div>
                <div className="button-container">
                  <button style={{ marginTop: '0px' }}
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OfflineTypeModal;
