import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';

import { BASE_URL, S3_BASE_URL, axiosApiInstance, failureAlert, successAlert } from "../../helpers/helper";
import { uploadFile } from "../../redux/actions/commonActions";
import { getBannerGames } from "../../redux/actions/homepageActions";
import PromoteGameModal from "./promoteGameModal";

const HomeBannerUpdateCard = ({ setUpdateHomeBanner, setHomeBannerEditDetails, homeBannerEditDetails, selectedBanner, subdomainOrgId, gameList }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({
        imageDesktop: "",
        imageMobile: ""
    });
    const [promoteGameError, setPromoteGameError] = useState("")
    const [submitClicked, setSubmitClicked] = useState(false);
    const [selectedGame, setSelectedGame] = useState({});
    const [showPromoteModal, setShowPromoteModal] = useState({ modal: false, action: false })
    const [activeButton, setActiveButton] = useState('AddBanner');
    const [bannerThumbnail, setBannerThumbnail] = useState('');
    const { userInfo } = useSelector((state) => state.getUser);
    const ref = React.createRef();
    const handleChange = async (e) => {
        e.persist();
        e.preventDefault();
        const file = e.target.files[0];
        if (file && file.type && file.type.startsWith("image/")) {
            const img = new Image();

            let width, height;
            img.onload = function () {
                width = this.width;
                height = this.height;
            }
            const _URL = window.URL || window.webkitURL;
            img.src = _URL.createObjectURL(file);

            const data = new FormData();
            data.append("banner-images", file);
            const response = await dispatch(uploadFile(data));
            if (response && response.status === 200) {
                if (response.data && response.data.data && response.data.data.path) {
                    setHomeBannerEditDetails(prevState => ({
                        ...prevState,
                        [e.target.name]: response.data.data.path,
                        [e.target.name + "Size"]: width + "x" + height
                    }));
                }
            }
            else if (response?.status?.includes?.("417"))
                failureAlert("Uploaded file contains some malware!");
            else if (response?.status?.includes?.("500"))
                failureAlert("File Format Not supported");
        }
    };
    const handleBannerAdd = async (e) => {
        e.preventDefault();
        setSubmitClicked(true);
    };
    useEffect(() => {
        if (submitClicked) {
            const callBack = async () => {
                const validation = validateInput();
                if (JSON.stringify(validation) === JSON.stringify({ imageDesktop: "", imageMobile: "" })) {
                    if (homeBannerEditDetails && userInfo && userInfo.data) {
                        let body;
                        if(homeBannerEditDetails?.bannerId){
                            body = {
                                coverMedia: homeBannerEditDetails.imageDesktop,
                                mobileCoverMedia: homeBannerEditDetails.imageMobile,
                                organizationId: userInfo?.data?.organizationId,
                                // userId: userInfo?.data?.id,
                                forOrganization: userInfo?.data?.organizationId ? true : false,
                                forLoggedIn: userInfo?.data?.id ? true : false,
                                forSubscribed: !userInfo?.data?.isPlanExpired,
    
                            }
                        }
                        else{
                            body = {
                                coverMedia: homeBannerEditDetails.imageDesktop,
                                mobileCoverMedia: homeBannerEditDetails.imageMobile,
                                organizationId: userInfo?.data?.organizationId,
                                // userId: userInfo?.data?.id,
                                forOrganization: userInfo?.data?.organizationId ? true : false,
                                forLoggedIn: userInfo?.data?.id ? true : false,
                                forSubscribed: !userInfo?.data?.isPlanExpired,
                                editedBannerId: homeBannerEditDetails?.editBannerId
                            }
                        }
                        const response = homeBannerEditDetails?.bannerId
                            ? await axiosApiInstance.put(`${BASE_URL}/api/banner/update/${homeBannerEditDetails?.bannerId}`, body)
                            : await axiosApiInstance.post(`${BASE_URL}/api/banner/add`, body);

                        if (response?.status === 200) {
                            setUpdateHomeBanner(false);
                            const subscription = (userInfo?.data?.organizationId) ? "SUBSCRIBED" : "UNSUBSCRIBED"
                            // const subscription = isAdmin ? organizationSubscribedStaus : (userInfo?.data?.organizationId ? "SUBSCRIBED" :"");

                            // await dispatch(getBannerGames(subdomainOrgId ? subdomainOrgId : (userInfo?.data?.organizationId ? userInfo?.data?.organizationId : ''), userInfo ? "LOGIN" : "NONLOGIN", subscription));
                            await dispatch(getBannerGames(subdomainOrgId ? subdomainOrgId : (userInfo?.data?.organizationId ? userInfo?.data?.organizationId : ''), userInfo ? "LOGIN" : "NONLOGIN", subscription, '', false));

                            successAlert(`Banner ${homeBannerEditDetails?.bannerId ? 'Updated' : 'Added'}!`);
                        }
                    }
                }
                else {
                    // setErrors(validation);
                    // setSubmitClicked(false);
                }
            }
            callBack();
        }
    }, [submitClicked]);

    const validateInput = () => {
        const error = {
            imageDesktop: "",
            imageMobile: ""
        };
        if (homeBannerEditDetails.imageDesktop === "" && submitClicked)
            error.imageDesktop = "Please select an image";
        if (homeBannerEditDetails.imageMobile === "" && submitClicked)
            error.imageMobile = "Please select an image";
        if (homeBannerEditDetails.imageDesktopSize !== "1850x420")
            error.imageDesktop = "Please upload image with required size.";
        if (homeBannerEditDetails.imageMobileSize !== "328x140")
            error.imageMobile = "Please upload image with required size.";
        return error;
    };
    useEffect(() => {
        setErrors(validateInput());
    }, [homeBannerEditDetails])

    useEffect(() => {
        if (showPromoteModal.action) {
            promoteGame()
        }
    }, [showPromoteModal])

    const promoteGame = async () => {
        if (selectedGame?.value && !promoteGameError) {
            const getGameBanner = gameList?.find((data) => data.id === selectedGame?.value)
            setPromoteGameError('')
            const subscription = userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired;
            const body = {
                coverMedia: getGameBanner?.desktopBanner,
                mobileCoverMedia: getGameBanner?.mobileBanner,
                organizationId: userInfo?.data?.organizationId,
                forOrganization: userInfo?.data?.organizationId ? true : false,
                forSubscribed: subscription,
                forLoggedIn: userInfo?.data?.id ? true : false
            }
            const response = await axiosApiInstance.post(`${BASE_URL}/api/banner/add`, body)
            if (response?.status === 200) {
                setUpdateHomeBanner(false);
                const subscription = (userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired) ? "SUBSCRIBED" : "UNSUBSCRIBED"
                await dispatch(getBannerGames(subdomainOrgId ? subdomainOrgId : (userInfo?.data?.organizationId ? userInfo?.data?.organizationId : ''), userInfo ? "LOGIN" : "NONLOGIN", subscription),);
                successAlert(`Game Promoted successfully !`);
            }
        }
        else {
            setPromoteGameError(promoteGameError ? "The Game didn't have any banner" : 'Please select an Game')
        }
    }
    const checkGameBanner = (game) => {
        const getGameBanner = gameList?.find((data) => data.id === game?.value)
        if (!getGameBanner?.desktopBanner && !getGameBanner?.mobileBanner) {
            setPromoteGameError("The Game didn't have any banner")
            setBannerThumbnail('')
        }
        else {
            setBannerThumbnail(getGameBanner?.bannerThumbnail)
            setPromoteGameError('')
        }
    }
    return (
        <div className="banner-create-modal" ref={ref} id="banner-create-modal">
            <div className="container BannerUpdateCardModal">
                <div className="close">
                    <span onClick={() => setUpdateHomeBanner(false)}>X</span>
                </div>
                <div className="flex justify-evenly items-center">
                    <div className={`bannerUpdateCardButtons cursor-pointer font-medium text-gray750 text-2xl ${activeButton === 'AddBanner' ? 'bg-yellow-300' : 'bg-white'}`} onClick={() => setActiveButton("AddBanner")}>{`${homeBannerEditDetails?.bannerId ? "Update" : "Add"} Banner`}</div>
                </div>
                {
                    activeButton === 'AddBanner' ?
                        <div className="form">
                            <form onSubmit={(e) => handleBannerAdd(e, setUpdateHomeBanner)}>
                                <div>
                                    <div className="group">
                                        <label htmlFor="image">Desktop*</label>
                                        <div className="form-group" style={{ margin: "0px" }}>
                                            <input
                                                type="file"
                                                name="imageDesktop"
                                                id="bannerImage"
                                                onChange={handleChange}
                                                className="form-control"
                                                accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div
                                            style={{
                                                marginLeft: "10px",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {"Size: 1850 x 420"}
                                        </div>
                                        <div title={selectedBanner?.coverMedia} className="text-end mb-3 w-80 truncate">{selectedBanner?.coverMedia}</div>
                                    </div>
                                    <div
                                        style={{
                                            color: "#F2545B",
                                            "font-family": "Fira Sans",
                                            "font-style": "normal",
                                            "font-weight": "normal",
                                            "font-size": "13px",
                                            marginLeft: "10px",
                                            // marginTop: "0px",
                                            marginBottom: "-10px",
                                        }}
                                    >
                                        {errors.imageDesktop}
                                    </div>
                                    <div className="group">
                                        <label htmlFor="image">Mobile*</label>
                                        <div className="form-group" style={{ margin: "0px" }}>
                                            <input
                                                type="file"
                                                name="imageMobile"
                                                id="bannerImage"
                                                onChange={handleChange}
                                                className="form-control"
                                                accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div
                                            style={{
                                                marginLeft: "10px",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {"Size: 328 x 140"}
                                        </div>
                                        <div title={selectedBanner?.mobileCoverMedia} className="text-end mb-3 w-80 truncate">{selectedBanner?.mobileCoverMedia}</div>
                                    </div>
                                    <div
                                        style={{
                                            color: "#F2545B",
                                            "font-family": "Fira Sans",
                                            "font-style": "normal",
                                            "font-weight": "normal",
                                            "font-size": "13px",
                                            marginLeft: "10px",
                                            // marginTop: "-10px",
                                            marginBottom: "-10px",
                                        }}
                                    >
                                        {errors.imageMobile}
                                    </div>
                                    <div className="form-group" style={{ marginTop: "20px" }}>
                                        <button className="btn btn-primary">UPLOAD</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        :
                        <div className="m-5 ">
                            <div className="flex items-start justify-between">
                                <div className="w-80">
                                    <Select
                                        options={gameList.map((game) => ({
                                            label: game.title,
                                            value: game.id,
                                        }))}
                                        onChange={(game) => {
                                            setSelectedGame(game)
                                            checkGameBanner(game)
                                        }}
                                        value={selectedGame?.label && selectedGame}
                                        className="form-control mx-2 ml-0"
                                        placeholder="Select a Game"
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                height: 35,
                                                minWidth: 200,
                                                boxSizing: "content-box",
                                                marginLeft: 0
                                            }),
                                            menu: (base) => ({
                                                ...base,
                                                // width: 300
                                            })
                                        }}
                                    />
                                    <div
                                        style={{
                                            color: "#F2545B",
                                            "font-family": "Fira Sans",
                                            "font-style": "normal",
                                            "font-weight": "normal",
                                            "font-size": "13px",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        {promoteGameError}
                                    </div>
                                </div>
                                <div className="bg-white promoteGameThumbnail bg-white-300">
                                    {bannerThumbnail
                                        &&
                                        (
                                            bannerThumbnail.includes('.mp4') ?
                                                <video controls autoPlay muted id="video_banner"
                                                >
                                                    <source
                                                        src={S3_BASE_URL + bannerThumbnail}
                                                        type="video/mp4"
                                                    />
                                                    Your browser does not support the video tag.
                                                </video>
                                                :
                                                <img src={S3_BASE_URL + bannerThumbnail} alt="bannerThumbnail" />
                                        )
                                    }
                                </div>

                            </div>
                            <div className="form-group" style={{ marginTop: "20px" }}>
                                <button className="btn btn-primary" onClick={() => promoteGame()}>Promote Game</button>
                            </div>
                        </div>
                }
            </div>
            {showPromoteModal.modal && selectedGame?.value && !promoteGameError && <PromoteGameModal setShowPromoteModal={setShowPromoteModal} />}
        </div>
    );
}

export default HomeBannerUpdateCard