import React, { useState, useEffect, useRef } from 'react';
import './statistics.css'; // Ensure this file contains your styles

const StatisticItem = ({ target, text }) => {
  const [count, setCount] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);
  const ref = useRef(null);

  const animateCounter = () => {
    let start = 0;
    const end = target;
    const duration = 2000; // Duration of animation in ms
    const stepTime = 50; // Time between steps in ms
    const steps = duration / stepTime;
    const stepValue = end / steps;

    const timer = setInterval(() => {
      start += stepValue;
      if (start >= end) {
        setCount(end);
        clearInterval(timer);

        // Reset the animation after 5 seconds
        setTimeout(() => {
          setCount(0);
          setHasAnimated(false); // Allows the animation to run again
        }, 5000); // Wait 5 seconds before resetting
      } else {
        setCount(Math.ceil(start));
      }
    }, stepTime);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setHasAnimated(true);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [hasAnimated]);

  useEffect(() => {
    if (hasAnimated) {
      animateCounter();
    }
  }, [hasAnimated]);

  return (
    <div className="statistics-item" ref={ref}>
      <div className="number-container">
        <span className="number">{count}</span>
        <span className="plus-sign">+</span>
      </div>
      <div className="text">{text}</div>
    </div>
  );
};

const Statistics = ({ statisticsData }) => {
  return (
    <div className="games-statistics-container">
      {statisticsData.map((item, index) => (
        <StatisticItem key={index} target={item.target} text={item.text} />
      ))}
    </div>
  );
};

export default Statistics;