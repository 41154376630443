import React from 'react';

import './modal.css';
import Modal from './modal';

import paymentsucess from '../../assets/images/paymentsucess.gif';
import thankyou from '../../assets/images/thankyou.svg';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/actions/userAction';


const OfflineQuoteSuccess1 = ({ toggle,closeCommentModal,data}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const handleClick = () => {
    closeCommentModal(false);
  
  }

  return (
    <Modal modalid="8" toggle={toggle}>
      <div className="modal-body">
        <div className="close-icon" data-dismiss="modal" aria-label="Close" onClick={handleClick}>
          <div className="close-btn-icon"></div>
        </div>
        <div className="thankyou-body success">
   
        <h6 className="thank-you-heading"> {data.Name}  </h6>
        <span className="f-14 p-0">{data.Comment} </span>
        
        </div>
      </div>
    </Modal>
  );
};
export default OfflineQuoteSuccess1;
