import React, { useEffect, useState } from 'react';
import './featureBanner.css'; // Include your styles here
import { uploadFile } from "../../redux/actions/commonActions";
import { BASE_URL, S3_BASE_URL, failureAlert, axiosApiInstance, successAlert } from "../../helpers/helper";
import DataTable from "react-data-table-component";
import { getAllFeatureBanners } from "../../redux/actions/featureBannerActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { ToastContainer } from "react-toastify";

const FeatureBanner = () => {
  const dispatch = useDispatch();
  const AllFeatureBanners = useSelector(state => state.featureBanners);
  const [rows, setRows] = useState([{ images: [null, null], errors: ['', ''] }]);
  const [featureBanners, setFeatureBanners] = useState([]);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    dispatch(getAllFeatureBanners(""));
  }, [dispatch]);

  useEffect(() => {
    setFeatureBanners(AllFeatureBanners?.featureBanner || []);
  }, [AllFeatureBanners]);

  const addRow = () => {
    setRows([...rows, { images: [null, null], errors: ['', ''] }]);
  };

  const handleDelete = async (row) => {
    const { id, mobileBanner, desktopBanner } = row;
    const banners = [mobileBanner, desktopBanner];

    if (banners.length > 0) {
      setLoaded(false);
      try {
        await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", banners);
        await axiosApiInstance.delete(`${BASE_URL}/api/add-feature-banner/deleteBannerInfo/${id}`);
        dispatch(getAllFeatureBanners(""));
        successAlert("Banners deleted successfully");
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        failureAlert("Failed to delete banner");
      } finally {
        setLoaded(true);
      }
    }
  };

  const validateImageDimensions = (file, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(file);

      img.onload = () => {
        URL.revokeObjectURL(url);
        if (img.width === expectedWidth && img.height === expectedHeight) {
          resolve(true);
        } else {
          reject(`Image dimensions should be ${expectedWidth}x${expectedHeight}`);
        }
      };

      img.onerror = () => {
        URL.revokeObjectURL(url);
        reject('Error loading image');
      };

      img.src = url;
    });
  };

  const validateVideoDimensions = (file, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      const url = URL.createObjectURL(file);

      video.onloadedmetadata = () => {
        URL.revokeObjectURL(url);
        if (video.videoWidth === expectedWidth && video.videoHeight === expectedHeight) {
          resolve(true);
        } else {
          reject(`Video dimensions should be ${expectedWidth}x${expectedHeight}`);
        }
      };

      video.onerror = () => {
        URL.revokeObjectURL(url);
        reject('Error loading video');
      };

      video.src = url;
    });
  };

  const handleFileUpload = async (rowIndex, columnIndex, event) => {
    event.preventDefault();
    const file = event.target.files[0];
    event.target.value = null;

    if (file) {
      try {
        let expectedWidth, expectedHeight;

        // if (file.type.startsWith('image/')) {
        //   expectedWidth = columnIndex === 0 ? 410 : 1440;
        //   expectedHeight = columnIndex === 0 ? 760 : 901;
        //   await validateImageDimensions(file, expectedWidth, expectedHeight);
        // } else if (file.type === 'video/mp4') {
        //   expectedWidth = columnIndex === 0 ? 410 : 1440;
        //   expectedHeight = columnIndex === 0 ? 760 : 901;
        //   await validateVideoDimensions(file, expectedWidth, expectedHeight);
        // }

        setRows(rows.map((row, index) =>
          index === rowIndex
            ? { ...row, images: row.images.map((img, i) => i === columnIndex ? file : img) }
            : row
        ));
      } catch (error) {
        setRows(rows.map((row, index) =>
          index === rowIndex
            ? { ...row, errors: row.errors.map((err, i) => i === columnIndex ? error : err) }
            : row
        ));
      }
    }
  };

  const removeRow = (rowIndex) => {
    setRows(rows.filter((_, index) => index !== rowIndex));
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("banner-images", file);

    try {
      const response = await dispatch(uploadFile(data));
      return response.data.data.path;
    } catch (error) {
      console.error('Upload failed:', error);
      return null;
    }
  };

  const uploadVideo = async (file) => {
    const data = new FormData();
    data.append("videobanners", file);

    try {
      const response = await dispatch(uploadFile(data));
      return response.data.data.path;
    } catch (error) {
      console.error('Upload failed:', error);
      return null;
    }
  };

  const handleSubmit = async () => {
    setLoaded(false);

    // Validate if all rows have files and if file sizes are acceptable
    const validationErrors = rows.map(row => {
      const errors = [];
      row.images.forEach((img, index) => {
        if (!img) {
          errors[index] = 'Required';
        } else if (img.size > 20 * 1024 * 1024) { // 20 MB
          errors[index] = 'File size should not exceed 20 MB';
        } else {
          errors[index] = '';
        }
      });
      return { ...row, errors, hasErrors: errors.some(error => error !== '') };
    });

    setRows(validationErrors);

    if (validationErrors.some(row => row.hasErrors) || rows.length === 0) {
      setLoaded(true);
      return;
    }

    try {
      const uploadPromises = rows.map(async (row) => {
        const [file1Path, file2Path] = await Promise.all([
          row.images[0] ? (row.images[0].type.startsWith('image/') ? uploadImage(row.images[0]) : uploadVideo(row.images[0])) : null,
          row.images[1] ? (row.images[1].type.startsWith('image/') ? uploadImage(row.images[1]) : uploadVideo(row.images[1])) : null
        ]);

        return {
          mobileBannerPath: file1Path,
          desktopBannerPath: file2Path
        };
      });

      const fileObjects = await Promise.all(uploadPromises);
      await axiosApiInstance.post(BASE_URL + "/api/add-feature-banner/add", fileObjects);
      dispatch(getAllFeatureBanners(""));
      setRows([{ images: [null, null], errors: ['', ''] }]);
      successAlert("Banners added successfully");
    } catch (error) {
      console.log(error);
      failureAlert("Failed to add request");
    } finally {
      setLoaded(true);
    }
  };

  const columns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      center: true
    },
    {
      name: 'Mobile Banner',
      cell: row => {
        const isVideo = row.mobileBanner.endsWith('.mp4');
        return isVideo ? (
          <video width="200" height="100" controls>
            <source src={S3_BASE_URL + row.mobileBanner} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={S3_BASE_URL + row.mobileBanner} alt="Mobile Banner" style={{ width: '200px', height: '100px', objectFit: 'cover' }} />
        );
      },
      sortable: true
    },
    {
      name: 'Desktop Banner',
      cell: row => {
        const isVideo = row.desktopBanner.endsWith('.mp4');
        return isVideo ? (
          <video width="200" height="100" controls>
            <source src={S3_BASE_URL + row.desktopBanner} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={S3_BASE_URL + row.desktopBanner} alt="Desktop Banner" style={{ width: '200px', height: '100px', objectFit: 'cover' }} />
        );
      },
      sortable: true
    },
    {
      name: 'Created At',
      selector: row => new Date(row.createdAt).toLocaleString(),
      sortable: true
    },
    {
      name: 'Actions',
      cell: row => (
        <button onClick={() => handleDelete(row)} style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer', borderRadius: '6px' }}>
          Delete
        </button>
      ),
      center: true
    }
  ];

  const customStyles = {
    headCells: {
      style: {
        "font-family": "firaSans-semibold",
        "font-size": "15px",
        "border-bottom": "1px solid Black",
        "border-top": "1px solid Black"
      }
    },
    cells: {
      style: {
        "font-family": "firaSans-regular",
        "font-size": "14px",
        "text-align": "center"
      }
    },
    header: {
      style: {
        "font-family": "firaSans-semibold",
        color: "#737373"
      }
    }
  };

  return (
    <>
      <LoadingComponent loaded={loaded} />
      <ToastContainer position="bottom-center" />
      <div className="container-feature">
        <div className="formRow">
          <div className="form-column">
            <h3 className="file-title">Mobile Banner</h3>
            <h4 className="errMsg">410 x 760</h4>
          </div>
          <div className="form-column">
            <h3 className="file-title">Desktop Banner</h3>
            <h4 className="errMsg">1440 x 901</h4>
          </div>
          <div className="form-column remove-column">
            <button onClick={addRow} className="add-row-button">Add Row</button>
          </div>
        </div>
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} className="formRow">
            <div className="form-column">
              <input
                type="file"
                accept="image/x-png,image/jpg,image/jpeg,image/gif,video/mp4"
                onChange={(e) => handleFileUpload(rowIndex, 0, e)}
              />
              {row.images[0] && row.images[0].type.startsWith('image/') && (
                <img
                  src={URL.createObjectURL(row.images[0])}
                  alt="preview"
                  className="preview-image"
                />
              )}
              {row.images[0] && row.images[0].type === 'video/mp4' && (
                <video width="300" controls>
                  <source src={URL.createObjectURL(row.images[0])} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              {row.errors[0] && <div className="errMsg">{row.errors[0]}</div>}
            </div>
            <div className="form-column">
              <input
                type="file"
                accept="image/x-png,image/jpg,image/jpeg,image/gif,video/mp4"
                onChange={(e) => handleFileUpload(rowIndex, 1, e)}
              />
              {row.images[1] && row.images[1].type.startsWith('image/') && (
                <img
                  src={URL.createObjectURL(row.images[1])}
                  alt="preview"
                  className="preview-image"
                />
              )}
              {row.images[1] && row.images[1].type === 'video/mp4' && (
                <video width="300" controls>
                  <source src={URL.createObjectURL(row.images[1])} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              {row.errors[1] && <div className="errMsg">{row.errors[1]}</div>}
            </div>
            <div className="form-column remove-column">
              <button onClick={() => removeRow(rowIndex)} className="remove-button">Remove</button>
            </div>
          </div>
        ))}
        <div className="formRow">
          <button onClick={handleSubmit} className="add-row-button" style={{ marginTop: '1rem' }}>Submit</button>
        </div>
        <div className="formRow" style={{ display: 'Block', marginTop: '1rem' }}>
          <DataTable
            title="Banners Uploaded"
            columns={columns}
            data={featureBanners}
            className="rdt_TableHead"
            responsive
            striped
            highlightOnHover
            pagination
            customStyles={customStyles}
          />
        </div>
      </div>
    </>
  );
};

export default FeatureBanner;
