import * as ActionTypes from "../constants/adminApiConstants";

function getUsersCountReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_USERS_COUNT_REQUEST:
      return { loading: true };
    case ActionTypes.GET_USERS_COUNT_SUCCESS:
      return { loading: false, usersCount: action.payload };
    case ActionTypes.GET_USERS_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
function downloadRegisteredUsersReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.DOWNLOAD_REGISTERED_USERS_REQUEST:
      return { loading: true };
    case ActionTypes.DOWNLOAD_REGISTERED_USERS_SUCCESS:
      return { loading: false, registeredUsersData: action.payload };
    case ActionTypes.DOWNLOAD_REGISTERED_USERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
function downloadSubscribedUsersReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.DOWNLOAD_SUBSCRIBED_USERS_REQUEST:
      return { loading: true };
    case ActionTypes.DOWNLOAD_SUBSCRIBED_USERS_SUCCESS:
      return { loading: false, subscribedUsersData: action.payload };
    case ActionTypes.DOWNLOAD_SUBSCRIBED_USERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
function updateSSOdetailsReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_SSO_DETAILS_REQUEST:
      return { loading: true };
    case ActionTypes.UPDATE_SSO_DETAILS_SUCCESS:
      return { loading: false, updatedSSODetails: action.payload };
    case ActionTypes.UPDATE_SSO_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
function getDiwaliClaimReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_DIWALI_CLAIMS_REQUEST:
      return { loading: true };
    case ActionTypes.GET_DIWALI_CLAIMS_SUCCESS:
      return { loading: false, diwaliClaims: action.payload };
    case ActionTypes.GET_DIWALI_CLAIMS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function getGameLinksReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_DIWALI_GAME_LINK_INFO_REQUEST:
      return { loading: true };
    case ActionTypes.GET_DIWALI_GAME_LINK_INFO_SUCCESS:
      return { loading: false, gameLinksInfo: action.payload };
    case ActionTypes.GET_DIWALI_GAME_LINK_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
function updateGameLinksReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_DDDT_LINKS_REQUEST:
      return { loading: true };
    case ActionTypes.GET_DDDT_LINKS_SUCCESS:
      return { loading: false, newGameLinksInfo: action.payload };
    case ActionTypes.GET_DDDT_LINKS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function toggleGameLinksActiveReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_REQUEST:
      return { loading: true };
    case ActionTypes.TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_SUCCESS:
      return { loading: false };
    case ActionTypes.TOGGLE_DIWALI_GAME_LINK_ACTIVE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}


function downloadGameLinkReportReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_DDDT_LINKS_GAME_REPORT_REQUEST:
      return { loading: true };
    case ActionTypes.GET_DDDT_LINKS_GAME_REPORT_SUCCESS:
      return { loading: false, downloadedDemoRequests: action.payload };
    case ActionTypes.GET_DDDT_LINKS_GAME_REPORT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function addXoxodayGameReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.XOXODAY_GAME_ADD_REQUEST:
      return { loading: true };
    case ActionTypes.XOXODAY_GAME_ADD_SUCCESS:
      return { loading: false, downloadedDemoRequests: action.payload };
    case ActionTypes.XOXODAY_GAME_ADD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function getXoxodayGameReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.XOXODAY_GAMES_GET_REQUEST:
      return { loading: true };
    case ActionTypes.XOXODAY_GAMES_GET_SUCCESS:
      return { loading: false, xoxoGames: action.payload };
    case ActionTypes.XOXODAY_GAMES_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function getXoxodayActiveGamesReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.XOXODAY_ORGANIZATION_ACTIVE_GAMES_GET_REQUEST:
      return { loading: true };
    case ActionTypes.XOXODAY_ORGANIZATION_ACTIVE_GAMES_SUCCESS:
      return { loading: false, xoxodayOrgActiveGames: action.payload };
    case ActionTypes.XOXODAY_ORGANIZATION_ACTIVE_GAMES_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function getUsersPointsReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_USERS_POINTS_REQUEST:
      return { loading: true };
    case ActionTypes.GET_USERS_POINTS_SUCCESS:
      return { loading: false, xoxoUserPoints: action.payload };
    case ActionTypes.GET_USERS_POINTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function pollQuestionPublishReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.PUBLISH_POLL_QUESTION_REQUEST:
      return { loading: true };
    case ActionTypes.PUBLISH_POLL_QUESTION_SUCCESS:
      return { loading: false, xoxoUserPoints: action.payload };
    case ActionTypes.PUBLISH_POLL_QUESTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  getUsersCountReducer,
  downloadRegisteredUsersReducer,
  downloadSubscribedUsersReducer,
  updateSSOdetailsReducer,
  getDiwaliClaimReducer,
  getGameLinksReducer,
  updateGameLinksReducer,
  toggleGameLinksActiveReducer,
  downloadGameLinkReportReducer,
  addXoxodayGameReducer,
  getXoxodayGameReducer,
  getXoxodayActiveGamesReducer,
  getUsersPointsReducer,
  pollQuestionPublishReducer
}
