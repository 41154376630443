import * as ActionTypes from "../constants/diwaliGameApiConstants";

import { axiosApiInstance, BASE_URL, decryptData, encryptData, GetToken, GetUserType } from "../../helpers/helper";

const addWordsArr = (wordsList) => async (dispatch) => {
  dispatch({ type: ActionTypes.DIWALI_GAME_UPDATE_REQUEST });
  try {
    const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/add-words", wordsList);
    dispatch({ type: ActionTypes.DIWALI_GAME_UPDATE_SUCCESS, payload: data });
    return status;
  } catch (error) {
    dispatch({
      type: ActionTypes.DIWALI_GAME_UPDATE_FAIL,
      payload: error?.response?.data?.message,
    });
    return { message: error?.response?.data?.message };
  }
};

const upadteReleasedWordsList = (wordsList) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_RELEASED_WORDS_LIST_REQUEST });
  try {
    const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/game/diwali/released-words", wordsList);
    dispatch({ type: ActionTypes.GET_RELEASED_WORDS_LIST_SUCCESS, payload: data?.data });
    return status;
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_RELEASED_WORDS_LIST_FAIL,
      payload: error?.response?.data?.message,
    });
    return { message: error?.response?.data?.message };
  }
};

const getWordsListArr = () => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_WORDS_LIST_REQUEST });
  try {
    const { data, status } = await axiosApiInstance.get(BASE_URL + "/api/game/diwali/get-words-tobe-released");
    dispatch({ type: ActionTypes.GET_WORDS_LIST_SUCCESS, payload: data?.data });
    return status;
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_WORDS_LIST_FAIL,
      payload: error?.response?.data?.message,
    });
    return { message: error?.response?.data?.message };
  }
};

const getDiwaliSettings = () => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_DIWALI_SETTINGS_REQUEST });
  try {
    const { data, status } = await axiosApiInstance.get(BASE_URL + "/api/game/diwali/get-dhoom-settings");
    dispatch({ type: ActionTypes.GET_DIWALI_SETTINGS_SUCCESS, payload: data?.data });
    return status;
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_DIWALI_SETTINGS_FAIL,
      payload: error?.response?.data?.message,
    });
    return { message: error?.response?.data?.message };
  }
};

const updateDiwaliSettings = (settings) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_DIWALI_SETTINGS_REQUEST });
  try {
    const { data, status } = await axiosApiInstance.put(BASE_URL + "/api/game/diwali/update-dhoom-settings",settings);
    dispatch({ type: ActionTypes.GET_DIWALI_SETTINGS_SUCCESS, payload: data?.data });
    return status;
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_DIWALI_SETTINGS_FAIL,
      payload: error?.response?.data?.message,
    });
    return { message: error?.response?.data?.message };
  }
};

export {
  addWordsArr,
  getWordsListArr,
  upadteReleasedWordsList,
  getDiwaliSettings,
  updateDiwaliSettings
}