import Filter from "bad-words";
import Picker from 'emoji-picker-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { io } from 'socket.io-client';

import smile from "../assets/images/smileoutline.png";
import { BASE_URL, failureAlert } from '../helpers/helper';
import { Words } from '../helpers/profanewords';

const filter = new Filter();
Words.forEach(word => {
    filter.addWords(word);
})

const ChatBox = ({ classPrefix, backupChats, isChat, onLaughEmojiClick ,fromGameChat, userType, email, orgId}) => {
    const authToken = localStorage.getItem('isAdmin')

    const [content, setContent] = useState("");
    const [chats, setChats] = useState([]);
    const [chatsToShow, setChatsToShow] = useState([]);
    const [showEmojiPanel, setShowEmojiPanel] = useState(false);
    const [currentCountToShow, setCurrentCountToShow] = useState(15);
    var colors = ['col-943d24', 'col-9b45b2', 'col-73a24e', 'col-f0a04b', 'col-f0a04b'];

    const chatLogRef = useRef();

    useEffect(() => {
        const socket = io(`${BASE_URL}`, {
            auth: { token: authToken }
        });
        socket?.emit("getGameChat");
        socket?.on("gameChatsMessage", (data) => {
            setChats(data);
        })
        // return () => {
        //     if (socket)
        //         socket.disconnect();
        // }
    }, [chats])

    useEffect(() => {
        document.addEventListener("mouseup", eventhandle, false);
        return () => {
            document.removeEventListener("mouseup", eventhandle.apply, false);
        }
    }, [])

    const eventhandle = (e) => {
        const element = document.getElementById("chat-form")
        if (element) {
            var isClickInsideElement = element.contains(e.target);
            if (!isClickInsideElement)
                setShowEmojiPanel(false);
        }
    }

    const handleChange = (e) => {
        setContent(e.target.value);
    }

    const handleSend = (e) => {
        e.preventDefault();
        if (content.length === 0)
            return;
        setShowEmojiPanel(false);
        if (email && orgId) {
            const socket = io(`${BASE_URL}`, {
                auth: { token: authToken }
            });
            // if (window.socket?.connected)
                socket.emit("gameChats", { userType, email, orgId, message: content });
            // else {
            //     const data = {
            //         ...details,
            //         chats: [...backupChats?.current?.chats, { ...details, message: content, createdAt: (new Date()).toISOString() }]
            //     }
            //     backupChats.current = data;
            // }
        }
        else
            failureAlert("Something is wrong!");
        setContent("");
    }

    useEffect(() => {
        var objDiv = document.getElementById("chat-log");
        if (objDiv)
            objDiv.scrollTop = 10000000000;
        if (chatLogRef.current)
            chatLogRef.current.scrollTop = 10000000000;
        // const messages = document.getElementsByClassName("message");
    }, [chats, backupChats?.current]);

    useEffect(() => {
        if (chats) {
            let data = [];
            if (chats.length - 15 >= currentCountToShow)
                data = chats.slice(chats.length - currentCountToShow, chats.length)
            else
                data = [...chats];
            setChatsToShow(data);
        }
    }, [chats, currentCountToShow]);

    const scrollHandler = (e) => {
        if (e.target.scrollTop <= 20) {
            if (currentCountToShow <= chats.length - 15) {
                setCurrentCountToShow(currentCountToShow + 15);
                e.target.scrollTop = 20
            }
            else
                setCurrentCountToShow(chats.length);
        }
    }

    const emojiClicked = (e, obj) => {
        setContent(prevState => (
            prevState + obj.emoji
        ))
    }
    const showFiltered = (message) => {
        let list = message.split(" ");
        let newlist = list.map(word => {
            try {
                return filter.clean(word);
            } catch (err) {
                return word;
            }
        })
        return newlist.join(" ");
    }
    return (
        <div className={classPrefix + " gameChatContainer chat-container"} style={{height:`${fromGameChat ?"400px":""}`}}>
            {/* style={{position: 'absolute', top: '-30px'}}  */}
            {/* <img src={chatboxIce} id="chatBoxIce" />      */}
            <ToastContainer position='bottom-center' />
            <div className="chat-log" id="chat-log" onScroll={scrollHandler} ref={chatLogRef}>
                {
                    chatsToShow.length > 0 ?
                        chatsToShow.map((chat, index) => (
                            <div key={JSON.stringify(chat.id)} className={`message ${isChat ? "" : "emojies"}  ${chat.email === email? "self" : ""}`}>
                                <span className={`name ${colors[index % 5]}`}>{ chat?.firstName?.split('@')[0] ||chat?.email?.substr(0, 5)}</span>
                                {
                                    !isChat &&
                                    <span className={`name ${colors[index % 5]}`}>:</span>
                                }
                                <span className="content">{showFiltered(chat.message)}</span>
                            </div>
                        ))
                        : backupChats?.current?.chats?.length > 0 ?
                            backupChats.current?.chats?.map((chat, index) => (
                                <div key={JSON.stringify(chat.id)} className={`message ${isChat ? "" : "emojies"}  ${chat.email === email ? "self" : ""}`}>
                                    <span className={`name ${colors[index % 5]}`}>{chat.userName}</span>
                                    {
                                        !isChat &&
                                        <span className={`name ${colors[index % 5]}`}>:</span>
                                    }
                                    <span className="content">{showFiltered(chat.message)}</span>
                                </div>
                            )) :
                            <div className="no-messages">
                                <h4>No Messages Yet!</h4>
                            </div>
                }
            </div>
            {
                    <form onSubmit={handleSend} id="chat-form">
                        {/* <img src={chatBoxSendIcon} alt="" id='emoji-btn' onClick={() => setShowEmojiPanel(prevState => !prevState)} /> */}
                        <Picker
                            pickerStyle={{ position: "absolute", bottom: 80, borderRadius: 0, width: "100%", height: "200px", display: showEmojiPanel ? "inherit" : "none", background: '#100829da' }}
                            disableSearchBar
                            onEmojiClick={emojiClicked}
                        />
                        <div className="form-group">
                            <input style={{ backgroundColor: '#100829da' }} type="text" className="form-field ml-4" id="webinar-chatbox" value={content} onChange={handleChange} placeholder={`Chat here`} />
                            <img src={smile} alt="" className='emoji-btn' onClick={() => setShowEmojiPanel(prevState => !prevState)} />
                        </div>
                        <button style={{ backgroundColor: '#100829da', borderRadius: '8px' }} className={`chatBtn  ${content.length > 0 ? "" : "disabled"}`}>
                            <img src="https://extramile-static-files.s3.ap-south-1.amazonaws.com/CHAT_BUTTON.gif" alt="" />
                        </button>
                        <div className="reaction p-2" style={{ cursor: 'pointer' }} onClick={() => onLaughEmojiClick()}>&#128514;</div>
                    </form> 
            }
        </div>
    )
}

export default ChatBox