export const ADD_QUESTION_REQUEST = "ADD_QUESTION_REQUEST";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL";

export const GET_EVENT_QUESTIONS_REQUEST = "GET_EVENT_QUESTIONS_REQUEST";
export const GET_EVENT_QUESTIONS_SUCCESS = "GET_EVENT_QUESTIONS_SUCCESS";
export const GET_EVENT_QUESTIONS_FAIL = "GET_EVENT_QUESTIONS_FAIL";

export const GET_EVENT_BOOKING_REQUEST = "GET_EVENT_BOOKING_REQUEST";
export const GET_EVENT_BOOKING_SUCCESS = "GET_EVENT_BOOKING_SUCCESS";
export const GET_EVENT_BOOKING_FAIL = "GET_EVENT_BOOKING_FAIL";
