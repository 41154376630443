import * as ActionTypes from "../constants/xoxoApiConstants";
import { axiosApiInstance, BASE_URL } from "../../helpers/helper";




const getSSORedirection = (body) => async (dispatch) => {
  dispatch({ type: ActionTypes.SSO_FETCHED_REQUEST });
  try {
    const { data ,status} = await axiosApiInstance.post(BASE_URL + "/api/xoxoday-game/sso",body);
    dispatch({ type: ActionTypes.SSO_FETCHED_SUCCESSFULLY, payload: data, });
    return { status, data };
  } catch (error) {
    dispatch({ type: ActionTypes.SSO_FETCH_FAILED, payload: error.message, });
    return error;
  }
};



export {
  getSSORedirection,
};
