import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./organisationuser.css";

import deleteIconAdmin from "../../assets/images/deleteIconAdmin.svg";
import search from "../../assets/images/search.svg";
import ROLES from "../../helpers/userTypes";
import arrowleft from "../../assets/images/paginationarrow.svg";
import arrowback from "../../assets/images/arrow-back.svg";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import PageLayout from "../../components/pagelayout/pagelayout";
import { deleteUsersBulk, logOut } from "../../redux/actions/userAction";
import { getUserByOrganisation } from "../../redux/actions/organisationActions";
import { useEffect } from "react";
import useDebouncedSearch from "../../helpers/debounce";
import { toggleOrgUserByAdmin } from "../../redux/actions/organisationActions";
import { getAllOrganisations } from "../../redux/actions/organisationActions";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { BASE_URL, IsAdmin, axiosApiInstance, successAlert } from "../../helpers/helper";
import ManageTeamDeleteUserModal from "../../components/modal/manageTeamDeleteUser";
import Select from "react-select";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getAllGames } from "../../redux/actions/homepageActions";
import { addXoxodayGame, getXoxoOrgActiveGamesList, getXoxodayEnabledStatus } from "../../redux/actions/adminApiActions";


const SelectItem = ({ name, id, selectedGames, setSelectedGames }) => {
  const add = () => {
    setSelectedGames([...selectedGames, id]);
  }
  const remove = () => {
    let games = [...selectedGames]
    games = games.filter(game => game !== id);
    setSelectedGames(games);
  }
  if (name === "Select Games")
    return (
      <span>{name}</span>
    )
  return (
    <div className="add-default-select-item">
      <h4>{name}</h4>
      <span>
        {
          selectedGames.includes(id) ?
            <input type="checkbox" defaultChecked onChange={remove} />
            : <input type="checkbox" onChange={add} />
        }
      </span>
    </div>
  )
}


const OrganisationUsers = (props) => {
  const { id } = useParams();
  const { allGames } = useSelector(state => state.allGames);
  const [loaded, setLoaded] = useState(true);
  const [isXoxo, setXoxo] = useState("");
  const [isParticipation, setParticipation] = useState(false);
  const [isWinner, setWinner] = useState(false);
  const [selectedGames, setSelectedGames] = useState("");
  const [selectedActiveGames, setSelectedActiveGames] = useState([]);
  const [clientId, setClientId] = useState("");
  const [gameOptions, setGameOptions] = useState([]);
  const [gameData, setGameData] = useState({ minumum: 0, maximum: 0, points: 0 });
  const useSearch = () => useDebouncedSearch((text) => searchDetails(text));
  const searchDetails = (text) => {
    dispatch(getUserByOrganisation(props.match.params.id, text));
  };
  const [orgDisabled, setOrgDisabled] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [deleteUser, setDeleteUser] = useState({});
  const { searchText, setSearchText, results } = useSearch();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const isAdmin = IsAdmin();

  useEffect(() => {
    dispatch(getUserByOrganisation(props.match.params.id, searchText, page));
  }, [page]);

  const UsersByOrganisation = useSelector((state) => state.usersByOrganisation);
  const { usersByOrganisation } = UsersByOrganisation;
  const AllOrganisationData = useSelector(state => state.allOrganisationData);
  const { allOrganisationData } = AllOrganisationData;
  const DeletedUsers = useSelector(state => state.deletedUsers);
  const [ownerCount, setOwnerCount] = useState(0);
  // Active organization game
  const orgActiveGamesList = useSelector(state => state.xoxoOrganizationActiveGames)
  const { xoxodayOrgActiveGames } = orgActiveGamesList;
  const handleChange = (e) => {
    setSearchText(e.target.value);
    setPage(1);
    // if (e.target.value.length === 0) {
    //   setTimeout(() => {
    //     dispatch(getUserByOrganisation(props.match.params.id, ""));
    //   }, 500)
    // }
  };
  useEffect(() => {
    if (!allOrganisationData) {
      dispatch(getAllOrganisations(''));
    }
  }, []);

  useEffect(() => {
    if (xoxodayOrgActiveGames?.data?.length) {
      setSelectedActiveGames(xoxodayOrgActiveGames?.data.map((data)=>data?.gameId))
    }
  }, [xoxodayOrgActiveGames?.data]);

  useEffect(() => {
    if (allOrganisationData && allOrganisationData.data) {
      let orgObject = allOrganisationData.data.allOrganisationDetails.find((org) => org.organizationId === props.match.params.id);
      setOrgDisabled(!orgObject.isActive);
    }
  }, [allOrganisationData]);

  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };
  useEffect(() => {
    if (
      (UsersByOrganisation && UsersByOrganisation.loading) ||
      (results && results.loading) ||
      (DeletedUsers && DeletedUsers.loading)
    ) {
      setLoaded(false);
    } else {
      setLoaded(true);
    }
  }, [UsersByOrganisation, results, DeletedUsers]);

  useEffect(() => {
    if(usersByOrganisation?.data){
      setXoxo(usersByOrganisation?.data?.isXoxoEnabled)
    }
    if (usersByOrganisation?.data?.users) {
      let count = 0;
      usersByOrganisation.data.users.map((user) => {
        if (user.role === ROLES.ORG_SUPER_ADMIN)
          count += 1;
      })
      setOwnerCount(count);
    }
  }, [usersByOrganisation])

  const handleToggleUser = async (userId, isActive) => {
    if (orgDisabled) {
      return
    }
    const resStatus = await dispatch(toggleOrgUserByAdmin(userId, isActive));
    if (resStatus === 200) {
      dispatch(getUserByOrganisation(props.match.params.id, ""));
    }
  };
  const handleUserDelete = (e) => {
    setOpenDeleteUserModal(true);
    setDeleteUser(e);
  }
  const handleDeleteUser = async () => {
    const response = await dispatch(deleteUsersBulk({ users: [{ id: deleteUser.id }] }));
    if (response === 200) {
      dispatch(getUserByOrganisation(props.match.params.id, ""));
      successAlert("User Deleted Successfully!");
      setOpenDeleteUserModal(false);
    }
  }


  useEffect(() => {
    dispatch(getAllGames());
  }, [])

  useEffect(() => {

    if (allGames && allGames.data) {
      let options = [];
      allGames.data.forEach((game) => {
        if (game.isxoxodays === true) {
          options.push({ value: game.title, label: game.title, id: game.id });
        }
      })
      setGameOptions(options);
    }
  }, [allGames]);

  const selectStyles = {
    control: (base => ({
      ...base,
      height: 45,
      background: '#f6f7f9',
      border: 0,
      fontSize: 14
    })),
    valueContainer: (base => ({
      ...base,
      height: 45
    })),
    input: (base => ({
      ...base,
      height: 45
    })),
    indicatorsContainer: (base => ({
      ...base,
      height: 45
    })),
    placeholder: (base => ({
      ...base,
      fontSize: 13
    }))
  }

  const successAlert = (content) => {
    toast.success(content, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        pauseOnFocusLoss: false
    });
}
const failureAlert = (content) => {
    toast.warning(content, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        pauseOnFocusLoss: false
    });
}

  const handleXoxoDayData = async () => {
    if (!isXoxo) {
      const response = await dispatch(addXoxodayGame({ clientId }));
      if (response.status === 200) {
        successAlert(response.data.message)
      }else{
        failureAlert(response.message)
      }
    } else {
      if (selectedGames !== "" && selectedGames.value === "Secret Santa") {

        let data = {};
        data.organizationId = id
        data.gameId = selectedGames.id
        data.gameTitle = selectedGames.value
        data.minimumGiftRange = parseInt(gameData.minumum);
        data.maximumGiftRange = parseInt(gameData.maximum);
        const response = await dispatch(addXoxodayGame(data));

    if (response.status === 200) {
      successAlert(response.data.message);
    } else if (response.status === 203) {
      failureAlert(response.data.message);
    } else {
      failureAlert(response.message);
    }


      } else if (selectedGames !== "" && selectedGames.value !== "Secret Santa") {
        let data = {};
        data.organizationId = id
        data.gameId = selectedGames.id
        data.gameTitle = selectedGames.value
        data.participation = isParticipation;
        data.winners = isWinner;
        data.pointsToOrganization = parseInt(gameData.points);
        const response = await dispatch(addXoxodayGame(data));

        if (response.status === 200) {
          successAlert(response.data.message)
        }else{
          failureAlert(response.message)
        }


      }
    }
  }


  // Get xoxoday organization active games list
  useEffect(() => {
    if (isXoxo) {
      dispatch(getXoxoOrgActiveGamesList(id, isXoxo));
    }
  }, [isXoxo]);

  const handleToggleButton= async()=>{
    const {data} = await axiosApiInstance.post(BASE_URL + "/api/xoxoday-game/getXoxoEnabledStatus",{id,isXoxo:!isXoxo})
    if (data.status === 200) {
      setXoxo(!isXoxo);
      successAlert(data.message)
    }else{
      failureAlert(data.message)
    }
}


const handleSelectedGames = async (e) => {
  const{data:{status,gameDetails}} = await axiosApiInstance.post(
    BASE_URL + "/api/xoxoday-game/getXoxoGameDetail",
    { orgId: id, gameId: e.id }
  );

  if (status === 200) {
    setSelectedGames(e)
    if(gameDetails?.gameTitle==="Secret Santa"){
      setGameData({
        ...gameData,
        minumum:gameDetails.minimumGiftRange,
        maximum: gameDetails.maximumGiftRange
      })
    }else{
      setGameData({
        ...gameData,
        points: gameDetails?.pointsToOrganization ?? 0
      })
      setParticipation(gameDetails?.participation ?? false)
      setWinner(gameDetails?.winners ?? false)
    }    
  } 
};



  const updateActiveXoxoGames = async () => {
    try {
      const result = await axiosApiInstance.post(BASE_URL + "/api/xoxoday-game/update-xoxoday-org-active-games", { activeXoxoGames: selectedActiveGames, orgId: id });
      if(result?.status === 200){
        toast.success('The Active xoxo game list updated successfully!', {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          pauseOnFocusLoss: false
      });
    }
    } catch (err) {
      console.log("err -", err);
      toast.error('Failed to update the Active games list!', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        pauseOnFocusLoss: false
      });
    }
  }

  return (
    <PageLayout
      sidebartitle=""
      active={"Organisation Details"}
      category
      sideBarContents={sidebarContentAdmin}
      profile
      {...props}
      signOut={signOut}
      {...props}
      isAdmin={isAdmin}
    >
      <div className="organisation-users">
        <div className="teams-search msearch">
          <div className="back-arrow">
            <Link to="/org-details-admin">
              <img src={arrowback} alt="back" />
            </Link>
          </div>
          <div className="mspan">
            <span className="teams-user">Organisation Users </span>
            <br />
            {/* <span className="teams-user-count">{orgUsers && orgUsers.data && orgUsers.data.length} of {myPlanDetails && myPlanDetails.data && myPlanDetails.data.planDetail
                        && myPlanDetails.data.planDetail.plan.userLimit}</span> */}
          </div>

       
        </div>
        <LoadingComponent loaded={loaded} />
        <ToastContainer position='bottom-center' />

        <div className="addedit-category organisation-users-header ">
          <form>
            {isXoxo ? (
              <>
                <div className="group">
                  <div className="form-group">
                    <h4 className="font-16">Enable XOXO</h4>
                    <div className="error-message">{""}</div>
                  </div>
                  <div className="form-group">
                    <div class="orgDetails-actions">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={isXoxo}
                          onChange={() => handleToggleButton()}
                        />
                        <span class="slider null"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="group">
                  <div className="form-group">
                    <h4 className="font-16">Select Games</h4>
                    <div className="error-message">{""}</div>
                  </div>
                  <div className="form-group">
                    <div class="orgDetails-actions">

                      <Select
                        className="form-select-orgs"
                        options={gameOptions}
                        placeholder="Select Game"
                        // onChange={(e) => setSelectedGames(e)}
                        onChange={(e) => handleSelectedGames(e)}
                        styles={selectStyles}
                      />
                    </div>
                  </div>
                </div>


                {selectedGames?.value === "Secret Santa" ? (
                  <>
                    <div className="group">
                      <div className="form-group">
                        <h4 className="font-16">Set Minimum Gift Range</h4>
                        <div className="error-message">{""}</div>
                      </div>
                      <div className="form-group">
                        <div class="orgDetails-actions">
                          <input
                            type="number"
                            value={gameData.minumum}
                            onChange={(e) =>
                              setGameData({
                                ...gameData,
                                minumum: e.target.value
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="group">
                      <div className="form-group">
                        <h4 className="font-16">Set Maximum Gift Range</h4>
                        <div className="error-message">{""}</div>
                      </div>
                      <div className="form-group">
                        <div class="orgDetails-actions">
                          <input
                            type="number"
                            value={gameData.maximum}
                            onChange={(e) =>
                              setGameData({
                                ...gameData,
                                maximum: e.target.value
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </>
                ) : (
                  selectedGames !== "" &&
                  <>
                    <div className="group">
                      <div className="form-group">
                        <h4 className="font-16">Set Participation</h4>
                        <div className="error-message">{""}</div>
                      </div>
                      <div className="form-group">
                        <div class="orgDetails-actions">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={isParticipation}
                              onChange={() => setParticipation(!isParticipation)}
                            />
                            <span class="slider null"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="group">
                      <div className="form-group">
                        <h4 className="font-16">Set Winners</h4>
                        <div className="error-message">{""}</div>
                      </div>
                      <div className="form-group">
                        <div class="orgDetails-actions">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={isWinner}
                              onChange={() => setWinner(!isWinner)}
                            />
                            <span class="slider null"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="group">
                      <div className="form-group">
                        <h4 className="font-16">Points To Organization</h4>
                        <div className="error-message">{""}</div>
                      </div>
                      <div className="form-group">
                        <div class="orgDetails-actions">
                          <input
                            type="text"
                            value={gameData.points}
                            onChange={(e) =>
                              setGameData({
                                ...gameData,
                                points: e.target.value
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="group">
                  <div className="form-group">
                    <h4 className="font-16">Enable XOXO</h4>
                    <div className="error-message">{""}</div>
                  </div>
                  <div className="form-group">
                    <div class="orgDetails-actions">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={isXoxo}
                          onChange={() => handleToggleButton()}
                        />
                        <span class="slider null"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="group">
                  <div className="form-group">
                    <h4 className="font-16">Add Client Id :-</h4>
                    <div className="error-message">{""}</div>
                  </div>
                  <div className="form-group">
                    <div class="orgDetails-actions">
                      <input
                        type="text"
                        value={clientId}
                        onChange={(e) => setClientId(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
          <div style={{ textAlign: "center" }}>
            {" "}
            <button className=" view-btn btn-primary " onClick={() => handleXoxoDayData()}>
              Submit
            </button>
          </div>
          {isXoxo ? <div className="mt-5">
            <div className="group flex items-center	justify-between	">
              <div className="form-group">
                <h4 className="font-16">Active XOXO Games</h4>
                <div className="error-message">{""}</div>
              </div>
              <div className="form-group w-[45%]">
                <div class="orgDetails-actions">

                  <div className="select">
                    <Select
                      classNamePrefix="react-select"
                      className="form-select"
                      options={gameOptions}
                      closeMenuOnSelect={false}
                      value={{ value: "", label: "Select Games" }}
                      formatOptionLabel={function (data) {
                        return (
                          <SelectItem selectedGames={selectedActiveGames} setSelectedGames={setSelectedActiveGames} id={data.id} name={data.label} />
                        );
                      }}
                      placeholder="Select Users"
                      styles={{
                        control: (base => ({
                          ...base,
                          cursor: "unset"
                        })),
                        option: (base, state) => ({
                          ...base,
                          borderBottom: '1px solid #f0f0f0',
                          background: '#fff',
                          color: '#000',
                          padding: 0,
                          cursor: 'unset'
                        }),
                        menuList: (base) => ({
                          ...base,
                          // overflow:'hidden'
                        })
                      }}
                    />
                    <span className="game-count">*{selectedActiveGames.length > 0 ? selectedActiveGames.length : "No"} {selectedActiveGames.length === 1 ? "Game" : "Games"} Selected</span>
                  </div>

                </div>
              </div>
            </div>
            <div className="text-center">
              <button className=" view-btn btn-primary w-[200px]" onClick={() => updateActiveXoxoGames()}>
                Update Active games
              </button>
            </div>
          </div> : null}
        </div>
        <div className="input-icon search-bar">
            <img src={search} alt="search" />
            <input
              type="text"
              name="search"
              placeholder="Search"
              value={searchText}
              onChange={handleChange}
            />
          </div>
        {/* Desktop table */}
        <div className="team-table">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Email ID</th>
                <th>Role</th>
                <th>Action</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {usersByOrganisation?.paginationData?.totalEntries > 0 ? (
                usersByOrganisation.data.users
                  .sort((a, b) => (a.role < b.role ? 1 : -1))
                  .map((user, index) => {
                    return (
                      <tr>
                        <td>{user.firstName + " " + user.lastName}</td>
                        <td>{user.email}</td>
                        <td className="teams-role">
                          {user.role === ROLES.ORG_SUPER_ADMIN
                            ? "Owner"
                            : user.role === ROLES.ORG_ADMIN
                              ? "Admin"
                              : "User"}
                        </td>
                        <td className="action-group">
                          <div className="orgDetails-actions">
                            <label className="switch">
                              <input
                                type="checkbox"
                                disabled={orgDisabled}
                                defaultChecked={user.isActive}
                              />
                              <span
                                className={`slider ${orgDisabled ? "disabled" : null
                                  }`}
                                onClick={() =>
                                  handleToggleUser(user.id, !user.isActive)
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                        <td className="action-group">
                          <div
                            className={`orgDetails-actions ${user.role === ROLES.ORG_SUPER_ADMIN &&
                              ownerCount < 2
                              ? "btn-tooltip"
                              : ""
                              }`}
                          >
                            <button
                              className={`view-btn btn-primary delete ${user.role === ROLES.ORG_SUPER_ADMIN &&
                                ownerCount < 2
                                ? "disabled"
                                : ""
                                }`}
                              onClick={() => {
                                if (
                                  user.role === ROLES.ORG_SUPER_ADMIN &&
                                  ownerCount < 2
                                )
                                  return;
                                handleUserDelete(user);
                              }}
                            >
                              <img src={deleteIconAdmin} alt="search" />
                            </button>
                            {user.role === ROLES.ORG_SUPER_ADMIN &&
                              ownerCount < 2 ? (
                              <div className="tooltip" role="tooltip">
                                <span>Atleast one owner should be there.</span>
                              </div>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <>{!UsersByOrganisation.loading && <div>No User found.</div>}</>
              )}
            </tbody>
          </table>
        </div>
        {/* Mobile view */}
        <div className="table-responsive m-teams-table">
          {usersByOrganisation?.paginationData?.totalEntries > 0 ? (
            usersByOrganisation.data.users
              .sort((a, b) => (a.role > b.role ? 1 : -1))
              .map((user, index) => {
                return (
                  <div className="team-m-card c-white-card">
                    <div className="team-m-top">
                      <div className="role">
                        <span>Role</span>
                        <div className="teams-m-title">
                          {user.role === ROLES.ORG_SUPER_ADMIN
                            ? "Owner"
                            : user.role === ROLES.ORG_ADMIN
                              ? "Admin"
                              : "User"}
                        </div>
                      </div>
                      <div className="action-group">
                        <div className="orgDetails-actions ">
                          <label className="switch">
                            <input
                              type="checkbox"
                              disabled={orgDisabled}
                              defaultChecked={user.isActive}
                            />
                            <span
                              className={`slider ${orgDisabled ? "disabled" : null
                                }`}
                              onClick={() =>
                                handleToggleUser(user.id, !user.isActive)
                              }
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="team-m-bottom">
                      <div>
                        <div className="teams-m-name">
                          {user.firstName + " " + user.lastName}
                        </div>
                        <div className="team-m-user">{user.email}</div>
                      </div>
                      <div
                        className={`orgDetails-actions ${user.role === ROLES.ORG_SUPER_ADMIN && ownerCount < 2
                          ? "btn-tooltip"
                          : ""
                          }`}
                      >
                        <button
                          className={`view-btn btn-primary delete ${user.role === ROLES.ORG_SUPER_ADMIN &&
                            ownerCount < 2
                            ? "disabled"
                            : ""
                            }`}
                          onClick={() => {
                            if (
                              user.role === ROLES.ORG_SUPER_ADMIN &&
                              ownerCount < 2
                            )
                              return;
                            handleUserDelete(user);
                          }}
                        >
                          <img src={deleteIconAdmin} alt="search" />
                        </button>
                        {user.role === ROLES.ORG_SUPER_ADMIN &&
                          ownerCount < 2 ? (
                          <div className="tooltip" role="tooltip">
                            <span>Atleast one owner should be there.</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <>{!UsersByOrganisation.loading && <div>No User found.</div>}</>
          )}
        </div>
        {/* Pagination */}
        {usersByOrganisation?.paginationData?.totalEntries > 0 && (
          <div className="pagination-wrapper">
            <button
              className={
                page > 1 ? "pagination-left enable" : "pagination-left"
              }
              onClick={() => {
                if (page > 1) setPage(page - 1);
                window.scrollTo(0, 0);
              }}
            >
              <img src={arrowleft} alt="arrow left" />
            </button>
            <div className="pagination-number">
              <h5>{page}</h5>
              <span>
                of{" "}
                {usersByOrganisation
                  ? Math.ceil(
                    usersByOrganisation?.paginationData?.totalEntries / 12
                  )
                  : 1}{" "}
                pages
              </span>
            </div>
            <button
              className={
                page <
                  Math.ceil(
                    usersByOrganisation?.paginationData?.totalEntries / 12
                  )
                  ? "pagination-right enable"
                  : "pagination-right"
              }
              onClick={() => {
                if (
                  page <
                  Math.ceil(
                    usersByOrganisation?.paginationData?.totalEntries / 12
                  )
                )
                  setPage(page + 1);
              }}
            >
              <img src={arrowleft} alt="arrow right" />
            </button>
          </div>
        )}
        {openDeleteUserModal && (
          <ManageTeamDeleteUserModal
            modalid="delete-user-modal"
            toggle={openDeleteUserModal}
            setOpenDeleteUserModal={setOpenDeleteUserModal}
            user={deleteUser}
            handleDeleteUser={handleDeleteUser}
            orgDetailPage
          />
        )}
      </div>
    </PageLayout>
  );
};

export default OrganisationUsers;
