import React, { useState, useRef } from "react";
import PageLayout from "../../components/pagelayout/pagelayout";

import LoadingComponent from "../../components/loader/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import "./org-logos.css";
import DataTable from "react-data-table-component";
import search from "../../assets/images/search.svg";
// import PaymentDetailsModal from "../modal/paymentDetailsModal";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { useEffect } from "react";
import { getAllPaymentForAdmin } from "../../redux/actions/paymentApiActions";
import useDebouncedSearch from "../../helpers/debounce";
import { verifyOfflinePayment } from "../../redux/actions/paymentApiActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { S3_BASE_URL } from "../../helpers/helper";
import deleteIcon from '../../assets/images/delete.svg'
import { SYMBOLS } from "../../constants";
import { getAllOrganizationLogos } from "../../redux/actions/organizationLogoActions";
import {
  successAlert,
  failureAlert,
  GOOGLE_RECAPTCHA_SITE_KEY,
  axiosApiInstance,
  encryptData,
  decryptData,
  IsAdmin,
  BASE_URL
} from "../../helpers/helper";
import { uploadFile } from '../../redux/actions/commonActions';



const OrganizationLogos = (props) => {
  const customStyles = {
    headCells: {
      style: {
        "font-family": "firaSans-semibold",
        "font-size": "15px",
        "border-bottom": "1px solid Black",
        "border-top": "1px solid Black"
      }
    },
    cells: {
      style: {
        "font-family": "firaSans-regular",
        "font-size": "14px",
        "text-align": "center"
      }
    },
    header: {
      style: {
        "font-family": "firaSans-semibold",
        color: "#737373"
      }
    }
  };

  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.organizationLogos.loading);
  const { orgLogos } = useSelector(state => state.organizationLogos);
  const isAdmin = IsAdmin();
  const [allOrgLogos, setLogos] = useState([]);
  const [loaded, setLoaded] = useState(true);

  const [companyName, setCompanyName] = useState('');
  const [preview, setPreview] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [fileError, setFileError] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(getAllOrganizationLogos(""));
  }, [dispatch]);

  useEffect(() => {
    if (orgLogos) {
      setLogos(orgLogos);
    }
  }, [orgLogos]);

  useEffect(() => {
    if (isLoading) {
      setLoaded(false);
    } else {
      setLoaded(true);
    }
  }, [isLoading]);

  const signOut = async () => {
    await dispatch(logOut());
    props.history.push(isAdmin ? "/admin" : "/");
  };

  const handleDelete = async (id, path) => {
    const logo = [path];

    if (logo.length > 0) {
      setLoaded(false);
      try {
        await axiosApiInstance.post(`${BASE_URL}/api/file/deleteBanners`, logo);
        await axiosApiInstance.delete(`${BASE_URL}/api/logos/delete/${id}`);
        dispatch(getAllOrganizationLogos(""));
        successAlert("Data deleted successfully");
      } catch (error) {
        failureAlert("Failed to delete banner");
      } finally {
        setLoaded(true);
      }
    }
  };
  const [logoType, setlogoType] = useState("org");

 
  const handlelogoTypeChange = (event) => {
    setlogoType(event.target.value);
  };
  const columns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      center: true
    },
    {
      name: "Logo",
      selector: (row) => row.path,
      cell: row => {
        const imageUrl = `${S3_BASE_URL}${row.path}`;
        return <img src={imageUrl} style={{ width: '100px', height: '70px', padding: '10px' }} />;
      },
      center: true
    },
    {
      name: "Organization Name",
      selector: (row) => row.organizationName,
      center: true
    },
    {
      name: "Logo Type",
      selector: (row) => row.logoType,
      center: true
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString()
    },
    {
      name: "Actions",
      cell: row => (
        <button 
          onClick={() => handleDelete(row.id, row.path)}
          className="btn btn-primary"
          style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
        >
          <img 
            src={deleteIcon}
            alt="Delete" 
            style={{ width: '20px', height: '20px' }} 
          />
        </button>
      ),
      center: true
    }
  ];

  const validateCompanyName = (value) => {
    return value.trim() ? '' : 'Company name is required.';
  };

  const validateFile = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setFileError('Please select a logo file.');
        setPreview('');
        resolve('Please select a logo file.');
      } else if (file.size > 10 * 1024 * 1024) { // 10MB in bytes
        setFileError('File size should not exceed 10MB.');
        setPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
          // if (img) {
          //   setFileError('Image dimensions must be 553x91 pixels.');
          //   setPreview('');
          //   resolve('Image dimensions must be 259x194 pixels.');
          // } else {
          // if (img.width !== 553 || img.height !== 91) {
          //   setFileError('Image dimensions must be 553x91 pixels.');
          //   setPreview('');
          //   resolve('Image dimensions must be 259x194 pixels.');
          // } else {
            setFileError('');
            setPreview(url);
            resolve('');
          // }
        };
        img.src = url;
      }
    });
  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);
    setCompanyNameError(validateCompanyName(value));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    await validateFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const file = fileInputRef.current.files[0];
    const nameError = validateCompanyName(companyName);
    setCompanyNameError(nameError);
    const fileError = await validateFile(file);
    setFileError(fileError);

    if (!nameError && !fileError) {
      if (file) {
        const formData = new FormData();
        formData.append("company-logos", file);
        setLoaded(false);

        try {
          const response = await dispatch(uploadFile(formData));
          if (response.status === 200) {
            const obj = {
              organisationName: companyName,
              logoPath: response.data.data.path,
              logoType:logoType
            };

            const result = await axiosApiInstance.post(`${BASE_URL}/api/logos/add`, obj);

            if (result.status === 200) {
              successAlert("Logo added successfully");
              setCompanyName('');
              setPreview('');
              setFileError('');
              setCompanyNameError('');
              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
              dispatch(getAllOrganizationLogos(""));
            }
          }
        } catch (error) {
          failureAlert("Failed to upload logo. Please try again.");
        } finally {
          setLoaded(true);
        }
      }
    }
  };

  return (
    <div className="admin-homepage">
      <LoadingComponent loaded={loaded} />
      <ToastContainer position="bottom-center" />
      <PageLayout
        sidebartitle=""
        active={"Logos"}
        category
        sideBarContents={sidebarContentAdmin}
        profile
        {...props}
        signOut={signOut}
        isAdmin={isAdmin}
      >
        <div className="container-payment">
          <div className="payment">
            <h1 className="title">Logos</h1>
          </div>
        </div>

        <div className="search-bar-container">
          <div className="input-icon search-bar">
            <img src={search} alt="search" />
            <input
              type="text"
              name="search"
              placeholder="Search"
            />
          </div>
        </div>

        <div className="logo-formtable-container">
          <div className="left-section">
            <div className="data-table">
              <DataTable
                title="Logos"
                columns={columns}
                data={allOrgLogos}
                className="rdt_TableHead"
                responsive
                striped
                highlightOnHover
                pagination
                customStyles={customStyles}
              />
            </div>
          </div>

          <div className="right-section">
            <form className="company-form" onSubmit={handleSubmit}>
              <h3>Upload Logo</h3>
              <div className="form-group">
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={companyName}
                  onChange={handleCompanyNameChange}
                  placeholder="Enter company name"
                />
                {companyNameError && <div className="errormessage">{companyNameError}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="companyName">Logo Type</label>
                <select
      id="logoType"
      name="logoType"
      value={logoType} 
      onChange={handlelogoTypeChange}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    >
      <option value="org">Org</option>
      <option value="whiteLogo">White Logo</option>
      <option value="blackLogo">Black Logo</option>
    </select>
              </div>
              <div className="form-group">
                <label htmlFor="logo">Logo</label>
                <label htmlFor="logo" className="errormessage">Size: 553 x 91 px</label>
                <input
                  type="file"
                  id="logo"
                  name="logo"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                {fileError && <div className="errormessage">{fileError}</div>}
              </div>
              {preview && (
                <div className="preview-container">
                  <img src={preview} alt="Logo Preview" className="logo-preview" />
                </div>
              )}
              <div className="form-group">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default OrganizationLogos;
