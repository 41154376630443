import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useMediaQuery } from 'react-responsive';

const WordBoard = ({ selectedValue, setSelectedValue, wordTobeReleased, selectedWords = 0 }) => {
    const isWideScreen = useMediaQuery({ minWidth: 1024 });
    const n = isWideScreen ? 5 : 10;
    const m = isWideScreen ? 10 : 5;
    const [ticketCard, setTicketCard] = useState([]);

    useEffect(() => {
        const result = [];
        for (let i = 0; i < n; i++) {
            const row = Array.from({ length: m });
            result.push(row);
        }
        setTicketCard(result);
    }, [n, m]);

    const selectValues = (cellNumber, isValueSelected) => {
        if (selectedWords !== 12) {
            if (isValueSelected) {
                setSelectedValue((prevValues) =>
                    prevValues.map((item) =>
                        item.value === cellNumber ? { ...item, value: '' } : item
                    )
                );
            }
            else {
                if (selectedValue.length < 12 || selectedValue.some((item) => item.value === '')) {
                    let fill = false
                    const updatedValues = selectedValue.map((item) => {
                        if (!fill && item.value === '') {
                            fill = true
                            return { value: cellNumber, index: cellNumber - 1, selectedBy: 'User' }
                        }
                        return item
                    });

                    const valueExists = updatedValues.some((item) => item.value === cellNumber);
                    if (updatedValues.every((item) => item.value !== '' && !valueExists)) {
                        updatedValues.push({ value: cellNumber, index: cellNumber - 1, selectedBy: 'User' });
                    }
                    setSelectedValue(updatedValues);
                }
                else {
                    Swal.fire({
                        title: 'Error',
                        text: 'You can select only 12 words',
                        icon: 'error',
                    })
                }
            }
        }
    }
    return (
        <>
            {ticketCard.length > 0 &&
                ticketCard.map((row, rIndex) => {
                    return (
                        <div className="flex" key={rIndex}>
                            {row.map((_, cIndex) => {
                                const cellNumber = wordTobeReleased[rIndex * m + cIndex];
                                const isValueSelected = selectedValue.some(
                                    (item) => item.value === cellNumber
                                );
                                return (
                                    <div className={`wordBoard break-all flex justify-center items-center text-center ${isValueSelected ? "bg-black text-white" : "bg-white"}`} key={cIndex} onClick={() => selectValues(cellNumber, isValueSelected)}>
                                        {cellNumber}
                                    </div>
                                );
                            })}
                        </div >
                    );
                })}
        </>
    );
}

export default WordBoard