import { useEffect, useState } from "react";

export default function TicketBoard({ selectedValue, setSelectedValue, action = '', selectedTickets, setSelectedTickets }) {
    const n = 3;
    const m = 8;
    const [chessBoard, setChessBoard] = useState([]);
    useEffect(() => {
        const result = [];
        for (let i = 0; i < n; i++) {
            const row = Array.from({ length: m });
            result.push(row);
        }
        setChessBoard(result);
    }, []);
    const [selectedValueIndex, setSelectedValueIndex] = useState(0);

    const selectTicket = (cellNumber, isValueSelected, rIndex, cIndex) => {
        cellNumber = cellNumber?.value
        if (isValueSelected) {
            setSelectedTickets(cellNumber, isValueSelected)
            // setSelectedTickets((prevValues) =>
            //     prevValues.filter((item) =>
            //         item.value !== cellNumber)
            // );
        }
        else {
            if (selectedTickets.length < 12 || selectedTickets?.some((item) => item.value === '')) {
                const updatedValues = selectedTickets?.map((item) =>
                    item.value === '' ? { value: cellNumber, index: cellNumber - 1, rowIndex: rIndex, colIndex: cIndex } : item
                );
                const valueExists = updatedValues?.some((item) => item.value === cellNumber);
                if (updatedValues.every((item) => item.value !== '' && !valueExists)) {
                    updatedValues.push({ value: cellNumber, index: cellNumber - 1, rowIndex: rIndex, colIndex: cIndex });
                }

                setSelectedTickets(updatedValues);
            }
            else {
                alert('Select only 12 values')
            }
        }
    }
    return (
        <>
            {chessBoard.length > 0 &&
                chessBoard.map((row, rIndex) => {
                    return (
                        <div className="flex" key={rIndex}>
                            {row.map((_, cIndex) => {
                                const ticketValue = (rIndex % 2 === 0 && cIndex % 2 === 0) || (rIndex === 1 && cIndex % 2 === 1) ? selectedValue[(rIndex === 2 ? (cIndex / 2 + 8) : rIndex === 1 ? ((cIndex + 1) / 2 + 3) : cIndex / 2)] : ''

                                const isValueSelected = selectedTickets?.some(
                                    (item) => {
                                        return item.value && item.value === ticketValue?.value}
                                );
                                return (
                                    <div
                                        className={`gameBox break-all flex justify-center text-center items-center ${(rIndex % 2 === 0 && cIndex % 2 === 0) || (rIndex % 2 === 1 && cIndex % 2 === 1)
                                            ? "bg-white" : "darkRose"
                                            } ${isValueSelected ? "bg-custom-yellow bg-yellow-400 text-black" : "bg-red"}`}
                                        key={cIndex}
                                        onClick={() => {
                                            if (ticketValue && action === 'ClaimTicket ')
                                                selectTicket(ticketValue, isValueSelected, rIndex, cIndex)
                                        }}
                                    >
                                        {ticketValue?.value}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
        </>
    );
}
