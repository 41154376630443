import * as ActionTypes from "../constants/xoxoApiConstants";

function getSSOReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.SSO_FETCHED_REQUEST:
      return { loading: true };
    case ActionTypes.SSO_FETCHED_SUCCESSFULLY:
      return { loading: false, SSO: action.payload };
    case ActionTypes.SSO_FETCH_FAILED:
      return { loading: false, error: action.payload };
    default: return state;
  }
}



export {
    getSSOReducer
}