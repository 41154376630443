import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./reports.css";

import OverviewGraph from "../../components/overviewgraph/overviewgraph";
import UserGame from "../../components/overviewgraph/usergame";
import ReportsTable from "../../components/reportstable/reportstable";
// imagge
import download from "../../assets/images/download.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadOverallReport,
  getOverallReports,
} from "../../redux/actions/reportsActions";
import Roles from '../../helpers/userTypes';
import LoadingComponent from "../../components/loader/LoadingComponent";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TeamReportsTable from "../../components/reportstable/teamReportsTable";
import OverviewGraphs from "../../components/overviewgraph/OverViewGraphs";
import DateRangePickerTwo from "../../components/overviewgraph/reportDashBoardGraphs/datePickerRange";
import { FaChevronDown } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";

import { addDays, format, startOfYear, endOfYear, subYears } from 'date-fns';
import { getDateFilter, getOrgGamesReports } from "../../redux/actions/dateFilterAction";




const Reports = (props) => {
  const OverallReports = useSelector((state) => state.overallReports);
  const { overAllReportFile } = useSelector((state) => state.overAllReportFile);

  const DateFilterValue = useSelector((state) => state.getDateFilterValue);
  const { dateFilter } = DateFilterValue;

  const OrgGamesReportData = useSelector((state) => state.getOrgGamesReports);
  const { orgGamesAllReport } = OrgGamesReportData;

  const [loaded, setLoaded] = useState(false);
  const [filterTab, setFilterTab] = useState(false);

  const filterTabRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterTabRef.current && !filterTabRef.current.contains(event.target)) {
        setFilterTab(false)
      }
    };
  
    document.addEventListener("click", handleClickOutside);
  
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterTabRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterTabRef.current && filterTabRef.current.contains(event.target)) {
        handleFilterTab(event, filterTab);
      }
    };
  
    document.addEventListener("click", handleClickOutside);
  
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterTabRef]);




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterTabRef.current && filterTabRef.current.contains(event.target)) {
        handleFilterTab(event);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterTabRef]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (OverallReports && OverallReports.loading || OrgGamesReportData && OrgGamesReportData.loading){
      setLoaded(false);
    } 
    else{
      setLoaded(true);
    } 
  }, [OverallReports, OrgGamesReportData]);


  useEffect(() => {
    dispatch(getOverallReports({ text: '' }));
    dispatch(downloadOverallReport());
    let startDate = formatDateToYYYYMMDD(new Date());
    let endDate = formatDateToYYYYMMDD(addDays(new Date(), -6));
    dispatch(getOrgGamesReports(endDate, startDate, "Last 7 Days"));
    dispatch(getDateFilter(new Date(), addDays(new Date(), -6), "Last 7 Days"))

  }, []);

  const UserInfo = useSelector((state) => state.getUser);
  const { userInfo } = UserInfo;

  var role = ""
  if (userInfo) {
    var { role } = userInfo.data;
  }

  const handleFilterTab = (e, tab) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("hanlde filter click" + tab);

    if (tab === true) {
      setFilterTab(false)
    }
    else {
      setFilterTab(true)

    }
  }

  const handleCross = async () => {
    let startDate = formatDateToYYYYMMDD(new Date());
    let endDate = formatDateToYYYYMMDD(addDays(new Date(), -6));

    await dispatch(getDateFilter(new Date(), addDays(new Date(), -6), "Last 7 Days"))

    dispatch(getOrgGamesReports(endDate, startDate, "Last 7 Days"));
    setFilterTab(!setFilterTab)

  }

  function formatDateToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

  return (
    <>
      <LoadingComponent loaded={loaded} />
      <div className=" flex flex-col lg:flex-row justify-between  mb-[24px] ">
        <h5 className="font-sans font-semibold text-[20px] mb-5 lg:mb-0">Dashboard</h5>

        <div className="flex items-center flex-row">
          <div className="flex flex-row items-center justify-between  w-[109px] md:w-[110px] lg:w-[130px] h-[40px] border relative bg-white p-3 cursor-pointer" 
          ref={filterTabRef}
          // onClick={(e)=> handleFilterTab(e,filterTab)}
          >
            <span className="font-sans text-[8px] md:text-[12px] lg:text-[14px]" >{dateFilter && dateFilter?.filterType ? dateFilter?.filterType : ''}</span>
            <FaChevronDown />
            {
              filterTab && filterTab === true ? (
                <DateRangePickerTwo filterTab={filterTab} setFilterTab={setFilterTab} startDate={dateFilter?.startDate} endDate={dateFilter?.endDate} filterTypeInfo={dateFilter?.filterType} />
              )
                : null

            }
          </div>
          <div className="flex flex-row p-3 w-[190px] md:w-[210px] lg:w-[225px] h-[40px] border mr-5 relative bg-white justify-between">
            <span className="font-sans text-[8px] md:text-[12px] lg:text-[14px]">{`${dateFilter && dateFilter?.startDate ? format(dateFilter?.startDate, 'dd/MMM/yyyy') : ''} - ${dateFilter && dateFilter?.endDate ? format(dateFilter?.endDate, 'dd/MMM/yyyy') : ''}`}</span>
            {
              dateFilter && dateFilter?.startDate && dateFilter.endDate && dateFilter?.filterType !== "Last 7 Days" ? (
                <MdOutlineCancel className=" h-[16px] w-[16px] cursor-pointer" onClick={handleCross} />

              ) : null
            }

          </div>

          {(role === Roles.ORG_SUPER_ADMIN) && overAllReportFile && (
            <a href={overAllReportFile.data.downloadUrl}>
              <button type="submit" className="btn btn-primary w-[130px] h-[40px] sm:w-[190px] md:w-[180px] sm:h-[40px] md:h-auto lg:h-auto lg:w-auto">
                <img src={download} alt="download" />
                <span className=" text-[11px] sm:text-[12px] md:text-[15px] lg:text-[16px]">Download Report</span>
              </button>
            </a>
          )}
        </div>


      </div>
      {role === Roles.ORG_SUPER_ADMIN ? <>
        {/* <OverviewGraph /> */}
        <OverviewGraphs />
        {/* <UserGame /> */}
      </>
        : null}

      <Tabs className="cg-tabs reports-tab databse-tabs">
        <TabList>
          <Tab >User Games Reports</Tab>
          <Tab >Team  Reports</Tab>

        </TabList>
        <TabPanel>
          <ReportsTable {...props} />        </TabPanel>
        <TabPanel>

          {/* <UserGame /> */}
          <TeamReportsTable {...props} />

        </TabPanel>

      </Tabs>

    </>

  );
};

export default Reports;