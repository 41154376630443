import React, { useState } from "react";
import PageLayout from "../../components/pagelayout/pagelayout";

import LoadingComponent from "../../components/loader/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import "./offlinequote.css";
import DataTable from "react-data-table-component";
import search from "../../assets/images/search.svg";
// import PaymentDetailsModal from "../modal/paymentDetailsModal";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { useEffect } from "react";
import { getAllPaymentForAdmin } from "../../redux/actions/paymentApiActions";
import useDebouncedSearch from "../../helpers/debounce";
import { verifyOfflinePayment } from "../../redux/actions/paymentApiActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { failureAlert, IsAdmin, successAlert } from "../../helpers/helper";

import { SYMBOLS } from "../../constants";
import { getAllOfflineQuotes } from "../../redux/actions/offlineQuoteApiActions";

const OfflineQuote = (props) => {
  const useSearch = () => useDebouncedSearch((text) => searchDetails(text));
  const customStyles = {
    headCells: {
      style: {
        "font-family": "firaSans-semibold",
        "font-size": "15px",
        "border-bottom": "1px solid Black",
        "border-top": "1px solid Black"
      }
    },
    cells: {
      style: {
        "font-family": "firaSans-regular",
        "font-size": "14px",
        "text-align": "center"
      }
    },
    header: {
      style: {
        "font-family": "firaSans-semibold",
        color: "#737373"
      }
    }
  };
  const searchDetails = (text) => {
    dispatch(getAllOfflineQuotes(text));
  };
  const { searchText, setSearchText, results } = useSearch();
  const AllOfflineQuotes = useSelector(
    (state) => state.offlineQuotes.offlineQuotesData
  );
  const isLoading = useSelector((state) => state.offlineQuotes.loading);
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const [detailsState, setDetailState] = useState({});
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [offdata, setTransactionData] = useState([]);
  const [verifyFlag, setVerifyFlag] = useState(false);
  const [loaded, setLoaded] = useState(true);

  const createNewObjects = (data) => {
    return data.map((item) => ({
      Created_Date: formatDate(item.createdAt),
      Name: item.fullName,
      Email: item.email,
      Organization: item.organizationName,
      Phone: item.phoneNumber,
      Game_Name: item.gameMaster.title
    }));
  };

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const headerRow = headers.join(",");
    const csvRows = data.map((obj) => {
      const row = headers.map((header) => {
        const field = obj[header];

        if (field && field.toString().includes(",")) {
          return `"${field}"`;
        } else {
          return field;
        }
      });
      return row.join(",");
    });

    return `${headerRow}\n${csvRows.join("\n")}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    // Get the day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }); // e.g., "Jul"
    const year = date.getFullYear();
  
    // Get the time in 12-hour format with AM/PM
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes} ${ampm}`;
  
    // Format the final string
    return `${month} ${day}, ${year}, ${formattedTime}`;
  };
  
 
  const handleDownload = () => {
    const newData = createNewObjects(AllOfflineQuotes);
    const csv = convertToCSV(newData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    dispatch(getAllOfflineQuotes(""));
  }, []);

  useEffect(() => {
    if ((AllOfflineQuotes && isLoading) || isLoading) {
      setLoaded(false);
    } else setLoaded(true);
  }, [AllOfflineQuotes, isLoading]);
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };

  const handleChange = (state) => {
    if (state.status === "PENDING") {
      setVerifyFlag(true);
    } else {
      setVerifyFlag(false);
    }
    setOpenDetailsModal(true);
    setDetailState(state);
  };
  const columns = [
  
    {
      name: "Name",
      // selector: "id",
      selector: (row) => row.fullName,
      center: true
    },
    {
      name: "Email",
      // selector: "companyName"
      selector: (row) => row.email
    },
    {
      name: "Organization ",
      // selector: "companyName"
      selector: (row) => row.organizationName
    },
    {
      name: "Phone Number ",
      // selector: "companyName"
      selector: (row) => row.phoneNumber
    },
    {
      name: "Game Name ",
      // selector: "companyName"
      selector: (row) => row.gameMaster.title
    },
    {
      name: "Subscription",
      // selector: "companyName"
      selector: (row) => row.Subscription
    },
    {
      name: "Created Date ",
      selector: (row) => formatDate(row.createdAt)
    }
  ];
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <div className="admin-homepage">
      <PageLayout
        sidebartitle=""
        active={"Onsite Quotes"}
        category
        sideBarContents={sidebarContentAdmin}
        profile
        {...props}
        signOut={signOut}
        {...props}
        isAdmin={isAdmin}
      >
        <LoadingComponent loaded={loaded} />
        <div className="container-payment">
          <div className="payment">
            <h1 className="title">Onsite  Quotes Details</h1>
          </div>
        </div>
    
        <div className="search-bar-container">
          <div className="input-icon search-bar">
            <img src={search} alt="search" />
            <input
              type="text"
              name="search"
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
            />
          </div>

          <button
            onClick={handleDownload}
            className="btn btn-primary play-game-btn"
          >
            Download CSV
          </button>
        </div>
      
        <div className="data-table">
          <DataTable
            title="Onsite Quotes"
            columns={columns}
            // onRowClicked={handleChange}
            data={AllOfflineQuotes} // add for checkbox selection
            className="rdt_TableHead"
            responsive="true"
            striped="true"
            highlightOnHover="true"
            pagination="true"
            customStyles={customStyles}
          />
      
        </div>
       
      </PageLayout>
    </div>
  );
};

export default OfflineQuote;
