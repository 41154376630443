export const GAME_DETAIL_GET_GAME_REQUEST = "GAME_DETAIL_GET_GAME_REQUEST";
export const GAME_DETAIL_GET_GAME_SUCCESS = "GAME_DETAIL_GET_GAME_SUCCESS";
export const GAME_DETAIL_GET_GAME_FAIL = "GAME_DETAIL_GET_GAME_FAIL";

export const GAME_ALL_REGION_REQUEST = 'GAME_ALL_REGION_REQUEST';
export const GAME_ALL_REGION_SUCCESS = 'GAME_ALL_REGION_SUCCESS';
export const GAME_ALL_REGION_FAIL = 'GAME_ALL_REGION_FAIL';


export const GAME_ALL_CATEGORY_REQUEST = 'GAME_ALL_CATEGORY_REQUEST';
export const GAME_ALL_CATEGORY_SUCCESS = 'GAME_ALL_CATEGORY_SUCCESS';
export const GAME_ALL_CATEGORY_FAIL = 'GAME_ALL_CATEGORY_FAIL';

export const PLANS_GET_ALL_PLANS_REQUEST = 'PLANS_GET_ALL_PLANS_REQUEST';
export const PLANS_GET_ALL_PLANS_SUCCESS = 'PLANS_GET_ALL_PLANS_SUCCESS';
export const PLANS_GET_ALL_PLANS_FAIL = 'PLANS_GET_ALL_PLANS_FAIL';

export const PLANS_GET_PLAN_DETAILS_REQUEST = 'PLANS_GET_PLAN_DETAILS_REQUEST';
export const PLANS_GET_PLAN_DETAILS_SUCCESS = 'PLANS_GET_PLAN_DETAILS_SUCCESS';
export const PLANS_GET_PLAN_DETAILS_FAIL = 'PLANS_GET_PLAN_DETAILS_FAIL';


export const GAME_GET_HR_ID_REQUEST = 'GAME_GET_HR_ID_REQUEST';
export const GAME_GET_HR_ID_SUCCESS = 'GAME_GET_HR_ID_SUCCESS';
export const GAME_GET_HR_ID_FAIL = 'GAME_GET_HR_ID_FAIL';

export const ADD_PURCHASE_ORDER_REQUEST = 'ADD_PURCHASE_ORDER_REQUEST';
export const ADD_PURCHASE_ORDER_SUCCESS = 'ADD_PURCHASE_ORDER_SUCCESS';
export const ADD_PURCHASE_ORDER_FAIL = 'ADD_PURCHASE_ORDER_FAIL';

export const ADD_SUBSCRIBED_GAMES_REQUEST = 'ADD_SUBSCRIBED_GAMES_REQUEST';
export const ADD_SUBSCRIBED_GAMES_SUCCESS = 'ADD_SUBSCRIBED_GAMES_SUCCESS';
export const ADD_SUBSCRIBED_GAMES_FAIL = 'ADD_SUBSCRIBED_GAMES_FAIL';

export const ADD_PLAN_TO_USER_REQUEST = 'ADD_PLAN_TO_USER_REQUEST';
export const ADD_PLAN_TO_USER_SUCCESS = 'ADD_PLAN_TO_USER_SUCCESS';
export const ADD_PLAN_TO_USER_FAIL = 'ADD_PLAN_TO_USER_FAIL';

export const SELECTED_GAMES_ID_REQUEST = 'SELECTED_GAMES_ID_REQUEST';
export const SELECTED_GAMES_ID_SUCCESS = 'SELECTED_GAMES_ID_SUCCESS';
export const SELECTED_GAMES_ID_FAIL = 'SELECTED_GAMES_ID_FAIL';

export const SELECTED_PLAN_ID_REQUEST = 'SELECTED_PLAN_ID_REQUEST';
export const SELECTED_PLAN_ID_SUCCESS = 'SELECTED_PLAN_ID_SUCCESS';
export const SELECTED_PLAN_ID_FAIL = 'SELECTED_PLAN_ID_FAIL';

export const ADD_FEEDBACK_REQUEST = 'ADD_FEEDBACK_REQUEST';
export const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS';
export const ADD_FEEDBACK_FAIL = 'ADD_FEEDBACK_FAIL';

export const ORGANISATION_GET_MY_GAMES_REQUEST = "ORGANISATION_GET_MY_GAMES_REQUEST";
export const ORGANISATION_GET_MY_GAMES_SUCCESS = "ORGANISATION_GET_MY_GAMES_SUCCESS";
export const ORGANISATION_GET_MY_GAMES_FAIL = "ORGANISATION_GET_MY_GAMES_FAIL";

export const ORGANISATION_TOGGLE_LIVE_GAMES_REQUEST = "ORGANISATION_TOGGLE_LIVE_GAMES_REQUEST";
export const ORGANISATION_TOGGLE_LIVE_GAMES_SUCCESS = "ORGANISATION_TOGGLE_LIVE_GAMES_SUCCESS";
export const ORGANISATION_TOGGLE_LIVE_GAMES_FAIL = "ORGANISATION_TOGGLE_LIVE_GAMES_FAIL";

export const GAME_SCHEDULED_BY_ME_REQUEST = 'GAME_SCHEDULED_BY_ME_REQUEST';
export const GAME_SCHEDULED_BY_ME_SUCCESS = 'GAME_SCHEDULED_BY_ME_SUCCESS';
export const GAME_SCHEDULED_BY_ME_FAIL = 'GAME_SCHEDULED_BY_ME_FAIL';

export const GAME_SCHEDULED_BY_OTHERS_REQUEST = 'GAME_SCHEDULED_BY_OTHERS_REQUEST';
export const GAME_SCHEDULED_BY_OTHERS_SUCCESS = 'GAME_SCHEDULED_BY_OTHERS_SUCCESS';
export const GAME_SCHEDULED_BY_OTHERS_FAIL = 'GAME_SCHEDULED_BY_OTHERS_FAIL';

export const GAME_GET_ALL_OBJECTIVES_REQUEST = "GAME_GET_ALL_OBJECTIVES_REQUEST";
export const GAME_GET_ALL_OBJECTIVES_SUCCESS = "GAME_GET_ALL_OBJECTIVES_SUCCESS";
export const GAME_GET_ALL_OBJECTIVES_FAIL = "GAME_GET_ALL_OBJECTIVES_FAIL";

export const GAME_GET_SERVER_OTP_REQUEST = "GAME_GET_SERVER_OTP_REQUEST";
export const GAME_GET_SERVER_OTP_SUCCESS = "GAME_GET_SERVER_OTP_SUCCESS";
export const GAME_GET_SERVER_OTP_FAIL = "GAME_GET_SERVER_OTP_FAIL";

export const GAME_BULK_UPDATE_REQUEST = "GAME_BULK_UPDATE_REQUEST";
export const GAME_BULK_UPDATE_SUCCESS = "GAME_BULK_UPDATE_SUCCESS";
export const GAME_BULK_UPDATE_FAIL = "GAME_BULK_UPDATE_FAIL";

export const GAME_VERIFY_REVIEW_EXIST_REQUEST = "GAME_VERIFY_REVIEW_EXIST_REQUEST";
export const GAME_VERIFY_REVIEW_EXIST_SUCCESS = "GAME_VERIFY_REVIEW_EXIST_SUCCESS";
export const GAME_VERIFY_REVIEW_EXIST_FAIL = "GAME_VERIFY_REVIEW_EXIST_FAIL";

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAIL = 'ADD_CATEGORY_FAIL';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const ADD_OBJECTIVE_REQUEST = "ADD_OBJECTIVE_REQUEST";
export const ADD_OBJECTIVE_SUCCESS = "ADD_OBJECTIVE_SUCCESS";
export const ADD_OBJECTIVE_FAIL = "ADD_OBJECTIVE_FAIL";

export const UPDATE_OBEJCTIVE_REQUEST = 'UPDATE_OBEJCTIVE_REQUEST';
export const UPDATE_OBEJCTIVE_SUCCESS = 'UPDATE_OBEJCTIVE_SUCCESS';
export const UPDATE_OBEJCTIVE_FAIL = 'UPDATE_OBEJCTIVE_FAIL';

export const DELETE_OBJECTIVE_REQUEST = "DELETE_OBJECTIVE_REQUEST";
export const DELETE_OBJECTIVE_SUCCESS = "DELETE_OBJECTIVE_SUCCESS";
export const DELETE_OBJECTIVE_FAIL = "DELETE_OBJECTIVE_FAIL";
