export const DIWALI_GAME_UPDATE_REQUEST="DIWALI_GAME_UPDATE_REQUEST";
export const DIWALI_GAME_UPDATE_SUCCESS="DIWALI_GAME_UPDATE_SUCCESS";
export const DIWALI_GAME_UPDATE_FAIL="DIWALI_GAME_UPDATE_FAIL"

export const GET_WORDS_LIST_REQUEST="GET_WORDS_LIST_REQUEST";
export const GET_WORDS_LIST_SUCCESS="GET_WORDS_LIST_SUCCESS";
export const GET_WORDS_LIST_FAIL="GET_WORDS_LIST_FAIL"

export const GET_RELEASED_WORDS_LIST_REQUEST="GET_RELEASED_WORDS_LIST_REQUEST";
export const GET_RELEASED_WORDS_LIST_SUCCESS="GET_RELEASED_WORDS_LIST_SUCCESS";
export const GET_RELEASED_WORDS_LIST_FAIL="GET_RELEASED_WORDS_LIST_FAIL"

export const GET_DIWALI_SETTINGS_REQUEST="GET_DIWALI_SETTINGS_REQUEST";
export const GET_DIWALI_SETTINGS_SUCCESS="GET_DIWALI_SETTINGS_SUCCESS";
export const GET_DIWALI_SETTINGS_FAIL="GET_DIWALI_SETTINGS_FAIL";

export const UPDATE_DIWALI_SETTINGS_REQUEST="UPDATE_DIWALI_SETTINGS_REQUEST";
export const UPDATE_DIWALI_SETTINGS_SUCCESS="UPDATE_DIWALI_SETTINGS_SUCCESS";
export const UPDATE_DIWALI_SETTINGS_FAIL="UPDATE_DIWALI_SETTINGS_FAIL";
