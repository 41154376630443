export const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAIL = "FILE_UPLOAD_FAIL";

export const EMPLOYEE_DATABASE_UPLOAD_REQUEST = "EMPLOYEE_DATABASE_UPLOAD_REQUEST";
export const EMPLOYEE_DATABASE_UPLOAD_SUCCESS = "EMPLOYEE_DATABASE_UPLOAD_SUCCESS";
export const EMPLOYEE_DATABASE_UPLOAD_FAIL = "EMPLOYEE_DATABASE_UPLOAD_FAIL";

export const EMPLOYEE_DATABASE_DOWNLOAD_REQUEST = "EMPLOYEE_DATABASE_DOWNLOAD_REQUEST";
export const EMPLOYEE_DATABASE_DOWNLOAD_SUCCESS = "EMPLOYEE_DATABASE_DOWNLOAD_SUCCESS";
export const EMPLOYEE_DATABASE_DOWNLOAD_FAIL = "EMPLOYEE_DATABASE_DOWNLOAD_FAIL";

export const WEBINAR_GET_ALL_REQUEST = "WEBINAR_GET_ALL_REQUEST";
export const WEBINAR_GET_ALL_SUCCESS = "WEBINAR_GET_ALL_SUCCESS";
export const WEBINAR_GET_ALL_FAIL = "WEBINAR_GET_ALL_FAIL";

export const TOGGLE_WEBINAR_ACCESS_REQUEST = "TOGGLE_WEBINAR_ACCESS_REQUEST";
export const TOGGLE_WEBINAR_ACCESS_SUCCESS = "TOGGLE_WEBINAR_ACCESS_SUCCESS";
export const TOGGLE_WEBINAR_ACCESS_FAIL = "TOGGLE_WEBINAR_ACCESS_FAIL";

export const ADD_WEBINAR_REQUEST = "ADD_WEBINAR_REQUEST";
export const ADD_WEBINAR_SUCCESS = "ADD_WEBINAR_SUCCESS";
export const ADD_WEBINAR_FAIL = "ADD_WEBINAR_FAIL";

export const DELETE_WEBINAR_REQUEST = "DELETE_WEBINAR_REQUEST";
export const DELETE_WEBINAR_SUCCESS = "DELETE_WEBINAR_SUCCESS";
export const DELETE_WEBINAR_FAIL = "DELETE_WEBINAR_FAIL";

export const UPDATE_WEBINAR_REQUEST = "UPDATE_WEBINAR_REQUEST";
export const UPDATE_WEBINAR_SUCCESS = "UPDATE_WEBINAR_SUCCESS";
export const UPDATE_WEBINAR_FAIL = "UPDATE_WEBINAR_FAIL";

export const DOWNLOAD_CHATS_REQUEST = "DOWNLOAD_CHATS_REQUEST";
export const DOWNLOAD_CHATS_SUCCESS = "DOWNLOAD_CHATS_SUCCESS";
export const DOWNLOAD_CHATS_FAIL = "DOWNLOAD_CHATS_FAIL";

export const DOWNLOAD_WEBINAR_PARTICIPANTS_REQUEST = "DOWNLOAD_WEBINAR_PARTICIPANTS_REQUEST";
export const DOWNLOAD_WEBINAR_PARTICIPANTS_SUCCESS = "DOWNLOAD_WEBINAR_PARTICIPANTS_SUCCESS";
export const DOWNLOAD_WEBINAR_PARTICIPANTS_FAIL = "DOWNLOAD_WEBINAR_PARTICIPANTS_FAIL";

export const ADD_DEMO_REQUEST_REQUEST = "ADD_DEMO_REQUEST_REQUEST";
export const ADD_DEMO_REQUEST_SUCCESS = "ADD_DEMO_REQUEST_SUCCESS";
export const ADD_DEMO_REQUEST_FAIL = "ADD_DEMO_REQUEST_FAIL";

export const DOWNLOAD_DEMO_REQUESTS_REQUEST = "DOWNLOAD_DEMO_REQUESTS_REQUEST";
export const DOWNLOAD_DEMO_REQUESTS_SUCCESS = "DOWNLOAD_DEMO_REQUESTS_SUCCESS";
export const DOWNLOAD_DEMO_REQUESTS_FAIL = "DOWNLOAD_DEMO_REQUESTS_FAIL";

export const ACTING_OWNER_CHANGE_REQUEST = "ACTING_OWNER_CHANGE_REQUEST";
export const ACTING_OWNER_CHANGE_SUCCESS = "ACTING_OWNER_CHANGE_SUCCESS";
export const ACTING_OWNER_CHANGE_FAIL = "ACTING_OWNER_CHANGE_FAIL";

export const WEBINAR_PARTICIPANTS_BACKUP_REQUEST = "WEBINAR_PARTICIPANTS_BACKUP_REQUEST";
export const WEBINAR_PARTICIPANTS_BACKUP_SUCCESS = "WEBINAR_PARTICIPANTS_BACKUP_SUCCESS";
export const WEBINAR_PARTICIPANTS_BACKUP_FAIL = "WEBINAR_PARTICIPANTS_BACKUP_FAIL";

export const WEBINAR_CHAT_BACKUP_REQUEST = "WEBINAR_CHAT_BACKUP_REQUEST";
export const WEBINAR_CHAT_BACKUP_SUCCESS = "WEBINAR_CHAT_BACKUP_SUCCESS";
export const WEBINAR_CHAT_BACKUP_FAIL = "WEBINAR_CHAT_BACKUP_FAIL";

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";

export const GET_NEW_TOKEN_REQUEST = "GET_NEW_TOKEN_REQUEST";
export const GET_NEW_TOKEN_SUCCESS = "GET_NEW_TOKEN_SUCCESS";
export const GET_NEW_TOKEN_FAIL = "GET_NEW_TOKEN_FAIL";

export const GET_ALL_WEBINAR_CATEGORIES_REQUEST = "GET_ALL_WEBINAR_CATEGORIES_REQUEST";
export const GET_ALL_WEBINAR_CATEGORIES_SUCCESS = "GET_ALL_WEBINAR_CATEGORIES_SUCCESS";
export const GET_ALL_WEBINAR_CATEGORIES_FAIL = "GET_ALL_WEBINAR_CATEGORIES_FAIL";

export const SET_SUBDOMAIN_ORG_ID = "SET_SUBDOMAIN_ORG_ID";
export const SUBDOMAIN_ORG_ID_LOADING = "SUBDOMAIN_ORG_ID_LOADING";
