import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import arrayMove from "array-move";

import "./index.css";
import { getAllGames, getAllSlogans, updateSlogan } from "../../redux/actions/homepageActions";
import SortableListGrid from "../sortableList/SortableListGrid";
import { ToastContainer } from "react-toastify";
import { failureAlert, successAlert } from "../../helpers/helper";

const SelectItem = ({ name, id, selectedGames, setSelectedGames }) => {

  const add = () => {
    setSelectedGames([...selectedGames, id]);
  }

  const remove = () => {
    let games = [...selectedGames];
    games = games.filter((game) => game !== id);
    setSelectedGames(games);
  }

  if (name === "Select Games")
    return (
      <span>{name}</span>
    )
  return (
    <div className="add-default-select-item">
      <h4>{name}</h4>
      <span>
        {selectedGames.includes(id) ? (
          <input type="checkbox" defaultChecked onChange={remove} />
        ) : (
          <input type="checkbox" onChange={add} />
        )}
      </span>
    </div>
  );
};

const SortableList = ({ sloganData, selectedGameList, selectedGames, setSelectedGames, selectedSloganIndex, setExistingGames, setSelectedGameList }) => {

  const [gameOrder, setGameOrder] = useState([]);

  useEffect(() => {
    setGameOrder(selectedGameList)
  }, [selectedGameList])

  const onSortEndDrag = (oldIndex, newIndex) => {
    let currentOrder = [...gameOrder];
    let newArray = arrayMove(currentOrder, oldIndex, newIndex);
    setGameOrder([...newArray]);
    setSelectedGameList([...newArray])
    setSelectedGames(newArray.map(data => data?.id))
  };

  const handleGameChange = (value) => {
    const updatedGameList = gameOrder.filter((currentGame) => currentGame.id !== value.id);
    setGameOrder(updatedGameList);
    setExistingGames(updatedGameList)
  }

  return <SortableListGrid
    items={gameOrder}
    setGameOrder={setGameOrder}
    onSortEnd={(oldIndex, newIndex) => onSortEndDrag(oldIndex, newIndex, sloganData[selectedSloganIndex - 1]?.id)}
    setSelectedGames={setSelectedGames}
    selectedGames={selectedGames}
    // setBannerDeleteModal={setBannerDeleteModal}
    sloganData={sloganData.id}
    ownerEdit={true}
    handleGameChange={(value) => { handleGameChange(value) }}
  />
}

function HomeAddGameInSloganModal({ setAddGamesMode, addGamesModel, setOnClickAddGame, setAddGameInSloganModal, sloganData, userType, selectedOrganization, organizationSubscribedStaus, selectedRegion, selectedSloganIndex, setSelectSloganIndex, setSloganData, userInfo, isAdmin }) {
  const ref = React.createRef();
  const dispatch = useDispatch();
  const { allGames } = useSelector(state => state.allGames);

  const [gameOptions, setGameOptions] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [selectedGameList, setSelectedGameList] = useState([]);
  const [sloganName, setSloganName] = useState("");
  const [sloganNameError, setSloganNameError] = useState("");
  const [existingGames, setExistingGames] = useState([]);

  useEffect(() => {
    dispatch(getAllGames());
  }, [])

  useEffect(() => {
    setSloganName(sloganData?.title)
  }, [sloganData?.title])

  useEffect(() => {
    if (allGames && allGames.data) {
      let games = [];
      // todo
      let gameIdList = [];
      let gameList = [];

      let options = [];
      allGames.data.forEach((game) => {
        if (!gameIdList.includes(game.id)) {

          if (game.slogan) {
            game.slogan.forEach(gameSlogan => {
              if (gameSlogan.id === sloganData?.id)
                games.push(game.id);
            })
          }
          gameIdList.push(game.id)
          gameList.push(game)
        }
        options.push({ value: game.title, label: game.title, id: game.id });
      })

      // alphabetical order sorting
      try {
        options = options.sort((x, y) => {
          let a = x.label.toLowerCase();
          let b = y.label.toLowerCase();
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      } catch (err) {
        console.log(err);
      }

      setGameOptions(options);
      setSelectedGames(games);
      setSelectedGameList(sloganData?.games);
      setExistingGames(sloganData?.games || []);
    }
  }, [allGames, sloganData]);

  useEffect(() => {
    if (ref.current)
      ref.current.style.top = document.documentElement.scrollTop + "px";
  }, [ref]);

  const handleClose = (e) => {
    e.preventDefault()

    if (addGamesModel === true) {
      setOnClickAddGame(false);
      setAddGamesMode(false);
      setAddGameInSloganModal(false);

    }
    else {
      setAddGameInSloganModal(false);

    }
  };
  const handleSelectChange = (selectedOption) => {
    const selectedGameIds = selectedOption.id ? [selectedOption.id] : [];
    const updatedExistingGames = existingGames.map((game) => ({ ...game }));
    const newSelectedGames = selectedGameIds.map((id) =>
      allGames?.data?.find((game) => game.id === id)
    );

    const gameData = updatedExistingGames.every(data => {
      return data.id !== newSelectedGames[0].id
    })

    let updatedGamesList;
    if (gameData) {
      const updatedGames = updatedExistingGames.concat(newSelectedGames);
      updatedGamesList = updatedGames
    } else {
      const selectedGamesData = updatedExistingGames.filter(game => game.id !== selectedOption.id);
      updatedGamesList = selectedGamesData
    }
    setExistingGames(updatedGamesList);
    setSelectedGameList(updatedGamesList);
    setSelectedGames(selectedGameIds);
    // setConfirmSloganSortModal(true);
    setSloganData(sloganData.id)
  };

  const handleUpdateSlogan = async (e) => {
    e.preventDefault();
    if (sloganName === "") {
      setSloganNameError("Please enter a valid slogan name");
      return;
    }

    let currentSelectedGames = selectedGameList.filter(gameList => selectedGames.includes(gameList.id)).map(game => { return { id: game.id } })

    let data = {
      position: 1,
      forOrganization: selectedOrganization?.organizationId ? true : false,
      forLoggedIn: userType === "LOGIN",
      forSubscribed: organizationSubscribedStaus === "SUBSCRIBED",
      organizationId: selectedOrganization?.organizationId
        ? selectedOrganization?.organizationId
        : null,
      games: [...currentSelectedGames], // Use the updated array here
      userId: null,
      region: selectedRegion,
    };

    let response;
    const sloganId = sloganData.id;
    response = await dispatch(updateSlogan(sloganId, { title: sloganName, games: data.games }));

    if (
      response &&
      response.status === 200 &&
      response.data &&
      (response.data.slogan || response?.data?.data)
    ) {


      const subscriptionStatus = isAdmin
        ? organizationSubscribedStaus
        : userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired
          ? "SUBSCRIBED"
          : "UNSUBSCRIBED";
      dispatch(getAllSlogans(isAdmin ? selectedOrganization.organizationId : userInfo?.data?.organizationId, isAdmin ? userType : userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, selectedRegion?.regionId, isAdmin));
      setAddGameInSloganModal(false);
      if (addGamesModel === true) {
        setOnClickAddGame(false);
        setAddGamesMode(false);
      }

      successAlert("Slogan Updated Sucessfully");

    }
    else {
      failureAlert("Failed To Update Slogan ")
    }
  };

  return (<>
    <ToastContainer position="top-center"></ToastContainer>
    <div className="add-game-in-slogan-modal" ref={ref} id="add-game-in-slogan-modal-home">
      <div className="container">
        <div className="btn-group">
          <button className="btn btn-primary" onClick={handleClose}>
            X
          </button>
        </div>
        <div className="form !px-7">
          <form>
            {
              addGamesModel === true ? (<>
                {/* <div className="form-group">
              <input
                type="text"
                value={sloganName}
                className="form-control add-slogan-field"
                onChange={(e) => setSloganName(e.target.value)}
                placeholder="Enter Slogan Name"
                style={{ margin: 0 }}
              // readOnly
              />
              <div className="error-message">{sloganNameError}</div>

            </div> */}

                <div className="select">
                  <Select
                    classNamePrefix="react-select"
                    className="form-select"
                    options={gameOptions}
                    closeMenuOnSelect={false}
                    value={{ value: "", label: "Select Games" }}
                    onChange={handleSelectChange}
                    formatOptionLabel={function (data) {
                      return (
                        <SelectItem
                          selectedGames={selectedGames}
                          setSelectedGames={setSelectedGames}
                          id={data.id || "select-games-option"}
                          gameOptions={gameOptions}
                          name={data.label}
                        />
                      );
                    }}
                    placeholder="Select Users"
                    styles={{
                      control: (base => ({
                        ...base,
                        cursor: "unset"
                      })),
                      option: (base, state) => ({
                        ...base,
                        borderBottom: '1px solid #f0f0f0',
                        background: '#fff',
                        color: '#000',
                        padding: 0,
                        cursor: 'unset'
                      }),
                      menuList: (base) => ({
                        ...base,
                      })
                    }}
                  />
                  <span className="game-count">*{selectedGames.length > 0 ? selectedGames.length : "No"} {selectedGames.length === 1 ? "Game" : "Games"} Selected</span>
                </div>
                {/* <div className="owner-scroll-design slogansRearangeSection">
              <SortableList
                sloganData={sloganData}
                selectedGameList={selectedGameList}
                selectedGames={selectedGames}
                setSelectedGames={setSelectedGames}
                setSelectedGameList={setSelectedGameList}
                selectedSloganIndex={selectedSloganIndex}
                setSelectSloganIndex={setSelectSloganIndex}
                setExistingGames={setExistingGames}
              />
            </div> */}
                <div className="form-group">
                  <button className="btn btn-primary mt-3" onClick={handleUpdateSlogan}>
                    Update
                  </button>
                </div>
              </>) : (<>
                <div className="form-group">
                  <input
                    type="text"
                    value={sloganName}
                    className="form-control add-slogan-field"
                    onChange={(e) => setSloganName(e.target.value)}
                    placeholder="Enter Slogan Name"
                    style={{ margin: 0 }}
                  // readOnly
                  />
                  <div className="error-message">{sloganNameError}</div>

                </div>

                <div className="select">
                  <Select
                    classNamePrefix="react-select"
                    className="form-select"
                    options={gameOptions}
                    closeMenuOnSelect={false}
                    value={{ value: "", label: "Select Games" }}
                    onChange={handleSelectChange}
                    formatOptionLabel={function (data) {
                      return (
                        <SelectItem
                          selectedGames={selectedGames}
                          setSelectedGames={setSelectedGames}
                          id={data.id || "select-games-option"}
                          gameOptions={gameOptions}
                          name={data.label}
                        />
                      );
                    }}
                    placeholder="Select Users"
                    styles={{
                      control: (base => ({
                        ...base,
                        cursor: "unset"
                      })),
                      option: (base, state) => ({
                        ...base,
                        borderBottom: '1px solid #f0f0f0',
                        background: '#fff',
                        color: '#000',
                        padding: 0,
                        cursor: 'unset'
                      }),
                      menuList: (base) => ({
                        ...base,
                      })
                    }}
                  />
                  <span className="game-count">*{selectedGames.length > 0 ? selectedGames.length : "No"} {selectedGames.length === 1 ? "Game" : "Games"} Selected</span>
                </div>
                <div className="owner-scroll-design slogansRearangeSection">
                  <SortableList
                    sloganData={sloganData}
                    selectedGameList={selectedGameList}
                    selectedGames={selectedGames}
                    setSelectedGames={setSelectedGames}
                    setSelectedGameList={setSelectedGameList}
                    selectedSloganIndex={selectedSloganIndex}
                    setSelectSloganIndex={setSelectSloganIndex}
                    setExistingGames={setExistingGames}
                  />
                </div>
                <div className="form-group">
                  <button className="btn btn-primary mt-3" onClick={handleUpdateSlogan}>
                    Update
                  </button>
                </div>
              </>)
            }

          </form>
        </div>
      </div>
    </div>
  </>
  );
}

export default HomeAddGameInSloganModal;