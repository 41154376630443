import React, { useState, useEffect, useRef } from "react";

import OnBoard from "../../components/onboard/onboard";
import Header from "../../components/header/header";
// import "./ResetPassword.css";

//Redux
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { checkUser } from "../../redux/actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import { GOOGLE_RECAPTCHA_SITE_KEY, axiosApiInstance, decryptData, encryptData, failureAlert, successAlert, } from "../../helpers/helper";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { BASE_URL } from '../../helpers/helper';

import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { CiCircleInfo } from "react-icons/ci";
import { FaRegCheckCircle } from "react-icons/fa";


const ResetPassword = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [resetEmailInput, setResetEmailInput] = useState({ email: "" })
    const [resetEmailTouch, setResetEmailTouch] = useState({ email: false });
    const [inputValue, setInputValue] = useState({ password: "", verifyPassword: "" });
    const [touch, setTouch] = useState({ password: false, verifyPassword: false });
    const [googleReCaptchaToken, setGoogleReCaptchaToken] = useState("");

    const [activeLoginForm, setActiveLoginForm] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [orgSignInBanner, setOrgSignInBanner] = useState('');
    const [invalidDataCount, setInvalidDataCount] = useState(0);
    const [resetUserEmail, setResetUserEmail] = useState('');
    const [displayFieldError, setDisplayFieldError] = useState({
        email: false,
        password: false,
        verifyPassword: false
    })


    const sendOtpDetails = useSelector(state => state.sendOtpToUser);
    const userDetails = useSelector(state => state.getUser)
    let { loading, error } = sendOtpDetails;
    const dispatch = useDispatch();
    const UserSignin = useSelector((state) => state.userSignin);
    let { userInfo } = UserSignin;
    const { orgDetailsById } = useSelector(state => state.getOrganisationById);
    let inviteId = new URLSearchParams(props.location.search).get('inviteId');
    const ResetUserId = new URLSearchParams(props.location.search).get('resetUserId');
    const Email = new URLSearchParams(props.location.search).get('email');



    useEffect(() => {
        if (ResetUserId) {
            checkUserFunc(ResetUserId);
            fetchOrgSigInBanner()
            setDisplayFieldError({
                email: false,
                password: false,
                verifyPassword: false
            });
        }
        if (Email) {
            setActiveLoginForm('forgot-password-email');
            setDisplayFieldError({
                email: false,
                password: false,
                verifyPassword: false
            });
        }
        if (!ResetUserId && !Email) {
            props.history.push("/")
        }

    }, [ResetUserId, Email])

    const checkUserFunc = async (id) => {
        const response = await dispatch(checkUser(id));
        if (response.status === 200 && response.data.message === "User Available") {
            setActiveLoginForm('reset-password-form')
            setResetUserEmail(response.data.email);
        }
    }

    useEffect(() => {
        if (activeLoginForm === "forgot-password-email") {
            setInputValue({ password: "", verifyPassword: "" });
            setTouch({ password: false, verifyPassword: false });

        }
        if (activeLoginForm === "reset-password-form") {
            setResetEmailInput({ email: "" });
            setResetEmailTouch({ email: false });

        }

    }, [activeLoginForm])

    const fetchOrgSigInBanner = async () => {
        setOrgSignInBanner('')
        const sessionid = new URLSearchParams(props.location.search).get('sessionId');
        const getSigninBannerImg = await axiosApiInstance.get(BASE_URL + "/api/organization/signin-banner-userid/" + ResetUserId);
        if (getSigninBannerImg && getSigninBannerImg.data && getSigninBannerImg.data.data !== null) {
            setOrgSignInBanner(getSigninBannerImg.data.data);
        }

    }


    const handelResetPasswordChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (touch[name]) {
            setTouch(prevState => ({ ...prevState, [name]: false }));
        }
        setInputValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handlePassLoginChange = (e, fieldName) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (resetEmailTouch[name]) {
            setResetEmailTouch(prevState => ({ ...prevState, [name]: false }));
        }
        setResetEmailInput((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    function validateEmail(email) {
        const regex = /[A-Z]/;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase()) && !regex.test(email);
    }


    function validatePassword(password) {
        let validLength = false;
        if (password.length > 4 && password.length <= 15) {
            validLength = true;
        }
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);

        return hasUpperCase && hasLowerCase && hasNumber && hasSymbol && validLength;
    }

    const newResetPasswordValidation = (input) => {

        const errors = {
            password: '',
            verifyPassword: ''
        };
        if (touch.password && !validatePassword(input.password)) {
            errors.password = "Invalid Password "
        }
        if (touch.password && input.password === "") {
            errors.password = "Your password length must be between 4 to 15 must contain at least one uppercase letter, one number, and one symbol"
        }
        if (touch.verifyPassword && inputValue.password !== "" && (input.verifyPassword !== inputValue.password || input.verifyPassword === "")) {
            errors.verifyPassword = "Password did Not match"
        }
        if (touch.verifyPassword && input.verifyPassword !== "" && inputValue.password === "") {
            errors.verifyPassword = "Please set password First"
        }
        if (touch.verifyPassword && input.verifyPassword === "") {
            errors.verifyPassword = "Password must be same as your set password"
        }
        return errors;
    };
    let ResetPasswordErrors = newResetPasswordValidation(inputValue);


    const LoginValidateInput = (input) => {

        const errors = {
            email: ''
        };
        if (resetEmailTouch.email && (!validateEmail(input.email) || input.email === " ")) {

            errors.email = "Please provide a valid Email";
        }
        return errors;
    };


    let LoginFormErrorMessg = LoginValidateInput(resetEmailInput);


    useEffect(() => {

        setLoaded(!loading);
    }, [loading])

    const getOrgIdFromInviteId = () => {
        if (inviteId) {
            try {
                const { organizationId } = decryptData(inviteId);
                return organizationId;
            } catch (err) {
                console.log(err)
            }
        }
        return null;
    }
    const invitedOrgId = getOrgIdFromInviteId();

    const handleSignInPage = () => {
        props.history.push("signin");
    }



    const handleNewPassword = async (e) => {
        e.preventDefault()
        if (inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" && ResetUserId) {
            if (googleReCaptchaToken) {
                const response = await axios.post(BASE_URL + `/api/user/verify/googlecaptcha`, { token: googleReCaptchaToken })
                if (response.data.data === true) {
                    const body = {
                        password: inputValue.password,
                        verifyPassword: inputValue.password,
                        id: ResetUserId,
                        troughLink: true
                    }
                    setLoaded(false);
                    const resetPassword = await axios.post(BASE_URL + "/api/user/update/user/password", body)
                    if (resetPassword && resetPassword.data.message === "Sucessfully Updated User Password") {
                        successAlert("Sucessfully updated user password");
                        setTimeout(() => {
                            setLoaded(true);
                            setInputValue({ password: "", verifyPassword: "" });
                            setTouch({ password: false, verifyPassword: false });
                            props.history.push("signin");
                        }, 1000)

                    }
                    else {
                        failureAlert("Something went wrong")
                    }
                } else {
                    failureAlert("Please verify you are not a robot.");

                }
            }
            else {
                failureAlert("Please verify you are not a robot.");
            }

        }
    }

    const handleResetPasswordEmailSubmit = async (e) => {
        e.preventDefault();
        if (LoginFormErrorMessg.email === '' && resetEmailInput.email !== "" && Email) {
            setLoaded(false);
            const body = {
                email: resetEmailInput.email
            }
            const resetMailResponse = await axiosApiInstance.post(BASE_URL + "/api/user/send/reset/password-link", body);
            if (resetMailResponse.data.message === "Reset Link Send to your Mail") {
                setLoaded(true);
                successAlert(`Mail with reset link has been sent to ${resetEmailInput.email}`);
                setResetEmailInput({ email: "" });
                setResetEmailTouch({ email: false });
            }
            else if (resetMailResponse.data.message === "User Not Available") {
                setLoaded(true);
                failureAlert("User not available");
            }
            else if (resetMailResponse.data.message === "dummy user email is not allowed"){
                failureAlert("Dummy user email is not allowed");
            }
            else {
                setLoaded(true);
                failureAlert("Something went wrong");
            }
        }

    }
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleGoogleCaptcha = async (value) => {
        if (value) {
            setGoogleReCaptchaToken(value)
        }
    }

    const handleIiconClick = (fieldName) => {
        setDisplayFieldError((prev) => ({ ...prev, [fieldName]: !displayFieldError[fieldName] }));
    }


    return (
        <div>
            <Header {...props} onboard inviteId={invitedOrgId} />
            <ToastContainer style={{ marginTop: '4rem' }} position="bottom-center" />
            <LoadingComponent loaded={loaded} />
            <OnBoard sideBannerImage={orgSignInBanner}>
                <div className="form-container">
                    <div className={loaded ? "auth-flow login" : "auth-flow login loading"} style={{ justifyContent: 'start' }} >{/**  style={{justifyContent:'start', marginTop: '-42px'}}*/}
                        {/* <div className="login-via-tabs" style={{
            borderBottom:'3px solid #0e9898'
          }}>
            <div className="login-via-tab">
              <span
                style={{
                  color: '#0e9898',
                  fontWeight: 'bold',
                  fontSize:  '1.3rem',
                }}
              >Forgot Your Password </span>
            </div>
          </div> */}

                        {

                            activeLoginForm === 'forgot-password-email' ? (<>
                                <div className="login-via-tabs" style={{ borderBottom: '3px solid var(--background-theme)' }}>
                                    <span className="signUp-heading">Forgot Your Password</span>
                                </div>

                                <form onSubmit={async (e) => await handleResetPasswordEmailSubmit(e)} className="user-login-form">
                                    <div className="auth-form">
                                        <div className="reset-text-cont">
                                            <p style={{ fontSize: '15.50px' }}>Please provide the email address where you would like to receive your password reset information.</p>
                                        </div>

                                        <div className="form-group" >
                                            <label htmlFor="email">Email Address</label>
                                            <div className="tooltip-input-container">

                                                {LoginFormErrorMessg.email !== "required*" && displayFieldError.email && <div className="tolltip-mid-error-text" style={{ display: LoginFormErrorMessg.email === "" ? 'none' : 'flex' }}>{LoginFormErrorMessg.email}</div>}
                                                {LoginFormErrorMessg.email !== "" && <CiCircleInfo style={{ position: 'absolute', left: '87%', transform: 'translateY(15px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('email')}></CiCircleInfo>}

                                                <input
                                                    className={LoginFormErrorMessg.email !== "" ? "error-input" : "form-control"}
                                                    id="email"
                                                    name="email"
                                                    placeholder="Enter your email"
                                                    onChange={(e) => handlePassLoginChange(e, "email")}
                                                    onBlur={() => setResetEmailTouch((prev) => ({ ...prev, email: true }))}
                                                    value={resetEmailInput.email}
                                                    required
                                                />
                                                {LoginFormErrorMessg.email && <div className="error-message" style={{ position: 'static' }}>{LoginFormErrorMessg.email}</div>}
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">
                                            Request Reset Link
                                        </button>
                                        <button type="button" className="btn btn-primary" style={{ background: '#1D1D1D' }} onClick={handleSignInPage}>
                                            Back To Login
                                        </button>
                                        {/* <a href="/signup">gggg</a> */}
                                    </div>
                                </form>

                            </>) : activeLoginForm === 'reset-password-form' ? (<>

                                <div className="login-via-tabs" style={{
                                    borderBottom: '3px solid var(--background-theme)',
                                    //  marginTop: '-23px'
                                }}>
                                    <span className="signUp-heading">Reset Account Password</span>
                                </div>

                                <form onSubmit={async (e) => await handleNewPassword(e)} className="user-login-form">
                                    <div className="auth-form">
                                        <div className="reset-text-cont" style={{ marginBottom: '10px' }}>
                                            <p style={{ fontSize: '15.50px' }}>Enter a new password for {resetUserEmail}</p>

                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="password">New Password *</label>
                                            <div className="tooltip-input-container">
                                                {ResetPasswordErrors.password !== "required*" && displayFieldError.password && <div className="tolltip-error-text" style={{ display: ResetPasswordErrors.password === "" ? 'none' : 'flex' }}>{ResetPasswordErrors.password}</div>}
                                                {ResetPasswordErrors.password !== "" && <CiCircleInfo style={{ position: 'absolute', left: '87%', transform: 'translateY(15px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('password')}></CiCircleInfo>}

                                                <div className="password-container">

                                                    <input
                                                        className={ResetPasswordErrors.password ? "error-input" : "form-control"}
                                                        id="password"
                                                        name="password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        placeholder="Enter new password"
                                                        onChange={(e) => handelResetPasswordChange(e)}
                                                        onBlur={() => setTouch(prevState => ({ ...prevState, password: true }))}
                                                        value={inputValue.password}
                                                        required

                                                    />
                                                    {
                                                        touch.password === true && ResetPasswordErrors.password === "" && inputValue.password !== "" ? (
                                                            <FaRegCheckCircle style={{ position: 'absolute', color: '#25da2a', right: '-7%' }} />

                                                        ) : null
                                                    }
                                                    <div
                                                        className="pass-eye-btn"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {showPassword ? <FaEye style={{ position: 'absolute' }} /> : <FaEyeSlash style={{ position: 'absolute' }} />}
                                                    </div>
                                                </div>

                                                {/* {!touch.password && <span style={{ fontSize: '10px', color: '#E25569', fontStyle: 'inherit', fontWeight: '100' }}>Your password length must be between 4 to 15 must contain at least one uppercase letter, one number, and one symbol<br /></span>} */}
                                                {ResetPasswordErrors.password && <div className="error-message" style={{ position: 'static' }}>{ResetPasswordErrors.password}</div>}
                                            </div>

                                            <label htmlFor="verifyPassword">Verify Password *</label>
                                            <div className="tooltip-input-container">
                                                {ResetPasswordErrors.verifyPassword !== "required*" && displayFieldError.verifyPassword && <div className="tolltip-error-text" style={{ display: ResetPasswordErrors.verifyPassword === "" ? 'none' : 'flex' }}>{ResetPasswordErrors.verifyPassword}</div>}
                                                {ResetPasswordErrors.verifyPassword !== "" && <CiCircleInfo style={{ position: 'absolute', left: '87%', transform: 'translateY(15px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('verifyPassword')}></CiCircleInfo>}

                                                <div className="password-container">
                                                    <input
                                                        className={ResetPasswordErrors.verifyPassword ? "error-input" : "form-control"}
                                                        id="verifyPassword"
                                                        name="verifyPassword"
                                                        type={showPassword ? 'text' : 'password'}
                                                        placeholder="Verify password"
                                                        onChange={(e) => handelResetPasswordChange(e)}
                                                        onBlur={() => setTouch(prevState => ({ ...prevState, verifyPassword: true }))}
                                                        value={inputValue.verifyPassword}
                                                        required
                                                    />

                                                    {
                                                        touch.verifyPassword === true && ResetPasswordErrors.verifyPassword === "" && inputValue.verifyPassword !== "" ? (
                                                            <FaRegCheckCircle style={{ position: 'absolute', color: '#25da2a', right: '-7%' }} />

                                                        ) : null
                                                    }

                                                    <div
                                                        className="pass-eye-btn"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {showPassword ? <FaEye style={{ position: 'absolute' }} /> : <FaEyeSlash style={{ position: 'absolute' }} />}
                                                    </div>
                                                </div>

                                                {/* {!touch.verifyPassword && <div className="error-message" style={{ position: 'static' }}>verify password must be same as your set password</div>} */}
                                                {ResetPasswordErrors.verifyPassword && <div className="error-message" style={{ position: 'static' }}>{ResetPasswordErrors.verifyPassword}</div>}
                                            </div>

                                        </div>
                                        <ReCAPTCHA
                                            style={{ marginTop: '15px' }}
                                            sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                                            onChange={handleGoogleCaptcha}
                                        ></ReCAPTCHA>
                                        <button type="submit" className="btn btn-primary">
                                            Reset Password
                                        </button>
                                        <button type="button" className="btn btn-primary" style={{ background: '#1D1D1D' }} onClick={handleSignInPage}>
                                            Back To Login
                                        </button>
                                    </div>
                                </form>
                            </>) : null
                        }

                    </div>
                </div>

            </OnBoard>
        </div>
    );
};

export default ResetPassword;
