import React, { useEffect, useState } from 'react';

import '../modal/modal.css';
import Modal from '../modal/modal';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BASE_URL, axiosApiInstance, failureAlert, successAlert } from '../../helpers/helper';
// import Modal from './modal';

const UpdatePasswordOtp = ({ setLoaded, userEmail, userId, resetPasswordType, modalid, toggle, setResetPasswordModal }) => {

    return (
        <Modal modalid={modalid} toggle={toggle}>
            <div className="modal-body">
                <div className="close-icon" data-dismiss="modal" aria-label="Close" onClick={() => setResetPasswordModal(false)} >
                    <div className="close-btn-icon" ></div>
                </div>
                <div className="thankyou-body" style={{ width: "400px" }}>
                   
                    <span style={{fontSize:'large', fontWeight:"bold"}}>Your password is not set. Please make one by using reset via OTP</span>
                </div>
            </div>
        </Modal>
    );
};
export default UpdatePasswordOtp;