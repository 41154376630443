import { UPDATE_FORM_DATA }  from "../constants/bookEventFilterConstants";
import { axiosApiInstance, BASE_URL } from "../../helpers/helper";



// Action creator to update form data
export const updateFilterFormData = (data) => ({
  type: UPDATE_FORM_DATA,
  payload: data,
});
