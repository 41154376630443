import React from "react";
import { absolute } from "synonyms/dictionary";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import videoTopIce from '../../assets/backgrounds/video_top_ice.png'
import videoBottomIce from '../../assets/backgrounds/video_bottom_ice.png'


export const VideoJS = (props) => {

    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const { options, onReady } = props;

    React.useEffect(() => {
        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                onReady && onReady(player);
            });
        } else {
            // you can update player here [update player through props]
            // const player = playerRef.current;
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;
        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player style={{width:'100%'}}>
            {/* style={{width:"50%", top:"-20px", left:"-20px"}} */}
            {/* style={{width:"50%", bottom:"-30px", right:"-15px"}} */}
            {/* <img src={videoTopIce} id="videoTopIce" /> */}
            {/* <img src={videoBottomIce} id="videoBottomIce" /> */}
            <video ref={videoRef} id={props.id} className="video-js vjs-big-play-centered" />
        </div>
    );
}

export default VideoJS;