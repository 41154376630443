import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import "./usersData.css";
import search from "../../assets/images/search.svg";
import arrowback from "../../assets/images/arrow-back.svg";
import editButton from "../../assets/images/editWithFrame.svg";
import deleteButton from "../../assets/images/deleteWithFrame.svg";
// import editButton from "../../assets/images/editButton.svg";
// import deleteButton from "../../assets/images/deleteButton.svg";
import arrowleft from "../../assets/images/paginationarrow.svg";
import ManageTeamDeleteUserModal from "../modal/manageTeamDeleteUser";
import ManageTeamAddEditUserModal from "../modal/manageTeamAddEditUsermodal";
import { getOrganisationUsers, deleteOrganisationUser } from '../../redux/actions/organisationActions';
import Roles, { OrgRoles } from '../../helpers/userTypes';
import { getMyPlans } from '../../redux/actions/plansApiActions';
import useDebouncedSearch from "../../helpers/debounce";
import LoadingComponent from "../loader/LoadingComponent";
import { BASE_URL, axiosApiInstance, failureAlert, successAlert } from "../../helpers/helper";
import ConfirmModal from "../modal/confirmModal";
import { deleteUsersBulk, downloadOrgUsers } from "../../redux/actions/userAction";
import { downloadFile } from "../../helpers/downloadFile";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../header/header";
import { MdErrorOutline } from "react-icons/md";
import { BiSolidLeftArrow } from "react-icons/bi";


const UsersData = (props) => {
  const useSearch = () => useDebouncedSearch(text => searchUsers(text));
  const searchUsers = (text) => {
    dispatch(getOrganisationUsers(text));
    setPage(1);
  }
  const { searchText, setSearchText, results } = useSearch();
  const dispatch = useDispatch();
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openAddEditUserModal, setOpenAddEditUserModal] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [deleteUser, setDeleteUser] = useState({});
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(true);
  const [deleteUsersList, setDeleteUsersList] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectAllStatus, setSelectAllStatus] = useState(false);
  const [isUserLimitExist, setIsUserLimitExist] = useState(false)


  const OrgUsers = useSelector(state => state.orgUsers);
  const { orgUsers } = OrgUsers;
  const UserInfo = useSelector((state) => state.getUser);
  const { userInfo } = UserInfo;
  const DeletedUsers = useSelector(state => state.deletedUsers);
  const { myPlanDetails } = useSelector(state => state.getMyPlans);
  const DeleteOrgUser = useSelector(state => state.deleteOrgUser);
  const DownloadOrgUsers = useSelector(state => state.downloadOrgUsers);

  const history = useHistory();
  const checkRef = useRef(null)

  useEffect(() => {
    if (orgUsers && myPlanDetails) {
      if (myPlanDetails.data && myPlanDetails.data.planDetail && orgUsers?.paginationData?.totalEntries >= myPlanDetails.data.planDetail.plan.userLimit) {
        setIsUserLimitExist(true)
      }
      else {
        setIsUserLimitExist(false)
      }

    }
  }, [OrgUsers, myPlanDetails])

  const handleEdit = (user) => {
    setEditUser(user);
    setOpenAddEditUserModal(true);
  }
  const handleDelete = (userId) => {
    setDeleteUsersList([userId]);
    setOpenDeleteUserModal(true)
  }
  useEffect(() => {
    if (OrgRoles.includes(userInfo?.data?.role))
      dispatch(getMyPlans());
  }, [userInfo?.data?.role]);
  useEffect(() => {
    dispatch(getOrganisationUsers(searchText, false, page));
  }, [page]);
  useEffect(() => {
    if (
      (OrgUsers && OrgUsers.loading) ||
      (results && results.loading) ||
      (DeleteOrgUser && DeleteOrgUser.loading) ||
      (DeletedUsers && DeletedUsers.loading) ||
      (DownloadOrgUsers && DownloadOrgUsers.loading)
    ) {
      setLoaded(false);
    }
    else
      setLoaded(true);
  }, [OrgUsers, results, DeleteOrgUser, DeletedUsers, DownloadOrgUsers])

  useEffect(() => {
    selectAllStatusInPage()
  }, [orgUsers, deleteUsersList])

  const selectAllStatusInPage = () => {
    const selectAllInPage = orgUsers?.data?.every((user) => deleteUsersList?.includes(user.id))
    setSelectAllStatus(selectAllInPage)
  }

  const handleChange = (e) => {
    setSearchText(e.target.value);
    // if (e.target.value.length === 0) {
    //   dispatch(getOrganisationUsers(""));
    //   setPage(1);
    // }
  }
  const handleDeleteUser = async (e) => {
    const response = await dispatch(deleteOrganisationUser(deleteUser.id));
    if (response === 200) {
      setOpenDeleteUserModal(false);
      dispatch(getOrganisationUsers(''));
    }
  }

  const handleDeleteUsersToggle = useCallback((e, user) => {
    e.stopPropagation();

    let deletelist = [...deleteUsersList];
    if (e.target.checked) {

      if (!deletelist.includes(user.id)) {
        deletelist.push(user.id);


      }
    }
    else {

      if (deletelist.includes(user.id)) {
        deletelist = deletelist.filter(id => id !== user.id);
      }
    }
    setDeleteUsersList(deletelist);
  }, [deleteUsersList])

  const handleCheckClick = (e, user) => {
    e.stopPropagation();
    checkRef.current(e, user)
  }

  checkRef.current = handleDeleteUsersToggle;


  const handleDeleteUsersBulk = async () => {
    let deletelist = [...deleteUsersList]
    deletelist = deletelist.filter((userId) => !userId.includes(userInfo?.data?.id)).map(id => ({ id }));
    const response = await dispatch(deleteUsersBulk({ users: deletelist }));
    if (response === 200) {
      setOpenConfirmModal(false);
      setOpenDeleteUserModal(false);
      successAlert(`${deletelist.length > 1 ? "Users" : "User"} deleted successfully`);
      dispatch(getOrganisationUsers(''));
      setDeleteUsersList([]);
      setPage(1);
    }
    else {
      failureAlert("Something went wrong!");
    }

  }

  const handleDownloadUsers = async () => {
    const response = await dispatch(downloadOrgUsers());
    if (response?.data?.data?.downloadUrl) {
      downloadFile(response.data.data.downloadUrl);
    }
    else
      failureAlert("Something went wrong!");
  }

  const handleBack = () => {
    history.push('/manage-users-team')
  }

  const checkAll = async (action) => {
    setSelectAllStatus(!selectAllStatus)
    let deletelist = [...deleteUsersList];
    if (action === "select total users") {
      if (deleteUsersList.length === (userInfo?.data?.role === "ORG_ADMIN" ? orgUsers?.userCountWithoutOwners
        : orgUsers?.paginationData?.totalEntries)) {
        let unSelectAllUsers = [...deleteUsersList]
        unSelectAllUsers?.forEach(id => {
          const index = deletelist.indexOf(id);
          if (index !== -1) {
            deletelist.splice(index, 1);
          }
        });
      }
      else {
        const { data: { data } } = await axiosApiInstance.get(`${BASE_URL}/api/organization/user?email=&limit&getAll=true`)
        // todo
        let totalUserList = [...orgUsers?.data, ...data]
        totalUserList.forEach((user) => {
          if (!deletelist.includes(user?.id)) {
            if (userInfo?.data?.role === "ORG_ADMIN") {
              if (user.role !== "ORG_SUPER_ADMIN") {
                deletelist.push(user.id);
              }
            } else {
              deletelist.push(user.id);
            }
          }
        })
      }
    }
    else if (!selectAllStatus) {
      orgUsers?.data?.forEach((user) => {
        if (!deletelist.includes(user?.id)) {
          deletelist.push(user.id);
        }
      })
    } else {
      orgUsers?.data?.forEach(user => {
        const index = deletelist.indexOf(user?.id);
        if (index !== -1) {
          deletelist.splice(index, 1);
        }
      });
    }
    setDeleteUsersList(deletelist);
  }
  const TableRow = React.memo(({ user }) => (
    <td className="delete-marks" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="animated-checkbox" onClick={e => handleDeleteUsersToggle(e, user)}>
        <div>
          <label class="checkbox-label">
            <input type="checkbox" class="checkbox-tow" checked={deleteUsersList.includes(user.id)} />
            <div class="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16px"
                viewBox="0 0 448 512"
              >
                <path
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                ></path>
              </svg>
            </div>
            <span class="check-box-container"></span>
          </label>
        </div>
      </div>
      {/* <input className="form-select" checked={deleteUsersList.includes(user.id)} onClick={e => handleDeleteUsersToggle(e, user)} type="checkbox" /> */}
    </td>
  ));

  return (
    <div>
      <Header profile />
      <main className="container conatiner-960 "
        style={{ width: '100%', maxWidth: '100%' }}
      >
        <div className="manageteam-wrapper">

          <div className="plandetail-premium flex flex-col">
          <div className="flex items-center w-[65px] cursor-pointer" onClick={handleBack}>
                    <BiSolidLeftArrow className='w-[18px] h-[18px] text-black' />
                    <span className=' font-sans text-[18px] text-black ml-1'>Back</span>
                </div>
            {/* <img src={arrowback} alt="back" onClick={handleBack} className='flex h-[30px] w-[30px]  hover:cursor-pointer mb-4' /> */}

            {/* <h5>User Data</h5> */}
          </div>
          <div className="flex items-center justify-center w-full">
            <h5 className=" text-[26px] md:text-[48px] lg:text-[48px] text-[#23282E] font-semibold">Manage User Data</h5>
          </div>

          {openDeleteUserModal && <ManageTeamDeleteUserModal toggle={openDeleteUserModal} user={deleteUser} handleDeleteUser={handleDeleteUsersBulk} setOpenDeleteUserModal={(data) => { if (!data) { setDeleteUsersList([]) }; setOpenDeleteUserModal(data); }} />}
          {openAddEditUserModal &&
            <ManageTeamAddEditUserModal
              modalid="add-user-modal"
              toggle={openAddEditUserModal}
              user={editUser}
              setOpenAddEditUserModal={setOpenAddEditUserModal}
              successAlert={successAlert}
            />}
          <ConfirmModal modalid="confirm-modal" toggle={openConfirmModal} setOpenConfirmModal={setOpenConfirmModal} title="Delete Users" question={`Are you sure you want to delete ${deleteUsersList?.length > 1 ? `${deleteUsersList.includes(userInfo?.data?.id) ? deleteUsersList.length - 1 : deleteUsersList.length} users` : "this user"}?`} confirmFunction={handleDeleteUsersBulk} />
          <LoadingComponent loaded={loaded} />
          <ToastContainer position="bottom-center" />
          <div className="teams-search msearch">
            <div className="mspan">
              <span className="teams-user">Users </span>
              <br />
              <span className="teams-user-count">{orgUsers?.paginationData?.totalEntries} of {myPlanDetails && myPlanDetails.data && myPlanDetails.data.planDetail
                && myPlanDetails.data.planDetail.plan.userLimit} users</span>
              {
                isUserLimitExist && (
                  <div className="flex bg-white rounded-[7px]">
                    <div className=" flex flex-row bg-[#E2556914] p-[10px] items-center justify-center w-full h-full text-[14px] text-[#E25569] rounded-[6px]">
                      <MdErrorOutline style={{ width: '19px', height: '19px', marginRight: '7px' }} />
                      You Have Exceeded Your User Limit
                    </div>
                  </div>
                )
              }

              {
                deleteUsersList.length !== 0 &&
                <>
                  <button onClick={() => checkAll('select total users')} className={`btn ms-2 ml-2 h-[34px] select-all-btn btn-secondry ${deleteUsersList.length > 0 ? "" : "invisible"}`}>{`${(userInfo?.data?.role === "ORG_ADMIN" ? deleteUsersList.length
                    === orgUsers?.userCountWithoutOwners
                    : deleteUsersList.length === orgUsers?.paginationData?.totalEntries) ? "Deselect" : "Select"} All ${(userInfo?.data?.role === "ORG_ADMIN" ? orgUsers?.userCountWithoutOwners : orgUsers?.paginationData?.totalEntries) - 1} Users`}</button>

                  <button onClick={e => checkAll()} className={`btn ms-2 ml-2 h-[36px] select-all-btn btn-secondry`}>{selectAllStatus ? "Deselect" : "Select"}  All</button>
                  <button className="btn btn-secondry" onClick={e => setOpenConfirmModal(true)}>Delete</button>
                </>
              }
            </div>
            <div className="download-container lg:items-center">
              {
                userInfo?.data?.role === Roles.ORG_SUPER_ADMIN &&
                <button className="btn btn-primary lg:download lg:mr-[20px] h-[48px] lg:h-[40px]" onClick={handleDownloadUsers}>Download Users</button>
              }
              <div className="input-icon search-bar mb-0">
                <img src={search} alt="search" />
                <input type="text" name="search" placeholder="Search" value={searchText} onChange={handleChange} />
              </div>
            </div>
          </div>
          {/* Desktop table */}
          <div className=" hidden lg:flex flex-col w-full ">
            <div className="flex items-center justify-start flex-row w-full h-[69px] bg-[#F8F9FD]  pl-[40px] mb-[7px]">
              <div className="flex items-center justify-start w-4/12">
                <span className="text-[20px] font-semibold text-[#23282E]">Full Name</span>
              </div>

              <div className="flex items-center justify-start w-3/12">
                <span className="text-[20px] font-semibold text-[#23282E]">Email ID</span>
              </div>

              <div className="flex items-center justify-start w-1/12">
                <span className="text-[20px] font-semibold text-[#23282E]">Role</span>
              </div>

              {
                deleteUsersList && deleteUsersList.length === 0 ? (
                  <div className="flex items-center justify-center w-4/12">
                    <span className="text-[20px] font-semibold text-[#23282E]">Action</span>
                  </div>

                ) : null
              }

              {
                deleteUsersList && deleteUsersList.length > 0 ? (<>
                  <div className="flex items-center justify-center w-4/12">
                    <button onClick={e => setOpenConfirmModal(true)} className={`btn h-[34px] text-xs-important btn-secondry ${deleteUsersList.length > 0 ? "" : "invisible"}`}>Delete</button>

                    <button onClick={() => checkAll()} className={`btn ms-2 ml-2 h-[34px] text-xs-important select-all-btn btn-secondry ${deleteUsersList.length > 0 ? "" : "invisible"}`}>{selectAllStatus ? "Deselect" : "Select"} All</button>
                    <button onClick={() => checkAll('select total users')} className={`btn ms-2 ml-2 h-[34px] text-xs-important select-all-btn btn-secondry ${deleteUsersList.length > 0 ? "" : "invisible"}`}>{`${(userInfo?.data?.role === "ORG_ADMIN" ? deleteUsersList.length
                      === orgUsers?.userCountWithoutOwners
                      : deleteUsersList.length === orgUsers?.paginationData?.totalEntries) ? "Deselect" : "Select"} All ${(userInfo?.data?.role === "ORG_ADMIN" ? orgUsers?.userCountWithoutOwners : orgUsers?.paginationData?.totalEntries) - 1} Users`}</button>

                  </div>
                </>)
                  : null
              }

            </div>
            {orgUsers?.paginationData?.totalEntries > 0 ? (orgUsers.data.sort((a, b) => a.role > b.role ? -1 : 1).map((user, index) => {
              return (
                <div className="flex flex-row w-full h-[69px] pl-[40px]"
                  style={{
                    border: '0.25px solid #707070',
                    borderRadius: '6px',
                    height: '69px',
                    marginTop: '7px',
                    marginBottom: '7px',
                    background:
                      deleteUsersList &&
                        deleteUsersList.length > 0 &&
                        (deleteUsersList.includes(user.id) &&
                          ((index > 0 && deleteUsersList.includes(orgUsers.data.sort((a, b) => (a.role > b.role ? -1 : 1))[index - 1]?.id)) ||
                            (index < orgUsers.data.length - 1 && deleteUsersList.includes(orgUsers.data.sort((a, b) => (a.role > b.role ? -1 : 1))[index + 1]?.id)))) ? '#F8F9FD' :
                        deleteUsersList.includes(user.id) &&
                          ((index > 0 && !deleteUsersList.includes(orgUsers.data.sort((a, b) => (a.role > b.role ? -1 : 1))[index - 1]?.id)) ||
                            (index < orgUsers.data.length - 1 && !deleteUsersList.includes(orgUsers.data.sort((a, b) => (a.role > b.role ? -1 : 1))[index + 1]?.id))) ? '#F8F9FD' :
                          '',
                    boxShadow:
                      deleteUsersList &&
                        deleteUsersList.length > 0 &&
                        (deleteUsersList.includes(user.id) &&
                          ((index > 0 && deleteUsersList.includes(orgUsers.data.sort((a, b) => (a.role > b.role ? -1 : 1))[index - 1]?.id)) ||
                            (index < orgUsers.data.length - 1 && deleteUsersList.includes(orgUsers.data.sort((a, b) => (a.role > b.role ? -1 : 1))[index + 1]?.id)))) ? 'rgba(0, 0, 0, 0.2) 0px 13px 13px' :
                        deleteUsersList.includes(user.id) &&
                          ((index > 0 && !deleteUsersList.includes(orgUsers.data.sort((a, b) => (a.role > b.role ? -1 : 1))[index - 1]?.id)) ||
                            (index < orgUsers.data.length - 1 && !deleteUsersList.includes(orgUsers.data.sort((a, b) => (a.role > b.role ? -1 : 1))[index + 1]?.id))) ? 'rgba(0, 0, 0, 0.2) 0px 0px 19px 1px' :
                          ''
                  }}
                >
                  <div className="flex items-center justify-start w-4/12">
                    <span className="text-[16px] font-sans font-semibold text-[#23282E] text-ellipsis overflow-hidden whitespace-nowrap">{`${user.firstName ? user.firstName : ""} ${user.lastName ? user.lastName : ""}`}</span>
                  </div>
                  <div className="flex items-center justify-start w-3/12">
                    <span className="text-[16px] font-sans font-semibold text-[#23282E] text-ellipsis overflow-hidden whitespace-nowrap">{user.email}</span>
                  </div>

                  <div className="flex items-center justify-start w-1/12">
                    <span className="text-[16px] font-sans font-semibold text-[#23282E] text-ellipsis overflow-hidden whitespace-nowrap">{user.role === Roles.ORG_SUPER_ADMIN ? "Owner" : user.role === Roles.ORG_ADMIN ? "Admin" : "Employee"}</span>
                  </div>
                  {
                    deleteUsersList && deleteUsersList.length === 0 ? (
                      <div className="flex items-center justify-around w-4/12">
                        {!(userInfo && userInfo.data && userInfo.data.role === Roles.ORG_ADMIN && user.role === Roles.ORG_SUPER_ADMIN) ?
                          userInfo && userInfo.data && user.email !== userInfo.data.email &&
                          <span className="flex w-[40%] items-center justify-evenly">
                            {/* <img src={editButton} alt="edit" className={`${deleteUsersList.length !== 0 ? "invisible" : ""} mx-2`} onClick={() => handleEdit(user)} /> */}
                            <div className="animated-btn-tooltip">
                              <button class="animated-editBtn"
                                onClick={() => handleEdit(user)}
                              >
                                <svg height="1em" viewBox="0 0 512 512">
                                  <path
                                    fill="#5F638A"
                                    d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                  ></path>
                                </svg>
                              </button>
                              <div className="hover-tooltip" role="tooltip">
                                <span>Edit</span>
                              </div>
                            </div>
                            {/* <img src={deleteButton} alt="delete" className={`${deleteUsersList.length !== 0 ? "invisible" : ""} mx-2`} onClick={() => handleDelete(user?.id)} /> */}

                            <div className="animated-btn-tooltip">
                              <button class="session-delete-button" onClick={() => handleDelete(user?.id)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 69 14"
                                  class="svgIcon bin-top"
                                >
                                  <g clip-path="url(#clip0_35_24)">
                                    <path
                                      fill="black"
                                      d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_35_24">
                                      <rect fill="white" height="14" width="69"></rect>
                                    </clipPath>
                                  </defs>
                                </svg>

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 69 57"
                                  class="svgIcon bin-bottom"
                                >
                                  <g clip-path="url(#clip0_35_22)">
                                    <path
                                      fill="black"
                                      d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_35_22">
                                      <rect fill="white" height="57" width="69"></rect>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>
                              <div className="hover-tooltip" role="tooltip">
                                <span>Delete</span>
                              </div>

                            </div>
                          </span>
                          : null
                        }
                        {
                          !(userInfo && userInfo.data && userInfo.data.role === Roles.ORG_ADMIN && user.role === Roles.ORG_SUPER_ADMIN) ?
                            userInfo && userInfo.data && user.email !== userInfo.data.email ? (
                              <div className="flex items-center justify-center w-[32px] h-[32px]">
                                <div className="animated-checkbox !cursor-default">
                                  <div>
                                    <label class="checkbox-label cursor-pointer">
                                      <input type="checkbox" class="checkbox-tow" checked={deleteUsersList.includes(user.id)} onChange={e => handleCheckClick(e, user)} />
                                      <div class="svg-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="16px"
                                          viewBox="0 0 448 512"
                                        >
                                          <path
                                            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                          ></path>
                                        </svg>
                                      </div>
                                      <span class="check-box-container"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ) : null
                            : null

                        }



                      </div>

                    ) :
                      null
                  }
                  {deleteUsersList && deleteUsersList.length !== 0 ? (
                    <div className=" flex items-center justify-center w-4/12">
                      {
                        !(userInfo && userInfo.data && userInfo.data.role === Roles.ORG_ADMIN && user.role === Roles.ORG_SUPER_ADMIN) ?
                          userInfo && userInfo.data && user.email !== userInfo.data.email ? (
                            <div className="flex items-center justify-center w-[32px] h-[32px] !cursor-default ">
                              <div className="animated-checkbox !cursor-default">
                                <div>
                                  <label class="checkbox-label !cursor-pointer">
                                    <input type="checkbox" class="checkbox-tow" checked={deleteUsersList.includes(user.id)} onChange={e => handleCheckClick(e, user)} />
                                    <div class="svg-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="16px"
                                        viewBox="0 0 448 512"
                                      >
                                        <path
                                          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                        ></path>
                                      </svg>
                                    </div>
                                    <span class="check-box-container"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : null
                          : null

                      }
                    </div>
                  )
                    : null
                  }
                </div>
              )
            }
            )
            )
              : null
            }

          </div>
          {/* Mobile view */}
          <div className="flex flex-col lg:hidden">
            {orgUsers?.paginationData?.totalEntries > 0 ? (orgUsers.data.sort((a, b) => a.role > b.role ? -1 : 1).map((user, index) => {

              return (
                <div className="team-m-card c-white-card" key={JSON.stringify(user)}>
                  <div className="team-m-top">
                    <div className="role">

                      <div className="teams-m-name">{`${user.firstName ? user.firstName : ""} ${user.lastName ? user.lastName : ""}`}</div>
                      <div className="team-m-user">{user.email}</div>
                      {/* <span>Role</span>
                      <div className="teams-m-title">{user.role === Roles.ORG_SUPER_ADMIN ? "Owner" : user.role === Roles.ORG_ADMIN ? "Admin" : "User"}</div> */}
                    </div>
                    <div className="action-group">
                      {
                        userInfo && userInfo.data && user.email !== userInfo.data.email &&
                        <>
                          <span>
                            {/* <img src={editButton} alt="edit" className={`${deleteUsersList.length !== 0 ? "invisible" : ""}`} onClick={() => handleEdit(user)} /> */}
                            <div className="animated-btn-tooltip">
                              <button class="animated-editBtn"
                                onClick={() => handleEdit(user)}
                              >
                                <svg height="1em" viewBox="0 0 512 512">
                                  <path
                                    fill="#5F638A"
                                    d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                  ></path>
                                </svg>
                              </button>
                              <div className="hover-tooltip" role="tooltip">
                                <span>Edit</span>
                              </div>
                            </div>
                          </span>

                          <span>
                            {/* <img src={deleteButton} alt="delete" className={`edit-btn ${deleteUsersList.length !== 0 ? "invisible" : ""}`} onClick={() => handleDelete(user)} /> */}
                            <div className="animated-btn-tooltip">
                              <button class="session-delete-button" onClick={() => handleDelete(user?.id)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 69 14"
                                  class="svgIcon bin-top"
                                >
                                  <g clip-path="url(#clip0_35_24)">
                                    <path
                                      fill="black"
                                      d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_35_24">
                                      <rect fill="white" height="14" width="69"></rect>
                                    </clipPath>
                                  </defs>
                                </svg>

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 69 57"
                                  class="svgIcon bin-bottom"
                                >
                                  <g clip-path="url(#clip0_35_22)">
                                    <path
                                      fill="black"
                                      d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_35_22">
                                      <rect fill="white" height="57" width="69"></rect>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>
                              <div className="hover-tooltip" role="tooltip">
                                <span>Delete</span>
                              </div>

                            </div>
                          </span>

                        </>
                      }
                    </div>
                  </div>

                  <div className="team-m-bottom"
                    style={
                      {
                        marginTop: userInfo && userInfo.data && user.email !== userInfo.data.email ? '0px' : '10px'

                      }
                    }
                  >
                    <div>

                      <div className={`flex items-end justify-center h-full w-full text-[16px] font-sans font-semibold `}>Role-{user.role === Roles.ORG_SUPER_ADMIN ? "Owner" : user.role === Roles.ORG_ADMIN ? "Admin" : "User"}</div>

                    </div>
                    {/* <div className="delete-marks"> */}
                    {
                      !(userInfo && userInfo.data && userInfo.data.role === Roles.ORG_ADMIN && user.role === Roles.ORG_SUPER_ADMIN) ?
                        userInfo && userInfo.data && user.email !== userInfo.data.email &&
                        // <input className="form-select" checked={deleteUsersList.includes(user.id)} onClick={e => handleDeleteUsersToggle(e, user)} type="checkbox" />
                        <TableRow user={user} />

                        : null
                    }
                    {/* </div> */}
                  </div>
                </div>
              );

            })) : (<>{!OrgUsers.loading && <div>No User found.</div>}</>)}



          </div>
          {/* Pagination */}
          {orgUsers?.paginationData?.totalEntries > 0 &&
            <div className="pagination-wrapper manage-team">
              <button
                className={(page > 1) ? "pagination-left enable" : "pagination-left"}
                onClick={() => {
                  if (page > 1) {
                    setPage(page - 1);
                    window.scrollTo(0, 0);
                  }
                }}
              >
                <img src={arrowleft} alt="arrow left" />
              </button>
              <div className="pagination-number">
                <h5>{page}</h5>
                <span>
                  of {orgUsers ? Math.ceil(orgUsers?.paginationData?.totalEntries / 12) : 1}{" "}
                  pages
                </span>
              </div>
              <button
                className={(page < Math.ceil(orgUsers?.paginationData?.totalEntries / 12)) ? "pagination-right enable" : "pagination-right"}
                onClick={() => {
                  if (page < Math.ceil(orgUsers?.paginationData?.totalEntries / 12)) {
                    setPage(page + 1);
                    window.scrollTo(0, 0);
                  }
                }}
              >
                <img src={arrowleft} alt="arrow right" />
              </button>
            </div>}
        </div>
      </main >
    </div >
  );
};
export default UsersData;
