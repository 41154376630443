import React, { useEffect, useState } from 'react';

import './leaderboard.css';
import morevertical from '../../assets/images/more-vertical.svg';
import { useSelector } from 'react-redux';
import { REPORT_LEADERBOARD_TABLE_DATA } from '../../constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import { BASE_URL, axiosApiInstance } from '../../helpers/helper';
import { BASE_URL, decryptData, encryptData, failureAlert, successAlert } from "../../helpers/helper";
import { center, flex, game, guest, report, state } from 'synonyms/dictionary';
import { useDispatch } from 'react-redux';
import { getGameReport, DownloadGuestSessionReport } from '../../redux/actions/reportsActions';
import axios from 'axios';


const LeaderBoard = (props) => {
  const mode = window.location.pathname.split("/")[1]
  const { gameReport } = useSelector(state => state.gameReport);
  const getRole = { 'ORG_SUPER_ADMIN': 'OWNER', 'ORG_ADMIN': 'ADMIN', 'EMPLOYEE': 'EMPLOYEE', 'USER': 'EMPLOYEE' };
  const [tableHeader, setTableHeader] = useState(REPORT_LEADERBOARD_TABLE_DATA);

  let [guestUserFieldData, setGuestUserFieldData] = useState([]);
  let [guestFields, setGuestFields] = useState([]);
  let [guestDataInfo, setGuestDataInfo] = useState([]);

  const [tableHead, setTableHead] = useState([]);
  const [tableBodyData, setTableBodyData] = useState([])

  const [rows, setRows] = useState([]);

  const dispatch = useDispatch()
  // const sessionDownloadLink = useSelector((state) => state.guestSessionReportLink);
  // const {downloadGuestSessionReportLink} = sessionDownloadLink


  useEffect(() => {

    if (mode === 'guest' && gameReport && gameReport.data && gameReport.data.guestFieldData.length > 0 && guestUserFieldData && guestFields) {
      const generateRows = () => {
        return guestUserFieldData.map((data, index) => {
          let row = {};
          guestFields.forEach(ele => {
            if (data.hasOwnProperty(ele)) {
              if (typeof data[ele] !== 'object') { // Assuming this is the correct check based on your code snippet
                row[ele] = data[ele];
              }
            } else if (data.customFields && data.customFields.hasOwnProperty(ele)) {
              row[ele] = data.customFields[ele];
            }
          });
          row.Rank = index + 1;
          row.Points = gameReport.data.sortReport[index]?.points;
          row.TimeTaken = gameReport.data.sortReport[index]?.time;
          row.UserType = getRole[gameReport.data.sortReport[index]?.user?.role] || 'GUEST USER';

          return row;
        });
      };

      setRows(generateRows());
    }

  }, [mode, gameReport, guestUserFieldData, guestFields])

  const getGuestSessionGameReportFileLink = async (e) => {
    // e.preventDefault();
    if (mode == "guest" && rows && rows.length > 0) {
      const response = await axios.post(BASE_URL + "/api/game-server/report/guest-session-game/report", { guestSessionGameLeaderBoard: rows });
      if (response.data.message === "REPORT FETCHED SUCCESSFULLY") {
        await dispatch(DownloadGuestSessionReport(response.data.data.downloadUrl));
      }
    }

  }

  useEffect(() => {
    if (mode == "guest" && rows && rows.length > 0) {
      getGuestSessionGameReportFileLink(rows)
    }

  }, [rows])


  useEffect(() => {
    if (mode == 'guest') {
      if (gameReport) {
        setGuestFields([])
        gameReport.data.session.guestUserFields.map((data) => {
          setGuestFields((perData) => [...perData, data.key])
        })
      }
    }
  }, [gameReport])

  useEffect(() => {
    if (mode == "guest" && gameReport && gameReport.data && gameReport.data.guestFieldData) {
      gameReport.data.guestFieldData.map((data) => {
        setGuestUserFieldData((perData) => [...perData, data])
      })

    }
  }, [gameReport])

  useEffect(() => {
    setGuestDataInfo([])

    guestUserFieldData.map((data, index) => {
      guestFields.map((ele, innIndex) => {

        if (data.hasOwnProperty(ele)) {
          if (typeof data[index] !== 'object') {

            setGuestDataInfo((perData) => [...perData, data[ele]])
          }
        }
        else {

          setGuestDataInfo((perData) => [...perData, data.customFields[ele]])
        }

      })
    })


  }, [guestUserFieldData, guestFields])


  return (
    <div>
      {/* table for desktop */}
      <div className="report-table leader-table overflow-x-auto">
        <table className="leader-board-table">
          <thead>
            <tr id='report-tb-head'>
              {/* <th>Player Name</th> */}
              {/* {mode === 'guest' ?
                tableHeader.map((it, index) => <th key={`table-row${index}`}>{it}</th>)
                : null} */}
              {/* {console.log("GAME REPORT: "+ JSON.stringify(gameReport.data.sortReport[0].user))} */}

              {/**dynamic guest user report coloumn start*/}

              {/* {
                mode === 'guest' && gameReport.data.guestFieldData && gameReport.data.guestFieldData.length > 0 ?
                  gameReport && gameReport.data && gameReport.data.session.guestUserFields.map((data, index) => (
                    <th key={`table-row${index}`}>{data.name}</th>
                  ))
                  : (<th>Player Name</th>)
              } */}

              {
                mode == 'guest' && gameReport && gameReport.data.guestFieldData && gameReport.data.guestFieldData.length > 0 ?
                  gameReport.data.session.guestUserFields.map((data, index) => (
                    <th key={`table-row${index}`}
                      style={{
                        display: data.name === "Device ID" ? 'none' : ''
                      }}
                    >{data.name}</th>
                  ))
                  : (<th>Player Name</th>)
              }

              <th>Scores</th>
              <th style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Time Taken</th>
              <th>User Type</th>
            </tr>
          </thead>
          <tbody>

            {
              mode === 'guest' && gameReport && gameReport.data && gameReport.data.guestFieldData.length > 0 && guestUserFieldData.map((data, index) => {
                return (<tr>
                  {
                    guestFields.map((ele, innIndex) => {

                      if (data.hasOwnProperty(ele)) {
                        if (typeof data[index] !== 'object') {
                          return (<td
                            style={{
                              display: gameReport.data.session.guestUserFields[innIndex].name === "Device ID" ? 'none' : ''
                            }}
                          >{data[ele]}</td>)
                        }

                      }
                      else {
                        return (<td>{data.customFields[ele]}</td>)

                      }

                    })
                  }
                  {
                    gameReport.data.sortReport && gameReport.data.sortReport.length > 0 ?
                      <>
                        <td>{gameReport.data.sortReport[index]?.points}</td>
                        <td style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {gameReport.data.sortReport[index]?.time}
                        </td>
                        <td>{getRole[gameReport.data.sortReport[index]?.user?.role] || 'GUEST USER'}</td>
                      </> : ''
                  }
                </tr>)
              })
            }

            {mode !== 'guest' && gameReport && gameReport.data && gameReport.data.sortReport ?
              gameReport.data.sortReport.map((report, index) => {
                return (
                  <tr>
                    <td style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}>
                      {(report?.user?.firstName) ? (
                        <div className="table-detail">
                          <div className="name-initial">
                            <span>{(report?.user?.firstName && report?.user?.firstName.slice) ? report?.user?.firstName.slice(0, 2).toUpperCase() : report?.user?.email && report?.user?.email.slice && report?.user?.email.slice(0, 2).toUpperCase()}</span>
                          </div>
                          <div className="table-desc">
                            <h4>{report?.user?.firstName && report?.user?.firstName !== "" ? report?.user?.firstName : report?.user?.email}</h4>
                          </div>
                        </div>
                      ) : null
                        // (
                        //   <div className="table-detail">
                        //     <div className="name-initial">
                        //       <span>{(report?.name?.slice) && report.name.slice(0, 2).toUpperCase()}</span>
                        //     </div>
                        //     <div className="table-desc">
                        //       <h4>{report.name}</h4>
                        //     </div>
                        //   </div>
                        // )
                      }
                    </td>

                    {/* <td><b>{index + 1}</b></td> */}
                    <td>{report?.points}</td>
                    {/* <td className="correct-ans">{report.correctAnswer ? report.correctAnswer : "---"}</td>
                    <td className="wrong-ans">{report.wrongAnswer ? report.wrongAnswer : "---"}</td>
                    <td className="un-ans">{report.unAnswered ? report.unAnswered : "---"}</td> */}
                    <td>
                      <div className="reports-dropdown-view" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {report?.time}
                        {/* <div className="reports-dropdown mshow">
                          <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src={morevertical} alt="dropdown" />
                          </button>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <h6>More info</h6>
                            <div className="correct-ans">
                              Correct Answer <span>{report.correctAnswer ? report.correctAnswer : "---"}</span>
                            </div>
                            <div className="wrong-ans">
                              Wrong Answer <span>{report.wrongAnswer ? report.wrongAnswer : "---"}</span>
                            </div>
                            <div className="un-ans">
                              Unaswered <span>{report.unAnswered ? report.unAnswered : "---"}</span>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </td>
                    <td>{getRole[report?.user?.role] || 'GUEST USER'}</td>
                  </tr>
                )
              }) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaderBoard;