import { axiosApiInstance, BASE_URL } from "../../helpers/helper";
import * as ActionTypes from "../constants/dateFilterConstants";


const getDateFilter = (startDate, endDate, filterType) => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_DATE_FILTER_REQUEST });
    try {
        if (startDate && endDate && filterType) {
            dispatch({ type: ActionTypes.GET_DATE_FILTER_SUCCESS, payload: { startDate, endDate, filterType }, });
        }
    } catch (error) {
        dispatch({ type: ActionTypes.GET_DATE_FILTER_FAIL, payload: "failed to get start and end date", });
    }
};


const getOrgGamesReports = (startDate, endDate, dateType) => async (dispatch) => {
    dispatch({ type: ActionTypes.GET_ORG_GAMES_REPORT_REQUEST });
    try {
        if (startDate && endDate && dateType) {
            const response = await axiosApiInstance.post(BASE_URL+"/api/game-server/report/org-games-reports",{startDate, endDate, dateType});
           dispatch({ type: ActionTypes.GET_ORG_GAMES_REPORT_SUCCESS, payload: response }); 
        }
    } catch (error) {
        dispatch({ type: ActionTypes.GET_ORG_GAMES_REPORT_FAIL, payload: "failed to get start and end date", });
    }
};


export {
    getDateFilter,
    getOrgGamesReports
}