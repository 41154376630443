import * as ActionTypes from "../constants/organizationLogoConstants";
import { axiosApiInstance, BASE_URL, encryptData } from "../../helpers/helper";




const getAllOrganizationLogos = (searchText) => async (dispatch) => {
 
  dispatch({ type: ActionTypes.FETCH_ORGANIZATIONLOGO_REQUEST });
  try {
    const { data } = await axiosApiInstance.get(
      BASE_URL + "/api/logos/all?search=" + searchText
    );
   
    dispatch({
      type: ActionTypes.FETCH_ORGANIZATIONLOGO_SUCCESS,
      payload: data.data
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.FETCH_ORGANIZATIONLOGO_FAILURE,
      payload: error.message
    });
  }
};





export { getAllOrganizationLogos };
