import { useState } from "react";

const HistoryPage = () => {
    const [historyTabType, setHistoryTabType] = useState("Product");

    return (
        <>
            <div className="container-fluid admin-dashboard-btn-group">
                <button
                    className="point-history-button"
                    onClick={() => {
                        setHistoryTabType("Products");
                    }}
                >
                    Product
                </button>
                <button
                    className="point-history-button"
                    onClick={() => {
                        setHistoryTabType("Claim History");
                    }}
                >
                    Claim History
                </button>
                <button
                    className="point-history-button"
                    onClick={() => {
                        setHistoryTabType("Points Utilized");
                    }}
                >
                    Points Utilized
                </button>
            </div>

            {historyTabType === "Products" && (<> This Is Product Page</>)}
            {historyTabType === "Claim History" && <>This Is Claim History Page</>}
            {historyTabType === "Points Utilized" && (
                <>This Is Points Utilized Page</>
            )}
        </>
    )
}
export default HistoryPage;