export const ORGANISATION_UPDATE_REQUEST="ORGANISATION_UPDATE_REQUEST";
export const ORGANISATION_UPDATE_SUCCESS="ORGANISATION_UPDATE_SUCCESS";
export const ORGANISATION_UPDATE_FAIL="ORGANISATION_UPDATE_FAIL";

export const ORGANISATION_LEAVE_REQUEST="ORGANISATION_LEAVE_REQUEST";
export const ORGANISATION_LEAVE_SUCCESS="ORGANISATION_LEAVE_SUCCESS";
export const ORGANISATION_LEAVE_FAIL="ORGANISATION_LEAVE_FAIL";

export const GET_ORGANISATION_USERS_REQUEST="GET_ORGANISATION_USERS_REQUEST";
export const GET_ORGANISATION_USERS_SUCCESS="GET_ORGANISATION_USERS_SUCCESS";
export const GET_ORGANISATION_USERS_FAIL="GET_ORGANISATION_USERS_FAIL";

export const DELETE_ORGANISATION_USER_REQUEST="DELETE_ORGANISATION_USER_REQUEST";
export const DELETE_ORGANISATION_USER_SUCCESS="DELETE_ORGANISATION_USER_SUCCESS";
export const DELETE_ORGANISATION_USER_FAIL="DELETE_ORGANISATION_USER_FAIL";

export const ORGANISATION_ADD_USER_REQUEST="ORGANISATION_ADD_USER_REQUEST";
export const ORGANISATION_ADD_USER_SUCCESS="ORGANISATION_ADD_USER_SUCCESS";
export const ORGANISATION_ADD_USER_FAIL="ORGANISATION_ADD_USER_FAIL";

export const ORGANISATION_EDIT_USER_REQUEST="ORGANISATION_EDIT_USER_REQUEST";
export const ORGANISATION_EDIT_USER_SUCCESS="ORGANISATION_EDIT_USER_SUCCESS";
export const ORGANISATION_EDIT_USER_FAIL="ORGANISATION_EDIT_USER_FAIL";

export const ORGANISATION_DELETE_REQUEST="ORGANISATION_DELETE_REQUEST";
export const ORGANISATION_DELETE_SUCCESS="ORGANISATION_DELETE_SUCCESS";
export const ORGANISATION_DELETE_FAIL="ORGANISATION_DELETE_FAIL";

export const ORGANISATION_GET_ALL_ORGANISATION_REQUEST = "ORGANISATION_GET_ALL_ORGANISATION_REQUEST";
export const ORGANISATION_GET_ALL_ORGANISATION_SUCCESS = "ORGANISATION_GET_ALL_ORGANISATION_SUCCESS";
export const ORGANISATION_GET_ALL_ORGANISATION_FAIL = "ORGANISATION_GET_ALL_ORGANISATION_FAIL";

export const ORGANISATION_GET_USER_BY_ORGANISATION_REQUEST = 'ORGANISATION_GET_USER_BY_ORGANISATION_REQUEST';
export const ORGANISATION_GET_USER_BY_ORGANISATION_SUCCESS = 'ORGANISATION_GET_USER_BY_ORGANISATION_SUCCESS';
export const ORGANISATION_GET_USER_BY_ORGANISATION_FAIL = 'ORGANISATION_GET_USER_BY_ORGANISATION_FAIL';

export const ORGANISATION_TOGGLE_ORGANISATION_REQUEST = "ORGANISATION_TOGGLE_ORGANISATION_REQUEST";
export const ORGANISATION_TOGGLE_ORGANISATION_SUCCESS = "ORGANISATION_TOGGLE_ORGANISATION_SUCCESS";
export const ORGANISATION_TOGGLE_ORGANISATION_FAIL = "ORGANISATION_TOGGLE_ORGANISATION_FAIL";

export const ORGANISATION_TOGGLE_ORG_USER_REQUEST = "ORGANISATION_TOGGLE_ORG_USER_REQUEST";
export const ORGANISATION_TOGGLE_ORG_USER_SUCCESS = "ORGANISATION_TOGGLE_ORG_USER_SUCCESS";
export const ORGANISATION_TOGGLE_ORG_USER_FAIL = "ORGANISATION_TOGGLE_ORG_USER_FAIL";

export const ORGANISATION_CONTACT_ORG_ADMIN_REQUEST = "ORGANISATION_CONTACT_ORG_ADMIN_REQUEST";
export const ORGANISATION_CONTACT_ORG_ADMIN_SUCCESS = "ORGANISATION_CONTACT_ORG_ADMIN_SUCCESS";
export const ORGANISATION_CONTACT_ORG_ADMIN_FAIL = "ORGANISATION_CONTACT_ORG_ADMIN_FAIL";

export const ORGANISATION_CREATE_SAMPLE_CSV_REQUEST = "ORGANISATION_CREATE_SAMPLE_CSV_REQUEST";
export const ORGANISATION_CREATE_SAMPLE_CSV_SUCCESS = "ORGANISATION_CREATE_SAMPLE_CSV_SUCCESS";
export const ORGANISATION_CREATE_SAMPLE_CSV_FAIL = "ORGANISATION_CREATE_SAMPLE_CSV_FAIL";

export const  ADD_ORGANISATION_BY_ADMIN_REQUEST="ADD_ORGANISATION_BY_ADMIN_REQUEST";
export const  ADD_ORGANISATION_BY_ADMIN_SUCCESS="ADD_ORGANISATION_BY_ADMIN_SUCCESS";
export const  ADD_ORGANISATION_BY_ADMIN_FAIL="ADD_ORGANISATION_BY_ADMIN_FAIL";

export const GET_ORGANISATION_MASTER_OTP_REQUEST="GET_ORGANISATION_MASTER_OTP_REQUEST";
export const GET_ORGANISATION_MASTER_OTP_SUCCESS="GET_ORGANISATION_MASTER_OTP_SUCCESS";
export const GET_ORGANISATION_MASTER_OTP_FAIL="GET_ORGANISATION_MASTER_OTP_FAIL";

export const UPDATE_MASTER_OTP_REQUEST="UPDATE_MASTER_OTP_REQUEST";
export const UPDATE_MASTER_OTP_SUCCESS="UPDATE_MASTER_OTP_SUCCESS";
export const UPDATE_MASTER_OTP_FAIL="UPDATE_MASTER_OTP_FAIL";

export const ADD_USERS_WITH_DUMMY_EMAIL_REQUEST="ADD_USERS_WITH_DUMMY_EMAIL_REQUEST";
export const ADD_USERS_WITH_DUMMY_EMAIL_SUCCESS="ADD_USERS_WITH_DUMMY_EMAIL_SUCCESS";
export const ADD_USERS_WITH_DUMMY_EMAIL_FAIL="ADD_USERS_WITH_DUMMY_EMAIL_FAIL";
